import { Typography, styled } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const TitleStyle = styled('div')(({ theme }) => ({
    '& img': {
        height: 30,
    },
    '& h6': {
        fontWeight: '700',
        margin: '12px 0 0',
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            lineHeight: '24px',
        },
        color: theme.palette.primary.main,
    },
    '& .MuiTypography-subtitle1': {
        fontFamily: 'Poppins',
        fontWeight: '400',
        width: '89%',
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            lineHeight: '22px',
        },
        color: theme.palette.primary.contrastText,
    },
}));

export default function headingCardStyle(props) {
    return (
        <TitleStyle>
            <ThemeProvider theme={theme}>
                <img src={props.iconName} alt="" />
                <Typography py={2} variant="h6">
                    {props.titleLine}
                </Typography>
                <Typography variant="subtitle1">{props.contentLine}</Typography>
            </ThemeProvider>
        </TitleStyle>
    );
}
