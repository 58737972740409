import {createContext, useState} from 'react';

export const FormContext = createContext();

const FormContextProvider = ({ children }) => {
    const [categoriesMasterData, setCategoriesMasterData] = useState([]);
    const [personalDetails, setPersonalDetails]= useState({
        username: {
            value: '',
            error: ''
        },
        mobileNumber: {
            value: '',
            error: ''
        },
        country: {
            value: '',
            error: ''
        },
        state: {
            value: '',
            error: ''
        },
        city: {
            value: '',
            error: ''
        },
        houseHoldPeople: {
            value: 1,
            error: ''
        },
        currency: {
            value: '',
            error: ''
        },
        gender: {
            value: '',
            error: ''
        },
        ageGroup: {
            value: '',
            error: ''
        }
    });
    const [openAccordionsStep3, setOpenAccordionsStep3] = useState(['Assets']);
    const [openAccordionsStep4, setOpenAccordionsStep4] = useState(['Assets']);
    const [openAccordionsStep5, setOpenAccordionsStep5] = useState(['Income/Cash Inflows']);
    const [linkedInstitutions, setLinkedInstitutions] = useState([]);
    const [yodleeAccessToken, setYodleeAccessToken] = useState(null);

    const formContextValues = {   
	    categoriesMasterData,
        setCategoriesMasterData,
        setPersonalDetails,
        personalDetails,
        openAccordionsStep3,
        setOpenAccordionsStep3,
        openAccordionsStep4,
        setOpenAccordionsStep4,
        openAccordionsStep5,
        setOpenAccordionsStep5,
        linkedInstitutions,
        setLinkedInstitutions,
        yodleeAccessToken,
        setYodleeAccessToken,
        addCategory: (categoryGroupId) => {
            const categoryGroups = [...categoriesMasterData];
            const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
            if (catGroup) {
                let position = 1;
                if (catGroup.categories?.length) {
                    position = catGroup.categories.length + 1;
                }
                const tempCategoryObj = {
                    id: Math.floor(100000000 + Math.random() * 900000000),
                    name: '',
                    isDiscretionaryDefaultValue: false,
                    isSelected: true,
                    isUserCategory: true,
                    isEdited: true,
                    isSynced: false,
                    position,
                    subCategories: [{
                        id: Math.floor(100000000 + Math.random() * 900000000),
                        isSelected: true,
                        isEdited: true,
                        isSynced: false,
                        name: 'Uncategorized',
                        isDefault: true,
                        position: 0,
                        isUpdatedManually: false,
                        budget: '',
                        isDiscretionary: false
                    }, {
                        id: Math.floor(100000000 + Math.random() * 900000000),
                        isSelected: true,
                        isEdited: true,
                        isSynced: false,
                        name: '',
                        position: 1,
                        isUpdatedManually: false,
                        budget: '',
                        isDiscretionary: false
                    }]
                };
                if (catGroup.name === 'Expenses/Cash Outflows') {
                    const catLoanPaymentIndex = catGroup.categories.findIndex((cat) => cat.name === 'Loan payments');
                    if (catLoanPaymentIndex > -1) {
                        const catLoanPayment = catGroup.categories[catLoanPaymentIndex];
                        tempCategoryObj.position = catLoanPayment.position;
                        catLoanPayment.position += 1;
                        catLoanPayment.isEdited = true;
                        catGroup.categories.splice(catLoanPaymentIndex, 0, tempCategoryObj);
                    }
                } else {
                    catGroup.categories.push(tempCategoryObj);
                }   
            }
            setCategoriesMasterData(categoryGroups);
        },
        updateCategoryData: (categoryGroupId, tempCatId, key, value) => {
            const categoryGroups = [...categoriesMasterData];
            const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
            if (catGroup) {
                const category = catGroup.categories?.find(cat => cat.id === tempCatId);
                if (category) {
                    category[key] = value;
                    if (key === 'name') {
                        category.error = '';
                        if (!category.isSynced && category.subCategories?.length === 1 && !category.subCategories?.name) {
                            category.subCategories[0].name = value;
                        }
                    }
                    if (key === 'isSelected') {
                        if (category.subCategories?.length) {
                            category.subCategories.forEach((subCategory) => {
                                subCategory.isSelected = value;
                                subCategory.isEdited = true;
                            });
                        }
                    }
                    category.isEdited = true;
                }
            }
            setCategoriesMasterData(categoryGroups);
        },
        addSubCategory: (categoryGroupId, catId) => {
            const categoryGroups = [...categoriesMasterData];
            const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
            if (catGroup) {
                const category = catGroup.categories?.find(cat => cat.id === catId);
                if (category) {
                    let position = 1;
                    if (category.subCategories?.length) {
                        position = category.subCategories.length + 1;
                    }
                    category.subCategories.push({
                        id: new Date().getTime(),
                        isSelected: true,
                        isEdited: true,
                        isSynced: false,
                        name: category.name,
                        position,
                        isUpdatedManually: category.isUpdatedManually,
                        budget: '',
                        isDiscretionary: category.isDiscretionaryDefaultValue
                    });
                }
            }
            setCategoriesMasterData(categoryGroups);
        },
        updateSubCategoryData: (categoryGroupId, catId, subCatId, key, value) => {
            const categoryGroups = [...categoriesMasterData];
            const catGroup = categoryGroups.find((cg) => cg.id === categoryGroupId);
            if (catGroup) {
                const category = catGroup.categories?.find(cat => cat.id === catId);
                if (category) {
                    const subCat = category.subCategories?.find(cat => cat.id === subCatId);
                    if (subCat) {
                        subCat[key] = value;
                        if (key === 'name') {
                            subCat.error = '';
                        }
                        subCat.isEdited = true;
                    }
                }
            }
            setCategoriesMasterData(categoryGroups);
        },
        updatePersonalDetails: (key, value) => {
            const clonePersonalDetails = {...personalDetails}
            clonePersonalDetails[key] = {
                value,
                error: ''
            };
            setPersonalDetails(clonePersonalDetails);
        },
        onCategoryAccordionClick: (panelName) => (event, isExpanded) => {
            const clonedAccordion = [...openAccordionsStep3];
            if (isExpanded) {
                clonedAccordion.push(panelName);
            } else {
                const categoryIndex = clonedAccordion.indexOf(panelName);
                if (categoryIndex > -1) {
                    clonedAccordion.splice(categoryIndex, 1);
                }
            }
            setOpenAccordionsStep3(clonedAccordion);
        },
        onSubCategoryAccordionClick: (panelName) => (event, isExpanded) => {
            const clonedAccordion = [...openAccordionsStep4];
            if (isExpanded) {
                clonedAccordion.push(panelName);
            } else {
                const categoryIndex = clonedAccordion.indexOf(panelName);
                if (categoryIndex > -1) {
                    clonedAccordion.splice(categoryIndex, 1);
                }
            }
            setOpenAccordionsStep4(clonedAccordion);
        }
    }; 	
    return (
        <FormContext.Provider value={formContextValues}>
            {children}
        </FormContext.Provider>
    );
}

export default FormContextProvider;