import { Typography, Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { getHomeInfoData } from './constant';
import { BannerImg, BottomLeftBox, BottomLeftBoxOne, BottomLeftBoxSec, BottomLeftBoxThe, BottomRightBox, BottomRightBoxOne, BottomRightBoxSec, Headline, ListItem, SaveSection, SubPoints, ThinkSection, TopLeftBox, TopLeftBoxOne, TopLeftBoxSec, TopRightBox, TopRightBoxOne, TopRightBoxSec } from './styled-components';

export default function Info() {
    const { t } = useTranslation();
    const homeInfo = getHomeInfoData(t);
    return (
        <>
            <SaveSection>
                <BannerImg alt="homeInfo" src="/static/homeImg/homeInfo.png" />
                <>
                    <Grid justifyContent="flex-start" alignItems="center" container spacing={2} py={20}>
                        <Grid item md={6} xs={12} />
                        <Grid item md={5} xs={12} mx={4}>
                            <Headline paragraph variant="subtitle1">
                                <Trans i18nKey="home-page.home-info-subtitle-1">
                                Why couldn’t there be an app that <strong>saves you time and effort</strong> by:
                                </Trans>
                
                            </Headline>
                            <ListItem>
                                {homeInfo.map((info) => (
                                    <li key={info.id}>
                                        <SubPoints gutterBottom variant="subtitle1">
                                            {info.text}
                                        </SubPoints>
                                    </li>
                                ))}
                            </ListItem>
                        </Grid>
                    </Grid>
                </>
            </SaveSection>
            <Grid justifyContent="flex-start" alignItems="center" container spacing={2} py={4} pb={10}>
                <Grid item xs={12}>
                    <ThinkSection>
                        <TopLeftBox>
                            <TopLeftBoxOne />
                            <TopLeftBoxSec />
                        </TopLeftBox>
                        <TopRightBox>
                            <TopRightBoxOne />
                            <TopRightBoxSec src="/static/homeImg/rectRightTopImg.png" alt="" />
                        </TopRightBox>
                        <BottomLeftBox>
                            <BottomLeftBoxThe />
                            <BottomLeftBoxSec src="/static/homeImg/rectBottomLeft.jpg" alt="" />
                            <BottomLeftBoxOne />
                        </BottomLeftBox>
                        <BottomRightBox>
                            <BottomRightBoxOne />
                            <BottomRightBoxSec />
                        </BottomRightBox>
                        <Typography variant="subtitle1">
                            {t('home-page.home-info-subtitle-2')}
                        </Typography>
                        <Typography variant="h3"> {t('home-page.home-info-subtitle-3')}</Typography>
                    </ThinkSection>
                </Grid>
            </Grid>
        </>
    );
}
