// @mui
import { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
// components
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, MidSideColumn, TotalFlow, BudgetInput, ButtonText, AutoFieldStyle, BackButtonStyle, BudgetPercentageInput, TotalFlowValueSubColumn, TotalAmountDiv, CalculatorPriceInputs, LightTooltip, InfoIcon } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol, calcPMT } from '../../../utils/calCommonFunction';

export default function CalculatorsPage() {
    const [mortgageAmount, setMortgageAmount] = useState("");
    const [originalAmortization, setOriginalAmortization] = useState("");
    const [annualInterest, setAnnualInterest] = useState("");
    const [compoundingFrequency, setCompoundingFrequency] = useState("");
    const [monthlyPayment, setMonthlyPayment] = useState("");
    const [mortgageStartDate, setMortgageStartDate] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [additionalPayment, setAdditionalPayment] = useState("");
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const compoundingFrequencyOptions = [
        { label: 'Annual' },
        { label: 'Semi-annual' },
    ]

    const getMortgagePayment = async () => {
        const getMorgageData = await calculator.getUserCalculator("mortgage-prepayment");
        if (getMorgageData.data) {
            const morgageJsonData = getMorgageData.data?.calcJson;
            setMortgageAmount(morgageJsonData.mortgage_amount);
            setOriginalAmortization(morgageJsonData.original_amortization);
            setAnnualInterest(morgageJsonData.annual_interest)
            setCompoundingFrequency(morgageJsonData.compounding_frequency);
            setMonthlyPayment(morgageJsonData.monthly_payment);
            setMortgageStartDate(morgageJsonData.mortgage_start_date);
            setCurrentDate(morgageJsonData.current_date)
            setAdditionalPayment(morgageJsonData.additional_payment)
        }
    }

    useEffect(() => {
        getMortgagePayment();
    }, []);

    const getMonthDifference = (startDateStr, endDateStr) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }

    const mortgagePrePaymentDetails = () => {
        if (mortgageAmount !== "" && originalAmortization !== "") {
            let rate = (Number(annualInterest) / (12 * 100));
            if (compoundingFrequency === 'Semi-annual') {
                rate = (((Number(annualInterest) / (2 * 100)) + 1) ** (1 / 6)) - 1;
            }
            const monthlyMortgage = calcPMT (rate, (Number(originalAmortization) * 12), Number(removeNumberFormatting(mortgageAmount)) * -1, 0).toFixed(2);
            const totalPaymt = ((Number(removeNumberFormatting(monthlyMortgage)) * (Number(originalAmortization)) * 12));
            const interestMortgage = (totalPaymt - Number(removeNumberFormatting(mortgageAmount)).toFixed(2));
            const beforeAdditionPay = Number(totalPaymt).toLocaleString("en-US", { minimumFractionDigits: 2 })
            const beforeInterestValue = Math.round(Number(interestMortgage)).toLocaleString("en-US", { minimumFractionDigits: 2 })
            const beforeTotalPayment = Math.round((Number(totalPaymt))).toLocaleString("en-US", { minimumFractionDigits: 2 });

            const monthlyPayment= Number(monthlyMortgage).toLocaleString("en-US", { minimumFractionDigits: 2 });
            const beforeTotal = Number(removeNumberFormatting(mortgageAmount)).toLocaleString("en-US", { minimumFractionDigits: 2 });
            const beforeInterestOver=beforeInterestValue;

            const monthDifference = getMonthDifference(mortgageStartDate, currentDate);
            let monthlyMortgageTemp = Number(removeNumberFormatting(monthlyMortgage));
            let interest = 0;
            let principal = 0;
            let balance = Number(removeNumberFormatting(mortgageAmount));
            const additionalPay = Number(removeNumberFormatting(additionalPayment));
            
            // Monthly payment value 
            let totalMonthly = 0;
            let totalIntrest1 = 0;
            let totalPrincipal = 0;
            let monthCounter = 1;

            do {
                interest = rate * balance;
                principal = monthlyMortgageTemp - interest;
                if (monthCounter === monthDifference) {
                    balance -= additionalPay;
                }
                balance -= principal;

                totalMonthly += monthlyMortgageTemp;
                totalIntrest1 += interest;
                totalPrincipal += principal;

                if (balance < monthlyMortgageTemp) {
                    monthlyMortgageTemp = balance;
                }
                monthCounter += 1;
            } while (principal > 1);
            const afterTotal = Number(removeNumberFormatting(mortgageAmount)).toLocaleString("en-US", { minimumFractionDigits: 2 });
            const afterInterestOver= Number(removeNumberFormatting(additionalPayment)) ? Math.round(Number(totalIntrest1.toFixed(2))).toLocaleString("en-US", { minimumFractionDigits: 2 }) : beforeInterestOver;
            const afterTotalPayment = Number(removeNumberFormatting(additionalPayment)) ? Math.round(Number(Number(totalMonthly + additionalPay).toFixed(2))).toLocaleString("en-US", { minimumFractionDigits: 2 }) : beforeTotalPayment;
            const totsavings = Number(removeNumberFormatting(beforeAdditionPay)) - (totalMonthly + additionalPay);
            const totSavingsIntrest = Number(removeNumberFormatting(beforeInterestValue)) - totalIntrest1;
            const totalPaymentSavings= Number(0).toLocaleString("en-US", { minimumFractionDigits: 2 });
            const totalInterestSavings= Number(removeNumberFormatting(additionalPayment)) ? Math.round(Number(Number(totSavingsIntrest).toFixed(2))).toLocaleString("en-US", { minimumFractionDigits: 2 }): '0.00';
            const totalPayment = Number(removeNumberFormatting(additionalPayment)) ? Math.round(Number(Number(totSavingsIntrest).toFixed(2))).toLocaleString("en-US", { minimumFractionDigits: 2 }) : '0.00';
            return { 
                afterTotal, 
                afterInterestOver, 
                totalPaymentSavings, 
                totalInterestSavings, 
                monthlyPayment, 
                beforeTotal, 
                beforeInterestOver, 
                totalPayment, 
                beforeTotalPayment, 
                afterTotalPayment 
            }   
        }
    }

    const mortgagePrePaymentObj=mortgagePrePaymentDetails();

    const resetFields = () => {
        setMortgageAmount("");
        setOriginalAmortization("");
        setCompoundingFrequency("")
        setMortgageStartDate("");
        setCurrentDate("");
        setAdditionalPayment("");
        setAnnualInterest("");
    }

    const saveLoanData = async () => {
        try {
            const postData = {
                "mortgage_amount": mortgageAmount,
                "original_amortization": originalAmortization,
                "annual_interest": annualInterest,
                "compounding_frequency": compoundingFrequency,
                "monthly_payment": monthlyPayment,
                "mortgage_start_date": mortgageStartDate,
                "current_date": currentDate,
                "additional_payment": additionalPayment
            }
            const loanDataObj = {
                "calcType": "mortgage-prepayment",
                "calcJson": postData
            };
            await calculator.addUserCalculator(loanDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'mortgage_start_date') {
            setMortgageStartDate(value);
        } else if (name === 'current_date') {
            setCurrentDate(value);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Title": "",
                "Description": "Descriptiont",
                "value": "Value"
            },
            {
                "Title": "",
                "Description": "Mortgage amount",
                "value": `${currencySymbol} ${mortgageAmount}`
            },
            {
                "Title": "",
                "Description": "Original amortization period in years",
                "value": originalAmortization
            },
            {
                "Title": "",
                "Description": "Annual interest rate",
                "value": annualInterest
            },
            {
                "Title": "",
                "Description": "Compounding frequency",
                "value": compoundingFrequency
            },
            {
                "Title": "",
                "Description": "Monthly Payment",
                "value": `${currencySymbol} ${mortgagePrePaymentObj?.monthlyPayment}`
            },
            {
                "Title": "",
                "Description": "Mortgage start date",
                "value": mortgageStartDate
            },
            {
                "Title": "",
                "Description": "Additional payment date",
                "value": currentDate
            },
            {
                "Title": "",
                "Description": "Additional payment amount",
                "value": `${currencySymbol} ${additionalPayment}`
            },
            {
                "Title": "",
                "Description": "",
                "value": ""
            },
            {
                "Title": "Payment Summary",
                "Description": "",
                "value": ""
            },
            {
                "Title": "",
                "Description": "",
                "value": ""
            },
            {
                "Title": "",
                "Description": "Total payments",
                "value": "Interest Over Life"
            },
            {
                "Title": "Before additional payment",
                "Description": `${currencySymbol} ${mortgagePrePaymentObj?.beforeTotal}`,
                "value": `${currencySymbol} ${mortgagePrePaymentObj?.beforeInterestOver}`
            },
            {
                "Title": "After additional payment",
                "Description": `${currencySymbol} ${mortgagePrePaymentObj?.afterTotal}`,
                "value": `${currencySymbol} ${mortgagePrePaymentObj?.afterInterestOver}`
            },
            {
                "Title": "Total saving",
                "Description": `${currencySymbol} ${mortgagePrePaymentObj?.totalPaymentSavings}`,
                "value": `${currencySymbol} ${mortgagePrePaymentObj?.totalInterestSavings}`
            }
        ];
        const style = [
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A11',
                "style": EXCEL_STYLE.boldColorHrVr
            },
            {
                "col": 'B13',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'C13',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'A16',
                "style": EXCEL_STYLE.boldColor
            },
        ]
        const cols = [{ width: 34 }, { width: 30 }, { width: 20 }];
        const merges = [
            // { s: { r: 5, c: 0 }, e: { r: 5, c: 2 } },

        ]
        downloadExcelHeader(postData, style, cols, merges, 'MortgagePrepayment', 2, ['C'], true);
    }

    return (
        <Page title={t('mortgage-prepayment.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('mortgage-prepayment.go-back')}
                    </BackButtonStyle>
                    <Title pt={5} title={t('mortgage-prepayment.calculator')} subtitle={t('mortgage-prepayment.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('mortgage-prepayment.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t('mortgage-prepayment.mortgage-amount')}
                                                <LightTooltip title={
                                                    <>
                                                        <p>{t('mortgage-prepayment.mortgage-amount-tool-tip')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" style={{ marginLeft: 2 }} />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="mortgage_amount"
                                                    value={mortgageAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setMortgageAmount(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setMortgageAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.amortization-period')}</td>
                                            <BudgetInput>
                                                <Input
                                                    type='number'
                                                    placeholder="0"
                                                    name="original_amortization"
                                                    value={originalAmortization}
                                                    onChange={(e) => {
                                                        setOriginalAmortization(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.annual-interest')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="annual_interest"
                                                    value={annualInterest}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        setAnnualInterest(value);
                                                    }}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('mortgage-prepayment.compounding-frequency')}
                                                <LightTooltip title={
                                                    <>
                                                        <p>{t('mortgage-prepayment.compounding-frequency-tool-tip')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" style={{ marginLeft: 2 }} />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <AutoFieldStyle
                                                    id="tags-outlined"
                                                    options={compoundingFrequencyOptions}
                                                    value={compoundingFrequency}
                                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                                    disableClearable
                                                    name="compounding_frequency"
                                                    onChange={(e, val) => {
                                                        setCompoundingFrequency(val.label);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" className="dropMenu" />
                                                    )}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.monthly-payment')}</td>
                                            <td><CalculatorPriceInputs>{currencySymbol}<span> {mortgagePrePaymentObj?.monthlyPayment ==="NaN"?'0.00': mortgagePrePaymentObj?.monthlyPayment}</span></CalculatorPriceInputs></td>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.mortgage-start-date')}</td>
                                            <BudgetInput>
                                                <Input
                                                    type='date'
                                                    placeholder="0"
                                                    name="mortgage_start_date"
                                                    value={mortgageStartDate}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.current-date')}</td>
                                            <BudgetInput>
                                                <Input
                                                    type='date'
                                                    placeholder="0"
                                                    name="current_date"
                                                    value={currentDate}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('mortgage-prepayment.additional-payment')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="additional_payment"
                                                    value={additionalPayment}
                                                    onBlur={(e)=>{
                                                        setAdditionalPayment(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setAdditionalPayment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <th>Payment Summary</th>
                                        <th>{''}</th>
                                        <th>{''}</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <th>{''}</th>
                                        <MidSideColumn>{t('mortgage-prepayment.total-payment')}</MidSideColumn>
                                        <MidSideColumn>{t('mortgage-prepayment.principal')}</MidSideColumn>
                                        <MidSideColumn>{t('mortgage-prepayment.interest-over')}</MidSideColumn>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>{t('mortgage-prepayment.before-payment')}</td>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.beforeTotalPayment}</MidSideColumn>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.beforeTotal}</MidSideColumn>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00': mortgagePrePaymentObj?.beforeInterestOver}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('mortgage-prepayment.after-payment')}</td>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.afterTotalPayment}</MidSideColumn>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00': mortgagePrePaymentObj?.afterTotal}</MidSideColumn>
                                        <MidSideColumn>{currencySymbol} {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.afterInterestOver}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <TotalFlow>{t('mortgage-prepayment.total-saving')}</TotalFlow>
                                        <TotalFlowValueSubColumn><TotalAmountDiv>{currencySymbol}<span> {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.totalPayment}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                        <TotalFlowValueSubColumn><TotalAmountDiv>{currencySymbol}<span> {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.totalPaymentSavings}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                        <TotalFlowValueSubColumn><TotalAmountDiv>{currencySymbol}<span> {mortgagePrePaymentObj===undefined?'0.00':mortgagePrePaymentObj?.totalInterestSavings}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>This is the amount you will save over the life of the mortgage.</td>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('mortgage-prepayment.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('mortgage-prepayment.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={saveLoanData}
                        >
                            {t('mortgage-prepayment.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('mortgage-prepayment.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('mortgage-prepayment.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
