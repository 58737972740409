import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getImgIcon = (name) => <img className="iconImgHub" src={name} alt={name} width={21} height={21} />;

const navConfig = [
    {
        title: 'Hub',
        path: '/',
        icon: getIcon('ant-design:home-filled'),
    },
    {
        title: 'Transactions',
        path: '/downloaded-transactions',
        icon: getIcon('el:download-alt'),
    },
    {
        title: 'Other Transactions',
        path: '/manual-transactions',
        icon: getImgIcon('/static/drawer/manual.png'),
    },
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: getImgIcon('/static/drawer/dashboard.png'),
    },
    {
        title: 'To Do’s',
        path: '/todos',
        icon: getIcon('ci:list-check'),
    },
    {
        title: 'Key Dates',
        path: '/key-dates',
        icon: getImgIcon('/static/drawer/keydate.png'),
    },
    {
        title: 'Calculators',
        path: '/calculators',
        icon: getImgIcon('/static/drawer/calculator.png'),
    },
    {
        title: 'Knowledge Base',
        path: '/knowledge-base',
        icon: getIcon('fluent:brain-circuit-20-filled'),
    },
    {
        title: 'Forums',
        path: '/forum-categories',
        icon: getImgIcon('/static/drawer/forum.png'),
    },
    {
        title: 'Suggestion Box',
        path: '/suggestion-box',
        icon: getImgIcon('/static/drawer/suggestionBox.png'),
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        icon: getIcon('raphael:mail'),
    },
    {
        title: 'Favourite websites',
        path: '/favorite-websites',
        icon: getIcon('ant-design:appstore-filled'),
    },
    {
        title: 'FAQs',
        path: '/faqs',
        icon: getIcon('raphael:question'),
    },
];

export default navConfig;
