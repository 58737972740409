import { Card, Grid, styled } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
    background:
    'url(/static/loginImg/loginLeftBottomBg.svg) left bottom no-repeat, url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    paddingBottom: 30,
    paddingTop: 60,
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
    [theme.breakpoints.down('xl')]: {
        paddingBottom: 0,
        paddingTop: 20,
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: 20,
        paddingTop: 40,
        backgroundSize: '400px, auto',
        backgroundPosition: '-50px 100%, calc(100% - 20px) calc(100% - 20px)',
    },
}));

export const HeaderStyle = styled(Grid)(({ theme }) => ({
    lineHeight: 0,
    width: 'auto',
    paddingRight: 100,
    display: 'flex',
    paddingTop: 80,
    paddingLeft: '10% !important',
    [theme.breakpoints.down('lg')]: {
        paddingRight: 60,
        width: '100%',
        marginBottom: 40,
        paddingLeft: '20% !important',
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    top: 0,
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const ContentStyle = styled(Grid)(({ theme }) => ({
    width: '90%',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    maxWidth: 430,
    minHeight: 'calc(100vh - 85px)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    paddingRight: '15%',
    [theme.breakpoints.down('lg')]: {
        paddingRight: '5%',
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '30px !important',
        paddingRight: 15,
    },
    [theme.breakpoints.down('xl')]: {
        minHeight: 'calc(100vh - 5px)',
    },
}));

export const LogoStyle = styled('div')(({ theme }) => ({
    '& a': {
        marginBottom: '-100%',
        float: 'left',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
        '& div': {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
            },
        },
    },
}));
