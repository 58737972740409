import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { AuthContext } from 'contexts/AuthProvider';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { showError, showSuccess } from '../../../utils/toast';
import auth from '../../../constants/services/auth';

export default function LoginForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const {
        setAuth,
        setCurrencyMasterData
    } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
        password: Yup.string().required(t('error-message.password-required')),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);
       
            if (!isScriptExist) {
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = () => {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }
       
            if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, () => {});

        return () => {
            const script = document.getElementById('recaptcha-key');
            if (script) {
                script.remove();
            }
            const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
            if (recaptchaElems.length) {
                recaptchaElems[0].remove();
            }
        }
    }, []);

    const onSubmit = async (data, event) => {
        event.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async token => {
                await loginUser(token, data);
            });
        });
    };

    const loginUser = async (token, data) => {
        setIsLoading(true);
        const {email, password} = data;
        const loginInfo = { email, password, token };
        try {
            const result = await auth.signIn(loginInfo);
            const {email, firstName, lastName, token, id, onboardingStatus,localCurrency, username, currencyData} = result.data;
            const currencySymbol = getCurrencySymbolByCurrencyCode(localCurrency);
            const sessionData = {
                email,
                firstName,
                lastName,
                token,
                username,
                id,
                currencySymbol,
                localCurrency
            };
            sessionStorage.setItem('userData', JSON.stringify(sessionData));
            setAuth(sessionData);
            localStorage.setItem('currencyData', JSON.stringify(currencyData));
            setCurrencyMasterData(currencyData);
            showSuccess(t, 'login-page.login-success');
            const notificationFlag = 'true'
            switch (onboardingStatus) {
                case 0: navigate('/setup', { replace: true });
                    break;
                case 5: navigate('/welcome', { replace: true, state: {notificationFlag} });
                    break;
                default: navigate('/onboarding', { replace: true, state: {onboardingStatus} });
            }
        } catch (error) {
            showError(t, error);
        } finally {
            setIsLoading(false);
        }
    };

    const ButtonText = styled('div')(({ theme }) => ({
        '& button': {
            textTransform: 'inherit',
            fontSize: 18,
            fontWeight: '500',
            [theme.breakpoints.up('xl')]: {
                fontSize: 22,
            },
        },
    }));

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                name="email"
                label={t('login-page.email-address')}
            />
            <div style={{ position: 'relative' }}>
                <RHFTextField
                    name="password"
                    label={t('login-page.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                <RHFCheckbox name="remember" label={t('login-page.remember-me')} />
                <Link href="/forgot-password" variant="subtitle2">
                    {t('login-page.forgot-password')}
                </Link>
            </Stack>
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                >
                    {t('login-page.login')}
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
