import request from '../../utils/request';

const getTodaysReminder= (requestedDate)=> request.get(`user-to-dos?requestedDate=${requestedDate}`);
const toDosList = (data) => request.post('to-dos-list', data);
const toDos = (data) => request.post('to-dos', data);
const toDosCheck = (data) => request.put('to-dos', data);
const deleteToDo = (toDoId) => request.delete(`to-dos/${toDoId}`);

export default {
    toDosList,
    toDos,
    getTodaysReminder,
    toDosCheck,
    deleteToDo
};