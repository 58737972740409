import { useTranslation } from 'react-i18next';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInputTwo } from '../styled-components';

export default function DebtView({ currencySymbol }) {
    const { t } = useTranslation();

    const getBudgetInputTwo = (value, negative = false, sty = {}) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, ...sty }}>
        <span>{negative ? '-': ''}{currencySymbol}</span>
        <div style={{ minHeight: 21 }}>{value}</div>
    </BudgetInputTwo>

    return (
        <>
            <TableStyle style={{ padding: '0px 2%', marginTop: '1%', borderSpacing: 15 }}>
                <tbody>
                    <tr>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.description')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease-payments')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.adjustments')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.balance')}</DiffTableContent>
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.opening-balance')}</CenterTbText>
                        <CenterTbText>21-Sep-21</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('30,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.payment')}</CenterTbText>
                        <CenterTbText>11-Mar-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('500', true, { color: 'red' })}
                        <CenterTbText />
                        {getBudgetInputTwo('29,500')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.payment')}</CenterTbText>
                        <CenterTbText>31-June-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('500', true, { color: 'red' })}
                        <CenterTbText />
                        {getBudgetInputTwo('29,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.payment')}</CenterTbText>
                        <CenterTbText>31-Aug-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('500', true, { color: 'red' })}
                        <CenterTbText />
                        {getBudgetInputTwo('28,500')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.payment')}</CenterTbText>
                        <CenterTbText>15-Oct-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('500', true, { color: 'red' })}
                        <CenterTbText />
                        {getBudgetInputTwo('28,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.adjust-for-interest')}</CenterTbText>
                        <CenterTbText>21-Dec-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('700')}
                        {getBudgetInputTwo('28,700')}
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('', false, { borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('2,000', true, { color: 'red', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('700', false, { borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                    </tr>
                    <tr/>
                    <tr>
                        <td colSpan={5} style={{ borderSpacing: 10 }}>
                            <CenterTbText style={{  fontWeight: 700,
                                fontSize: 18,
                                color: '#00773E' }}>{t('view-manual-transactions.net-inc-dec-house')}</CenterTbText>
                            <CenterTbText />    
                        </td>
                        {getBudgetInputTwo('1,300', true, { color: 'red', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                    </tr>

                </tbody>
            </TableStyle>
        </>
    );
}
