import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/Page';
import SmallLogo from '../../../components/SmallLogo';
import { ListItem, MainPoints, RootStyle, SectionStyle, SmallLogoStyle, StartButton, SubPoints, SubTitle, Title, UlStyle, Wrapper } from './styled-components';
import { getWelcomeScreenData } from './constant';

export default function Setup() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const welcomeScreenData = getWelcomeScreenData(t);
    const onSubmit = () => {
        navigate('/onboarding', { replace: true });
    };
    return (
        <Page title={t('onboarding.one-time-setup-title')}>
            <RootStyle>
                <SectionStyle>
                    <SmallLogoStyle>
                        <SmallLogo sx={{ width: 40, height: 'auto' }} />
                    </SmallLogoStyle>
                </SectionStyle>
                <Wrapper>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Title variant="h2">{t('onboarding.one-time-setup-title')}</Title>
                            <SubTitle variant="subtitle1">
                                {t('onboarding.one-time-setup-subtitle')}
                            </SubTitle>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ListItem>
                                {welcomeScreenData.map((data) => <li key={data.id}>
                                    <MainPoints variant="h6">{data.title}</MainPoints>
                                    {data.content && <SubPoints variant="subtitle1">
                                        {data.content}
                                    </SubPoints>}
                                    {data.children && 
                                        <UlStyle>
                                            {data.children.map((child) => <li key={child.id}>{child.text}</li>)}
                                        </UlStyle>
                                    }
                                </li>)}
                            </ListItem>
                            <StartButton>
                                <LoadingButton size="large" type="submit" variant="contained" onClick={onSubmit}>
                                    {t('onboarding.start-now')}
                                </LoadingButton>
                            </StartButton>
                        </Grid>
                    </Grid>
                </Wrapper>
            </RootStyle>
        </Page>
    );
}
