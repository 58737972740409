// @mui
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/system';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// components
import knowledgebase from '../../constants/services/knowledgebase';
import transform from '../../constants/strapiHelper';
import Title from '../../components/Title';
import Page from '../../components/Page';
import { BackButtonStyle } from '../calculators/styled-components';

const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '100px 10%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

// ----------------------------------------------------------------------

export default function KnowledgeBaseDetailPage() {

    const navigate = useNavigate();
    const BLANK_BLOG = {
        title: '',
        content: '',
    };
    const [blog, setBlog]= useState(BLANK_BLOG);
    const { blogId } = useParams();
    
    const getBlog = async (blogId) => {
        if(blogId === '') {
            setBlog(BLANK_BLOG);
            return;
        }
        const blogsData = await knowledgebase.getBlogDetail(blogId);
        let blogsDetail = BLANK_BLOG;
        if (blogsData.data && blogsData.data.length > 0) {
            blogsDetail = {
                title: blogsData.data[0].attributes.title,
                topic: blogsData.data[0].attributes.topic.data.attributes.title,
                headerImage: blogsData.data[0].attributes.headerImage,
                content: blogsData.data[0].attributes.content,
            };  
        }
        setBlog(blogsDetail);
    }

    useEffect(() => {
        getBlog(blogId);
    }, [blogId]);

    const { t } = useTranslation();

    return (
        <Page title={t('knowledgebase.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle
                        variant="contained"
                        onClick={() => navigate(-1)}>{t('embedded-rate.go-back')}
                    </BackButtonStyle>
                    <Title title={t('knowledgebase.title')} subtitle={blog.title} />
                    <Container >
                        <Box sx={{ height: '60vh',  border: '2px solid #00773E',        
                            textDecoration: 'none',
                            fontWeight: 'normal',
                            borderRadius: '25px',
                            padding: '5% 10%',
                            fontSize: '18px',
                            textAlign: 'left',
                            overflow: 'auto',
                            marginTop: '5%' 
                        }}>{ReactHtmlParser(blog.content, {transform: (node, index) => transform(node)})}</Box>
                    </Container>      
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
