import { useEffect, useState } from 'react';
import { Grid, Typography, Link, styled, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/Title';
import Page from '../../components/Page';
import { MY_PROFILE_TILES } from './constant';
import { CardStyle, ContentStyle, GridStyle, PageTopRightBg, Wrapper } from './styled-components';
import user from '../../constants/services/user';

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign:' center',
    textTransform: 'none',
}))

export default function MyProfilePage() {
    const { t } = useTranslation();
    const [personalInfo, setPersonalInfo] = useState();
    const getPersonalDetail = async () => {
        const profDetail = await user.getProfile();
        setPersonalInfo(profDetail?.data || {});
    }
    const navigate = useNavigate();
    useEffect(() => {
        getPersonalDetail();
    }, []);

    return (
        <Page title={t('my-profile.heading')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/welcome')}>
                        {t('debt-repayment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('my-profile.heading')} subtitle={t('my-profile.description')} />
                    <Grid container spacing={4} pt={3} justifyContent="center" marginTop={5}>
                        {MY_PROFILE_TILES.map((cal, index) => (
                            <GridStyle key={index} item xs={12} sm={6} md={4}>
                                <CardStyle>
                                    <Link href={cal.path} >
                                        <Typography variant="h4">{cal.title}</Typography>
                                    </Link>
                                </CardStyle>
                            </GridStyle>
                        ))}
                    </Grid>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/welcome')}>
                        {t('debt-repayment.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
