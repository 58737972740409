import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { ForgotForm } from '../../sections/auth/forgot';
import { ContentStyle, HeaderStyle, LogoStyle, RootStyle, SectionStyle } from './styled-components';
  
export default function ForgotPassword() {
    const { t } = useTranslation();
    return (
        <Page title={t('forgot-password.title')}>
            <RootStyle>
                <SectionStyle />
                <Grid container direction="row" spacing={2} alignItems="baseline">
                    <HeaderStyle item xs={12} sm={6}>
                        <LogoStyle>
                            <Logo sx={{ width: 430, height: 'auto' }} />
                        </LogoStyle>
                    </HeaderStyle>
                    <ContentStyle item xs={12} sm={6}>
                        <ForgotForm />
                    </ContentStyle>
                </Grid>
            </RootStyle>
        </Page>
    );
}
  