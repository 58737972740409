import { toast } from 'react-toastify';

export const showToast = (toastText, options) => {
    toast(toastText, options);
};

export const showSuccess = (t, message, placeholderContent = {}) => {
    if (message) {
        // showToast(t(message, placeholderContent), { type: 'success' });
        return;
    }
    console.log(message);
};

export const showError = (t, error) => {
    const message = error?.message || 'API_ERROR';
    if (message) {
        showToast(t(`api-error.${message}`), { type: 'error' });
        return;
    }
    console.log(error);
};