export const MY_PROFILE_TILES = [
    {
        title: 'Personal Information',
        hoverText: '',
        path: 'my-profile/personal-info'
    },
    {
        title: 'Financial Institutions',
        hoverText: '',
        path: 'my-profile/link-financial-institutions'
    },
    {
        title: 'Budget',
        hoverText: '',
        path: 'my-profile/budget',
    },
    {
        title: 'Categories',
        hoverText: '',
        path: 'my-profile/category'
    },
    {
        title: 'Sub-Categories',
        hoverText: '',
        path: 'my-profile/sub-category',
    }
];