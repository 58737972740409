import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled, Autocomplete, TextField, Grid, Typography, Button } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MuiPhoneNumber from 'material-ui-phone-number';
import Page from "components/Page";
import Title from "components/Title";
import { useNavigate } from 'react-router-dom';
import { showError, showSuccess } from 'utils/toast';
import { AuthContext } from 'contexts/AuthProvider';
import countryStatesData from '../../../constants/countryStatesData';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { currencyCodes } from '../../../utils/currencyCodes';
import { getCurrencySymbolByCurrencyCode, getParamByParam } from '../../../constants/countryCurrencyData';
import { ageGroupOptions, genderOptions } from './constant';
import { Wrapper, PageTopRightBg, ContentStyle } from "./styled-components";
import user from '../../../constants/services/user';

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '8px',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

const Text = styled(RHFTextField)(({ theme }) => ({
    paddingBottom: '-8px !important',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px !important',
    },
    marginBottom: '10px !important',
}));

const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

export const GridWrapper = styled(Grid)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    padding: '0px 10% 40px calc(10% + 90px)',
    [theme.breakpoints.down('md')]: {
        padding: '20px 10px 80px 60px', 
    },
}));

export const TypographyStyle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    paddingBottom: 48,
    paddingTop: 48,
}));

const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: 'left',
    textTransform: 'none',
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));

const PhoneInput = styled(MuiPhoneNumber)(({ theme }) => ({
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export default function PersonalInfo() {
    const {
        setAuth
    } = useContext(AuthContext);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [countryOptions] = useState(countryStatesData);
    const [stateOptions, setStateOptions] = useState([]);

    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [householdPeopleOptions] = useState(Array.from({length: 20}, (_, i) => i + 1));
    const [personalInfo, setPersonalInfo] = useState(null);

    const methods = useForm({});
    const [personalDetails, setPersonalDetails]= useState({
        firstName: {
            value: '',
            error: ''
        },
        lastName: {
            value: '',
            error: ''
        },
        email: {
            value: '',
            error: ''
        },
        mobileNumber: {
            value: '',
            error: ''
        },
        username: {
            value: '',
            error: ''
        },
        country: {
            value: '',
            error: ''
        },
        state: {
            value: '',
            error: ''
        },
        city: {
            value: '',
            error: ''
        },
        houseHoldPeople: {
            value: 1,
            error: ''
        },
        currency: {
            value: '',
            error: ''
        },
        gender: {
            value: '',
            error: ''
        },
        ageGroup: {
            value: '',
            error: ''
        }
    });
    const getProfleSummary = async () => {
        let profDetail = await user.getProfile();
        profDetail = profDetail?.data || {};
        const defData = {
            firstName: {
                value: profDetail?.firstName || '',
                error: ''
            },
            lastName: {
                value: profDetail?.lastName || '',
                error: ''
            },
            email: {
                value: profDetail?.email || '',
                error: ''
            },
            mobileNumber: {
                value: profDetail?.mobileNumber || '',
                error: ''
            },
            username: {
                value: profDetail?.username || '',
                error: ''
            },
            country: {
                value: profDetail?.user_detail?.country || '',
                error: ''
            },
            state: {
                value: profDetail?.user_detail?.state || '',
                error: ''
            },
            city: {
                value: profDetail?.user_detail?.city || '',
                error: ''
            },
            houseHoldPeople: {
                value: profDetail?.user_detail?.houseHoldPeople || '',
                error: ''
            },
            currency: {
                value: profDetail?.user_detail?.localCurrency || '',
                error: ''
            },
            gender: {
                value: profDetail?.user_detail?.gender || '',
                error: ''
            },
            ageGroup: {
                value: profDetail?.user_detail?.ageGroup || '',
                error: ''
            }
        };
        setPersonalInfo(profDetail);
        setPersonalDetails(defData);
        populateDefaultData(defData);
    }

    useEffect(() => {
        getProfleSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updatePersonalDetails = (key, value) => {
        const clonePersonalDetails = {...personalDetails}
        clonePersonalDetails[key] = {
            value,
            error: ''
        };
        setPersonalDetails(clonePersonalDetails);
    };

    const populateDefaultData = async (defData) => {
        const defaultCountry = countryOptions.find((cd) => cd.country === defData?.country?.value);
        if (defaultCountry) {
            let currencyCode = defData?.currency?.value;
            if (!currencyCode) {
                currencyCode = getParamByParam('countryName', defaultCountry.country, 'currency');
            }
            setPersonalDetails({
                ...defData,
                country: {
                    value: defaultCountry,
                    error: ''
                },
                currency: {
                    value: currencyCode || '',
                    error: ''
                }
            });    
            setStateOptions(defaultCountry.states);
            setSelectedCurrency(currencyCodes.find((c) => c.CurrencyCode === currencyCode));
        }
    };

    const initalizeCurrency = (val) => {
        
        const currencyCode = getParamByParam('countryName', val.country, 'currency');
        setSelectedCurrency(currencyCodes.find((c) => c.CurrencyCode === currencyCode));
        setPersonalDetails({
            ...personalDetails,
            country: {
                value: val,
                error: ''
            },
            currency: {
                value: currencyCode,
                error: ''
            },
            state: {
                value: '',
                error: ''
            },
            city: {
                value: '',
                error: ''
            },
        });
    }

    const onCountryChange = (event, val) => {
        setStateOptions(val.states);
        initalizeCurrency(val);
    };

    const savePersonalDetails = async () => {
        const { firstName, lastName, email, username, country, city, state, houseHoldPeople, gender, ageGroup, currency, mobileNumber } = personalDetails;
        let hasError = false;
        if (!firstName.value) {
            hasError = true;
            firstName.error = t('personal-info.first-name-required');
        }
        if (!lastName.value) {
            hasError = true;
            lastName.error = t('personal-info.last-name-required');
        }
        if (!username.value) {
            hasError = true;
            username.error = t('personal-info.username-required');
        } else if (username.value && username.error) {
            hasError = true;
        }
        if (!mobileNumber.value) {
            hasError = true;
            mobileNumber.error = t('personal-info.phone-number-required');
        }
        if (!country.value) {
            hasError = true;
            country.error = t('personal-info.country-required');
        }
        if (!city.value) {
            hasError = true;
            city.error = t('personal-info.city-required');
        }
        if (!state.value) {
            hasError = true;
            state.error = t('personal-info.state-required');
        }
        if (!houseHoldPeople.value) {
            hasError = true;
            houseHoldPeople.error = t('personal-info.household-people-required');
        }
        if (!gender.value) {
            hasError = true;
            gender.error = t('personal-info.gender-required');
        }
        if (!ageGroup.value) {
            hasError = true;
            ageGroup.error = t('personal-info.age-group-required');
        }
        if (!currency.value) {
            hasError = true;
            currency.error = t('personal-info.currency-required');
        }
        if (hasError) {
            setPersonalDetails({
                firstName,
                lastName,
                email,
                username,
                country,
                city,
                state,
                houseHoldPeople,
                gender,
                ageGroup,
                currency,
                mobileNumber
            });
            return false;
        }
        editPersonalDetails();
    };
    const editPersonalDetails = async () => {
        const {country, city, username, state, houseHoldPeople, gender, ageGroup, currency, firstName, lastName, mobileNumber} = personalDetails;
        const profileInfo = {
            firstName: firstName?.value,
            lastName: lastName?.value,
            mobileNumber: mobileNumber?.value,
            user_detail: {
                country: country?.value?.country || 'Canada',
                city: city?.value,
                state: state?.value,
                localCurrency: currency?.value,
                houseHoldPeople: houseHoldPeople?.value,
                gender: gender?.value,
                ageGroup: ageGroup?.value,
            }
        };
        try {
            await user.updatePersonalDetails(profileInfo);
            const userSessObj = JSON.parse(sessionStorage.getItem('userData'));
            const currencySymbol = getCurrencySymbolByCurrencyCode(currency?.value);
            userSessObj.currencySymbol = currencySymbol;
            userSessObj.localCurrency = currency?.value;
            sessionStorage.setItem('userData', JSON.stringify(
                userSessObj
            ));
            setAuth(userSessObj);
            showSuccess(t, 'personal-info.profile-update-success');
            getProfleSummary();
        } catch (error) {
            showError(t, error);
        }
    };

    return (
        <Page title={t('personal-info.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/my-profile')}>
                        {t('mortgage-prepayment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('personal-info.title')} subtitle={t('personal-info.description')} />
                    <TypographyStyle variant="h4">{t('personal-info.personal-info')}</TypographyStyle>
                    <FormProvider methods={methods}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.first-name')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <Text
                                    name="firstName"
                                    error={personalDetails.firstName.error}
                                    helperText={personalDetails.firstName.error}
                                    value={personalDetails.firstName.value}
                                    onChange={(e) => {
                                        updatePersonalDetails('firstName', e.target.value);
                                    }} />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.country')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                            
                                    options={countryOptions}
                                    value={personalDetails?.country.value}
                                    getOptionLabel={(option) => (option?.country || '')}
                                    disableClearable
                                    onChange={onCountryChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select Country"
                                            className="dropMenu"
                                            error={personalDetails.country.error}
                                            helperText={personalDetails.country.error}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.last-name')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <Text
                                    name="lastName"
                                    error={personalDetails.lastName.error}
                                    helperText={personalDetails.lastName.error}
                                    value={personalDetails.lastName.value}
                                    onChange={(e) => {
                                        updatePersonalDetails('lastName', e.target.value);
                                    }} />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.state')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                                    options={stateOptions}
                                    value={personalDetails.state.value}
                                    disableClearable
                                    onChange={(e, val) => {
                                        updatePersonalDetails('state', val);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select State"
                                            className="dropMenu"
                                            error={personalDetails.state.error}
                                            helperText={personalDetails.state.error}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.username')}
                                    <LightTooltip title={<>This will be displayed as your name when you participate in forums or make suggestions. <br/ >Only letters (a-z), numbers (0-9) and periods (.) are allowed.</>}>
                                        <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" style={{ marginLeft: 5 }}/>
                                    </LightTooltip>
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <Text
                                    disabled
                                    name="username"
                                    error={personalDetails.username.error}
                                    helperText={personalDetails.username.error}
                                    value={personalDetails.username.value}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.city')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <Text
                                    name="city"
                                    error={personalDetails.city.error}
                                    helperText={personalDetails.city.error}
                                    value={personalDetails.city.value}
                                    onChange={(e) => {
                                        updatePersonalDetails('city', e.target.value);
                                    }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.email-address')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <Text
                                    disabled
                                    name="email"
                                    error={personalDetails.email.error}
                                    helperText={personalDetails.email.error}
                                    value={personalDetails.email.value}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.phone-number')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <PhoneInput
                                    fullWidth
                                    defaultCountry={'ca'}
                                    onChange={(value) => updatePersonalDetails('mobileNumber', value)}
                                    value={personalDetails.mobileNumber.value}
                                    error={personalDetails.mobileNumber.error}
                                    helperText={personalDetails.mobileNumber.error}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.gender')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                                    options={genderOptions}
                                    value={personalDetails.gender.value}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disableClearable
                                    onChange={(e, val) => {
                                        updatePersonalDetails('gender', val.label);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            className="dropMenu"
                                            error={personalDetails.gender.error}
                                            helperText={personalDetails.gender.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.no-of-people')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                                    options={householdPeopleOptions}
                                    value={personalDetails.houseHoldPeople.value}
                                    getOptionLabel={(option) => option.toString()}
                                    disableClearable
                                    onChange={(e, val) => {
                                        updatePersonalDetails('houseHoldPeople', parseInt(val, 10));
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select"
                                            className="dropMenu"
                                            error={personalDetails.houseHoldPeople.error}
                                            helperText={personalDetails.houseHoldPeople.error}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.age-group')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                                    options={ageGroupOptions}
                                    value={personalDetails.ageGroup.value}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    disableClearable
                                    onChange={(e, val) => {
                                        updatePersonalDetails('ageGroup', val.label);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            className="dropMenu"
                                            error={personalDetails.ageGroup.error}
                                            helperText={personalDetails.ageGroup.error}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={2}>
                                <Input shrink htmlFor="bootstrap-input">
                                    {t('personal-info.local-currency')}
                                </Input>
                            </Grid>
                            <Grid item xs={3}>
                                <AutoFieldStyle
                                    options={currencyCodes}
                                    value={selectedCurrency}
                                    getOptionLabel={(option) => (option?.CurrencyCode || '')}
                                    disableClearable
                                    onChange={(e, val) => {
                                        setSelectedCurrency(val);
                                        updatePersonalDetails('currency', val.CurrencyCode);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select Currency Code"
                                            className="dropMenu"
                                            error={personalDetails.currency.error}
                                            helperText={personalDetails.currency.error}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <div style={{ paddingRight: '8%', paddingTop: '5%' }}>
                            <Button
                                style={{float:'right', marginBottom:15, borderRadius: '0px', paddingInline: '40px', maxWidth: '150px', minWidth: '150px' }}
                                variant="contained"
                                onClick={() => savePersonalDetails()}>
                                {t('personal-info.save')}
                            </Button>
                            <Button
                                variant="outlined"
                                style={{float:'right', marginBottom:15, borderRadius: '0px', paddingInline: '40px', marginRight: '10px', maxWidth: '150px', minWidth: '150px' }}
                                onClick={() => navigate('/my-profile')}
                            >
                                {t('personal-info.cancel')}
                            </Button>
                        </div>
                    </FormProvider>
                    
                    <BackButtonStyle variant="contained" onClick={() => navigate('/my-profile')}>
                        {t('mortgage-prepayment.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
                
            </Wrapper>
        </Page>
    );
}
