import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import transaction from 'constants/services/transaction';
import { showError, showSuccess } from 'utils/toast';
import user from 'constants/services/user';
import { useTranslation } from 'react-i18next';
import { FormProvider, RHFTextField } from '../../components/hook-form';
import EditForm from './EditForm';
import Title from '../../components/Title';
import { Wrapper, Fragment, PageTopRightBg, ButtonText, DividerStyle, DividerGrid, MainGrid, NoteGrid } from './styled-components';

export default function DownloadEdits() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const transactionId = location?.state?.transactionId || null;
    const [userCategories, setUserCategories] = useState([]);
    const [transactionData, setTransactionData] = useState({
        transactionDate: new Date(),
        payee: '',
        category: '',
        subCategory: '',
        tag: '',
        notes: '',
    });

    const methods = useForm();

    const populateData = async () => {
        try {
            const response = await user.getUserCategories();
            const categories = response?.data || [];
            setUserCategories(categories);

            const transactionObj = await transaction.getTransaction(transactionId);
            const { transactionDate, merchant, categoryId, subCategoryId, tag, notes } = transactionObj.data;

            const category = categories.find((c) => c.id === categoryId);
            let subCategory = null;

            if (category) {
                subCategory = category.sub_categories.find((c) => c.id === subCategoryId);;
            } 
            setTransactionData({
                merchant,
                transactionDate,
                payee: merchant?.name || '',
                category,
                subCategory,
                tag: tag || '',
                notes: notes || '',
            });
        } catch (error) {
            showError(t, error);
        }
    };

    useEffect(() => {
        if (transactionId) {
            populateData();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async () => {
        const {transactionDate, category, subCategory, tag, notes, payee, merchant} = transactionData;
        const payload = {
            categoryId: category?.id || null,
            subCategoryId: subCategory?.id || null,
            tag,
            notes,
            transactionDate,
            merchant: {
                ...merchant,
                name: payee,
            }
        }
        try {
            await transaction.updateTransaction(transactionId, payload);
            showSuccess(t, 'downloaded-transactions.update-success');
            navigate(-1);
        } catch (err) {
            showError(t, err);
        }
    }

    const updateTransactionData = (key, value) => {
        const clonedData = {...transactionData};
        clonedData[key] = value;
        if (key === 'category') {
            clonedData.subCategory = '';
        }
        setTransactionData(clonedData);
    };

    return (
        <Fragment>
            <FormProvider methods={methods}>
                <Wrapper>
                    <PageTopRightBg />
                    <Title title="Downloaded Transactions" subtitle="Edits?" />
                    <MainGrid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <EditForm
                                transactionData={transactionData}
                                updateTransactionData={updateTransactionData}
                                userCategories={userCategories}
                            />
                        </Grid>
                        <DividerGrid item md={1} xs={12}>
                            <DividerStyle />
                        </DividerGrid>
                        <NoteGrid item md={5} xs={12}>
                            <RHFTextField
                                multiline
                                rows={6}
                                name="notes"
                                label="Notes"
                                value={transactionData.notes}
                                onChange={(e) => updateTransactionData('notes', e.target.value)}
                            />
                        </NoteGrid>
                    </MainGrid>
                    <ButtonText>
                        <LoadingButton fullWidth size="large" variant="outlined" onClick={() => navigate(-1)}>
                        Cancel
                        </LoadingButton>
                        <LoadingButton fullWidth size="large" variant="contained" onClick={onSubmit}>
                        Save
                        </LoadingButton>
                    </ButtonText>
                </Wrapper>
            </FormProvider>
        </Fragment>
    );
}