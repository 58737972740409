import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
// components
import { getPositiveNumber, getNegativeNumber, getCurrencySymbol } from '../../../utils/calCommonFunction';
import {
    TableStyle,
    GridExtraSpacing,
    ExecutiveTbStart,
    ExecutiveTitleStyle,
    NoDataTd,
    ExecutiveDivStyle,
    ExecutiveDivSpacing,
    LoaderGif,
    TotalAmountDiv,
    ExecutiveTbEnd,
} from './styled-component';

export default function BudgetTable(props) {
    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();
    return (
        <>
            <ExecutiveDivStyle>
                <ExecutiveDivSpacing style={{ padding: '24px 0px' }}>
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ margin: '0px', width: '100%' }}
                    >
                        <GridExtraSpacing item xs={12} sm={6} md={6} style={{ padding: '0px !important' }}>
                            <ExecutiveTitleStyle>{props?.tableFirstHeading}</ExecutiveTitleStyle>
                            <ExecutiveTitleStyle>{props?.tableFirstSubHeading}</ExecutiveTitleStyle>
                            <TableStyle>
                                <thead>
                                    <tr>
                                        <ExecutiveTbStart style={{ width: '30%' }}>{props?.thFirst}</ExecutiveTbStart>
                                        <th style={{ textAlign: 'end', width: '30%' }}>{props?.thSecond}</th>
                                        <th style={{ textAlign: 'end', width: '40%' }}>{props?.thThird}</th>
                                    </tr>
                                </thead>
                                {props?.tableloader ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {props.increseThisYear?.length > 0 ? (
                                            props?.increseThisYear?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{getNegativeNumber(item.amount, true, false)}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{props.underBudgetflag
                                                                ? getPositiveNumber(item.budget_by, true, false)
                                                                : getNegativeNumber(item.budget_by, true, false)}{' '}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </TableStyle>
                        </GridExtraSpacing>
                        <div>{''}</div>
                        <GridExtraSpacing item xs={12} sm={6} md={6} style={{ padding: '0px !important' }}>
                            <ExecutiveTitleStyle>{props?.tableSecondHeading}</ExecutiveTitleStyle>
                            <ExecutiveTitleStyle>{props?.tableSecondSubHeading}</ExecutiveTitleStyle>
                            <TableStyle>
                                <thead>
                                    <tr>
                                        <ExecutiveTbStart style={{ width: '30%' }}>{props?.thFirst}</ExecutiveTbStart>
                                        <th style={{ textAlign: 'end', width: '30%' }}>{props?.thSecond}</th>
                                        <th style={{ textAlign: 'end', width: '40%' }}>{props?.thThird}</th>
                                    </tr>
                                </thead>
                                {props?.tableloader ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {props.increseThisMonth?.length > 0 ? (
                                            props?.increseThisMonth?.map((item, indx) => (
                                                <tr key={indx}>
                                                    <td>{item.name}</td>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{getNegativeNumber(item.amount, true, false)}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{props.underBudgetflag
                                                                ? getPositiveNumber(item.budget_by, true, false)
                                                                : getNegativeNumber(item.budget_by, true, false)}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </TableStyle>
                        </GridExtraSpacing>
                    </Grid>
                </ExecutiveDivSpacing>
            </ExecutiveDivStyle>
        </>
    );
}
