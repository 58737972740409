import { Grid, styled, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    height:'100%',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const LoaderGif= styled('img')(()=>({
    width:'180px',
    margin:'auto'
}))

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 14%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
        '& .MuiGrid-container':{
            paddingTop:'0px'
        },
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    height: '185px',
    fontSize: 16,
    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const TopSideBar = styled('td')(() => ({
    color: '#00773E',
    fontWeight: '600'
}));

export const SubmitButton = styled(LoadingButton)(() => ({
    padding: '6px 6px',
    height: 'auto',
    minWidth: '72px'
}));

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' center',
}))

export const TableUpperDiv= styled('div')(({theme})=>({
    marginTop: '-28px',
    minHeight:'185px',
    padding: '30px 50px 10px 44px !important' ,
    fontWeight: 400,
    background: '#fff',
    border: '2px solid #155041',
    borderRadius: '10px',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        padding: '20px !important',
    },
}))

export const TotalAmountDiv = styled('div')(() => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
}));

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '50px 15%',
    [theme.breakpoints.down('md')]: {
        padding: '0 0 20px',
    },
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        margin: '0 12px',
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));

export const TableSubHeadingColumn = styled('td')(() => ({
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    // textAlign: 'end !important',
    color: '#64A940 !important',
    marginLeft: '20px'
}));