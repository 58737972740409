import { styled, Grid, Autocomplete, Button, Checkbox } from '@mui/material';
import { RHFTextField } from 'components/hook-form';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const Fragment = styled('div')(({ theme }) => ({
    padding: theme.spacing(10, 10, 0),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2),
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '50px 15% 140px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 0 40px',
    },
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        margin: '0 12px',
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));

export const DividerStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    width: 1,
    position: 'relative',
    [theme.breakpoints.down('md')]: {
        width: 'calc(98% - 7px)',
        height: 1,
    },
}));

export const DividerGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
}));

export const MainGrid = styled(Grid)(({ theme }) => ({
    paddingTop: 0,
}));

export const NoteGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    '& form': {
        width: '80% !important',
        [theme.breakpoints.down('md')]: {
            width: '100% !important',
        },
    },
}));

export const MainBox = styled('div')(({ theme }) => ({
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme, props }) => ({
    fontSize: 14,
    paddingBottom: '20px',
    ...props,
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 14,
        },
    },
    '& .MuiInputBase-root': {
        fontSize: 14,
        [theme.breakpoints.up('xl')]: {
            fontSize: 14,
        },
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

export const InputStyle = styled(RHFTextField)(({ theme }) => ({
    paddingBottom: 20,
    fontSize: 14,
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 14,
        },
    },
    '& .MuiInputBase-input': {
        fontSize: 14,
        [theme.breakpoints.up('xl')]: {
            fontSize: 14,
        },
    }
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    marginBottom: '20px',
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                padding: '10px 20px 10px  !important',
                fontSize: 14,
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    marginTop: '-28px',
    padding: '42px 14% 62px 15%',
    fontWeight: 400,
    background: '#fff',
    border: '2px solid #155041',
    borderRadius: '10px',
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 14,
        minWidth: 500,
    },
    [theme.breakpoints.down('llg')]: {
        padding: '42px 15% 62px 15%',
        fontSize: 14,
    },

    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 14,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
}));

export const DiffTableContent = styled('td')(({ theme, props }) => ({
    color: '#00773E',
    fontWeight: '600',
    textAlign: 'left',
    ...props
}));

export const AddMoreTbBUtton = styled('td')(({ props }) => ({
    paddingLeft: '12px',
    paddingTop: '14px',
    ...props
}));

export const AddMoreButton = styled(Button)(() => ({
    color: '#00773f',
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 'auto',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0px !important',
    textTransform: 'none'
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    paddingTop: 10,
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const BudgetInput = styled('td')(({ props }) => ({
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    paddingLeft: 0,
    ...props,
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '151px',
    },
}));

export const GroupHeader = styled("div")(() => ({
    padding: "4px 10px",
    color: 'rgb(0, 119, 62)',
    fontWeight: 600,
    textTransform: 'uppercase'
}));
  
export const GroupItems = styled("ul")({
    padding: 0
});
