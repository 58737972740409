import { useContext } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FinancialTitle from '../FinancialTitle';
import Iconify from '../../../../../components/Iconify';
import { AccordionStyle, HeadArea, Title } from './styled-components';
import { FormContext } from '../../context/StepperContext';
import ListAddBudget from '../ListAddBudget';
import BudgetTable from '../Budget';

export default function Step5() {
    const {
        categoriesMasterData: categoryGroups,
    } = useContext(FormContext);

    const filteredCategoryGroups = categoryGroups.filter((catGrp) => ['Income/Cash Inflows', 'Expenses/Cash Outflows'].includes(catGrp.name));

    const getTotal = (subCategories) => subCategories.reduce((total, item) => {
        const budget = item.budget.toString().replace(/[^0-9.-]+/g, '');
        total += Number(budget);
        return total;
    }, 0);

    const getTotalBudget = (categoryGroup) => {
        const filteredCategories = categoryGroup.categories.filter(cat => cat.isSelected);
        let total = 0;
        filteredCategories.forEach((fc) => {
            total += getTotal(fc.subCategories);
        });
        return total;
    }

    const getTotalInflow = () => {
        const incomeCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Income/Cash Inflows');
        if (incomeCat) {
            return getTotalBudget(incomeCat);
        }
        return 0;
    };

    const getTotalOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            return getTotalBudget(expenseCat);
        }
        return 0;
    };

    const getDiscNonDiscOutflow = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        let discretionaryOutflow = 0;
        let nonDiscretionaryOutflow = 0;
        if (expenseCat) {
            const filteredCategories = expenseCat.categories.filter(cat => cat.isSelected && cat.name !== 'Loan payments');
            filteredCategories.forEach((fc) => {
                discretionaryOutflow += getTotal(fc.subCategories.filter((c) => c.isDiscretionary));
                nonDiscretionaryOutflow += getTotal(fc.subCategories.filter((c) => !c.isDiscretionary));
            });
        }
        return {
            discretionaryOutflow,
            nonDiscretionaryOutflow
        }
    };

    const getLoanRepayment = () => {
        const expenseCat = filteredCategoryGroups.find((catGrp) => catGrp.name === 'Expenses/Cash Outflows');
        if (expenseCat) {
            const loanCategory = expenseCat.categories.find(cat => cat.isSelected && cat.name === 'Loan payments');
            if (loanCategory) {
                return getTotal(loanCategory.subCategories);
            }
        }
        return 0;
    };

    const cashInflow = getTotalInflow();
    const cashOutflow = getTotalOutflow();
    const totalLoanRepayment = getLoanRepayment();
    const {discretionaryOutflow, nonDiscretionaryOutflow} = getDiscNonDiscOutflow();

    return (
        <>
            <HeadArea>
                <FinancialTitle
                    heading="Step 5"
                    subheading="Add monthly budget details"
                />
            </HeadArea>
            <Grid container>
                {filteredCategoryGroups.map((categoryGroup, index) =>
                    <AccordionStyle key={categoryGroup.id} defaultExpanded={index === 0 && true }>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{categoryGroup.name}</Typography>
                            <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                            <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListAddBudget
                                categoryGroup={categoryGroup}
                                categories={categoryGroup.categories}
                                cashInflow={cashInflow}
                                cashOutflow={cashOutflow}
                            />
                        </AccordionDetails>
                    </AccordionStyle>
                )}
                <AccordionStyle>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Summary</Typography>
                        <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                        <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <BudgetTable
                            cashInflow={cashInflow}
                            cashOutflow={cashOutflow}
                            totalLoanRepayment={totalLoanRepayment}
                            discretionaryOutflow={discretionaryOutflow}
                            nonDiscretionaryOutflow={nonDiscretionaryOutflow}
                        />
                    </AccordionDetails>
                </AccordionStyle>
            </Grid>
        </>
    );
}
