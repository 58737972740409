
import { Typography, styled } from '@mui/material';

export const LoginBtn = styled(Typography)(() => ({
    opacity: 0,
    position: 'absolute',
    width: 'auto',
    zIndex: 9,
    height: 35,
    transform: 'scaleX(1.5)',
    cursor: 'pointer',
}));

export const DividerStyle = styled('hr')(({ theme }) => ({
    background: theme.palette.primary.main,
    margin: '64px 0',
    height: 1,
    border: 0,
    [theme.breakpoints.down('xl')]: {
        margin: '20px 0',
    },
}));

export const SocialFormButton = styled('div')(({ theme }) => ({
    '& button': {
        fontSize: 18,
        fontWeight: '500',
        borderWidth: 2,
        color: theme.palette.primary.main,
        textTransform: 'inherit',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
        '& img': {
            marginRight: 24,
        },
        '& p': {
            fontSize: 18,
            fontWeight: 500,
            minWidth: 190,
            textAlign: 'left',
        },
    },
}));