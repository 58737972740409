import { styled, Grid, Box, IconButton, Autocomplete } from '@mui/material';
import { RHFTextField } from '../../components/hook-form';

export const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: '10px',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
    height: '100vh'
}));

export const SearchButton = styled('div')(({ theme }) => ({
    paddingBottom: '18px',
    width: '90px',
    height: '73px',
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        height: 0,
        paddingBottom: '84px',
    },
    '& .additional-padding': {
        [theme.breakpoints.up('md')]: {
            paddingTop: '50px',
        },
    }
}));

export const SearchIconButton = styled(IconButton)(() => ({
    padding: 0,
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 5%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 5%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const LoadButton = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '50px 0 0 0',
    "& button": {
        padding: "0 80px"
    },
}));

export const Textfield = styled(RHFTextField)(() => ({
    // maxWidth: '195px',
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '30px',
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const LabelStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

export const Content = styled('div')(() => ({
    paddingBottom: '40px',
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                padding: '10px 0px 10px 10px  !important',
                fontSize: 14,
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));