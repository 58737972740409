const getWhyNotFreeData = (t) => ([
    {
        id: 1,
        text: t('home-page.not-free-data-1'),
    },
    {
        id: 2,
        text: t('home-page.not-free-data-2')
    },
    {
        id: 3,
        text: t('home-page.not-free-data-3')
    },
]);

export {
    getWhyNotFreeData,
}