import request from '../../utils/request';

const signIn = (payload) => request.post('login', payload);
const forgotPassword = (payload) => request.post('forgot-password', payload);
const resetPassword = (payload) => request.post('reset-password', payload);
const googleLogin = (payload) => request.post('google/login', payload);
const facebookLogin = (payload) => request.post('facebook/login', payload);
const logout = () => request.post('logout');

export default {
    signIn,
    forgotPassword,
    resetPassword,
    googleLogin,
    facebookLogin,
    logout
};