import request from '../../utils/request';

const getMasterData = () => request.get('categories/master-data');
const saveUserPersonalInfo = (data) => request.post('users/personal-info', data);
const saveCategoryData = (data) => request.post('/categories/user', data);
const addSubCategoryData = (data) => request.post('/sub-categories', data);
const getUserProfileSummary = () => request.get('/users/profile-summary');
const getOnboardingData = () => request.get('/users/onboarding-data');
const saveFinancialInstution = (data) => request.post('users/link-financial-institution', data);
const updateOnboardingStatus = (status) => request.post(`users/update-onboarding-status/${status}`);
const skipOnboarding = () => request.post('users/skip-onboarding');

export default {
    getMasterData,
    saveUserPersonalInfo,
    saveCategoryData,
    addSubCategoryData,
    getUserProfileSummary,
    getOnboardingData,
    saveFinancialInstution,
    updateOnboardingStatus,
    skipOnboarding
};