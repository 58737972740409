// @mui
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography, Link, Card, CardActionArea, CardMedia, CardContent, Box, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import debounce from 'lodash.debounce';
// components
import knowledgebase from '../../constants/services/knowledgebase';
import Title from '../../components/Title';
import Page from '../../components/Page';
import { API_URL_WITHOUT_CONTEXTPATH } from '../../constants/url';

const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '100px 10%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

const NavCalStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '64px',
    marginBottom: '36px',
    background: '#F8F8F8',
    padding: '16px 50px',
    borderRadius: '10px',
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        position: 'absolute'
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 0px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
    '& label':{
        fontSize:'20px',
        fontWeight: '600',
        color: '#00773E',
        marginLeft: '12px',
    }
}));

export const CheckBoxLabel = styled('h4')(({ theme }) => ({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00773E',
    marginLeft: '10px'
}));

export const CardTitle = styled('h5')(({ theme }) => ({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00773E',
    
}));

export const CardTopic = styled('h5')(({ theme }) => ({
    border: '1px solid #00773E',        
    color: '#00773E',
    fontWeight: 'normal',
    borderRadius: '25px',
    padding: '4px 10px 4px',
    fontSize: '12px',
    textAlign: 'left',
    margin: '10px 0',
    marginRight: 'auto',
    display: 'inline-block'
}));

export const LoadButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '84px 0 95px',
    "& button": {
        padding: "0 80px"
    },
}));

export const LabelStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginRight: 10,
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 16,
    },
}));
// ----------------------------------------------------------------------

export default function KnowledgeBasePage() {

    const ALL_TOPICS = 'All';
    const SELECTED_TOPICS = 'SELECTED_TOPICS';
    const NUMBER_OF_BLOGS = 'NUMBER_OF_BLOGS';
    const DEFAULT_PAGE_SIZE = 9;
    const [topics, setTopics]= useState([ALL_TOPICS]);
    const [blogs, setBlogs]= useState([]);
    const [numberOfBlogs, setNumberOfBlogs]= useState(DEFAULT_PAGE_SIZE);
    const [blogsFound, setBlogsFound]= useState(0);
    const [selectedTopics, setSelectedTopics]= useState(ALL_TOPICS);
    const [searchText, setSearchText]= useState('');

    const deboundedSearch = debounce(() => {
        getBlogs(selectedTopics, numberOfBlogs);
    }, 500);

    useEffect(() => {
        deboundedSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
    
    const getTopics = async () => {
        const topicsData = await knowledgebase.getTopics();
        const topicList = [ALL_TOPICS];    
        if (topicsData.data) {
            topicsData.data.forEach((topic) => {
                topicList.push(topic.attributes.title);
            });
        }
        setTopics(topicList);
        let numberOfBlogToBeFetched = numberOfBlogs
        let topicsSelected = topicList;
        if(localStorage.getItem(SELECTED_TOPICS)) {
            topicsSelected = localStorage.getItem(SELECTED_TOPICS).split(',');
            numberOfBlogToBeFetched = localStorage.getItem(NUMBER_OF_BLOGS).split(',');
            localStorage.removeItem(SELECTED_TOPICS);
            localStorage.removeItem(NUMBER_OF_BLOGS);
        }
        setSelectedTopics(topicsSelected);
        setNumberOfBlogs(numberOfBlogToBeFetched);
        getBlogs(topicsSelected, numberOfBlogToBeFetched);
    }
    
    const getBlogs = async (topicList, pageSize) => {
        if(topicList.length === 0) {
            setBlogs([]);
            return;
        }
        const checkedTopics = topicList.filter(e => e !== ALL_TOPICS);
        const blogsData = await knowledgebase.getBlogs(checkedTopics, searchText, pageSize);
        const blogsList = [];
        if (blogsData.data) {
            blogsData.data.forEach((blog) => {
                blogsList.push({
                    id: blog.id,
                    title: blog.attributes.title,
                    topic: blog.attributes.topic,
                    headerImage: blog.attributes.headerImage,
                });
            });
            setBlogsFound(blogsData.meta.pagination.total)
        }
        setBlogs(blogsList);
    }

    useEffect(() => {
        getTopics();
    }, []);

    const updateLocalStorage = () => {
        localStorage.setItem(SELECTED_TOPICS, selectedTopics);
        localStorage.setItem(NUMBER_OF_BLOGS, numberOfBlogs);
    }

    const { t } = useTranslation();

    const checkBoxCheckedData = (value, data) => {
        let checkedTopics = [];
        if(data) {
            if(value === ALL_TOPICS) {
                checkedTopics = topics;
            } else {
                checkedTopics = [value, ...selectedTopics];
            }
        } else if(value === ALL_TOPICS) {
            checkedTopics = [];
        } else {
            checkedTopics = selectedTopics.filter(e => e !== ALL_TOPICS);
            checkedTopics = checkedTopics.filter(e => e !== value);
        }
        
        setSelectedTopics(checkedTopics);
        setNumberOfBlogs(DEFAULT_PAGE_SIZE);
        getBlogs(checkedTopics, DEFAULT_PAGE_SIZE);
    };

    const loadMore = () => {
        if(blogs && blogsFound >= numberOfBlogs) {
            const pageSize = numberOfBlogs + DEFAULT_PAGE_SIZE;
            setNumberOfBlogs(pageSize);
            getBlogs(selectedTopics, pageSize);
        }
    }

    return (
        <Page title={t('knowledgebase.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Title title={t('knowledgebase.title')} subtitle={t('knowledgebase.description')} />
                    <NavCalStyle>
                        {
                            topics.map((topic, index)=> <FormControlLabel key={index}  control={<CheckboxStyle checked={(selectedTopics.indexOf(topic) !== -1)} onChange={(e) => checkBoxCheckedData(topic, e.target.checked)}/>} label={<CheckBoxLabel>{topic}</CheckBoxLabel>} />)
                        }
                    </NavCalStyle>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LabelStyle shrink htmlFor="search">
                            Select All and Search
                        </LabelStyle>
                        <TextField
                            value={searchText}
                            name="search"
                            style={{ minWidth: 350 }}
                            placeholder="Enter text here..."
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        {blogs.map((cal, key) => {
                            const image = cal.headerImage?.data?.attributes?.url ? `${API_URL_WITHOUT_CONTEXTPATH}${cal.headerImage?.data?.attributes?.url}` : '/static/homeImg/fullHomeBanner.png';
                            const imageAltText = cal.headerImage?.data?.attributes?.name || '';
                            const {title} = cal;
                            const topic = cal.topic?.data?.attributes?.title || '';
                            return (
                                <Grid item xs={4} key={key} >
                                    <Link href={`/knowledge-base-detail/${cal.id}`} underline="none" onClick={() => updateLocalStorage()}>
                                        <Card key={key} xs={12} sm={6} md={4} sx={{ maxWidth: 345 }}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="120"
                                                    image ={image}
                                                    alt={imageAltText || ''}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom component="div" >
                                                        <CardTitle>{title}</CardTitle>
                                                    </Typography>
                                                    <Typography component="div"><CardTopic>{topic}</CardTopic></Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Link>
                                </Grid> 
                            );
                        })}
                    </Grid>
                    {blogsFound >= numberOfBlogs && <LoadButton>
                        <LoadingButton size="large"  variant="contained" onClick={() => loadMore()}>
                            Load more
                        </LoadingButton>
                    </LoadButton>}
                </ContentStyle>
            </Wrapper>
        </Page>);
}
