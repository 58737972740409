import { styled, Typography, Grid, Box, Card, Stack } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
    background:
    'url(/static/loginImg/loginLeftBottomBg.svg) left bottom no-repeat, url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    paddingBottom: 40,
    [theme.breakpoints.up('llg')]: {
        paddingBottom: 50,
    },
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px, auto',
        backgroundPosition: '-50px 100%, calc(100% - 20px) calc(100% - 20px)',
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
    padding: '30px 10% 40px',
    [theme.breakpoints.up('llg')]: {
        padding: '60px 10% 40px',
    },
    [theme.breakpoints.down('md')]: {
        padding: '50px 10px 40px 60px',
    },
}));

export const DataGrid = styled(Grid)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
    },
}));

export const ProfileData = styled(Box)(({ theme }) => ({
    '& .pTitle': {
        fontSize: '18px',
        fontWeight: 700,
        color: theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    '& .pDescription': {
        fontSize: '16px',
        fontWeight: 400,
        color: theme.palette.primary.contrastText,
    },
}));

export const ProceedingBtn = styled('div')(({ theme }) => ({
    padding: '30px 0px 8px',
    [theme.breakpoints.up('llg')]: {
        padding: '100px 0px 28px',
    },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
    paddingTop: '0px',
    [theme.breakpoints.up('llg')]: {
        paddingTop: '20px',
    },
}));

export const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    width: '60%',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 3px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));