import { Grid, styled, Button } from '@mui/material';

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    '& th': {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#00773E',
        paddingTop: 9,
        paddingBottom: 9,
        verticalAlign: 'baseline',
        paddingRight:10,
        paddingLeft:10,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: '14px !important',
        },
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        paddingRight:10,
        paddingLeft:10,
        verticalAlign: 'baseline',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: '14px !important',
        },
    },
    [theme.breakpoints.down('md')]: {
        padding: '0px !important',
    },
}));

export const TotalFlowValueColumn = styled('td')(({theme}) => ({
    display:'flex',
    justifyContent:'end',
    '& div':{
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #00773E',
        borderBottom: ' solid 3px #00773E',
        fontSize: '18px',
        fontWeight: '600',
        width: '110px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px ',
            width: '110px'
        },
    }
}));

export const MobileResDiv= styled('div')(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        '& .MuiGrid-root .MuiGrid-item':{
            paddingLeft:'0px'
        },
        '& .MuiGrid-container':{
            marginLeft:'0px'
        }
    }
}))

export const TotalAmountDiv = styled('div')(() => ({
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    // fontSize: '18px !important',
}));

export const TotalFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: '18px !important',
    paddingTop: 0,
    paddingBottom: 0,
    color: '#4B4B4B'
}));

export const ExecutiveTdAlignEnd = styled('td')(() => ({
    textAlign:'end',
}));

export const ExecutiveTbEnd = styled('td')(() => ({
    textAlign:'end',
    '& div':{
        width: '110px',
        float: 'right'
    }
}));

export const ExecutiveTbStart = styled('th')(() => ({
    textAlign:'start !important'
}));

export const TableThHeading = styled('th')(() => ({
    textAlign: 'center !important',
    color: 'black !important',
    fontSize: '18px !important',
}));

export const ExecutiveDivStyle = styled('div')(({theme}) => ({
    background: '#FFFFFF',
    border: '2px solid #155041',
    borderRadius: '10px',
    marginTop: '50px',
    marginLeft: '32px',
    [theme.breakpoints.down('md')]: {
        marginTop: '20px',
        marginLeft: '0px',
    },
}));

export const ExecutiveDivSpacing = styled('div')(({theme}) => ({
    padding: '0px',
}))

export const LoaderGif= styled('img')(()=>({
    width:'180px',
    margin:'auto'
}))

export const GridSpacing = styled(Grid)(({theme})=>({
    padding: '0px !important',
    '& table':{
        padding: '16px 30px!important',
        height: '395px',
    },
    [theme.breakpoints.down('md')]: {
        '& table':{
            padding: '20px 0px !important',
            height: '290px'
        },
    },
    [theme.breakpoints.down('md')]: {
        '& table':{
            height: 'auto !important',
            padding:'25px 0px !important',
        },
    },
}))

export const ExecutiveTitleStyle = styled('h4')(()=>({
    textAlign: 'center',
    fontSize: '18px',
    marginBottom: '14px'
}))

export const NoDataTd= styled('td')(()=>({
    textAlign: 'center',
    fontWeight: '600',
    verticalAlign: 'middle !important'
}))

export const ExecutiveNetDivStyle = styled('div')(({theme}) => ({
    background: '#64A940',
    border: '2px solid #64A940',
    borderRadius: '8px',
    marginTop: '20px',
    padding: '20px 44px',
    '& th':{
        color:'#fff'
    },
    '& td':{
        color:'#000',
        fontSize: '14px',
        '& div':{
            borderColor: '#000 !important',
            fontSize: '15px',
            fontWeight: '600',
            width: '110px'
        }
    },
    [theme.breakpoints.down('md')]: {
        padding: '20px',
    },
}));

export const SummaryFirstDiv= styled('div')(({theme})=>({
    marginLeft: '20px', 
    marginTop: '20px', 
    borderRight: '1px solid #155041', 
    borderBottom: '1px solid #155041', 
    paddingRight:'20px',
    [theme.breakpoints.down('sm')]: {
        borderRight:'none',
        padding: '0px',
        marginRight: '20px',
        marginTop: '0px'
    },
}))

export const SummarySecondDiv= styled('div')(({theme})=>({
    marginLeft: '0px', 
    paddingLeft: '20px', 
    marginTop: '20px', 
    paddingRight:'20px',
    [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid #155041',
        padding: '0px',
        marginLeft: '20px',
        marginRight: '20px',
        marginTop: '0px'
    },
}))

export const SummaryLastDiv= styled('div')(({theme})=>({
    borderTop: '1px solid #155041', 
    marginLeft: '-1px', 
    marginTop: '-1px', 
    borderLeft: '1px solid #155041', 
    paddingLeft: '20px', 
    marginRight:'20px', 
    paddingTop:'20px',
    [theme.breakpoints.down('sm')]: {
        borderLeft:'none',
        marginLeft: '20px',
        padding: '0px',
        marginTop: '0px'
    },
}))

export const GridExtraSpacing = styled(Grid)(({theme})=>({
    padding:'25px 16px !important',
    '&:first-of-type':{
        marginRight: '-1px',
        borderRight:'1px solid #155041',
    },
    '&:last-of-type':{
        borderLeft:'1px solid #155041',
    },
    [theme.breakpoints.down('sm')]: {
        '&:first-of-type':{
            borderRight:'none',
            borderBottom: '1px solid #155041',
        },
        '&:last-of-type':{
            borderLeft:'none',
        },
    },
}))

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '300px',
    paddingTop: '23px',
    padding: '40px 0px 0px 0px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        padding: '0 0 20px',
    },
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        margin: '0 12px',
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
            fontSize: '15px',
            padding: '7px',
            height: '40px',
        },
    },
}));

export const TableUpperDiv= styled('div')(({theme})=>({
    marginTop: '-28px',
    padding: '30px 50px 10px 44px !important' ,
    fontWeight: 400,
    background: '#fff',
    border: '2px solid #155041',
    borderRadius: '10px',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
        overflow: 'auto',
        padding: '20px !important',
    },
}))

export const SpanTotalFlow = styled('td')(({theme}) => ({
    textAlign:'end',
    '& div': {
        float: 'right',
        padding: '7px 0px',
        borderTop: 'solid 1px #00773E',
        borderBottom: ' solid 3px #00773E',
        fontSize: '16px',
        fontWeight: '600',
        width: '120px',
        minWidth: '120px',
        [theme.breakpoints.down('md')]: {
            minWidth: '80px',
            fontSize: '14px ',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px ',
            width: '120px'
        },
    }
}));

export const ReportAmountTd = styled('td')(() => ({
    textAlign:'end',
    '& div':{
        width: '120px',
        float: 'right'
    }
}));