import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SmallLogo from '../../../components/SmallLogo';
import onboarding from '../../../constants/services/onboarding';
import { ContentStack, DataGrid, Heading, ProceedingBtn, ProfileData, RootStyle, SectionStyle, SmallLogoStyle, Wrapper } from './styled-components';

export default function ProfileSummary() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const getUserInfo = async (userId) => {
        const profileSummary = await onboarding.getUserProfileSummary(userId);
        const details = profileSummary.data;
        const firstName = details?.firstName || '';
        const lastName = details?.lastName || '';
        const email = details?.email || '';
        const username = details?.username || '';
        const { membershipNumber, expirationDate } = details?.subscription;
        
        const { city, state, country, createdAt, houseHoldPeople, gender, ageGroup } = details?.user_detail;
        const localData = [
            {
                title: 'Name: ',
                description: firstName.concat(' ').concat(lastName),
            },
            {
                title: 'Membership Number: ',
                description:  membershipNumber,
            },
            {
                title: 'Email Address: ',
                description: email,
            },
            {
                title: 'Username: ',
                description: username,
            },
            {
                title: 'Location: ',
                description: city.concat(', ').concat(state).concat(', ').concat(country),
            },
            {
                title: 'Gender: ',
                description: gender,
            },
            {
                title: 'Age Group: ',
                description: ageGroup,
            },
            {
                title: 'Customer Since: ',
                description: new Date(createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
            },
            {
                title: 'Subscription Expiry Date: ',
                description: new Date(expirationDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
            },
            {
                title: 'Number of people in household: ',
                description: houseHoldPeople,
            }
        ]
        setData(localData);
    };

    useEffect(() => {
        const userDataSession = sessionStorage.getItem('userData');
        const userId = JSON.parse(userDataSession).id;
        getUserInfo(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToDashboard = () => navigate('/welcome', { replace: true });
    const navigateToPersonal = () => navigate('/my-profile', { replace: true });

    return (
        <RootStyle>
            <SectionStyle>
                <SmallLogoStyle>
                    <SmallLogo sx={{ width: 40, height: 'auto' }} />
                    <Link variant="body">Home Page</Link>
                </SmallLogoStyle>
            </SectionStyle>
            <Wrapper container>
                <Grid container item spacing={1}>
                    <DataGrid item md={7} xs={12}>
                        <Heading variant="h3"><pre style={{ font: 'inherit' }}>Here is your</pre><pre style={{ font: 'inherit' }}>profile summary</pre></Heading>
                    </DataGrid>
                    <Grid item md={5} xs={12}>
                        <ContentStack direction="column" justifyContent="center" alignItems="flex-start" spacing={3}>
                            {data.map((user) => (
                                <ProfileData key={user.title}>
                                    <span className="pTitle">{user.title}</span>
                                    <span className="pDescription">{user.description}</span>
                                </ProfileData>
                            ))}
                        </ContentStack>
                        <ProceedingBtn>
                            <LoadingButton fullWidth size="large" variant="contained" onClick={navigateToDashboard}>
                                Proceed to the Hub
                            </LoadingButton>
                        </ProceedingBtn>
                        <LoadingButton fullWidth size="large" variant="outlined" onClick={navigateToPersonal}>
                            Edit Profile
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Wrapper>
        </RootStyle>
    );
}
