import { styled, Box } from '@mui/material';

export const HomeHeaderBanner = styled('div')(() => ({
    width: '100%',
    minHeight: '100vh',
    height: 620,
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

export const HeadBanner = styled('div')(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: 25,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
        padding: '8px 5px',
    },
}));

export const CaptionText = styled('div')(({ theme }) => ({
    width: 650,
    paddingLeft: 150,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '100vh',
    height: 620,
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
        width: '90%',
        paddingLeft: 50,
    },
    [theme.breakpoints.down('sm')]: {
        width: '95%',
        paddingLeft: 20,
    },
    '& h1': {
        [theme.breakpoints.up('lg')]: {
            fontSize: '90px !important',
            lineHeight: '74px !important',
        },
    },
    '& h2': {
        margin: '27px 0 !important',
        [theme.breakpoints.up('lg')]: {
            fontSize: '72px !important',
            lineHeight: '74px !important',
        },
    },
    '& h5': {
        fontWeight: '400',
        width: '89%',
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            lineHeight: '22px',
        },
        color: theme.palette.primary.contrastText,
    },
}));

export const RightButtons = styled(Box)(({ theme }) => ({
    '& a': {
        marginLeft: 15,
        marginTop: 13,
        [theme.breakpoints.up('md')]: {
            minWidth: 210,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 5,
            padding: 5,
            height: 30,
            fontSize: 12,
        },
        borderRadius: 5,
        fontWeight: '500',
    },
}));