import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import transaction from 'constants/services/transaction';
import { fDate } from 'utils/formatTime';
import { showError } from 'utils/toast';
import user from 'constants/services/user';
import DialogAtom from "components/dialog/Dialog";
import Title from '../../components/Title';
import Page from '../../components/Page';
import Table from './table/Table'
import { ContentStyle, Wrapper, PageTopRightBg, Content } from './styled-components';
import Filter from './filter/Filter';
import TransactionEditDialog from './edit-dialog/TransactionEditDialog';

export default function DownloadedTransactions() {
    const { t } = useTranslation();
    const [accountFilters, setAccountFilters] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [filtersData, setFiltersData] = useState({
        dateFrom: null,
        dateTo: null,
        amountFrom: '',
        amountTo: '',
        category: '',
        subCategory: '',
        payee: '',
        isReviewed: false,
        transactionType: 'Expense'
    });
    const [loading, setLoading] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [userCategories, setUserCategories] = useState([]);
    const [editTransaction, setEditTransaction] = useState(null);
    const [splitConfirmDialog, setSplitConfirmDialog] = useState(false);

    const getFilters = async () => {
        const response = await transaction.getTransactionFilters();
        setAccountFilters(response?.transactionFilters || null);
    };

    const fetchTransactions = async () => {
        const filters = {};
        setLoading(true);
        // eslint-disable-next-line no-restricted-syntax, prefer-const
        for (let [key, value] of Object.entries(filtersData)) {
            if (value) {
                if (key === 'dateFrom' || key === 'dateTo') {
                    value = fDate(value, 'dd.MM.yyyy');
                }
                if (key === 'category' && (!filtersData.subCategory || !filtersData.subCategory?.length)) {
                    const filteredData = Object.keys(accountFilters)
                        .filter(key => value.includes(key))
                        .reduce((obj, key) => {
                            obj[key] = accountFilters[key];
                            return obj;
                        }, {});
                    if (filteredData) {
                        const filteredAccounts = [...Object.values(filteredData).flat().map((a) => a.accountId)]
                        value = filteredAccounts.join(',');
                    }
                }
                if (key === 'subCategory' && value.length) {
                    const accId = value.map((v) => v.id);
                    value = accId.join(',');
                }
                if (['category', 'subCategory'].includes(key)) {
                    key = 'account';
                }
                if (value?.length) {
                    filters[key] = value;
                }
            }
        }

        try {
            const response = await transaction.getTransactions(filters);
            setLoading(false);
            const transactionsList = response?.transactions || [];
            setTransactions(transactionsList);
        } catch (error) {
            setLoading(false);
            showError(t, error);
        }
    };

    const getCategories = async () => {
        const response = await user.getUserCategories();
        const categories = response?.data || [];
        setUserCategories(categories);
    };

    useEffect(() => {
        getFilters();
        fetchTransactions();
        getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateFilters = (key, value) => {
        let updatedFilters = {
            ...filtersData,
            [key]: value,
        }
        if (key === 'category') {
            updatedFilters = {
                ...updatedFilters,
                'subCategory': []
            }
        }
        setFiltersData(updatedFilters);
    };

    const onTransactionReviewed = async (transactionId, isReviewed) => {
        try {
            await transaction.updateTransaction(transactionId, { isReviewed: !isReviewed });
            const transactionList = [...transactions];
            const transactionObj = transactionList.find((trans) => trans.id === transactionId);
            if (transactionObj) {
                transactionObj.isReviewed = !isReviewed;
                setTransactions(transactionList);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const onRowClick = async (transactionId) => {
        const transactionObj = await transaction.getTransaction(transactionId);
        const { id, transactionDate, merchant, categoryId, subCategoryId, tag, notes, amount } = transactionObj.data;

        const category = userCategories.find((c) => c.id === categoryId);
        let subCategory = null;

        if (category) {
            subCategory = category.sub_categories.find((c) => c.id === subCategoryId);;
        } 
        setEditTransaction({
            id,
            merchant,
            transactionDate,
            payee: merchant?.name || '',
            category,
            subCategory,
            tag: tag || '',
            notes: notes || '',
            amount
        });
        setShowEditDialog(true);
    };

    const updateTransactionData = (key, value) => {
        const clonedData = {...editTransaction};
        clonedData[key] = value;
        if (key === 'category') {
            const { name, sub_categories: subCategories } = value;
            const subCategory = subCategories.find((c) => c.name === name);
            clonedData.subCategory = subCategory || '';
        }
        setEditTransaction(clonedData);
    };

    const splitTransactions = async () => {
        const {id, tag, notes, splitArray = [] } = editTransaction;
        const splitTransactions = splitArray.map((split) => ({
            tag,
            notes,
            amount: parseFloat(split.balance) || 0,
            category_id: split.category.id || null,
            sub_category_id: split.subCategory.id || null,
        }));

        try {
            const response = await transaction.splitTransaction(id, splitTransactions);
            if (response.status === 'SUCCESS') {
                fetchTransactions();    
            }
            setEditTransaction(null);
            setShowEditDialog(false);
        } catch (err) {
            showError(t, err);
        }
    };

    const updateTransaction = async () => {
        const {id, transactionDate, category, subCategory, tag, notes, payee, merchant, existingTransaction = false, futureTransaction = false } = editTransaction;
        const payload = {
            categoryId: category?.id || null,
            subCategoryId: subCategory?.id || null,
            tag,
            notes,
            transactionDate,
            merchant: {
                ...merchant,
                name: payee,
            },
            existingTransaction,
            futureTransaction,
        }
        try {
            const response = await transaction.updateTransaction(id, payload);
            if (existingTransaction) {
                fetchTransactions();  
            } else if (response?.data) {
                const {data} = response;
                const { id } = data;
                const transactionList = [...transactions];
                const index = transactionList.findIndex((trans) => trans.id === id);
                if (index > -1) {
                    transactionList[index] = {...data};
                    setTransactions(transactionList);
                }
            }
            setEditTransaction(null);
            setShowEditDialog(false);
        } catch (err) {
            showError(t, err);
        }
    }

    const transactionSave = async (sArray) => {
        if (sArray?.length) {
            await splitTransactions();
        } else {
            updateTransaction();
        }
    }

    const saveTransaction = async () => {
        const {splitArray = [], existingTransaction, futureTransaction } = editTransaction;
        if (existingTransaction || futureTransaction) {
            setSplitConfirmDialog(true);
        } else {
            transactionSave(splitArray);
        }
    };

    const onDialogAction = (buttonKey) => {
        if (buttonKey === 'cancel') {
            setSplitConfirmDialog(false);
        } else {
            transactionSave(editTransaction?.splitArray || []);
            setSplitConfirmDialog(false);
        }
    }

    const getConfirmDialog = () => <DialogAtom
        open={splitConfirmDialog}
        maxWidth="sm"
        dialogTitle="Confirm Dialog"
        content={(
            <>
                Are you sure to make this change as it could impact multiple existing transactions (past and present) ?
            </>
        )}
        onDialogAction={onDialogAction}
    />

    return (
        <Page title={t('downloaded-transactions.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Content>
                        <Title title={t('downloaded-transactions.title')} subtitle={t('downloaded-transactions.subtitle')} tooltip={t('downloaded-transactions.tooltip')} />
                    </Content>
                    {
                        accountFilters === null ?
                            <>
                                <Typography variant="h6" style={{textAlign: 'center'}}>Please add a financial institution to see data</Typography>
                                
                            </> :
                            <>
                                <Grid container spacing={2}>
                                    <Grid item md={11} xs={12}>
                                        <Filter
                                            filtersData={filtersData}
                                            updateFilters={updateFilters}
                                            accountFilters={accountFilters}
                                        />
                                    </Grid>
                                    <Grid item md={1} xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <LoadingButton loading={loading} size="large" onClick={() => fetchTransactions()} variant="contained">
                            Go
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4} pt={0} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs={12}>
                                        <Table
                                            transactions={transactions}
                                            onTransactionReviewed={onTransactionReviewed}
                                            onRowClick={onRowClick}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                    }
                    {showEditDialog ? <TransactionEditDialog
                        showEditDialog={showEditDialog}
                        transactionData={editTransaction}
                        updateTransactionData={updateTransactionData}
                        userCategories={userCategories}
                        onClose={() => {
                            setEditTransaction(null);
                            setShowEditDialog(false);
                        }}
                        onSave={saveTransaction}
                    />: ''}
                    {getConfirmDialog()}
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}