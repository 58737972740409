import { Grid, styled, Button, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    height:'100%',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const TotalFlowValueColumn = styled('td')(() => ({
    display:'flex',
    justifyContent:'end',
    '& div':{
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #00773E',
        borderBottom: ' solid 3px #00773E',
        fontSize: '16px',
        fontWeight: '600',
        width: '120px',
    }
}));

export const NoDataTd= styled('td')(()=>({
    textAlign: 'center',
    fontWeight: '600'
}))

export const LoaderGif= styled('img')(()=>({
    width:'180px',
    margin:'auto'
}))

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 4%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 4%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
        '& .MuiGrid-container':{
            paddingTop:'0px'
        },
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderSpacing: '0px !important',
    '& th': {
        textAlign: 'left',
        paddingTop: 9,
        paddingBottom: 9,
        paddingRight: '10px !important',
        paddingLeft:'10px !important',
        color: theme.palette.primary.dark,
        fontSize: 18,
        width: '120px',
        minWidth: '120px',
        background: '#fff',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
        '&.highlighted-th': {
            background: 'rgb(158, 203, 181) !important',
        }
    },
    '& td': {
        paddingTop: 9,
        paddingBottom: 10,
        paddingRight:'10px !important',
        paddingLeft:'10px !important',
        background: '#fff',
        width: '120px',
        minWidth: '120px',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 14,
        },
        '&.highlighted-td': {
            background: '#F2F8F5',
        }
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '&.highlighted-fields': {
        '& th': {
            ':last-child, :nth-last-child(2)': {
                background: 'rgb(158, 203, 181) !important',
            },
        },
        '& td': {
            ':last-child, :nth-last-child(2)': {
                background: '#F2F8F5',
            },
        }
    }
}));

export const SpanTotalFlow = styled('div')(({theme}) => ({
    padding: '7px 0px',
    borderTop: 'solid 1px #00773E',
    borderBottom: ' solid 3px #00773E',
    fontSize: '16px',
    fontWeight: '600',
    minWidth: '120px',
    width: '120px',
    marginLeft: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('xl')]: {
        width: '120px',
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '80px',
        fontSize: '12px ',
    },
}));

export const TopSideBar = styled('td')(() => ({
    color: '#00773E',
    fontWeight: '600'
}));

export const SubmitButton = styled(LoadingButton)(() => ({
    padding: '6px 6px',
    height: 'auto',
    minWidth: '72px'
}));

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' center',
}))

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '300px',
    paddingTop: '23px',
    padding: '40px 0px 0px 0px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
        padding: '0 0 20px',
    },
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        margin: '0 12px',
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
            fontSize: '15px',
            padding: '7px',
            height: '40px',
        },
    },
}));

export const TableSubHeadingColumn = styled('td')(() => ({
    fontWeight: '700 !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    // textAlign: 'end !important',
    color: '#64A940 !important',
    marginLeft: '20px',
}));

export const StickyTable= styled('div')(({theme}) => ({
    padding: '30px 50px 10px 44px !important' ,
    background: '#fff',
    minHeight:'180px',
    border: '2px solid #155041',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        padding: '10px !important',
        fontSize: 12,
        marginTop:'10px !important',
    },
    [theme.breakpoints.down('llg')]: {
        padding: '42px 15% 62px 15%',
    },
    '& .chartjs-render-monitor':{
        minHeight: '230px',
    }
}));

export const TableWrapper= styled('div')(() => ({
    overflowX: 'auto',
    paddingBottom: '15px'
}));

export const StickyFirstTh= styled('th')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '250px !important',
    minWidth: '250px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const StickySecondTh= styled('th')(({theme}) => ({
    position: 'sticky !important',
    right: '139px',
    zIndex: 12,
    textAlign:'end !important',
    [theme.breakpoints.down('md')]: {
        right: '78px',
    },
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        right: 'auto',
    },
}));

export const StickyTh = styled('th')(({theme}) => ({
    position: 'sticky !important',
    left: '250px',
    zIndex: 12,
    width: '150px !important',
    minWidth: '150px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const ExtraTd = styled('td')(()=>({
    padding: '1px !important'
}))

export const StickyThirdTh= styled('th')(({theme}) => ({
    position: 'sticky !important',
    right: '-1px',
    zIndex: 12,
    textAlign:'end !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        right: 'auto',
    },
}));

export const StickyFourthTh= styled('th')(({theme}) => ({
    position: 'sticky !important',
    left: '160px',
    zIndex: 12,
    width: '130px !important',
    minWidth: '130px !important',
    textAlign:'end !important',
    [theme.breakpoints.down('md')]: {
        left: '78px',
    },
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
    '&.align-left': {
        textAlign: 'left !important',
    }
}));

export const StickyFirstTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '250px !important',
    minWidth: '250px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const StickySecondTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    right: '139px',
    zIndex: 12,
    textAlign:'end',
    [theme.breakpoints.down('md')]: {
        right: '78px',
    },
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        right: 'auto',
    },
}));

export const StickyThirdTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    right: '-1px',
    zIndex: 12,
    textAlign:'end',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        right: 'auto',
    },
}));

export const StickyFourthTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    left: '160px',
    zIndex: 12,
    width: '130px !important',
    minWidth: '130px !important',
    textAlign:'end',
    [theme.breakpoints.down('md')]: {
        left: '78px',
    },
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));
export const StickyTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    left: '250px',
    zIndex: 12,
    width: '150px !important',
    minWidth: '150px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const GraphStyle= styled('div')(({theme})=>({
    overflow: 'auto',
    marginTop:'0px !important',
    '& .chartjs-render-monitor':{
        height: '400px !important',
    },
    [theme.breakpoints.down('sm')]: {
        '& .chartjs-render-monitor':{
            height: '450px !important',
            width: '100% !important',
        }
    },
}));

export const AmountDiv = styled('div')(({theme}) => ({
    padding: '7px 0px',
    borderTop: 'solid 1px #00773E',
    borderBottom: ' solid 3px #00773E',
    fontSize: '16px',
    fontWeight: '600',
    minWidth: '120px',
    width: '120px',
    marginLeft: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
        width: '120px',
    },
    [theme.breakpoints.down('md')]: {
        minWidth: '80px',
        fontSize: '12px ',
    },
}));

export const StickyCreditFirstTd= styled('td')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '160px !important',
    minWidth: '160px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const StickyCreditFirstTh= styled('th')(({theme}) => ({
    position: 'sticky !important',
    left: '0px',
    zIndex: 12,
    width: '160px !important',
    minWidth: '160px !important',
    [theme.breakpoints.down('sm')]: {
        position:'inherit !important',
        left: 'auto',
    },
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 125,
    height: 30,
    padding: 0,
    display: 'flex',
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 5,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 67,
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 0,
        position: 'relative',
        '&:before, &:after': {
            position: 'absolute',
            left: 0,
            top: 2,
            content: "'Yes'",
            width: 67,
            height: 30,
            textAlign: 'center',
            color: theme.palette.secondary.contrastText,
            [theme.breakpoints.down('md')]: {
                top: 5,
            },
        },
        '&:after': {
            content: "'No'",
            left: '100%',
            color: theme.palette.primary.contrastText,
        },
        '&.Mui-checked': {
            transform: 'translateX(67px)',
            '&:before': {
                left: '-100%',
                content: "'Yes'",
                color: theme.palette.primary.contrastText,
            },
            '&:after': {
                content: "'No'",
                left: '0',
                color: theme.palette.secondary.contrastText,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: 'inherit',
            },
        },
        '&.Mui-disabled': {
            color: 'rgb(158, 203, 181) !important',
            pointerEvents: 'none',
            cursor: 'default',
            '& .MuiSwitch-thumb': {
                backgroundColor: 'rgb(158, 203, 181) !important',
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 67,
        height: 30,
        borderRadius: 0,
        background: theme.palette.primary.main,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'inherit',
        boxSizing: 'border-box',
    },
}));