import { Grid, styled, Input, Link, Typography, Menu, alpha, TextareaAutosize } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    height:'100%',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 14%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const HeaderTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    padding: '12px 0px',
    fontSize: 16,
    borderSpacing: '0px',
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    ' & tr:nth-of-type(even) ': {
        background: '#e9e9e988',
    },
    '& th': {
        color: theme.palette.primary.dark,
        fontSize: 20,
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingTop: '12px',
        textAlign: 'start',
        paddingBottom: '2px',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: '14px',
        paddingRight: '24px',
        paddingLeft: '24px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const HeaderDivStyle = styled('div')({
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
    marginTop: '20px',
});

export const TitleBorderStyle = styled('td')(({ theme }) => ({
    borderBottom: '2px solid #89A69F',
}))

export const StyledInput = styled(Input)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    '& input': {
        textAlign: 'left',
    },
}));

export const StyledTypography = styled('td')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    '& span': {
        marginRight: -10,
    },
    '& input': {
        textAlign: 'left',
    },
}));

export const StyledButton = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    marginRight: '0px',
    fontWeight: '400',
    lineHeight: '2.8',
    marginBottom: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
}));

export const StyledTask = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '440px',
    textTransform: 'capitalize'
}));

export const DateInputStyle = styled(Input)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px',
}));

export const DropDownMenu = styled(Menu)(({ theme }) => ({
    '& ul': {
        padding: '8px 6px !important',
        background: '#F8F8F8',
        border: '1px solid #00773E',
        borderRadius: '10px',
        '&li': {
            padding: '10px'
        }
    },
}))

export const FormHeaderTd = styled('td')(() => ({
    fontWeight: '600'
}))

export const BorderStyle = styled('td')(() => ({
    borderBottom: '2px solid #89A69F',
    paddingTop: '0px !important',
    paddingBottom:'0px !important'
}))

export const ForumTopicsTd = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '6px'
}))

export const ForumSubTopicsTd = styled(Typography)(() => ({
    cursor: 'pointer',
    '& p': {
        fontSize: '14px',
        'strong': {
            color: '#00773E'
        }
    }
}))

export const ForumTagsTopicsTd = styled('span')(() => ({
    fontWeight: '500',
    color: '#00773E'
}))

export const ForumLikeBox = styled(Typography)(() => ({
    cursor: 'pointer',
    "& .MuiBadge-badge": {
        color: "lightgreen",
        backgroundColor: "green"
    }
}))

export const ForumDateTd = styled('td')(() => ({
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '0px !important'
}))

export const ForumStyleBtn = styled('td')(() => ({
    verticalAlign: 'top',
    horizontalAlign: 'right',
    paddingTop: '0px',
    paddingRight: '0px',
    display: 'flex',
    justifyContent: 'end'
}))

export const ForumTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    padding: '12px 0px',
    fontSize: 16,
    borderSpacing: '0px',
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    '& th': {
        color: theme.palette.primary.dark,
        fontSize: 18,
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingTop: '12px',
        textAlign: 'start',
        paddingBottom: '2px',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: '14px',
        paddingRight: '24px',
        paddingLeft: '24px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const ForumDivStyle = styled('div')({
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
});

export const RepliesTdStyle = styled('td')({
    verticalAlign: 'top',
    horizontalAlign: 'right',
    paddingTop: '0px !important'
})

export const BootstrapTextarea = styled(TextareaAutosize)(({ theme }) => ({
    padding: 20,
    fontFamily: 'Poppins',
    marginBottom: '0px !important',
    outlineColor: '#00773E',
    height: '200px !important',
    backgroundColor: '#F8F8F8',
    borderColor: '#00773E',
    borderRadius: 10,
    label: {
        fontSize: 16,
        color: theme.palette.primary.contrastText,
        transform: 'scale(1)',
        marginBottom: 10,
    },
    'label + &': {
        marginTop: '0 !important',
        width: '100%',
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginBottom: 30,
        },
    },
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        borderRadius: 10,
        '&:not(.Mui-error)': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f8f8f8',
            },
        },
    },
    '& .Mui-error': {
        marginLeft: 0,
    },
    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 10,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
        '&:focus-visible': {
            outline: 'none',
            '& ~ .MuiOutlinedInput-notchedOutline': {
                borderColor: '#F8F8F8',
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            },
        },
        '&:-webkit-autofill': {
            boxShadow: 'inset 0 0 150px 0 #F8F8F8',
        },
    },
}));

export const ForumMediaFlag = styled(Typography)(() => ({
    display: 'flex',
    flexDirection: 'row',
    cursor:'pointer',
    alignItems: 'center',
    '& span': {
        fontSize: 14
    }
}))