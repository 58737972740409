import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@mui/material/Input';
import moment from 'moment';
import { TextField, Button, Grid, styled, Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import { AuthContext } from 'contexts/AuthProvider';
import transaction from 'constants/services/transaction';
import { LoadingButton } from '@mui/lab';
import { fDate } from 'utils/formatTime';
import calenderIcon from '../../../downloadEdits/CalenderIcon';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInput, AddMoreTbBUtton, AddMoreButton, BudgetInputTwo, DateGrid } from '../styled-components';
import { currencyCodes } from '../../../../utils/currencyCodes';
import { getCurrency, getCurrencyMultiplicationFactor, getPositiveNumber } from '../../../../utils/calCommonFunction';

const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    padding: '5px 10px',
    fontSize: '14px',
    fontWeight: '500',
    textAlign:' center',
    textTransform: 'none',
}))

const InputLabel = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '8px',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export default function EditOthersView({ categoryDetail, onSaveData, onUnassign, isSaving }) {
    const {
        auth,
    } = useContext(AuthContext);
    const { t } = useTranslation();
    const [jsonData, setJsonData] = useState([{
        description: 'Opening balance',
        date: '',
        increase: '',
        decrease: '',
        balance: '',
        balanceLocalCurrency: '',
    }]);
    const navigate = useNavigate();
    const [description, setDescription] = useState(categoryDetail?.manual_transaction?.description || '');
    const [currency, setCurrency] = useState(currencyCodes.find((row) => {
        if (categoryDetail?.manual_transaction?.currency) {
            return row.CurrencyCode === categoryDetail?.manual_transaction?.currency
        }
        return row.CurrencyCode === getCurrency()
    }));

    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [currencyMultiplicatonFactor, setCurrencyMultiplicationFactor] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (currency) {
            const symbol = getCurrencySymbolByCurrencyCode(currency.CurrencyCode);
            setCurrencySymbol(symbol);
            if (auth?.localCurrency !== currency.CurrencyCode) {
                const factor = getCurrencyMultiplicationFactor(currency.CurrencyCode, auth.localCurrency);
                setCurrencyMultiplicationFactor(factor);
            } else {
                setCurrencyMultiplicationFactor(1);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    useEffect(() => {
        if (categoryDetail?.manual_transaction?.data?.tableData?.length) {
            const tableData = categoryDetail?.manual_transaction?.data?.tableData?.map((rowData) => ({
                ...rowData,
                increase: Number(rowData?.increase)?.toLocaleString("en-US") || '',
                decrease: Number(rowData?.decrease)?.toLocaleString("en-US") || '',
                balance: Number(rowData?.balance?.toString().replace(/[^0-9.-]+/g, ''))?.toLocaleString("en-US") || '',
                balanceLocalCurrency: Number(rowData?.balanceLocalCurrency?.toString().replace(/[^0-9.-]+/g, ''))?.toLocaleString("en-US") || '',
            }));
            setJsonData(tableData);
        }
    }, [categoryDetail]);

    const getBudgetInputTwo = (value, negative = false, sty = {}, symbol = currencySymbol) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, paddingBlock: 10, ...sty }}>
        <span>{negative ? '-': ''}{symbol}</span>
        <div>{value}</div>
    </BudgetInputTwo>

    const formatAndHandleChange = (index, e) => {
        const value = e.target.value.replace(/[^0-9.-]+/g, '');
        if (value === '' || /^[0-9\b]+$/.test(value)) {
            const budget = Number(value).toLocaleString("en-US");
            handleChange(index, e.target.name, budget);
        }
    }

    const handleChange = (index, name, value) => {
        const clonedJsonData = [...jsonData];
        clonedJsonData[index] = {...clonedJsonData[index], [name]: value }
        setJsonData(clonedJsonData);
    }

    const onAddAnother = () => {
        const clonedJsonData = [...jsonData];
        clonedJsonData.push({
            description: '',
            date: '',
            increase: '',
            decrease: '',
            balance: '',
            balanceLocalCurrency: ''
        });
        setJsonData(clonedJsonData);
    }

    const getTotal = (totalKey) => {
        let total = 0;
        jsonData.forEach((row) => {
            if (row[totalKey] === '') return;
            total += Number(row[totalKey].replace(/[^0-9.-]+/g, ''));
        });
        return total;
    }

    const getBalance = () => getTotal('increase') - getTotal('decrease');
    const onSaveInfo = () => {
        const isDataValid = jsonData.every((data) => data.date && moment(data.date).isValid());
        if (!isDataValid) {
            // eslint-disable-next-line no-alert
            alert('Please enter a date for all new transactions');
            return;
        }

        onSaveData({
            manualTransaction: {
                description: "",
                currency: currency.CurrencyCode,
                targetCurrency: auth?.localCurrency,
                data: {
                    tableData: [...jsonData].map((rowData, index) => ({
                        ...rowData,
                        formattedDate: fDate(rowData?.date, 'dd.MM.yyyy'),
                        increase: rowData?.increase?.replace(/[^0-9.-]+/g, '') || '',
                        decrease: rowData?.decrease?.replace(/[^0-9.-]+/g, '') || '',
                        balance: getRowBalance(index)
                    })),
                }
            }
        });
    };

    const fetchBalance = async () => {
        const isDataValid = jsonData.every((data) => data.date && moment(data.date).isValid());
        if (!isDataValid) {
            // eslint-disable-next-line no-alert
            alert('Please enter a date for all new transactions');
            return;
        }
        setIsSubmitting(true);
        const payload =  {
            sourceCurrency: currency?.CurrencyCode,
            targetCurrency: auth?.localCurrency,
            data: [...jsonData].map((rowData, index) => ({
                ...rowData,
                formattedDate: fDate(rowData?.date, 'dd.MM.yyyy'),
                increase: rowData?.increase?.replace(/[^0-9.-]+/g, '') || '',
                decrease: rowData?.decrease?.replace(/[^0-9.-]+/g, '') || '',
                balance: getRowBalance(index)
            }))
        };

        const res = await transaction.fetchBalanceInLocalCurrency(payload);
        if (res?.data) {
            const transformedData = res?.data?.map((rowData) => ({
                ...rowData,
                increase: Number(rowData?.increase)?.toLocaleString("en-US") || '',
                decrease: Number(rowData?.decrease)?.toLocaleString("en-US") || '',
                balance: Number(rowData?.balance?.toString().replace(/[^0-9.-]+/g, ''))?.toLocaleString("en-US") || '',
                balanceLocalCurrency: rowData?.balanceLocalCurrency || '',
            }));
            setJsonData(transformedData);
            setIsSubmitting(false);
        }
    };

    const getRowBalance = (index) => {
        const rowBalance = Number(jsonData[0].balance?.replace(/[^0-9.-]+/g, ''));
        let computation = 0;
        for (let ind = 1; ind <= index; ind += 1) {
            computation += Number(jsonData[ind].increase?.replace(/[^0-9.-]+/g, ''));
            computation -= Number(jsonData[ind].decrease?.replace(/[^0-9.-]+/g, ''));
        }
        return rowBalance + computation;
    }

    const getNetIncreaseDecrease = () => {
        const netBalance = getRowBalance(jsonData.length - 1);
        const balanceInLocalCurrency = netBalance * currencyMultiplicatonFactor;

        const netIncreaseDecrease = getBalance();
        let openingBalanceLocalCurrency = 0;
        if (jsonData[0]?.balanceLocalCurrency) {
            openingBalanceLocalCurrency = Number(jsonData[0].balanceLocalCurrency?.toString().replace(/[^0-9.-]+/g, ''));
        }
        const netIncDecInLocalCurrency = balanceInLocalCurrency - openingBalanceLocalCurrency;
        return (
            <>
                <tr>
                    <td colSpan={5} style={{ color: '#00773E', fontWeight: 700 }}>
                        {t('view-manual-transactions.balance-as-of')}
                    </td>
                    {getBudgetInputTwo(getPositiveNumber(netBalance), netBalance < 0, { color: netBalance < 0 ? 'red': 'black', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' })}
                    {auth?.localCurrency !== currency?.CurrencyCode ? getBudgetInputTwo(getPositiveNumber(balanceInLocalCurrency), netBalance < 0, { color: netBalance < 0 ? 'red': 'black', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' }, auth.currencySymbol) : <></>}
                </tr>
                <tr>
                    <td colSpan={5} style={{ color: '#00773E', fontWeight: 700 }}>
                        {t('view-manual-transactions.net-inc-dec-house')}
                    </td>
                    {getBudgetInputTwo(getPositiveNumber(netIncreaseDecrease), netIncreaseDecrease < 0, { color: netIncreaseDecrease < 0 ? 'red': 'black', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' })}
                    {auth?.localCurrency !== currency?.CurrencyCode ? getBudgetInputTwo(getPositiveNumber(netIncDecInLocalCurrency), netIncreaseDecrease < 0, { color: netIncreaseDecrease < 0 ? 'red': 'black', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' }, auth.currencySymbol) : <></>}
                </tr>
            </>
        )
    };
    
    const onUnassignTemplate = () => {
        onUnassign(categoryDetail?.id);
    };

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <InputLabel shrink htmlFor="bootstrap-input" style={{ fontWeight: 500 }}>
                        {t('edit-manual-transactions.select-currency')}
                    </InputLabel>
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 0 }}>
                    <AutoFieldStyle
                        options={currencyCodes}
                        value={currency}
                        getOptionLabel={(option) => (option?.CurrencyCode || '')}
                        disableClearable
                        onChange={(event, value) => setCurrency(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select Currency Code"
                                className="dropMenu"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} pt={2}>
                <Grid item xs={3}>
                    <InputLabel shrink htmlFor="bootstrap-input" style={{ fontWeight: 500 }}>
                        {t('edit-manual-transactions.enter-description')}
                    </InputLabel>
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 0 }}>
                    <Input
                        name="city"
                        style={{ width: '100%' }}
                        className="dropMenu"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }} />
                </Grid>
            </Grid>
            <TableStyle style={{ padding: '0px 2%', marginTop: '3%', borderSpacing: 20 }}>
                <tbody>
                    <tr>
                        <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'left' }}>
                            {t('view-manual-transactions.description')}
                        </td>
                        <DiffTableContent>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.balance')} ({currency.CurrencyCode})</DiffTableContent>
                        {(auth?.localCurrency !== currency?.CurrencyCode) && <DiffTableContent style={{ textAlign: 'right'}}>{t('view-manual-transactions.balance')} ({auth.localCurrency})</DiffTableContent>}
                    </tr>
                    {jsonData.map((row, index) => <tr key={index}>

                        <td colSpan={2} style={{ textAlign: 'left' }}>
                            {index === 0 ? <div>{row.description}</div> : 
                                <Input
                                    style={{ width: '90%'}}
                                    type='text'
                                    name="description"
                                    value={row?.description}
                                    placeholder=""
                                    onBlur={(event) => handleChange(index, event.target.name, event.target.value)}
                                    onChange={(event) => handleChange(index, event.target.name, event.target.value)}
                                />
                            }
                        </td>
                        <BudgetInput>
                            <DateGrid item md={4.5} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={row?.date || null}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        inputFormat='dd-MMM-yy'
                                        onChange={(newValue) => {
                                            handleChange(index, 'date', newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} 
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:"Date"
                                            }}
                                        />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </BudgetInput>
                        <BudgetInput>
                            {index === 0 ? '': <>
                                <span>{currencySymbol}</span>
                                <Input
                                    type='text'
                                    name="increase"
                                    value={row?.increase}
                                    placeholder="0"
                                    onBlur={(event) => formatAndHandleChange(index, event)}
                                    onChange={(event) => formatAndHandleChange(index, event)}
                                />
                            </>}
                        </BudgetInput>
                        <BudgetInput>
                            {index === 0 ? '' : <>
                                <span style={{ color: 'red' }}>{currencySymbol}</span>
                                <Input
                                    type='text'
                                    name="decrease"
                                    style={{ color: 'red' }}
                                    value={row?.decrease}
                                    placeholder="0"
                                    onBlur={(event) => formatAndHandleChange(index, event)}
                                    onChange={(event) => formatAndHandleChange(index, event)}
                                />
                            </>}
                        </BudgetInput>
                        {index === 0 ? <BudgetInput>
                            <span>{currencySymbol}</span>
                            <Input
                                type='text'
                                name="balance"
                                value={row?.balance}
                                placeholder="0"
                                onBlur={(event) => formatAndHandleChange(index, event)}
                                onChange={(event) => formatAndHandleChange(index, event)}
                            />
                        </BudgetInput>: getBudgetInputTwo(Number(getRowBalance(index).toString().replace('-', '')).toLocaleString("en-US"), false, { color: getRowBalance(index) < 0 ? 'red' : 'black' })}

                        {(auth?.localCurrency !== currency?.CurrencyCode) && getBudgetInputTwo(getPositiveNumber(row?.balanceLocalCurrency.toString().replace(/[^0-9.-]+/g, '')), false, { color: getRowBalance(index) < 0 ? 'red' : 'black', paddingRight: 10 })}
                    </tr>)}
                    <tr>
                        <AddMoreTbBUtton style={{ textAlign: 'left', paddingLeft: 0 }}><AddMoreButton onClick={onAddAnother}>{t('edit-manual-transactions.add-another')}</AddMoreButton></AddMoreTbBUtton>
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo(getTotal('increase').toLocaleString("en-US"), false, { borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' })}
                        {getBudgetInputTwo(getTotal('decrease').toLocaleString("en-US"), true, { color: 'red', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E' })}
                    </tr>
                    <tr><td>&nbsp;</td></tr>
                    {getNetIncreaseDecrease()}
                </tbody>
            </TableStyle>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40}}>
                <BackButtonStyle variant="contained" onClick={() => navigate('/manual-transactions')}>
                    {t('mortgage-prepayment.go-back')}
                </BackButtonStyle>
                <div>
                    <Button
                        variant="contained"
                        style={{marginRight: 5 }}
                        onClick={onUnassignTemplate}>
                        {t('edit-manual-transactions.unassign-template')}
                    </Button>
                    {auth?.localCurrency !== currency?.CurrencyCode &&
                    <LoadingButton
                        variant="contained"
                        style={{marginRight: 5 }}
                        onClick={fetchBalance}
                        loading={isSubmitting}
                    >
                        {t('edit-manual-transactions.update-balance-in-local-currency')}
                    </LoadingButton>
                    }
                    <LoadingButton
                        variant="contained"
                        onClick={onSaveInfo}
                        loading={isSaving}
                    >
                        {t('edit-manual-transactions.save')}
                    </LoadingButton>
                </div>
            </div>
        </>
    );
}
