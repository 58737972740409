import { styled } from '@mui/material';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const HeadingStyle = styled('div')(({ theme }) => ({
    '& h1': {
        fontWeight: '700',
        [theme.breakpoints.up('lg')]: {
            fontSize: 56,
            lineHeight: '69px',
        },
        color: theme.palette.primary.main,
    },
    '& h2': {
        fontWeight: '700',
        margin: '0 0 30px',
        [theme.breakpoints.up('lg')]: {
            fontSize: 40,
            lineHeight: '48px',
        },
        color: theme.palette.primary.dark,
        position: 'relative',
        '&:after': {
            position: 'absolute',
            left: -136,
            top: 20,
            width: 122,
            height: 1,
            background: theme.palette.primary.contrastText,
            content: "''",
            [theme.breakpoints.down('md')]: {
                width: 50,
                left: -50,
            },
        },
    },
    '& h5': {
        fontWeight: '400',
        width: '89%',
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
            lineHeight: '22px',
        },
        color: theme.palette.primary.contrastText,
    },
}));

export default function headingStyle(props) {
    return (
        <HeadingStyle>
            <ThemeProvider theme={theme}>
                <Typography variant="h1">{props.headLineOne}</Typography>
                <Typography variant="h2">{props.headLineTwo}</Typography>
                <Typography variant="h5">{props.headLineThree}</Typography>
            </ThemeProvider>
        </HeadingStyle>
    );
}
