import { Typography, styled } from '@mui/material';

export const Error = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 30,
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        fontSize: 12,
    },
}));

export const ErrorPwd = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 'auto',
    fontSize: 14,
    // position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        fontSize: 12,
    },
}));

export const ErrorTc = styled(Typography)(({ theme }) => ({
    color: 'red',
    margin: '-30px 0px 5px 0px',
    height: 30,
    fontSize: 14,
    position: 'absolute',
    lineHeight: '14px',
    [theme.breakpoints.down('xl')]: {
        marginTop: -16,
        fontSize: 12,
    },
}));

export const ButtonText = styled('div')(({ theme }) => ({
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));

export const CheckStyle = styled('div')(({ theme }) => ({
    '& label': {
        marginRight: 5,
    },
    '& a': {
        fontSize: 16,
        color: theme.palette.primary.main,
    },
}));

export const DividerStyle = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    // margin: '64px 0',
    height: 2,
    border: 0,
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
        margin: '20px 0',
    },
    '& span': {
        position: 'absolute',
        top: -12,
        left: '50%',
        transform: 'translateX(-50%)',
        background: theme.palette.secondary.contrastText,
        padding: '0 30px',
    },
}));