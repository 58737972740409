import { styled, Box, Checkbox, Divider, Table } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const TableStyle = styled(Table)(({ theme }) => ({
    width: '100%',
    position:'relative',
    paddingTop: '10px',
    minWidth: 550,
    borderRadius: '10px !important',
    borderSpacing: '0px !important',
    [theme.breakpoints.down('md')]: {
        paddingTop: 10,
    },
    '& tr': {
        cursor: 'pointer',
        '&:nth-child(odd)': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        },
        '& .checkBox': {
            paddingLeft: '33px !important',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '14px !important',
            },
        },
        '&.transaction-reviewed': {
            background: 'rgba(76, 175, 80, 0.1)'
        }
    },
    '& th': {
        textAlign: 'left',
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.primary.dark,
        padding: '20px 10px',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
        '&.css-1crl80v-MuiTableCell-root': {
            lineHeight: '0.5rem',
        },
        '& .MuiTableSortLabel-root': {
            color: theme.palette.primary.dark,
        }
    },
    '& td': {
        padding: '2px 10px',
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            padding: '15px 5px 30px',
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const SwitchStyle = styled(Box)(({ theme }) => ({
    width: 136,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '& p': {
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 1,
    },
    '& span + p': {
        left: 'inherit',
        right: 0,
    },
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const DividerStyle = styled(Divider)(({ theme }) => ({
    position: "absolute",
    width: "100% !important",
    borderColor: theme.palette.primary.main,
    display: "inline-block",
    [theme.breakpoints.down('md')]: {
        width: '100% !important',
    },
}));

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

export const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));