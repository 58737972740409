import { styled, Grid, Button } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    paddingTop: '10px',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    // height: '100vh'
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const Content = styled('div')(() => ({
    paddingBottom: '50px',
}));

export const ContentStyle = styled('div')(({ theme, templateName }) => {
    let padding = '20px 12% 10% 12%'
    if (templateName === 'DEBT' || templateName === 'OTHERS') {
        padding = '20px 10% 10% 10%';
    }
    if (templateName === 'INVESTMENT') {
        padding = '20px 7% 10% 7%';
    }
    return {
        padding,
        [theme.breakpoints.down('xl')]: {
            padding,
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4, 2),
        },
        '& h1': {
            width: 400,
            marginBottom: 20,
        },
        '& h2': {
            display: 'none',
        },
    }
});

export const CardStyle = styled('div')(({ theme }) => ({
    padding: '10px 16px',
    height: '166px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    boxShadow: '0px 8px 24px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 11,
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.cardHover,
        boxShadow: '0 8px 24px 1px rgba(0, 0, 0, 0.05)',
        '& p': {
            display: 'flex',
        },
        '& h5': {
            display: 'none',
        }
    },
    [theme.breakpoints.between('md', 'lg')]: {
        minHeight: '122px',
    },
    [theme.breakpoints.down('xl')]: {
        // padding: '48px 10px',
        height: '166px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    '& p': {
        fontSize: 19,
        fontWeight: '400',
        marginTop: '14px',
        color: '#155041',
        margin: '10px 0',
        lineHeight: '26px',
        display: 'none',
        minHeight: 80,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
            minHeight: 'auto',
            lineHeight: '22px',
            marginBottom: '10px'
        }
    },
    '& a': {
        textDecoration: 'none',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& h4': {
        fontSize: 22,
        fontWeight: '700',
        color: theme.palette.primary.main,
        marginBottom: '15px',
        margin: '10px 0',
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            fontSize: 18,
            marginBottom: '15px'
        }
    },
    '& h5': {
        border: '1px solid #00773E',        
        textDecoration: 'none',
        borderRadius: '50px',
        padding: '4px 10px',
        fontSize: '12px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& svg': {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        marginTop: 21,
        marginBottom: 21,
        [theme.breakpoints.down('xl')]: {
            marginTop: 10,
            marginBottom: 9,
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
}));

export const TotalFlow = styled('td')(() => ({
    fontWeight: 700,
    fontSize: 18,
    paddingTop: 0,
    paddingBottom: 0,
    color: '#00773E'
}));

export const TableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    marginTop: '-28px',
    padding: '42px 14% 62px 15%',
    fontWeight: 400,
    background: '#fff',
    border: '2px solid #155041',
    borderRadius: '10px',
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    [theme.breakpoints.down('llg')]: {
        padding: '42px 15% 62px 15%',
    },

    '& th': {
        textAlign: 'left',
        paddingBottom: '4px',
        color: theme.palette.primary.dark,
        fontSize: 18,
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        minWidth: 110,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const DiffTableContent = styled('td')(({ theme }) => ({
    color: '#00773E',
    fontWeight: '600',
    textAlign: 'left',
}));

export const CenterTbText = styled('td')(({ props }) => ({
    textAlign: 'left',
    ...props
}));

export const NegativeText = styled('td')(() => ({
    textAlign: 'left',
    color: 'red'
}));

export const TotalValue = styled('td')(() => ({
    '& div':{
        justifyContent: 'space-between',
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #00773E',
        borderBottom: ' solid 3px #00773E',
        fontSize: '16px',
        fontWeight: '600',
        width: '110px',
        marginLeft:'auto'
    }
}));

export const TotalFlowValueSubColumn = styled('td')(() => ({
    '& div':{
        justifyContent: 'space-between',
        padding: '7px 0 7px 2px',
        borderTop: 'solid 1px #00773E',
        borderBottom: ' solid 3px #00773E',
        fontSize: '16px',
        fontWeight: '600',
        width: '110px',
        // marginLeft:'auto'
    }
}));

export const TotalAmountDiv = styled('div')(() => ({
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
}));

export const BudgetInput = styled('td')(({ props }) => ({
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    paddingLeft: 0,
    ...props,
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'right',
        fontSize: '14px',
        width: '140px',
    },
}));

export const BudgetInput2 = styled('td')(({ props }) => ({
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    paddingLeft: 0,
    ...props,
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& input': {
        textAlign: 'left',
        fontSize: '14px',
        width: '151px',
        paddingTop: 15
    },
}));

export const AddMoreTbBUtton = styled('td')(() => ({
    paddingLeft: '12px',
    paddingTop: '14px'
}));

export const AddMoreButton = styled(Button)(() => ({
    color: '#00773f',
    cursor: 'pointer',
    textDecoration: 'underline',
    width: 'auto',
    fontWeight: '500',
    fontSize: '14px',
    padding: '0px !important',
    textTransform: 'none'
}));

export const BudgetInputTwo = styled('td')(() => ({
    paddingLeft: 10,
    alignItems: 'center',
    width: '151px',
    marginLeft: 'auto',
    '& span': {        
        position: 'absolute',
        zIndex: '1'
    },
    '& div': {
        textAlign: 'right',
        fontSize: '14px',
        // width: '151px',
    },
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                width: 70,
                padding: '10px 0px 10px 10px  !important',
                fontSize: 12,
                [theme.breakpoints.up('xl')]: {
                    fontSize: 16,
                    width: 100,
                },
                textAlign: 'left'
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));
