import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Page from "components/Page";
import Title from "components/Title";
import { PageTopRightBg, SubmitButton, Wrapper } from "./styled-components";
import { BackButtonStyle, ContentStyle } from "../styled-component";
import CreditCardFilter from "../filter/CreditCardFilter";
import { AmountDiv, LoaderGif, NoDataTd, StickyFirstTd, StickyFirstTh, StickyTable, StickyThirdTd, StickyThirdTh, TableStyle, TableWrapper } from "../Expense/styled-component";
import dashboard from '../../../constants/services/dashboard';
import {
    getCurrencySymbol,
    getMonthNameYear,
    getPositiveNumber,
} from '../../../utils/calCommonFunction';
import { ReportAmountTd, TotalAmountDiv } from "../ExecutiveSummary/styled-component";

export default function NetWorth() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);
    const [netWorth, setNetWorth] = useState(null);
    const [monthColumn, setMonthColumn] = useState([]);
    const currencyCode = getCurrencySymbol();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM')
    });

    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const fetchData = async () => {
        setLoading(true);
        const queryString = Object.keys(filtersData)
            .map(
                (key) =>
                    // eslint-disable-next-line
                    key + '=' + filtersData[key]
            )
            .join('&');

        const response = await dashboard.getNetWorth(queryString);
        const data = response?.netWorth;

        const monthRange = Object.keys(data);
        monthRange.sort();
        setMonthColumn(monthRange);
        setNetWorth(data);
        setTableLoader(false);
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTableRow = (filterKey) => {
        let total = 0;
        return <tr>
            <StickyFirstTd>Total {filterKey}</StickyFirstTd>
            {monthColumn.map((item, index) => {
                const amount = netWorth[item][filterKey];
                total += amount;
                return (
                    <ReportAmountTd key={index}>
                        <TotalAmountDiv
                            style={{
                                width: 120,
                                float: 'right',
                            }}
                        >
                            {currencyCode}
                            <span>
                                {getPositiveNumber(amount)}
                            </span>
                        </TotalAmountDiv>
                    </ReportAmountTd>
                )})}
            <StickyThirdTd className="highlighted-td">
                <TotalAmountDiv
                    style={{
                        width: 120,
                        float: 'right',
                    }}
                >
                    {currencyCode}{' '}
                    <span>
                        {getPositiveNumber(total / monthColumn.length)}
                    </span>
                </TotalAmountDiv>
            </StickyThirdTd>
        </tr>
    }
 
    const getNetWorthRow = () => {
        let totalNetWorth = 0;
        let totalAssets = 0;
        let totalLiabilities = 0;
        const increaseDecrease = [''];
        let lastNetWorth = 0;
        return <>
            <tr>
                <StickyFirstTd style={{
                    height: '51px',
                    fontSize: '16px',
                    fontWeight: '600',
                }}>Net Worth</StickyFirstTd>
                {monthColumn.map((item, index) => {
                    const amountAssets = netWorth[item].Assets;
                    totalAssets += amountAssets;

                    const amountLiabilities = netWorth[item].Liabilities;
                    totalLiabilities += amountLiabilities;

                    totalNetWorth = (amountAssets - amountLiabilities);
                    if (index === 0) {
                        lastNetWorth = totalNetWorth; 
                    }
                    if (index > 0) {
                        increaseDecrease.push(totalNetWorth - lastNetWorth);
                        lastNetWorth = totalNetWorth; 
                    }

                    return <td key={index}>
                        <AmountDiv>
                            {currencyCode}
                            <span>
                                {getPositiveNumber(totalNetWorth)}
                            </span>
                        </AmountDiv>
                    </td>
                })}
                <StickyThirdTd className="highlighted-td">
                    <AmountDiv>
                        {currencyCode}
                        <span>
                            {getPositiveNumber((totalAssets / 3) - (totalLiabilities / 3))}
                        </span>
                    </AmountDiv>
                </StickyThirdTd>
            </tr>
            <tr>
                <StickyFirstTd>Increase (Decrease)</StickyFirstTd>
                {increaseDecrease.map((item, index) => <ReportAmountTd key={index}>
                    <TotalAmountDiv
                        style={{
                            width: 120,
                            float: 'right',
                        }}
                    >
                        {index > 0 ? <>
                            {currencyCode}
                            <span>
                                {getPositiveNumber(item)}
                            </span>
                        </> : <>&nbsp;</>}
                        
                    </TotalAmountDiv>
                </ReportAmountTd>
                )}
            </tr>
        </>;
    };

    return (
        <Page title={t('net-worth.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle
                        variant="contained"
                        onClick={() => navigate('/dashboard/all-reports')}>{t('net-worth.back')}
                    </BackButtonStyle>
                    <Title title={t('net-worth.dashboard')} subtitle={t('net-worth.subtitle')} />

                    <Grid container pt={5} pb={5} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                            />
                        </Grid>
                        <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                            <SubmitButton loading={loading} size="large" variant="contained" onClick={() => fetchData()}>
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle>
                                        {
                                            tableLoader ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                :
                                                <>
                                                    {netWorth ? (
                                                        <>
                                                            <thead>
                                                                <tr>
                                                                    <StickyFirstTh>
                                                                        &nbsp;
                                                                    </StickyFirstTh>
                                                                    {monthColumn.map((item, index) => (
                                                                        <th style={{ textAlign: 'end' }} key={index}>
                                                                            {getMonthNameYear(item)}
                                                                        </th>
                                                                    ))}
                                                                    <StickyThirdTh className="highlighted-th">
                                                                        {t('net-worth.average')}
                                                                    </StickyThirdTh>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {getTableRow('Assets')}
                                                                {getTableRow('Liabilities')}
                                                                {getNetWorthRow()}
                                                            </tbody>
                                                        </>
                                                    ) : (
                                                        <tbody>
                                                            <tr>
                                                                <NoDataTd colSpan={4}>
                                                                    {t('executive-summary.no-data-found')}
                                                                </NoDataTd>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </>
                                        }
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
