import request from '../../utils/request';

const getTransactionFilters = () => request.get('transactions/filters');
const getTransactions = (payload) => request.post('transactions', payload);
const getTransaction = (id) => request.get(`transactions/${id}`);
const updateTransaction = (id, payload) => request.put(`transactions/${id}`, payload);
const getManaulTransaction = () => request.get('sub-categories');
const saveTemplate = (id, payload) => request.put(`/sub-categories/${id}`, payload);
const getCategoryDetail = (id) => request.get(`/sub-categories/${id}`);
const splitTransaction = (id, payload) => request.put(`transactions/split/${id}`, payload);
const unassignTemplate = (id) => request.delete(`/sub-categories/${id}/unassign-template`);
const fetchBalanceInLocalCurrency = (payload) => request.post('sub-categories/fetch-balance', payload);

export default {
    getTransactionFilters,
    getTransactions,
    getTransaction,
    updateTransaction,
    getManaulTransaction,
    saveTemplate,
    getCategoryDetail,
    splitTransaction,
    unassignTemplate,
    fetchBalanceInLocalCurrency
};