import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography, Link, IconButton, InputAdornment, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from 'contexts/AuthProvider';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { ButtonText, CheckStyle, DividerStyle } from './styled-components';
import user from '../../../constants/services/user';
import { showError } from '../../../utils/toast';

export default function RegisterForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        setAuth,
    } = useContext(AuthContext);

    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required(t('error-message.first-name-required')),
        lastName: Yup.string().required(t('error-message.last-name-required')),
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
        password: Yup.string()
            .required(t('error-message.password-required'))
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                t('error-message.password-validation')
            ),
        confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('error-message.password-must-match')),
        tnc: Yup.boolean().required(t('error-message.term-and-conditions')).oneOf([true], t('error-message.password-must-match'))
    });

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        tnc: false
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        formState: { isSubmitting, errors },
    } = methods;

    const onSubmit = async (data, subscriptionType = 'FREE_TRIAL') => {
        const {firstName, lastName, email, password} = data;
        const userInfo = {
            firstName,
            lastName,
            email,
            password,
            subscriptionType,
        };
        try {
            const result = await user.registerUser(userInfo);
            const {email, firstName, lastName, token, id} = result.data;
            const sessionData = {
                email,
                firstName,
                lastName,
                token,
                id,
            };
            sessionStorage.setItem('userData', JSON.stringify(sessionData));
            setAuth(sessionData);
            navigate('/setup', { replace: true });
        } catch (error) {
            showError(t, error);
        }
    };

    const startFreeTrial = (data) => {
        onSubmit(data, 'FREE_TRIAL');
    }

    const payAnnual = (data) => {
        onSubmit(data, 'ANNUAL_SUBSCRIPTION');
    };

    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid style={{ position: 'relative', }}>
            <FormProvider methods={methods}>
                <Grid container direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Grid item xs={6}>
                        <RHFTextField
                            name="firstName"
                            label="First Name"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RHFTextField
                            name="lastName"
                            label="Last Name"
                        />
                    </Grid>
                </Grid>
                <RHFTextField
                    name="email"
                    label="Email Address"
                />

                <div style={{ position: 'relative' }}>
                    <RHFTextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        helperText={t('error-message.password-validation')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div style={{ position: 'relative' }}>
                    <RHFTextField
                        name="confirmPassword"
                        label="Confirm Password"
                        type={showCPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowCPassword(!showCPassword)} edge="end">
                                        <Iconify icon={showCPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <CheckStyle direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ mb: 3 }}>
                    <Typography variant="body2">
                        <RHFCheckbox name="tnc" label={t('register-page.i-agree-with')} />
                        <Link underline="always" color="text.primary" href="/terms-and-conditions">
                            {t('register-page.terms-conditions')}
                        </Link>
                    </Typography>
                    {errors.tnc && <FormHelperText error style={{
                        marginTop: -10
                    }}>
                        {t('error-message.term-and-conditions')}
                    </FormHelperText>}
                </CheckStyle>
                
                <ButtonText>
                    <LoadingButton
                        fullWidth
                        size="large"
                        onClick={handleSubmit(startFreeTrial)}
                        variant="contained"
                        loading={isSubmitting}
                    >
                        {t('register-page.start-my-trial')}
                    </LoadingButton>

                    <DividerStyle sx={{ my: 6 }}>
                        <span>or</span>
                    </DividerStyle>

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="button"
                        variant="outlined"
                        loading={isSubmitting}
                        onClick={handleSubmit(payAnnual)}
                    >
                        {t('register-page.annual-subscription')}
                    </LoadingButton>
                </ButtonText>
            </FormProvider>
        </Grid>
    );
}
