import { Grid, styled, Autocomplete, InputLabel, alpha, TextareaAutosize  } from '@mui/material';

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '20px 20%',
    zIndex: -1,
    [theme.breakpoints.down('xl')]: {
        padding: '20px 20%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const Wrapper = styled('div')(({ theme }) => ({
    // height: '100vh',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const Fragment = styled('div')(({ theme }) => ({
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '8px',
    position: 'relative',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('lg')]: {
            fontSize: 15,
            minWidth: '100% !important',
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
            minWidth: '264px !important',
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const InputLabelStyle = styled(InputLabel)(({ theme }) => ({
    fontSize: 16,
    color: theme.palette.primary.contrastText,
    transform: 'scale(1)',
    marginBottom: 10,
    zIndex: -1,
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('xl')]: {
        marginBottom: '8px',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#4B4B4B',
        marginLeft: '5px',
    },
}));

export const ButtonStyle = styled('div')(({ theme }) => ({
    float: 'right',
    marginTop: -20,
    padding: '10px 0px 8px',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '100px 0 28',
    },
}));

export const BootstrapTextarea = styled(TextareaAutosize)(({ theme }) => ({
    padding: 20,
    outlineColor: '#a9d1d9',
    height: '200px !important',
    backgroundColor: '#F8F8F8',
    borderColor: '#F8F8F8',
    borderRadius: 10,
    label: {
        fontSize: 16,
        color: theme.palette.primary.contrastText,
        transform: 'scale(1)',
        marginBottom: 10,
    },
    'label + &': {
        marginTop: '0 !important',
        width: '100%',
        marginBottom: 40,
        [theme.breakpoints.down('xl')]: {
            marginBottom: 30,
        },
    },
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        borderRadius: 10,
        '&:not(.Mui-error)': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#f8f8f8',
            },
        },
    },
    '& .Mui-error': {
        marginLeft: 0,
    },
    '& .MuiInputBase-input': {
        width: '100%',
        borderRadius: 10,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        fontSize: 16,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
        '&:focus-visible': {
            outline: 'none',
            '& ~ .MuiOutlinedInput-notchedOutline': {
                borderColor: '#F8F8F8',
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            },
        },
        '&:-webkit-autofill': {
            boxShadow: 'inset 0 0 150px 0 #F8F8F8',
        },
    },
}));
