import React, { useContext, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gapi } from 'gapi-script';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'contexts/AuthProvider';
import { DividerStyle, LoginBtn, SocialFormButton } from './styled-components';
import auth from '../../../constants/services/auth';
import { showError, showSuccess } from '../../../utils/toast';

export default function AuthSocial() {
    const {
        setAuth,
        setCurrencyMasterData
    } = useContext(AuthContext);

    const { t } = useTranslation();
    const navigate = useNavigate();

    // TODO: Uncomment this if using passport.js
    // const onGoogleLogin = async () => {
    //     window.open("http://localhost:8080/auth/google", "_self");
    // };

    const navigateUser = (result) => {
        const { id, email, firstName, lastName, token, currencyData } = result.data;
        const sessionData = {
            id,
            email,
            firstName,
            lastName,
            token
        };
        sessionStorage.setItem('userData', JSON.stringify(sessionData));
        setAuth(sessionData);
        localStorage.setItem('currencyData', JSON.stringify(currencyData));
        setCurrencyMasterData(currencyData);
        if (result?.data?.isNewUser) {
            navigate('/setup', { replace: true });
        } else {
            showSuccess(t, 'login-page.login-success');
            navigate('/welcome', { replace: true });
        }
    };

    const handleLogin = async (googleData) => {
        if (googleData?.tokenObj) {
            try {
                const result = await auth.googleLogin({ token: googleData.tokenObj.id_token });
                navigateUser(result);
            } catch (error) {
                showError(t, error);
            }
        }
    };

    const responseFacebook = async (response) => {
        if (response?.accessToken) {
            try {
                const result = await auth.facebookLogin({ accessToken: response?.accessToken });
                navigateUser(result);
            } catch (error) {
                showError(t, error);
            }
        }
    };

    const autoLoad = false;

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: '',
            });
        };
        gapi.load('client:auth2', initClient);
    }, []);

    return (
        <>
            <DividerStyle sx={{ my: 2 }} />
            <Stack direction="column" spacing={2}>
                <SocialFormButton>
                    <LoadingButton fullWidth size="large" color="inherit" variant="outlined">
                        {/* <Iconify icon="ant-design:google-plus-outlined" width={30} height={30} mr={2} /> */}
                        <img src="/static/loginImg/google-icon.svg" width="20" alt="" />
                        <Typography>Log in with Google</Typography>
                        {/* TODO: uncomment below line to use passport login */}
                        {/* <LoginBtn onClick={onGoogleLogin} /> */}
                        <LoginBtn>
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                buttonText="Log in with Google"
                                onSuccess={handleLogin}
                                onFailure={handleLogin}
                                cookiePolicy={'single_host_origin'}
                                plugin_name="Moolah Plus"
                            />
                        </LoginBtn>
                    </LoadingButton>
                </SocialFormButton>
                <SocialFormButton>
                    <LoadingButton fullWidth size="large" color="inherit" variant="outlined">
                        {/* <Iconify icon="gg:facebook" width={30} height={22} mr={2} /> */}
                        <img src="/static/loginImg/f-logo.png" width="20" alt="" />
                        <Typography>Log in with Facebook</Typography>
                        <LoginBtn>
                            <FacebookLogin
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                autoLoad={autoLoad}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                cssClass="my-facebook-button-class"
                                icon="fa-facebook"
                            />
                        </LoginBtn>
                    </LoadingButton>
                </SocialFormButton>
            </Stack>
        </>
    );
}
