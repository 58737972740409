// @mui
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Bargraph from '../Graphs/Bargraph';
import dashboard from '../../../constants/services/dashboard';
import { getCurrencySymbol, getNegativeNumber } from '../../../utils/calCommonFunction';
import {
    TableStyle,
    GridExtraSpacing,
    ExecutiveTbStart,
    ExecutiveTitleStyle,
    ExecutiveTbEnd,
    ExecutiveDivStyle,
    ButtonText,
    ExecutiveDivSpacing,
    NoDataTd,
    LoaderGif,
    TotalAmountDiv,
} from './styled-component';

export default function ExecutiveTopTransactionsTable({ getinvestmentChartData }) {
    const [tableLoader, setTableLoader] = useState(false);
    const [showExecutive, setShowExecutive] = useState(true);
    const [fiveExpenseYearData, setFiveExpenseYearData] = useState([]);
    const [fiveExpenseMonthData, setFiveExpenseMonthData] = useState([]);

    // chart
    const [topfiveTransYearChart, setTopfiveTransYearChart] = useState([]);
    const [topfiveTransMonthChart, setTopfiveTransMonthChart] = useState([]);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const getExecutiveSummaryData = async () => {
        const topFiveexpenseMonth = `topexpenses?type=month`;
        const topFiveexpenseYear = `topexpenses?type=year`;

        let fiveExpenseByMonth;
        let fiveExpenseByYear;

        try {
            fiveExpenseByMonth = await getIncInvestmentData(topFiveexpenseMonth);
            setFiveExpenseMonthData(fiveExpenseByMonth);
        }
        catch (error) {
            console.log("err", error)
        }

        try {
            fiveExpenseByYear = await getIncInvestmentData(topFiveexpenseYear);
            setFiveExpenseYearData(fiveExpenseByYear);
        }
        catch (error) {
            console.log("err", error)
        }

        const topfiveTraYearObj = getinvestmentChartData(fiveExpenseByYear, 'Top 5 Expense Transactions This Year');
        const topfiveTraMonthObj = getinvestmentChartData(fiveExpenseByMonth, 'Top 5 Expense Transactions This Month');

        setTopfiveTransYearChart(topfiveTraYearObj);
        setTopfiveTransMonthChart(topfiveTraMonthObj);
    }

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true)
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false)
            return data;
        } catch (error) {
            console.log(error);
            setTableLoader(false)
        }
    };

    useEffect(() => {
        getExecutiveSummaryData()
    }, [])

    return (
        <>
            {
                showExecutive ?
                    <ExecutiveDivStyle>
                        <ExecutiveDivSpacing style={{ padding: '24px 0px' }}>
                            <Grid
                                container
                                spacing={4}
                                justifyContent="center"
                                alignItems="flex-start"
                                style={{ margin: '0px', width: '100%' }}
                            >
                                <GridExtraSpacing item xs={12} sm={6} md={6}>
                                    <ExecutiveTitleStyle>{t('executive-summary.top-5-expense-transactions')}</ExecutiveTitleStyle>
                                    <ExecutiveTitleStyle>{t('executive-summary.this-year')}</ExecutiveTitleStyle>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <ExecutiveTbStart style={{ width: '25%' }}>{t('executive-summary.date')}</ExecutiveTbStart>
                                                <th style={{ textAlign: 'start', width: '45%' }}>{t('executive-summary.payee')}</th>
                                                <th style={{ textAlign: 'end', width: '30%' }}>{t('executive-summary.amount')}</th>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {fiveExpenseYearData?.length > 0 ? (
                                                    fiveExpenseYearData?.map((item, indx) => (
                                                        <tr key={indx}>
                                                            <td>{item.transaction_date}</td>
                                                            <td>{item?.payee}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>{getNegativeNumber(item.amount)}</span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </GridExtraSpacing>
                                <GridExtraSpacing item xs={12} sm={6} md={6}>
                                    <ExecutiveTitleStyle>{t('executive-summary.top-5-expense-transactions')}</ExecutiveTitleStyle>
                                    <ExecutiveTitleStyle>{t('executive-summary.this-month')}</ExecutiveTitleStyle>
                                    <TableStyle>
                                        <thead>
                                            <tr>
                                                <ExecutiveTbStart style={{ width: '25%' }}>{t('executive-summary.date')}</ExecutiveTbStart>
                                                <th style={{ textAlign: 'start', width: '45%' }}>{t('executive-summary.payee')}</th>
                                                <th style={{ textAlign: 'end', width: '30%' }}>{t('executive-summary.amount')}</th>
                                            </tr>
                                        </thead>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {fiveExpenseMonthData?.length > 0 ? (
                                                    fiveExpenseMonthData?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.transaction_date}</td>
                                                            <td>{item?.payee}</td>
                                                            <ExecutiveTbEnd>
                                                                <TotalAmountDiv>
                                                                    {currencySymbol}{' '}
                                                                    <span>{getNegativeNumber(item.amount)}</span>
                                                                </TotalAmountDiv>
                                                            </ExecutiveTbEnd>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </GridExtraSpacing>
                            </Grid>
                        </ExecutiveDivSpacing>
                    </ExecutiveDivStyle>
                    :
                    (
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="flex-start"
                            style={{ margin: '0px', width: '100%' }}
                        >
                            <Grid item xs={12} sm={6} md={6}>
                                <Bargraph chartData={topfiveTransYearChart} currencyCode={currencySymbol} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Bargraph chartData={topfiveTransMonthChart} currencyCode={currencySymbol} />
                            </Grid>
                        </Grid>
                    )
            }
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowExecutive(!showExecutive)}
                >
                    {showExecutive ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
            </ButtonText>
        </>
    );
}
