import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import onboarding from 'constants/services/onboarding';
import yodlee from 'constants/services/yodlee';
import CountryData from 'constants/countryData';
import { showError } from 'utils/toast';
import { addDuration } from 'utils/formatTime';
import { FormContext } from 'pages/onboarding/stepper/context/StepperContext';
import FinancialTitle from '../FinancialTitle';
import { FastLinkContainer, Fragment, HeadArea, TextArea } from './styled-components';
import LinkedAccountInfo from './LinkedAccountInfo';

export default function Step2({onNext}) {
    const {
        personalDetails,
        linkedInstitutions,
        setLinkedInstitutions,
        yodleeAccessToken,
        setYodleeAccessToken
    } = useContext(FormContext);
    const { t } = useTranslation();
    const [showOverview, setShowOverview] = useState(true);
    const navigate = useNavigate();

    let selectedCountryData = CountryData.find(c => c.label === personalDetails?.country?.country);
    if (!selectedCountryData) {
        selectedCountryData = CountryData.find((c) => c.label === 'Canada');
    }

    const saveFinancialInstution = async (financialInstitutions) => {
        try {
            const institutions = financialInstitutions.filter((fi) => fi.status === 'SUCCESS').map((fi) => ({
                providerAccountId: fi.providerAccountId,
                providerId: fi.providerId,
                providerName: fi.providerName,
                requestId: fi.requestId,
                additionalStatus: fi.additionalStatus,
                status: fi.status
            }));
            if (institutions?.length) {
                const result = await onboarding.saveFinancialInstution({institutions});
                setLinkedInstitutions(result?.linkedInstitutions || []);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const isAccesssTokenValid = () => {
        if (!yodleeAccessToken?.accessToken) {
            return false;
        }
        if (addDuration(yodleeAccessToken.issuedAt, {seconds: 1799}) < new Date()) {
            return false;
        }
        return true;
    }

    const loadFastlinkContainer = async () => {
        try {
            let accessTokenInfo = yodleeAccessToken;
            document.body.classList.add('loading-indicator');
            if (!isAccesssTokenValid()) {
                const result = await yodlee.getYodleeToken();
                accessTokenInfo = result?.token || null;
                setYodleeAccessToken(accessTokenInfo);
            }
            const { accessToken } = accessTokenInfo;
            const config = {
                fastLinkURL: process.env.REACT_APP_YODLEE_FASTLINK_URL,
                accessToken: `Bearer ${accessToken}`,
                params: { configName: "Aggregation", isIFrameMounted:true },
                onSuccess: () => {
                    document.body.classList.remove('loading-indicator');
                },
                onError: (data) => {
                    console.error("fi onError:");
                    console.error(data);
                    if (data.code) {
                        setShowOverview(true);
                    }
                    if (data.message === 'FastLink already in use, multiple instances of fastLink may not work as expected.') {
                        window.fastlink.close();
                    }
                    document.body.classList.remove('loading-indicator');
                },
                onClose: async (data) => {
                    console.log("fi onClose:");
                    console.log(data);
                    if (data?.sites?.length) {
                        await saveFinancialInstution(data.sites);
                    }
                    setShowOverview(true);
                    document.body.classList.remove('loading-indicator');
                },
                onEvent: () => {
                    document.body.classList.remove('loading-indicator');
                }
            }
            window.fastlink.open(config, "container-fastlink");
        } catch (error) {
            console.log(error);
            showError(t, error);
        }
    }

    useEffect(() => {
        if (!showOverview) {
            loadFastlinkContainer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverview]);

    const getHeading = () => {
        if (linkedInstitutions.length) {
            return "Step 2.3"
        } 
        if (showOverview) {
            return "Step 2.1"
        } 
    };

    const byPassOnboarding = async () => {
        try {
            await onboarding.updateOnboardingStatus(5);
            navigate('/welcome', { replace: true })
        } catch (error) {
            showError(t, error);
        }
    };

    return (
        <Fragment>
            {showOverview ? <>
                <HeadArea>
                    <FinancialTitle
                        heading={getHeading()}
                        subheading={t('step2.sub-heading')}
                        description={linkedInstitutions.length ? t('step2.description_2') : ''}
                    />
                </HeadArea>
                {!linkedInstitutions.length && <ul style={{ marginBottom: 20, marginLeft: 20, color: 'red' }}>
                    <li style={{ marginBottom: 5 }}>For your security and comfort please click <a href="#">here</a> to read our Data Security and Privacy information.</li>
                    <li style={{ marginBottom: 5 }}>We use a third party API, provided by a company that is a leader in this space, which has connections to over 17,000 financial institutions worldwide.</li>
                    <li style={{ marginBottom: 5 }}>All of the connection information that you will provide is neither entered nor stored in the moolah+ system, rather they are entered in an "i-frame" (a screen) that is provided by the API company.</li>
                </ul>}
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20}}>
                    <Button
                        variant="contained"
                        style={{marginTop: 10, marginBottom: 15, textTransform: 'none'}}
                        onClick={() => setShowOverview(false)}>
                        {linkedInstitutions.length === 0 ? 'Link a financial institution' : 'Link more financial institution(s)'}
                    </Button>
                    {linkedInstitutions.length > 0 && <Button
                        variant="contained"
                        style={{marginTop: 10, marginLeft: 10, marginBottom: 15, textTransform: 'none', float: 'right'}}
                        onClick={() => onNext()}>
                            Next
                    </Button>}
                </div>
                <Grid container spacing={2}>
                    <LinkedAccountInfo linkedInstitutions={linkedInstitutions} />
                </Grid>
                {linkedInstitutions.length === 0 &&
                <div style={{ marginTop: 10 }}>If you wish to by-pass the one-time set-up process at this time and simply navigate the site instead, click <a href="#" onClick={byPassOnboarding}>here</a>.<br />What this means is that:
                    <ul style={{ marginTop: 5, marginLeft: 20 }}>
                        <li style={{ marginBottom: 5 }}>You will not have linked your banks, credit cards and investment accounts so no transactions will be downloaded</li>
                        <li style={{ marginBottom: 5 }}>The system will display the default categories and sub-categories</li>
                        <li style={{ marginBottom: 5 }}>You will not have set up a budget</li>
                    </ul>
                You can set-up all of this information, after your review by clicking on "My Settings" on the Hub</div>
                }
            </> : <>
                <Grid container spacing={2}>
                    <TextArea item md={6} xs={12}>
                        <FinancialTitle
                            heading="Step 2.2"
                            subheading={t('step2.sub-heading2')}
                            description={t('step2.description')}
                        />
                    </TextArea>
                    <Grid item md={6} xs={12}>
                        <FastLinkContainer id="container-fastlink" />
                    </Grid>
                </Grid>
            </>}
        </Fragment >
    );
}
