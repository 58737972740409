import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import Page from '../components/Page';
import HeadingStyle from '../components/HeadingStyle';
import Iconify from '../components/Iconify';

const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
}));

const ContentStyle = styled('div')(({ theme }) => ({
    padding: theme.spacing(10, 10),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    }
}));

const CardStyle = styled('div')(({ theme }) => ({
    padding: '35px 10px',
    textAlign: 'center',
    boxShadow: '0px 8px 24px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 11,
    '& p': {
        fontSize: 18,
        fontWeight: '700',
        color: theme.palette.primary.main,
        margin: '10px 0',
    },
    '& svg': {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        }
    }
}));

// ----------------------------------------------------------------------

export default function WelcomePage() {
    const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
    const NavCardData = [
        {
            title: 'Hub',
            path: '/',
            icon: getIcon('ant-design:home-filled'),
        },
        {
            title: 'Downloaded Transactions',
            path: '/',
            icon: getIcon('el:download-alt'),
        },
        {
            title: 'Manual Categories',
            path: '/',
            icon: getIcon('eva:edit-2-fill'),
        },
        {
            title: 'Dashboard',
            path: '/',
            icon: getIcon('bxs:dashboard'),
        },
        {
            title: 'To Do’s',
            path: '/todos',
            icon: getIcon('ci:list-check'),
        },
        {
            title: 'Key Dates',
            path: '/key-dates',
            icon: getIcon('uil:calender'),
        },
        {
            title: 'Calculators',
            path: '/',
            icon: getIcon('ant-design:calculator-filled'),
        },
        {
            title: 'Knowledge Base',
            path: '/knowledge-base',
            icon: getIcon('fluent:brain-circuit-20-filled'),
        },
        {
            title: 'Forums',
            path: '/forum-categories',
            icon: getIcon('entypo:chat'),
        },
        {
            title: 'Suggestion Box',
            path: '/',
            icon: getIcon('ri:delete-bin-6-fill'),
        },
        {
            title: 'Contact Us',
            path: '/',
            icon: getIcon('raphael:mail'),
        },
        {
            title: 'Profile',
            path: '/',
            icon: getIcon('bxs:user'),
        },
    ];

    const [username, setUserName] = useState(
        localStorage.getItem('user') ? localStorage.getItem('user') : 'Jane'
    )

    return (
        <Page title="Welcome Screen">    
            <PageTopRightBg />
            <ContentStyle>
                <HeadingStyle headLineOne={"Welcome ".concat(username).concat('!')} headLineThree="What brings you here today?" />
                <Grid container spacing={4} pt={8}>
                    {NavCardData.map((nav, key) => (
                        <Grid key={key} item xs={12} sm={6} md={4}>
                            <CardStyle>
                                {nav.icon}
                                <Typography>{nav.title}</Typography>
                            </CardStyle>
                        </Grid>
                    ))}      
                </Grid>
            </ContentStyle>      
        </Page>
    );
}
