import { Box, Grid, styled } from '@mui/material';

export const HeadListItem = styled('ul')(({ theme }) => ({
    maxWidth: '100%',
    listStyle: 'none',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    '& li': {
        minWidth: '100%',
        position: 'relative',
        paddingBottom: 10,
        '& h6': {
            marginLeft: 20,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 4,
            background: 'url("/static/homeImg/img1.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: 12,
            height: 12,
        },
        '&:nth-child(2)': {
            '&:before': {
                background: 'url("/static/homeImg/img2.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    maxWidth: '100%',
    listStyle: 'none',
    position: 'relative',
    paddingLeft: 55,
    paddingRight: 55,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    '& li': {
        minWidth: '100%',
        position: 'relative',
        paddingBottom: 10,
        '& h6': {
            marginLeft: 20,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 4,
            background: 'url("/static/homeImg/img1.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: 12,
            height: 12,
        },
        '&:nth-child(2)': {
            '&:before': {
                background: 'url("/static/homeImg/img2.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(3)': {
            '&:before': {
                background: 'url("/static/homeImg/img3.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(5)': {
            '&:before': {
                background: 'url("/static/homeImg/img2.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(6)': {
            '&:before': {
                background: 'url("/static/homeImg/img3.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
    },
}));

export const BenefitSection = styled(Grid)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    paddingBottom: '30px',
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    [theme.breakpoints.down('md')]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: '10px',
    },
}));

export const Head = styled('div')(({ theme }) => ({
    paddingLeft: 10,
    '& div': {
        '& h5': {
            width: '45%',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    },
}));

export const Wrapper = styled('div')(({ theme }) => ({
    paddingBottom: '90px',
    [theme.breakpoints.down('md')]: {
        paddingBottom: '50px',
    },
}));

export const DividerBottom = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    width: 750,
    height: 1,
    border: 0,
    position: 'relative',
}));

export const DividerStyle = styled('div')(({ theme }) => ({
    background: theme.palette.primary.main,
    marginLeft: '60px !important',
    width: 150,
    height: 1,
    border: 0,
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
        margin: '20px 0',
    },
}));

export const HeadBox = styled(Box)(() => ({
    paddingTop: '20px',
}));

export const HeadCard = styled(Grid)(() => ({
    '& div': {
        fontSize: '16px !important',
    },
}));