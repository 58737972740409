import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from "react-toastify";
import ThemeProvider from './theme';
import './style.css';
import Page404 from "./pages/Page404/Page404";
import PrivateRoutes from "./components/PrivateRoutes";
import HomePage from "./pages/home-page/HomePage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import Setup from "./pages/onboarding/setup/Setup";
import OnboardingStepper from "./pages/onboarding/stepper/Stepper";
import DashboardLayout from "./layouts/dashboard";
import WelcomePage from "./pages/WelcomePage";
import ProfileSummary from "./pages/onboarding/summary/ProfileSummary";
import CalculatorsPage from "./pages/calculators/CalculatorsPage";
import LoanPayment from "./pages/calculators/LoanPayment/LoanPayment";
import LeasePayment from "./pages/calculators/LeasePayment/LeasePayment";
import MortgagePayment from "./pages/calculators/MortgagePayment/MortgagePayment";
import EmbeddedRate from "./pages/calculators/EmbeddedRate/EmbeddedRate";
import ReturnOnInvestment from "./pages/calculators/ReturnOnInvestment/ReturnOnInvestment";
import PresentValue from "./pages/calculators/PresentValue/PresentValue";
import EmergencyFund from "./pages/calculators/EmergencyFund/EmergencyFund";
import SavingsGoal from "./pages/calculators/SavingsGoal/SavingsGoal";
import FutureValueSaving from "./pages/calculators/FutureValueSavings/FutureValueSaving"
import Retirement from "./pages/calculators/Retirement/Retirement"
import ProjectBudget from "./pages/calculators/ProjectBudget/ProjectBudget"
import Welcome from "./pages/welcome/Welcome";
import TermsAndConditions from "./pages/tnc/tnc";
import DownloadedTransactions from "./pages/downloaded-transactions/DownloadedTransactions";
import DownloadEdits from "./pages/downloadEdits/DownloadEdits";
import FormContextProvider from "./pages/onboarding/stepper/context/StepperContext";
import ContactUs from "./pages/contact-us/ContactUs";
import KeyDates from "./pages/keydates/KeyDates";
import ToDos from "./pages/todos/ToDos";
import KnowledgeBasePage from "./pages/knowledge-base/KnowledgeBasePage";
import KnowledgeBaseDetailPage from "./pages/knowledge-base/KnowledgeBaseDetailPage";
import MortgagePrepayment from "./pages/calculators/MortgagePrepayment/MortgagePrepayment";
import DebtRepayment from "./pages/calculators/DebtRepayment/DebtRepayment";
import Error from "./pages/error/Error";
import MyProfilePage from "./pages/profile/MyProfile";
import PersonalInfo from "./pages/profile/personal-info/PersonalInfo";
import SuggestionBox from "./pages/suggestion-box/SuggestionBox";
import FinancialInstitution from "./pages/profile/financial-institution/financial-institution";
import Category from "./pages/profile/category/category";
import SubCategory from "./pages/profile/sub-category/sub-category";
import Budget from "./pages/profile/budget/budget";
import ManualTransactions from "./pages/manual-transactions/manual-transactions";
import ViewManualTransactions from "./pages/manual-transactions/view";
import EditManualTransactions from "./pages/manual-transactions/edit";
import Dashboard from "./pages/dashboard/dashboard";
import AllReports from "./pages/dashboard/AllReports/AllReports";
import AssetComposition from './pages/dashboard/AssetComposition/AssetComposition'; 
import CreditCard from "./pages/dashboard/Liabilities/CreditCard";
import IncomeExpense from "./pages/dashboard/IncomeExpense/IncomeExpense";
import ForumCategory from "./pages/forum/ForumCategories";
import ForumTopics from "./pages/forum/ForumTopics";
import ForumTopic from "./pages/forum/ForumTopic";
import Expense from "./pages/dashboard/Expense/Expense";
import FavoriteWebsites from "./pages/favorite-websites/favorite-websites";
import Discretionary from "./pages/dashboard/Discretionary/Discretionary"
import FAQ from "./pages/faqs/faqs";
import FinancialGoals from "./pages/dashboard/FinancialGoals/FinancialGoals";
import NetWorth from "./pages/dashboard/NetWorth/NetWorth";
import IncomeExpenseSummary from "./pages/dashboard/IncomeExpenseSummary/IncomeExpenseSummary";
import DebtPayment from "./pages/dashboard/DebtPayment/DebtPayment";
import AuthProvider from "./contexts/AuthProvider";
import ResetPassword from "./pages/reset-password/ResetPassword";

export default function App() {
    const [explode, setExplode] = useState(false);

    return (
        <ThemeProvider>
            <AuthProvider>
                <ToastContainer />
                <ErrorBoundary
                    FallbackComponent={Error}
                    onReset={() => setExplode(false)}
                    resetKeys={[explode]}
                >
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route element={<PrivateRoutes />}>                        
                            <Route element={<DashboardLayout />}>
                                <Route path="welcome" element={<Welcome />} />
                                <Route path="contact-us" element={<ContactUs />} />
                                <Route path="key-dates" element={<KeyDates/>}/>
                                <Route path="favorite-websites" element={<FavoriteWebsites/>}/>
                                <Route path="todos" element={<ToDos/>}/>
                                <Route path="/welcome-page" element={<WelcomePage />} />
                                <Route path="/suggestion-box" element={<SuggestionBox/>} />
                                <Route path="/downloaded-transactions" element={<DownloadedTransactions />} />
                                <Route path="/manual-transactions" element={<ManualTransactions />} />
                                <Route path="/edit-manual-transactions" element={<EditManualTransactions />} />
                                <Route path="/view-manual-transactions" element={<ViewManualTransactions />} />
                                <Route path="/view-all-templates" element={<ViewManualTransactions />} />
                                <Route path="/download-edits" element={<DownloadEdits />} />
                                <Route exact path="/my-profile" element={<MyProfilePage/>}/>
                                <Route exact path="/my-profile/personal-info" element={<PersonalInfo />}/>
                                <Route exact path="/my-profile/link-financial-institutions" element={<FinancialInstitution />}/>
                                <Route exact path="/my-profile/category" element={<Category />}/>
                                <Route exact path="/my-profile/sub-category" element={<SubCategory />}/>
                                <Route exact path="/my-profile/budget" element={<Budget />}/>
                                <Route exact path="/calculators" element={<CalculatorsPage/>}/>
                                <Route exact path="calculators/loan-payment" element={<LoanPayment/>}/>
                                <Route exact path="calculators/lease-payment" element={<LeasePayment/>}/>
                                <Route exact path="calculators/mortgage-payment" element={<MortgagePayment/>}/>
                                <Route exact path="calculators/embedded-rate" element={<EmbeddedRate/>}/>
                                <Route exact path="calculators/return-on-investment" element={<ReturnOnInvestment/>}/>
                                <Route exact path="calculators/present-value" element={<PresentValue/>}/>
                                <Route exact path="calculators/emergency-fund" element={<EmergencyFund/>}/>
                                <Route exact path="calculators/savings-goal" element={<SavingsGoal/>}/>
                                <Route exact path="calculators/future-value-savings"  element={<FutureValueSaving/>}/>
                                <Route exact path="calculators/retirement"  element={<Retirement/>}/>
                                <Route exact path="calculators/project-budget"  element={<ProjectBudget/>}/>
                                <Route exact path="calculators/mortgage-prepayment" element={<MortgagePrepayment/>}/>
                                <Route exact path="calculators/debt-repayment" element={<DebtRepayment/>}/>
                                <Route exact path="dashboard" element={<Dashboard/>}/>
                                <Route exact path="dashboard/all-reports" element={<AllReports/>}/>
                                <Route exact path="dashboard/asset-composition" element={<AssetComposition reportName={'asset-composition'}/>}/>
                                <Route exact path="dashboard/liabilities-composition" element={<AssetComposition reportName={'liabilities-composition'}/>}/>
                                <Route exact path="dashboard/line-of-credit" element={<CreditCard apiData="linesofcredit" reportName={'line-of-credit'} />}/>
                                <Route exact path="dashboard/liabilities-credit-card" element={<CreditCard apiData="creditcardusage" reportName={'liabilities-credit-card'}/>}/>
                                <Route exact path="dashboard/income-composition" element={<IncomeExpense reportName={'income-composition'}/>}/>
                                <Route exact path="dashboard/expense-composition" element={<IncomeExpense reportName={'expense-composition'}/>}/>
                                <Route exact path="dashboard/income-expense-summary" element={<IncomeExpenseSummary />}/>
                                <Route exact path="dashboard/variable-expense" element={<Discretionary reportName={'variable-expense'}/> }/>
                                <Route exact path="dashboard/fixed-expense" element={<Discretionary reportName={'fixed-expense'}/>}/>
                                <Route exact path="dashboard/top-five-expense" element={<Expense reportName={'top-five-expense'}/>}/>
                                <Route exact path="dashboard/top-ten-expense" element={<Expense reportName={'top-ten-expense'}/>}/>
                                <Route exact path="dashboard/financial-goals" element={<FinancialGoals />}/>
                                <Route exact path="dashboard/net-worth" element={<NetWorth />} />
                                <Route exact path="dashboard/debt-payment-report" element={<DebtPayment />} />
                                <Route path="/forum-categories" element={<ForumCategory />} />
                                <Route path="/forum-topics/category/:categoryId" element={<ForumTopics />} />
                                <Route path="/forum-topics/tag/:tag" element={<ForumTopics />} />
                                <Route path="/forum-topics/topic/:topicId" element={<ForumTopic />} />
                                <Route path="/knowledge-base" element={<KnowledgeBasePage />} />
                                <Route path="/knowledge-base-detail/:blogId" element={<KnowledgeBaseDetailPage />} />
                                <Route path="/faqs" element={<FAQ />} />
                            </Route>
                        </Route>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/setup" element={<Setup />} />
                        <Route path="/onboarding" element={<FormContextProvider>
                            <OnboardingStepper />
                        </FormContextProvider>} />
                        <Route path="/profile-summary" element={<ProfileSummary />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </ErrorBoundary>
            </AuthProvider>
        </ThemeProvider>
    );
}