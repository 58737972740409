// @mui
import {useEffect, useState } from 'react';
import { Grid} from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, TotalFlowValueSubColumn, MidSideColumn, TotalFlow, BudgetInput, ButtonText, BackButtonStyle, BudgetPercentageInput, TotalSubHeadingColumn, ExtraTdSpacing, TotalFlowHeader, TotalAmountDiv } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, downloadExcelHeader, formatZeroValues, getCurrencySymbol } from '../../../utils/calCommonFunction';

export default function FutureValueSaving() {
    const [currSavingInvBalance, setCurrSavingInvBalance] = useState("");
    const [monthlyInvestment, setMonthlyInvestment] = useState("");
    const [termInYears, setTermInYears] = useState("");
    const [estAnnualRor, setEstAnnualRor] = useState("");
    const { t } = useTranslation();
    const navigate= useNavigate();
    const currencySymbol = getCurrencySymbol();
    
    const getFutureValueDetails=async()=>{
        const getFutureValueData = await calculator.getUserCalculator("future-value");
        if (getFutureValueData.data) {
            const loanJsonData = getFutureValueData.data?.calcJson;
            setCurrSavingInvBalance(loanJsonData.curr_savinginvbalance);
            setMonthlyInvestment(loanJsonData.monthly_investment);
            setTermInYears(loanJsonData.term_in_years);
            setEstAnnualRor(loanJsonData.est_annual_ror);
        }
    }
    
    useEffect(() => {
        getFutureValueDetails()
    }, []);

    const futureValueCalculator=(rate, nper, pmt, pv, type)=>{
        // eslint-disable-next-line
        let pow = Math.pow(1 + rate, nper), fv;
        if (rate) {
            fv = (pmt*(1+rate*type)*(1-pow)/rate)-pv*pow;
        } else {
            fv = -1 * (pv + pmt * nper);
        }
        return fv.toFixed(2);
    }

    // calculation
    const currSavingFvBalanceCalc = Number(removeNumberFormatting(currSavingInvBalance))
    const currentSavingValue = Number(removeNumberFormatting(monthlyInvestment))* Number(removeNumberFormatting(termInYears))*12;
    const totalInvestmentValue = currSavingFvBalanceCalc + currentSavingValue;
    const totalFutureValue= futureValueCalculator(Number((removeNumberFormatting(estAnnualRor)/100)), Number(removeNumberFormatting(termInYears)),0, Number(-currSavingFvBalanceCalc),0)
    const totalAdditionalValue= -futureValueCalculator(Number((removeNumberFormatting(estAnnualRor)/100)/12), Number((removeNumberFormatting(termInYears)*12)), Number(removeNumberFormatting(monthlyInvestment)),0,0)
    const totalSubInvestmentValue = Number(totalFutureValue) + Number(totalAdditionalValue);
    const totalInvestMentOver= Number(totalFutureValue) + Number(totalAdditionalValue);
    const totalIncomeEarnedValue=Number(totalSubInvestmentValue) - Number(totalInvestmentValue);
    const currSavingInvOverTerm = currSavingFvBalanceCalc.toLocaleString("en-US", {minimumFractionDigits:2});
    const currentSavingBalance= currentSavingValue.toLocaleString("en-US", {minimumFractionDigits:2});
    const totalInvestmentOverTer= totalInvestmentValue.toLocaleString("en-US", {minimumFractionDigits:2});
    const totalFutureInvestment= formatDollarValues(totalFutureValue).toLocaleString("en-US", {minimumFractionDigits:2});
    const totalFutureAdditionalValue= totalAdditionalValue.toLocaleString("en-US", {minimumFractionDigits:2});
    const totalInvestMentValue= totalInvestMentOver.toLocaleString("en-US", {minimumFractionDigits:2});
    const totalIncomeEarned=totalIncomeEarnedValue.toLocaleString("en-US", {minimumFractionDigits:2});

    const resetFields = () => {
        setCurrSavingInvBalance("");
        setMonthlyInvestment("");
        setTermInYears("");
        setEstAnnualRor("");
    }

    const saveFutureValueData = async () => {
        try {
            const postData = {
                "curr_savinginvbalance": currSavingInvBalance,
                "monthly_investment": monthlyInvestment,
                "term_in_years": termInYears,
                "est_annual_ror": estAnnualRor,
            }
            const embeddedDataObj = {
                "calcType": "future-value",
                "calcJson": postData
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'term_in_years') {
            setTermInYears(formatDollarValues(value).toString().slice(0,2));
        }
        if (name === 'est_annual_ror') {
            setEstAnnualRor(formatDollarValues(value));
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "title":"",
                "Description": "Description",
                "value": "value"
            },
            {
                "title":"",
                "Description": "Current savings/investment balance",
                "value": `${currencySymbol} ${currSavingInvBalance}`
            },
            {
                "title":"",
                "Description": "Monthly investment",
                "value": `${currencySymbol} ${monthlyInvestment}`
            },
            {
                "title":"",
                "Description": "Term in years",
                "value": termInYears
            },
            {
                "title":"",
                "Description": "Estimated annual rate of return",
                "value": `${estAnnualRor} %`
            },
            {
                "title":"",
                "Description": "",
                "value": ""
            },
            {
                "title":"Summary Over Full Term",
                "Description": "",
                "value": ""
            },
            {
                "title":"",
                "Description": "",
                "value": ""
            },
            {
                "title":"",
                "Description": "Investment Over Term",
                "value": "Future Value"
            },
            {
                "title":"Current savings/investments balance",
                "Description": `${currencySymbol} ${currSavingInvOverTerm}`,
                "value": `${currencySymbol} ${currentSavingBalance}`
            },
            {
                "title":"Additional savings/investments over term",
                "Description": `${currencySymbol} ${totalFutureInvestment}`,
                "value": `${currencySymbol} ${totalFutureAdditionalValue}`
            },
            {
                "title":"",
                "Description": "",
                "value": ""
            },
            {
                "title":"Total",
                "Description": `${currencySymbol} ${totalInvestmentOverTer}`,
                "value": `${currencySymbol} ${totalInvestMentValue}`
            },
            {
                "title":"",
                "Description": "",
                "value": ""
            },
            {
                "title":"Total Income Earned",
                "Description": `${currencySymbol} ${totalIncomeEarned}`,
                "value": ""
            }
        ];
        const style = [
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A7',
                "style": EXCEL_STYLE.boldColorHrVr
            },
            {
                "col": 'B9',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C9',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C13',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'B13',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'B10',
                "style": EXCEL_STYLE.onlyRight
            },
            {
                "col": 'B11',
                "style": EXCEL_STYLE.onlyRight
            },
            {
                "col": 'B15',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'A15',
                "style": EXCEL_STYLE.boldColor
            },
        ]
        const cols = [{ width: 34 }, { width: 30 }, { width: 20 }];
        const merges = [
            { s: { r: 5, c: 0 }, e: { r: 5, c: 2 } },
            { s: { r: 7, c: 0 }, e: { r: 7, c: 2 } },
            { s: { r: 11, c: 0 }, e: { r: 11, c: 2 } },
            { s: { r: 13, c: 0 }, e: { r: 13, c: 2 } },
        ]
        downloadExcelHeader(postData, style, cols, merges, 'FutureValue', 2, ['C'], true );
    }

    return (
        <Page title={t('future-saving.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('future-saving.go-back')}
                    </BackButtonStyle>
                    <Title title={t('future-saving.calculator')} subtitle={t('future-saving.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('future-saving.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('future-saving.current-saving')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="curr_savinginvbalance"
                                                    value={currSavingInvBalance}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setCurrSavingInvBalance(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setCurrSavingInvBalance(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('future-saving.monthly-investment')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="monthly_investment"
                                                    value={monthlyInvestment}
                                                    onBlur={(e)=>{
                                                        setMonthlyInvestment(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setMonthlyInvestment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('future-saving.term-in-year')}</td>
                                            <BudgetInput>
                                                <span style={{ padding:'5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="term_in_years"
                                                    value={termInYears}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('future-saving.annual-rate')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="est_annual_ror"
                                                    value={estAnnualRor}
                                                    onChange={handleChanged}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle style={{ padding: '42px 5% 62px 5%' }}>
                                <tbody>
                                    <tr>
                                        <TotalFlowHeader>{t('future-saving.summary')}</TotalFlowHeader>
                                        <td>{''}</td>
                                        <td>{''}</td>
                                    </tr>
                                    <ExtraTdSpacing>
                                        <td>{''}</td>
                                    </ExtraTdSpacing>
                                    <tr>
                                        <td>{''}</td>
                                        <TotalSubHeadingColumn style={{width:'185px'}}>{t('future-saving.investment')}</TotalSubHeadingColumn>
                                        <TotalSubHeadingColumn style={{textAlign:'end'}}>{t('future-saving.future-value')}</TotalSubHeadingColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('future-saving.current-saving')}</td>
                                        <MidSideColumn><span>{currencySymbol} {currSavingInvOverTerm}</span></MidSideColumn>
                                        <MidSideColumn><span>{currencySymbol} { totalFutureInvestment}</span></MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('future-saving.additional-saving')}</td>
                                        <MidSideColumn><span>{currencySymbol} {currentSavingBalance}</span></MidSideColumn>
                                        <MidSideColumn><span>{currencySymbol} {totalFutureAdditionalValue.replace(/-/, '')}</span></MidSideColumn>
                                    </tr>
                                    <ExtraTdSpacing>
                                        <td>{''}</td>
                                    </ExtraTdSpacing>
                                    <tr>
                                        <td>{t('future-saving.total')}</td>
                                        <TotalFlowValueSubColumn><TotalAmountDiv>{currencySymbol}<span> {totalInvestmentOverTer}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                        <TotalFlowValueSubColumn><TotalAmountDiv style={{marginLeft:'auto'}}>{currencySymbol}<span> {totalInvestMentValue}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                    </tr>
                                    <ExtraTdSpacing>
                                        <td>{''}</td>
                                    </ExtraTdSpacing>
                                    <tr>
                                        <TotalFlow style={{ textAlign: 'center' }}>{t('future-saving.total-income')}</TotalFlow>
                                        <TotalFlowValueSubColumn><TotalAmountDiv>{currencySymbol}<span> {totalIncomeEarned}</span></TotalAmountDiv></TotalFlowValueSubColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('future-saving.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('future-saving.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={saveFutureValueData}
                        >
                            {t('future-saving.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('future-saving.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('future-saving.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
