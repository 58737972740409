import { useState } from 'react';
import { Grid, TextField, styled, Button } from "@mui/material";
import DialogAtom from "components/dialog/Dialog";
import { FormProvider, RHFTextField } from "components/hook-form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input from '@mui/material/Input';
import { useNavigate } from 'react-router-dom';
import { getCurrencySymbol } from 'utils/calCommonFunction';
import EditForm from "./EditForm";
import { DividerGrid, DividerStyle, MainGrid, TableStyle, CheckboxStyle, DiffTableContent, AutoFieldStyle, AddMoreButton, AddMoreTbBUtton, BudgetInput, GroupHeader, GroupItems } from "./styled-components";

export const InputLabel = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

export default function TransactionEditDialog({showEditDialog, transactionData, updateTransactionData, userCategories, onClose, onSave}) {
    const { t } = useTranslation();
    const methods = useForm();
    const [jsonData, setJsonData] = useState([{
        category: '',
        subCategory: '',
        balance: ''
    }]);
    const [existingTransaction, setExistingTransaction] = useState(false);
    const [futureTransaction, setFutureTransaction] = useState(false);
    const navigate = useNavigate();    
    const onDialogAction = (buttonKey) => {
        if (buttonKey === 'save') {
            onSave();
        } else if (buttonKey === 'cancel') {
            onClose();
        }
    }

    const getSubCategories = (cat) => {
        const selectedCategoryId = cat?.category?.id || '';
        if (selectedCategoryId) {
            const selectedCategory = userCategories.find((cate) => cate.id === selectedCategoryId);
            return selectedCategory?.sub_categories;
        }
        return [];
    }

    const handleChange = (index, name, value) => {
        const clonedJsonData = [...jsonData];
        const selectedRowData = {...clonedJsonData[index]}
        selectedRowData[name] = value;
        if (name === 'category') {
            const { name, sub_categories: subCategories } = value;
            const subCategory = subCategories.find((c) => c.name === name);
            selectedRowData.subCategory = subCategory || '';
        }
        clonedJsonData[index] = selectedRowData;
        setJsonData(clonedJsonData);
        updateTransactionData('splitArray', clonedJsonData)
    }

    const onAddAnother = () => {
        const clonedJsonData = [...jsonData];
        clonedJsonData.push({
            category: '',
            subCategory: '',
            balance: ''
        });
        setJsonData(clonedJsonData);
    }

    const getRemainingBalance = () => {
        let bal = transactionData?.amount?.amount;
        jsonData.forEach((rowInfo) => {
            bal -= Number(rowInfo?.balance?.replace(/[^0-9.-]+/g, ''))
        });
        return Number(bal?.toFixed(2));
    }

    const getTotalamount = () => {
        let total = 0;
        jsonData.forEach((row) => {
            total += Number(row?.balance?.replace(/[^0-9.-]+/g, ''));
        });
        return total;
    }

    const isSaveDisabled = () => {
        const tAmount = Number(transactionData?.amount?.amount);
        if (Number(getRemainingBalance()) === tAmount) {
            if (jsonData[0].category || jsonData[0].subCategory || jsonData[0].balance) {
                return true;
            }
            return false;
        }
        const hasRowError = jsonData.findIndex((row) => !row.category || !row.subCategory || !row.balance);
        if (hasRowError >= 0) {
            return true;
        }
        return !!getRemainingBalance();
    }

    const transactionAmount = transactionData?.amount?.amount;

    return (
        <DialogAtom
            open={showEditDialog}
            dialogTitle="Edit"
            isSaveDisabled={isSaveDisabled()}
            additionalButtons={<>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate('/my-profile/category')}
                    autoFocus
                >
                    Add category
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => navigate('/my-profile/sub-category')}
                    autoFocus
                >
                    Add sub category
                </Button>
            </>}
            content={(
                <>
                    <FormProvider methods={methods}>
                        <div>
                            <MainGrid container spacing={2}>
                                <Grid item md={5} xs={12}>
                                    <EditForm
                                        transactionData={transactionData}
                                        updateTransactionData={updateTransactionData}
                                        userCategories={userCategories}
                                    />
                                    <Grid container spacing={2}>
                                        <Grid item md={5} xs={12}>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                            Notes
                                            </InputLabel>
                                        </Grid>
                                        <Grid item md={7} xs={12}>
                                            <RHFTextField
                                                multiline
                                                rows={2}
                                                name="notes"
                                                padding="0px"
                                                fontSize={14}
                                                value={transactionData?.notes}
                                                onChange={(e) => updateTransactionData('notes', e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column' }}>
                                        <InputLabel>For this Payee, apply Category change to all:</InputLabel>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckboxStyle
                                                checked={existingTransaction} 
                                                onChange={() => {
                                                    updateTransactionData('existingTransaction', !existingTransaction);
                                                    setExistingTransaction(!existingTransaction);
                                                }} />
                                            <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 0 }}>
                                            Existing transactions
                                            </InputLabel>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckboxStyle
                                                checked={futureTransaction} 
                                                onChange={() => {
                                                    updateTransactionData('futureTransaction', !futureTransaction);
                                                    setFutureTransaction(!futureTransaction)
                                                }} />
                                            <InputLabel shrink htmlFor="bootstrap-input" style={{ marginTop: 0 }}>
                                            Future transactions
                                            </InputLabel>

                                        </div>
                                    </div>
                                </Grid>
                                <DividerGrid item md={1} xs={12}>
                                    <DividerStyle />
                                </DividerGrid>
                                <Grid item md={6} xs={12} style={{ paddingLeft: 0 }}>
                                    <DiffTableContent style={{ paddingBottom: 20, fontSize: 16 }}>Spilt transaction amount & reallocate</DiffTableContent>
                                    <TableStyle style={{ padding: '0px', marginTop: '0%', borderSpacing: '20px 0px' }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'left'}}>
                                                    {t('transaction-edit-dialog.category')}
                                                </td>
                                                <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'left'}}>
                                                    {t('transaction-edit-dialog.sub-category')}
                                                </td>
                                                <DiffTableContent>{t('transaction-edit-dialog.amount')}</DiffTableContent>
                                            </tr>
                                            {jsonData.map((row, index) => <tr key={index}>
                                                <td colSpan={2} style={{ }}>
                                                    <AutoFieldStyle
                                                        disableClearable
                                                        style={{ paddingBottom: 0 }}
                                                        options={userCategories}
                                                        groupBy={(option) => option.categoryGroupName}
                                                        getOptionLabel={(option) => (option?.name || '')}
                                                        value={row.category}
                                                        onChange={(e, value) => {
                                                            handleChange(index, 'category', value);
                                                        }}
                                                        id="tags-outlined"
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" placeholder="Select category" className="dropMenu" />
                                                        )}
                                                        renderGroup={(params) => (
                                                            <li key={params.key}>
                                                                <GroupHeader>{params.group}</GroupHeader>
                                                                <GroupItems>{params.children}</GroupItems>
                                                            </li>
                                                        )}
                                                    />
                                                </td>
                                                <td colSpan={2} style={{ }}>
                                                    <AutoFieldStyle
                                                        disableClearable
                                                        style={{ paddingBottom: 0 }}
                                                        options={getSubCategories(row)}
                                                        getOptionLabel={(option) => (option?.name || '')}
                                                        value={row.subCategory}
                                                        onChange={(e, value) => {
                                                            handleChange(index, 'subCategory', value);
                                                        }}
                                                        id="tags-outlined"
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" placeholder="Select sub category" className="dropMenu" />
                                                        )}
                                                    />
                                                </td>
                                                <BudgetInput>
                                                    <span>{getCurrencySymbol()}</span>
                                                    <Input
                                                        type='text'
                                                        name="balance"
                                                        value={row?.balance}
                                                        placeholder="0"
                                                        onBlur={(event) => {
                                                            let balance = event.target.value;
                                                            if (transactionAmount < 0 && balance.indexOf('-') === -1) {
                                                                balance = `-${balance}`;
                                                            }
                                                            handleChange(index, 'balance', balance)
                                                        }}
                                                        onChange={(event) => {
                                                            let balance = event.target.value;
                                                            if (transactionAmount < 0 && balance.indexOf('-') === -1) {
                                                                balance = `-${balance}`;
                                                            }
                                                            handleChange(index, 'balance', balance)
                                                        }}
                                                    />
                                                </BudgetInput>
                                            </tr>)}
                                            <tr>
                                                <td colSpan={5} style={{ color: '#00773E', fontWeight: '600', textAlign: 'center' }}>
                                                    <span>Remaining amount: </span>
                                                    <span style={{ color: 'black' }}>{getCurrencySymbol()}{' '}{getRemainingBalance()}</span>
                                                    <span style={{ marginLeft: 15 }}>{t('transaction-edit-dialog.allocated-amount')}</span>
                                                    <span style={{ color: 'black', marginLeft: 3 }}>{getCurrencySymbol()}{' '}{getTotalamount()}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <AddMoreTbBUtton style={{ textAlign: 'left', paddingLeft: 0 }}><AddMoreButton onClick={onAddAnother}>{t('edit-manual-transactions.add-another')}</AddMoreButton></AddMoreTbBUtton>
                                            </tr>
                                        </tbody>
                                    </TableStyle>
                                </Grid>
                            </MainGrid>
                        </div>
                    </FormProvider>
                </>
            )}
            onDialogAction={onDialogAction}
        />
    );
}