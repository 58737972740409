// @mui
import { useState, useEffect } from 'react';
import { Grid, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    PageTopRightBg,
    ContentStyle,
    Wrapper,
    HeaderDivStyle,
    HeaderTableStyle,
    BorderStyle,
    FormHeaderTd,
} from './styled-components';
// components
import forum from '../../constants/services/forum';
import Title from '../../components/Title';
import Page from '../../components/Page';
import AutoCompleteForumSearch from './AutoCompleteForumSearch';

export default function ForumCategory() {

    const [categories, setCategories]= useState([]);
    const navigate= useNavigate();
    const { t } = useTranslation();
    
    const getCategories = async () => {
        const categoriesData = await forum.getCategories();
        const categoriesList = [];    
        if (categoriesData.data) {
            categoriesData.data.forEach((category) => {
                categoriesList.push({
                    id: category.id,
                    title: category.attributes.title,
                    description: category.attributes.description,
                    topics: category.attributes.topics.data
                });
            });
        }
        setCategories(categoriesList);
    }
    
    useEffect(() => {
        getCategories();
    }, []);

    return (
        <Page title={t('forumcategory.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Title title={t('forumcategory.title')} subtitle={t('forumcategory.description')} />
                    <Stack direction="row-reverse" justifyContent="center" alignItems="flex-center" spacing={3} style={{paddingTop: '20px'}}><AutoCompleteForumSearch /></Stack> 
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <HeaderTableStyle>
                                    <thead>
                                        <tr>
                                            <th style={{width:'30%'}}>{t('forumcategory.category')}</th>
                                            <th style={{width:'55%'}}>{t('forumcategory.category-description')}</th>
                                            <th style={{width:'15%', textAlign :'center'}}>{t('forumcategory.numberOfTopic')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <BorderStyle colSpan={3}>{""}</BorderStyle>
                                        </tr>
                                        {
                                            categories.map((category)=> 
                                                <tr key={category.id} style={{cursor: 'pointer'}} onClick={() => navigate(`/forum-topics/category/${category.id}`, { state: { categoryName: category.title } })}>
                                                    <FormHeaderTd>{category.title}</FormHeaderTd>
                                                    <td>{category.description}</td>
                                                    <td style={{textAlign :'center'}}>{category.topics.length}</td>
                                                </tr>  
                                            )
                                        }
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                        </Grid>
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>);
}