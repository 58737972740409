// @mui
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { getCurrencySymbolByCurrencyCode } from 'constants/countryCurrencyData';
import Bargraph from '../Graphs/Bargraph';
import Piegraph from '../Graphs/Piegraph';
import { CHARTCOLOR } from '../constant';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import Filter from '../filter/Filter';
import { removeEmpty, getMonthNameYear, getPositiveNumber, getCurrencySymbol } from '../../../utils/calCommonFunction';
import dashboard from '../../../constants/services/dashboard';
import { NoDataTd, ButtonText, SpanTotalFlow, ReportAmountTd } from '../ExecutiveSummary/styled-component';
import {
    Wrapper,
    PageTopRightBg,
    ContentStyle,
    TableUpperDiv,
    SubmitButton,
    TableStyle,
    TopSideBar,
    BackButtonStyle,
    TotalAmountDiv,
    LoaderGif,
} from './styled-component';

export default function AssetComposition(props) {
    const currencyCode = getCurrencySymbol();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const { reportName } = props;
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: null,
        categoryGroupId: location?.state || null,
        categoryId: null,
        subCatId: null,
        type: 1,
    });

    const [monthColumn, setMonthColumn] = useState([]);
    const [assetData, setAssetData] = useState([]);
    const [assetPercentageData, setAssetPercentageData] = useState([]);
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [totalCurrAssetData, setTotalCurrAssetData] = useState([]);
    const [totalCompAssetData, setTotalCompAssetData] = useState([]);
    const [totalIncDescAssetData, setTotalIncDescAssetData] = useState([]);
    const [totalCurrentPercentage, setTotalCurrentPercentage] = useState([]);
    const [totalComparePercentage, setTotalComparePercentage] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [horichartData, setHoriChartData] = useState([]);
    const [showGraph, setShowGraph] = useState(true);
    const [tableLoader, setTableLoader] = useState(false);

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getdashboardData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        let response;
        setTableLoader(true);
        if (reportName === 'asset-composition') {
            response = await dashboard.getAssetComposition(filtersDataObj);
        } else if (reportName === 'liabilities-composition') {
            response = await dashboard.getLiabilitiesComposition(filtersDataObj);
        }
        setTableLoader(false);
        const resultData = response;
        const currentMonth = filtersData.fromMonth;
        const { compareMonth } = filtersData;
        // const keys = resultData.categoriesData.keys();
        const assetDates = Object.keys(resultData.categoriesData);
        setMonthColumn(assetDates);
        const { categoriesData } = resultData;
        const allIds = {};
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(categoriesData)) {
            categoriesData[key].forEach((item) => {
                if (item.account_id) {
                    allIds[item.account_id] = item.account_name;
                } else if (!item.id) {
                    allIds[item.name] = item.name;
                } else {
                    allIds[item.id] = item.name;
                }
            });
        }

        const totalcurrentMonth =
            categoriesData[currentMonth] &&
            categoriesData[currentMonth].reduce((acc, item) => acc + Number(item.values), 0);
        setTotalCurrAssetData(totalcurrentMonth);

        const totalcompareMonth =
            categoriesData[compareMonth] &&
            categoriesData[compareMonth].reduce((acc, item) => acc + Number(item.values), 0);
        setTotalCompAssetData(totalcompareMonth);

        const filterIds = [...new Set(Object.keys(allIds))];
        const assetValueObj = [];
        const assetPercentageObj = [];
        let currencySymbol = null;
        let currTotalPerVal = 0;
        let compTotalPerVal = 0;
        let totalIncDescVal = 0;
        // eslint-disable-next-line
        filterIds.map((id, index) => {
            let currentMD = 0;
            let compareMD = 0;
            let currentpercentage = 0;
            let comparepercentage = 0;
            let currency = null;
            let incdescVal = 0;
            if (categoriesData[currentMonth]) {
                currentMD = categoriesData[currentMonth][index]?.values;
                currentMD = currentMD || 0;
                currency = categoriesData[currentMonth][index]?.currency;
                currentpercentage =
                    totalcurrentMonth !== 0
                        ? Number(Number(currentMD * 100) / totalcurrentMonth).toFixed(2)
                        : '0.00';
                currentpercentage = currentpercentage === 'NaN' ? 0 : currentpercentage;
                currTotalPerVal += currentpercentage === 'NaN' ? 0 : Number(currentpercentage);
            }
            if (categoriesData[compareMonth]) {
                compareMD = categoriesData[compareMonth][index]?.values;
                compareMD = compareMD || 0;
                currency = categoriesData[compareMonth][index]?.currency;
                comparepercentage =
                    totalcompareMonth !== 0
                        ? Number((compareMD * 100) / totalcompareMonth).toFixed(2)
                        : '0.00';
                compTotalPerVal += Number(comparepercentage);
            }
            incdescVal = Number(currentMD - compareMD).toFixed(2);
            totalIncDescVal += Number(incdescVal);
            currencySymbol = getCurrencySymbolByCurrencyCode(currency);
            assetValueObj.push({
                name: allIds[id],
                current_month: currentMD,
                compare_month: compareMD,
                incdesc_val: incdescVal,
                currencySymbol: currencySymbol || currencyCode,
            });
            assetPercentageObj.push({
                name: allIds[id],
                current_month_percentage: currentpercentage,
                compare_month_percentage: comparepercentage,
            });
        });
        const assetValWithoutCashArray = assetValueObj.filter((obj) => obj.name !== 'Cash');
        const assetCashobj = assetValueObj.filter((obj) => obj.name === 'Cash');
        assetValWithoutCashArray.sort((a, b) => Number(a.current_month - b.current_month));
        assetCashobj.push(...assetValWithoutCashArray);
        setAssetData(assetCashobj);
        setCurrencySymbol(currencySymbol);
        currTotalPerVal = Math.round(currTotalPerVal) > 100 ? 100 : Math.round(currTotalPerVal);
        compTotalPerVal = Math.round(compTotalPerVal) > 100 ? 100 : Math.round(compTotalPerVal);
        setTotalCurrentPercentage(currTotalPerVal.toFixed(2));
        setTotalComparePercentage(compTotalPerVal.toFixed(2));
        setTotalIncDescAssetData(totalIncDescVal.toFixed(2));
        const graphData = getGraphData(assetValueObj);
        setChartData(graphData);

        const assetPercentWithoutCashArray = assetPercentageObj.filter((obj) => obj.name !== 'Cash');
        const assetPercentCashobj = assetPercentageObj.filter((obj) => obj.name === 'Cash');
        assetPercentWithoutCashArray.sort((a, b) => Number(a.current_month_percentage - b.current_month_percentage));
        assetPercentCashobj.push(...assetPercentWithoutCashArray);
        setAssetPercentageData(assetPercentCashobj);
        const horiChartData = getHorizontalGraphData(assetPercentageObj);
        setHoriChartData(horiChartData);
    };

    const getGraphData = (data) => {
        let labels = [];
        let dataset = [];
        if (data.length > 0) {
            labels = data.map((item) => item.name);
            if (filtersData.compareMonth) {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                    },
                    {
                        label: getMonthNameYear(filtersData.compareMonth),
                        data: [],
                        backgroundColor: 'rgba(255, 99, 132, 1)',
                    },
                ];
            } else {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: 'rgba(54, 162, 235, 1)',
                    },
                ];
            }
            let monthData;
            for (let i = 0; i <= data.length - 1; i += 1) {
                const item = data[i];
                const currVal = Number(item.current_month);
                const compVal = Number(item.compare_month);
                monthData = [currVal, compVal];
                for (let j = 0; j < dataset.length; j += 1) {
                    const reqvalue = monthData[j];
                    dataset[j].data.push(reqvalue);
                }
            }
        }

        const graphTitle = `${currencyCode} ${t(`${reportName}.graph-title`)}`;
        return { labels, dataset, title: graphTitle };
    };

    const getHorizontalGraphData = (data) => {
        let labels = [];
        let dataset = [];
        if (data.length > 0) {
            labels = data.map((item) => item.name);
            if (filtersData.compareMonth) {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    },
                    {
                        label: getMonthNameYear(filtersData.compareMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    },
                ];
            } else {
                dataset = [
                    {
                        label: getMonthNameYear(filtersData.fromMonth),
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    },
                    
                ];
            }
            let monthData;
            for (let i = 0; i <= data.length - 1; i += 1) {
                const item = data[i];
                const currVal = Number(item.current_month_percentage);
                const compVal = Number(item.compare_month_percentage);
                monthData = [currVal, compVal];
                for (let j = 0; j < dataset.length; j += 1) {
                    const reqvalue = monthData[j];
                    dataset[j].data.push(reqvalue);
                    dataset[j].backgroundColor.push(CHARTCOLOR[i]);
                }
            }
        }
        const graphTitle = `% ${t(`${reportName}.graph-title`)}`;    
        return { labels, dataset, title: graphTitle };
    };

    useEffect(() => {
        getdashboardData();
        getCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategories = async () => {
        const response = await dashboard.getDashboardCategory({categoryGroupId:filtersData.categoryGroupId});
        const categories = response?.data || [];
        setCategories(categories);
    };

    const getFilterData = () => {
        getdashboardData();
    };

    return (
        <Page title={t(`${reportName}.title`)}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/dashboard/all-reports')}>
                        {t(`${reportName}.back`)}
                    </BackButtonStyle>
                    <Title title={t(`${reportName}.dashboard`)} subtitle={t(`${reportName}.subtitle`)} />

                    <Grid container pt={5} pb={5} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <Filter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                categories={categories}
                                setFiltersData={setFiltersData}
                                showCategoryFilter={['liabilities-composition', 'asset-composition'].includes(
                                    reportName
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    {showGraph ? (
                        <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} sm={12} md={12}>
                                <TableUpperDiv>
                                    <TableStyle>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <>
                                                {assetData.length > 0 ? (
                                                    <>
                                                        <thead>
                                                            {
                                                                <tr>
                                                                    <th style={{ width: '25%' }}>{''}</th>
                                                                    {monthColumn?.map((item, index) => (
                                                                        <TopSideBar key={index}
                                                                            style={{ width: '25%', textAlign: 'end' }}
                                                                        >
                                                                            {getMonthNameYear(item)}
                                                                        </TopSideBar>
                                                                    ))}
                                                                    {/* <TopSideBar style={{ width: '25%', textAlign: 'end' }}>{getMonthNameYear(filtersData.fromMonth)}</TopSideBar> */}
                                                                    {/* <TopSideBar style={{ width: '25%', textAlign: 'end' }}>{getMonthNameYear(filtersData.compareMonth)}</TopSideBar> */}
                                                                    {monthColumn?.length > 1 && (
                                                                        <TopSideBar
                                                                            style={{ width: '25%', textAlign: 'end' }}
                                                                        >
                                                                            {'Increase(Decrease)'}
                                                                        </TopSideBar>
                                                                    )}
                                                                </tr>
                                                            }
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                            </tr>
                                                        </tbody>
                                                        {
                                                            <tbody>
                                                                {assetData.map((val, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ width: '25%' }}>{val.name}</td>
                                                                        <ReportAmountTd>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>
                                                                                    {getPositiveNumber(
                                                                                        val.current_month
                                                                                    )}
                                                                                </span>
                                                                            </TotalAmountDiv>
                                                                        </ReportAmountTd>
                                                                        {monthColumn?.length > 1 && (
                                                                            <ReportAmountTd>
                                                                                <TotalAmountDiv>
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getPositiveNumber(
                                                                                            val.compare_month
                                                                                        )}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </ReportAmountTd>
                                                                        )}
                                                                        {monthColumn?.length > 1 && (
                                                                            <ReportAmountTd>
                                                                                <TotalAmountDiv>
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getPositiveNumber(
                                                                                            val.incdesc_val
                                                                                        )}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </ReportAmountTd>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    {<td>{t(`${reportName}.total`)}</td>}
                                                                    <SpanTotalFlow>
                                                                        <TotalAmountDiv>
                                                                            {currencyCode}{' '}
                                                                            <span>{getPositiveNumber(totalCurrAssetData)}</span>
                                                                        </TotalAmountDiv>
                                                                    </SpanTotalFlow>
                                                                    {monthColumn?.length > 1 && (
                                                                        <SpanTotalFlow>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>{getPositiveNumber(totalCompAssetData)}</span>
                                                                            </TotalAmountDiv>
                                                                        </SpanTotalFlow>
                                                                    )}
                                                                    {monthColumn?.length > 1 && (
                                                                        <SpanTotalFlow>
                                                                            <TotalAmountDiv>
                                                                                {currencyCode}{' '}
                                                                                <span>{getPositiveNumber(
                                                                                    totalIncDescAssetData
                                                                                )}</span>
                                                                            </TotalAmountDiv>
                                                                        </SpanTotalFlow>
                                                                    )}
                                                                </tr>
                                                            </tbody>
                                                        }
                                                        <tbody>
                                                            <tr>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                                <td>{''}</td>
                                                            </tr>
                                                        </tbody>

                                                        <tbody>
                                                            <tr>
                                                                <TopSideBar style={{ textAlign: 'end' }}>
                                                                    {''}
                                                                </TopSideBar>
                                                                {monthColumn?.map((item, index) => (
                                                                    <TopSideBar key={index}
                                                                        style={{ width: '25%', textAlign: 'end' }}
                                                                    >
                                                                        {getMonthNameYear(item)}
                                                                    </TopSideBar>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                        {assetPercentageData.length > 0 && (
                                                            <tbody>
                                                                {assetPercentageData.map((val, index) => (
                                                                    <tr key={index}>
                                                                        <td>{val.name}</td>
                                                                        <td style={{ textAlign: 'end' }}>
                                                                            {val.current_month_percentage} %
                                                                        </td>
                                                                        {monthColumn?.length > 1 && (
                                                                            <td style={{ textAlign: 'end' }}>
                                                                                {val.compare_month_percentage} %
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    {totalCurrentPercentage >= 0 && (
                                                                        <td>{t(`${reportName}.total`)}</td>
                                                                    )}
                                                                    <SpanTotalFlow>
                                                                        <div>
                                                                            {totalCurrentPercentage} %{' '}
                                                                        </div>
                                                                    </SpanTotalFlow>
                                                                    {monthColumn?.length > 1 && (
                                                                        <SpanTotalFlow>
                                                                            <div>
                                                                                {totalComparePercentage} %{' '}
                                                                            </div>
                                                                        </SpanTotalFlow>
                                                                    )}
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <NoDataTd colSpan={4}>
                                                                {t('executive-summary.no-data-found')}
                                                            </NoDataTd>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </>
                                        )}
                                    </TableStyle>
                                </TableUpperDiv>
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <Bargraph chartData={chartData} currencyCode={currencyCode} />
                            <Piegraph chartData={horichartData} />
                        </>
                    )}
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={() => setShowGraph(!showGraph)}
                        >
                            {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                        </LoadingButton>
                    </ButtonText>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
