import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ONBOARDING_STEPS } from "./constant";

export default function StepperActions({ activeStep = 0, handleBack = () => {}, handleNext = () => {}, additionalStyles = {}}) {
    const { t } = useTranslation();

    return <div style={additionalStyles}>
        <Button
            variant="outlined"
            disabled={activeStep === 0 || activeStep === 2}
            style={{marginBottom: 15, ...additionalStyles}}
            onClick={() => handleBack()}>
            {t('onboarding.back')}
        </Button>
        <Button
            variant="contained"
            style={{float:'right', marginBottom:15, ...additionalStyles}}
            onClick={() => handleNext()}>
            {activeStep === ONBOARDING_STEPS.length - 1 ? t('onboarding.all-done') : t('onboarding.next')}
        </Button>
    </div>
}