const getBenefitsData = (t) => ([
    {
        id: 1,
        text: t('home-page.benefit-1') 
    },
    {
        id: 2,
        text: t('home-page.benefit-2')
    },
    {
        id: 3,
        text: t('home-page.benefit-3')
    },
    {
        id: 4,
        text: t('home-page.benefit-4')
    },
    {
        id: 5,
        text: t('home-page.benefit-5')
    },
    {
        id: 6,
        text: t('home-page.benefit-6')
    },
]);

const getWhatDoYouGetData = (t) => ([
    {
        id: 1,
        icon: '/static/homeImg/downloadIcon.svg',
        title: t('home-page.what-do-you-get-1-title'),
        content: t('home-page.what-do-you-get-1-content') 
    },
    {
        id: 2,
        icon: '/static/homeImg/transactionIcon.svg',
        title: t('home-page.what-do-you-get-2-title'),
        content: t('home-page.what-do-you-get-2-content') 
    }
]);

const getAdditionalBenefitsData = (t) => ([
    {
        id: 1,
        icon: '/static/homeImg/todoIcon.svg',
        title: t('home-page.add-benefits-1-title'),
        content: t('home-page.add-benefits-1-content-line') 
    },
    {
        id: 2,
        icon: '/static/homeImg/keyIcon.svg',
        title: t('home-page.add-benefits-2-title'),
        content: t('home-page.add-benefits-2-content-line') 
    },
    {
        id: 3,
        icon: '/static/homeImg/calcIcon.svg',
        title: t('home-page.add-benefits-3-title'),
        content: t('home-page.add-benefits-3-content-line') 
    },
    {
        id: 4,
        icon: '/static/homeImg/baseIcon.svg',
        title: t('home-page.add-benefits-4-title'),
        content: t('home-page.add-benefits-4-content-line') 
    },
    {
        id: 5,
        icon: '/static/homeImg/forumIcon.svg',
        title: t('home-page.add-benefits-5-title'),
        content: t('home-page.add-benefits-5-content-line') 
    },
    {
        id: 6,
        icon: '/static/homeImg/suggestionIcon.svg',
        title: t('home-page.add-benefits-6-title'),
        content: t('home-page.add-benefits-6-content-line') 
    },
]);

export {
    getBenefitsData,
    getWhatDoYouGetData,
    getAdditionalBenefitsData,
};