import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeadingStyle from '../../../components/HeadingStyle';
import HeadingCardStyle from '../../../components/HeadingCardStyle';
import { getAdditionalBenefitsData, getBenefitsData, getWhatDoYouGetData } from './constant';
import { BenefitSection, DividerBottom, DividerStyle, Head, HeadBox, HeadCard, HeadListItem, ListItem, Wrapper } from './styled-components';

export default function HomePageBanner() {
    const { t } = useTranslation();
    const benefits = getBenefitsData(t);
    const whatDoYouGet = getWhatDoYouGetData(t);
    const additionalBenefits = getAdditionalBenefitsData(t);

    return (
        <Wrapper>
            <BenefitSection px={20}>
                <Grid container spacing={2} pt={20}>
                    <Head>
                        <HeadingStyle
                            headLineOne={t('home-page.benefit-headline-1')}
                            headLineTwo={t('home-page.benefit-headline-2')}
                            headLineThree={t('home-page.benefit-headline-3')}
                        />
                        <HeadBox>
                            <HeadListItem>
                                {whatDoYouGet.map((w) => (
                                    <li key={w.id}>
                                        <Typography variant="subtitle1">{w.content}</Typography>
                                    </li>
                                ))}
                            </HeadListItem>
                        </HeadBox>
                    </Head>
                </Grid>
                <Grid container spacing={2} py={6}>
                    <HeadCard item xs={12} md={5}>
                        <HeadingCardStyle
                            iconName="/static/homeImg/dashboardIcon.svg"
                            titleLine={t('home-page.dashboard')}
                            contentLine={t('home-page.view-charts-tables')}
                        />
                        <Typography py={4} variant="subtitle1">
                            {t('home-page.dashboard-summary')}
                        </Typography>
                        <Typography pb={2} variant="subtitle1">
                            {t('home-page.other-things')}
                        </Typography>
                        <Box>
                            <ListItem>
                                {benefits.map((benefit) => (
                                    <li key={benefit.key}>
                                        <Typography variant="subtitle1">{benefit.text}</Typography>
                                    </li>
                                ))}
                            </ListItem>
                        </Box>
                        <DividerStyle />
                    </HeadCard>
                    <Grid item xs={12} md={7} my={6}>
                        <img width="100%" src="/static/homeImg/getRightImg.svg" alt="" />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12} md={5}>
                            <Typography py={5} variant="subtitle1">
                                <b>{t('home-page.enable-additional-solution')}</b>
                            </Typography>
                        </Grid>
                        <Grid container spacing={10}>
                            {additionalBenefits.map((addBenefit) => (
                                <Grid item xs={6} sm={4} key={addBenefit.id}>
                                    <HeadingCardStyle
                                        iconName={addBenefit.icon}
                                        titleLine={addBenefit.title}
                                        contentLine={addBenefit.content}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </BenefitSection>
            <DividerBottom />
        </Wrapper>
    );
}
