const getAdvantageData = (t) => ([
    {
        id: 1,
        text: t('home-page.advantage-1'),
    },
    {
        id: 2,
        text: t('home-page.advantage-2')
    },
    {
        id: 3,
        text: t('home-page.advantage-3')
    },
]);

export {
    getAdvantageData,
}