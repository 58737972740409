import request from '../../utils/request';

const getDashboardCategory = (data) => request.post('dashboard/categories', data);
const getDashboardSubCategoryData = (categoryId) => request.get(`dashboard/sub-categories/${categoryId}`);
const getDashboardData = (data) => request.post('dashboard', data);
const getcreditCardUsage = (data) => request.get(data);
const getExecutivesummary =()=> request.get('dashboard/executivesummary')
const getDashboardCategoryDetails =(data)=> request.get(`dashboard/${data}`)

const getSavingDetails =(data)=> request.post('dashboard/getSavings',data)

const getNonAndDescCategory= (data)=> request.post('dashboard/getcategorydiscnondisc',data)

const getExpenseDetails= (data)=> request.post('dashboard/getexpense',data)

const getAssetComposition =(data)=> request.post('dashboard/asset-composition',data)
const getLiabilitiesComposition =(data)=> request.post('dashboard/liabilities-composition',data)
const getCashComposition =(data)=> request.get(`dashboard/cash-composition?${data}`);
const getNetWorth = (data) => request.get(`dashboard/net-worth?${data}`);
const getDebtPaymentDetails = (data) => request.get(`dashboard/debt-payment?${data}`);

export default {
    getExpenseDetails,
    getDashboardCategory,
    getDashboardSubCategoryData,
    getDashboardData,
    getSavingDetails,
    getcreditCardUsage,
    getNonAndDescCategory,
    getExecutivesummary,
    getDashboardCategoryDetails,
    getAssetComposition,
    getLiabilitiesComposition,
    getCashComposition,
    getNetWorth,
    getDebtPaymentDetails
};