// @mui
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import { dayFormat } from 'utils/formatTime';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, BudgetPercentageInput, SpanTotalFlow, TotalAmountDiv, BackButton, TotalSubFlow, BudgetInput, BackButtonStyle, ButtonText, DiffTableContent, TotalSubAmount, LightTooltip, InfoIcon } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol } from '../../../utils/calCommonFunction';

export default function CalculatorsPage() {
    // States
    const [sgFor, setSgFor] = useState("");
    const [targetAmount, setTargetAmount] = useState("");
    const [currentAmount, setCurrentAmount] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [targetYear, setTargetYear] = useState("");
    const [annualInvestment, setAnnualInvestment] = useState("");
    const [expectedReturn, setExpectedReturn] = useState("");
    const [detailSummaryData, setDetailSummaryData] = useState([])

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currencySymbol = getCurrencySymbol();

    const getSavingsGoalDetails = async () => {
        const getSavingsGoalData = await calculator.getUserCalculator("savings-goal");
        if (getSavingsGoalData.data) {
            const loanJsonData = getSavingsGoalData.data?.calcJson;
            setSgFor(loanJsonData.sg_for);
            setTargetAmount(loanJsonData.target_amount);
            setCurrentAmount(loanJsonData.current_amount);
            setCurrentDate(loanJsonData.current_date);
            setTargetYear(loanJsonData.target_year);
            setAnnualInvestment(loanJsonData.annual_investment);
            setExpectedReturn(loanJsonData.expected_return);
        }
    }

    useEffect(() => {
        getSavingsGoalDetails();
    }, []);

    const savingsGoalDetails = () => {
        const detailsSummary = [];
        let element = [];
        const yearTemp = currentDate;
        const expectedReturnTemp = Number(removeNumberFormatting(expectedReturn));
        const annualInvestmentTemp = Number(removeNumberFormatting(annualInvestment));
        let investmentReturnTemp = 0;
        let balanceTemp = Number(removeNumberFormatting(currentAmount));

        const summaryDataTemp = {
            slNo: 0,
            year: currentDate,
            contribution: "",
            investmentReturn: "",
            balance: currentAmount
        };
        detailsSummary.push(summaryDataTemp);
        
        for (let i = 1; i <= targetYear; i += 1) {
            investmentReturnTemp = (expectedReturnTemp / 100) * balanceTemp;
            balanceTemp = balanceTemp + annualInvestmentTemp + investmentReturnTemp;

            const summaryDataTemp = {
                slNo: i,
                year: getFormattedYear(yearTemp, i),
                contribution: Number(annualInvestmentTemp.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 }),
                investmentReturn: Number(investmentReturnTemp.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 }),
                balance: Number(balanceTemp.toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 })
            };
            detailsSummary.push(summaryDataTemp);
            element = Object.values(detailsSummary);
        }
        return detailsSummary;
    };

    const getFormattedYear = (date, index) => {
        const currentTime = new Date(date)
        const month = currentTime.getMonth()
        const day = currentTime.getDate()
        const year = currentTime.getFullYear() + index
        if (year.toString() === 'NaN') {
            return '';
        }
        // eslint-disable-next-line no-useless-concat
        return `${year  }-` + `${Number(month) < 9 ? `0${month+1}` : `${month+1}` }-` + `${Number(day) < 9 ? `0${day}` : `${day}` }`;
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'current_date') {
            setCurrentDate(value);
        }
    }

    const resetFields = () => {
        setSgFor("");
        setTargetAmount("");
        setCurrentAmount("");
        setCurrentDate("");
        setTargetYear("");
        setAnnualInvestment("");
        setExpectedReturn("");
        setDetailSummaryData([]);
    }

    const savePresentData = async () => {
        try {
            const postData = {
                "sg_for": sgFor,
                "target_amount": targetAmount,
                "current_amount": currentAmount,
                "current_date": currentDate,
                "target_year": targetYear,
                "annual_investment": annualInvestment,
                "expected_return": expectedReturn
            }
            const embeddedDataObj = {
                "calcType": "savings-goal",
                "calcJson": postData
            };

            // TODO - Change the implementation based on the return
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "slNo": "",
                "year": "Description",
                "contribution": "Value",
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Savings goal for",
                "contribution": sgFor,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Target amount",
                "contribution": `${currencySymbol} ${targetAmount}`,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Current amount saved",
                "contribution": `${currencySymbol} ${currentAmount}`,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Current date",
                "contribution": currentDate,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Target years",
                "contribution": targetYear,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Annual investment",
                "contribution": `${currencySymbol} ${annualInvestment}`,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "Expected investment return",
                "contribution": `${expectedReturn} %`,
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "",
                "year": "",
                "contribution": "",
                "investmentReturn": "",
                "balance": "",
            },
            {
                "slNo": "Year#",
                "year": "Year",
                "contribution": "Contribution",
                "investmentReturn": "Investment Return",
                "balance": "Balance",
            },
            {
                "slNo": "Opening balance      0",
                "year": "",
                "contribution": "",
                "investmentReturn": "",
                "balance": "",
            },
        ];
        const style = [
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A11',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'A10',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B10',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C10',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D10',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'E10',
                "style": EXCEL_STYLE.boldHeader
            },
        ]
        const afterMap = savingsGoalDetails().map((item) => {
            item.contribution = item.contribution !== '' ? `${currencySymbol} ${item.contribution}` : '';
            item.investmentReturn = item.investmentReturn !== '' ? `${currencySymbol} ${item.investmentReturn}` : '';
            item.balance = item.balance !== '' ? `${currencySymbol} ${item.balance}` : '';
            return item;
        })
        const finalPost = postData.concat(afterMap).concat([{
            contribution: `${currencySymbol} ${getTotal('contribution')}`,
            investmentReturn: `${currencySymbol} ${getTotal('investmentReturn')}`,
            balance: '',
            year: 'Total',
            slNo: ''
        }])
        const cols = [{ width: 21 }, { width: 25 }, { width: 13 }, { width: 20 }, { width: 12 }];
        const merges = [
            { s: { r: 10, c: 0 }, e: { r: 11, c: 0 } },
        ]
        downloadExcelHeader(finalPost, style, cols, merges, 'SavingGoal', 2, ['B', 'C', 'D', 'E'], true);
    }

    const getTotal = (totalKey) => {
        let sum = 0;
        savingsGoalDetails().forEach((item) => {
            const val = Number(item[totalKey]) === "NaN" ? 0 : Number(removeNumberFormatting(item[totalKey]));
            sum += val;
        });
        return Number(sum.toFixed(2)).toLocaleString("en-US", {minimumFractionDigits:2});
    }

    return (
        <Page title={t('savings-goal.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('savings-goal.go-back')}
                    </BackButtonStyle>
                    <Title title={t('savings-goal.calculator')} subtitle={t('savings-goal.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('savings-goal.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('savings-goal.sg-for')}</td>
                                            <BudgetInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    name="sg_for"
                                                    value={sgFor}
                                                    placeholder={t('savings-goal.sg-for_text')}
                                                    onChange={(e) => {
                                                        setSgFor(e.target.value);
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.target-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="target_amount"
                                                    value={targetAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setTargetAmount(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setTargetAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.current-amount-saved')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="current_amount"
                                                    value={currentAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setCurrentAmount(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setCurrentAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.current-date')}</td>
                                            <BudgetInput>
                                                <Input
                                                    type='date'
                                                    placeholder="0"
                                                    name="current_date"
                                                    value={currentDate}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.target-year')}</td>
                                            <BudgetInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    name="target_year"
                                                    value={targetYear}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setTargetYear(e.target.value.toString().slice(0, 2));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.annual-investment')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="annual_investment"
                                                    value={annualInvestment}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setAnnualInvestment(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setAnnualInvestment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                                <LightTooltip title={
                                                    <>
                                                        <p>{t('savings-goal.saving-goal-tooltip')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" style={{ top: '14px', right: '-22px' }} />
                                                </LightTooltip>
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('savings-goal.expected-return')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="expected_return"
                                                    value={expectedReturn}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        setExpectedReturn(value);
                                                    }}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle style={{ padding: '42px 4% 42px' }}>
                                <tbody>
                                    <tr>
                                        <td>{''}</td>
                                        <DiffTableContent>{t('savings-goal.year_no')}</DiffTableContent>
                                        <DiffTableContent>{t('savings-goal.year')}</DiffTableContent>
                                        <DiffTableContent>{t('savings-goal.contribution')}</DiffTableContent>
                                        <DiffTableContent>{t('savings-goal.investment-return')}</DiffTableContent>
                                        <DiffTableContent>{t('savings-goal.balance')}</DiffTableContent>
                                    </tr>
                                    {
                                        savingsGoalDetails().map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <TotalSubFlow>{item.slNo === 0 ? t('savings-goal.opening-balance') : ''}</TotalSubFlow>
                                                    <TotalSubAmount>{item.slNo}</TotalSubAmount>
                                                    <TotalSubAmount>{item.year}</TotalSubAmount>
                                                    <TotalSubAmount>{item.contribution === "" ? item.contribution : <span>{currencySymbol} {item.contribution}</span>}</TotalSubAmount>
                                                    <TotalSubAmount>{item.investmentReturn === "" ? item.investmentReturn : <span>{currencySymbol} {item.investmentReturn}</span>}</TotalSubAmount>
                                                    <TotalSubAmount>{currencySymbol} {item.balance}</TotalSubAmount>
                                                </tr>
                                            </>
                                        ))
                                    }
                                    <tr>
                                        <TotalSubFlow>{t('')}</TotalSubFlow>
                                        <TotalSubFlow>{t('')}</TotalSubFlow>
                                        <TotalSubFlow>{t('savings-goal.total')}</TotalSubFlow>
                                        <SpanTotalFlow><TotalAmountDiv>{currencySymbol}<span> {getTotal('contribution')}</span></TotalAmountDiv></SpanTotalFlow>
                                        <SpanTotalFlow><TotalAmountDiv>{currencySymbol}<span> {getTotal('investmentReturn')}</span></TotalAmountDiv></SpanTotalFlow>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('savings-goal.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('savings-goal.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={savePresentData}
                        >
                            {t('savings-goal.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('savings-goal.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('savings-goal.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
