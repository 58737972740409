import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { TextField, Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, LabelStyle, FormStyling, DateGrid } from './styled-components';

export default function Filter({ filtersData, updateFilters, debtAccountFilters }) {
    const methods = useForm({});

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return `0${n}`;
        }
        return n
    }

    const filterSubCategories = (selectedCategory = []) => {
        if (!selectedCategory?.length) {
            const filteredAccounts = [...Object.values(debtAccountFilters).flat().map((a) => ({id: a.accountId, label: a.accountName}))];
            setSubCategories(filteredAccounts);
            return;
        }
        const filteredData = Object.keys(debtAccountFilters)
            .filter(key => selectedCategory.includes(key))
            .reduce((obj, key) => {
                obj[key] = debtAccountFilters[key];
                return obj;
            }, {});
        if (filteredData) {
            const filteredAccounts = [...Object.values(filteredData).flat().map((a) => ({id: a.accountId, label: a.accountName}))]
            setSubCategories(filteredAccounts);
        }
    };

    const updateFilterValue = (key, value) => {
        if (key === 'categoryId') {
            if (value !== null) {
                updateFilters('categoryId', value);
                filterSubCategories(value);
            } else {
                updateFilters('categoryId', value);
                filterSubCategories();
            }
        } else if (key === 'subCatId') {
            if (value && value.id !== 'all') {
                updateFilters(key, value);
            } else {
                updateFilters(key, null);
            }
        }
        if (key === 'fromMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const monthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const selectMonthformat = `${selectmonth.getFullYear()}-${monthName}`;
            updateFilters(key, selectMonthformat);
        }
        if (key === 'compareMonth') {
            const parsezone= moment.parseZone(value).format('MM/DD/YYYY')
            const selectmonth = new Date(parsezone);
            const compareMonthName = appendLeadingZeroes(selectmonth.getMonth() + 1);
            const compareYear = selectmonth.getFullYear();
            const compareMonthformat = `${compareYear}-${compareMonthName}`;
            updateFilters(key, compareMonthformat);
        }
    };

    const populateFilterData = () => {
        const accTypes = [...Object.keys(debtAccountFilters)];
        setCategories(accTypes);
    };

    useEffect(() => {
        if (debtAccountFilters) {
            populateFilterData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debtAccountFilters]);

    return (
        <Box>
            <FormStyling>
                <FormProvider methods={methods}>
                    <Grid container spacing={2} >
                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    From Date
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.compareMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('compareMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>

                        <Grid item md={3} xs={12}>
                            <DateGrid>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    To Date
                                </LabelStyle>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={moment.parseZone(filtersData.fromMonth).format('MM/DD/YYYY')}
                                        views={["year", "month"]}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        onChange={(date) => updateFilterValue('fromMonth', date)}
                                        renderInput={(params) => <TextField {...params}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: "To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                Category
                            </LabelStyle>
                            <AutoFieldStyle
                                options={categories}
                                id="category"
                                value={filtersData.categoryId}
                                onChange={(e, val) => {
                                    updateFilterValue('categoryId', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <LabelStyle shrink htmlFor="bootstrap-input">
                                Sub-Category
                            </LabelStyle>
                            <AutoFieldStyle
                                disabled={!filtersData.categoryId}
                                options={subCategories}
                                id="sub-category"
                                value={filtersData.subCatId}
                                onChange={(e, val) => {
                                    updateFilterValue('subCatId', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </FormStyling>
        </Box>
    );
}