import { Grid, styled, Input, Link, Typography, Autocomplete, Menu, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const Wrapper = styled('div')(({ theme }) => ({
    height: '100%',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 12%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 12%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const HeaderDivStyle = styled('div')({
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
    marginTop: '20px',
    overflow: 'hidden'
});

export const StyledInput = styled(Input)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
}));

export const StyledTypography = styled(Typography)(() => ({
    justifyContent: 'flex-start',
    borderBottom: '1px solid #004800',
    textAlign: 'left',
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
}));

export const StyledButton = styled(Link)(() => ({
    cursor: 'pointer',
    marginRight: '0px',
    fontWeight: '400',
    lineHeight: '2.8',
    marginBottom: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
}));

export const StyledTask = styled(Typography)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '240px',
}));

export const DateInputStyle = styled(Input)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '180px',
}));

export const TableOverFlow = styled('div')(({ theme })=>({
    [theme.breakpoints.down('md')]: {
        overflow:'auto'
    },
}))

export const HeaderTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    minHeight:'480px',
    fontSize: 16,
    paddingBottom: '14px',
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    '& th': {
        color: theme.palette.primary.dark,
        fontSize: 18,
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingTop: '24px',
        textAlign: 'start',
        paddingBottom: '2px',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: '24px',
        paddingLeft: '24px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& p.Mui-error': {
        color: 'rgb(255, 72, 66)',
        fontSize: '0.75rem',
        fontWeight: 400,
        textAlign: 'left',
        position: 'absolute',
        margin: '3px'
    }
}));

export const TitleBorderStyle = styled('td')(() => ({
    borderBottom: '2px solid #89A69F',
}))

export const TitleStyle = styled('div')(({ theme }) => ({
    textAlign: 'center',
    '& h6': {
        fontWeight: 700,
        color: theme.palette.primary.main,
        position: 'relative',
        display: 'inline-block',
        margin: 'auto',
        '&:before': {
            position: 'absolute',
            top: '50%',
            width: '50px',
            height: '1px',
            background: theme.palette.primary.main,
            content: "' '",
            left: -65,
        },
        '&:after': {
            position: 'absolute',
            width: '50px',
            height: '1px',
            background: theme.palette.primary.main,
            content: "' '",
            right: -65,
            top: '50%',
        },
    },
    '& h4': {
        paddingTop: '17px',
        fontWeight: 700,
        color: theme.palette.primary.contrastText,
    },
}));

export const CheckboxStyle = styled(Checkbox)(() => ({
    padding: '0px !important'
}));

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    position: 'relative',
    width: '170px !important',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '0px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            minWidth: '170px !important',
        }
    },
    '& .MuiInputBase-root': {
        paddingRight:'10px !important',
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const DropDownMenu = styled(Menu)(() => ({
    '& ul': {
        padding: '8px 6px !important',
        background: '#F8F8F8',
        border: '1px solid #00773E',
        borderRadius: '10px',
        '&li': {
            padding: '10px'
        }
    },
}))

export const DatePickerStyle= styled(DatePicker)(()=>({
    '& input':{
        fontSize: '14px',
        width: '122px',
        padding:'12px 0px 12px 12px'
    }
}))