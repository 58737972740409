// @mui
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import ExecutiveIncDecTable from './ExecutiveIncDecTable';
import ExecutiveTopTransactionsTable from './ExecutiveTopTransactionsTable';
import dashboard from '../../../constants/services/dashboard';
import { CHARTCOLOR } from '../constant';
import {
    getCurrencySymbol,
} from '../../../utils/calCommonFunction';
import {
    MobileResDiv,
    ButtonText,
} from './styled-component';
import ExecutiveBudgetTable from './ExecutiveBudgetTable';
import Bargraph from '../Graphs/Bargraph';
import { ExecutiveTableSummary } from './ExecutiveTableSummary';
import ExecutiveRecurringAndLastTable from './ExecutiveRecurringAndLastTable';

export default function ExecutiveSummary() {

    const [budgetUnderYearData, setBudgetUnderYearData] = useState([]);
    const [budgetUnderMonthData, setBudgetUnderMonthData] = useState([]);
    const [underBudgetYearChart, setUnderBudgetYearChart] = useState([]);
    const [underBudgetMonthChart, setUnderBudgetMonthChart] = useState([]);

    const [bugetOverYearData, setBugetOverYearData] = useState([]);
    const [bugetOverMonthData, setBugetOverMonthData] = useState([]);
    const [overBudgetYearChart, setOverBudgetYearChart] = useState([]);
    const [overBudgetMonthChart, setOverBudgetMonthChart] = useState([]);

    const [showBudget, setShowBudget] = useState(true);
    const [showUnderBudget, setShowUnderBudget] = useState(true);

    const [tableLoader, setTableLoader] = useState(false);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true)
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false)
            return data;
        } catch (error) {
            setTableLoader(false)
            console.log('error', error);
        }
    };

    const getExecutiveSummaryData = async () => {
        // api paths
        const budgetUnderYear = `budget?type=year&budget=u`;
        const budgetUnderMonth = `budget?type=month&budget=u`;
        const bugetOverYear = `budget?type=year&budget=o`;
        const bugetOverMonth = `budget?type=month&budget=o`;

        let budgetUnderYearWise;
        let budgetUnderMonthWise;
        let bugetOverYearWise;
        let bugetOverMonthWise;
        try {
            budgetUnderYearWise = await getIncInvestmentData(budgetUnderYear);
            setBudgetUnderYearData(budgetUnderYearWise);
        }
        catch (error) {
            console.log("error", error);
        }

        try {
            budgetUnderMonthWise = await getIncInvestmentData(budgetUnderMonth);
            setBudgetUnderMonthData(budgetUnderMonthWise);
        }
        catch (error) {
            console.log("error", error);
        }

        try {
            bugetOverYearWise = await getIncInvestmentData(bugetOverYear);
            setBugetOverYearData(bugetOverYearWise);
        }
        catch (error) {
            console.log("error", error);
        }

        try {
            bugetOverMonthWise = await getIncInvestmentData(bugetOverMonth);
            setBugetOverMonthData(bugetOverMonthWise);
        }
        catch (error) {
            console.log("error", error);
        }

        const overBudgetYearObj = getinvestmentChartData(
            bugetOverYearWise,
            'Top 5 Expenses that are Over Budget This Year'
        );
        const overBudgetMonthObj = getinvestmentChartData(
            bugetOverMonthWise,
            'Top 5 Expenses that are Over Budget This Month'
        );
        const underBudgetYearObj = getinvestmentChartData(
            budgetUnderYearWise,
            'Top 5 Expenses that are Under Budget This Year'
        );
        const underBudgetMonthObj = getinvestmentChartData(
            budgetUnderMonthWise,
            'Top 5 Expenses that are Under Budget This Month'
        );

        setOverBudgetYearChart(overBudgetYearObj);
        setOverBudgetMonthChart(overBudgetMonthObj);
        setUnderBudgetYearChart(underBudgetYearObj);
        setUnderBudgetMonthChart(underBudgetMonthObj);
    };

    const getinvestmentChartData = (data, titlename) => {
        // eslint-disable-next-line array-callback-return
        const labels = data?.map((item) => item?.name);
        const datasetval = data?.map((item) => {
            let amount = null;
            if (titlename.includes('Budget')) {
                amount = item?.budget_by;
            } else {
                amount = item?.amount;
            }

            if (titlename.includes('Expense')) {
                amount = Number(amount);
                if (amount < 0) {
                    amount = Number(-amount);
                }
                if (amount > 0) {
                    amount = Number(amount);
                }
            }
            return amount || 0;
        });
        const dataset = [];

        labels.forEach((item, index) => {
            const dataitem = { label: labels[index], data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });
        for (let i = 0; i <= datasetval.length - 1; i += 1) {
            const monthData = datasetval[i];
            dataset[i].data.push(monthData);
        }
        return { labels: [''], dataset, title: titlename };
    };

    useEffect(() => {
        getExecutiveSummaryData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MobileResDiv>
            <ExecutiveTableSummary />
            <ExecutiveIncDecTable
                getinvestmentChartData={getinvestmentChartData}
            />

            <ExecutiveTopTransactionsTable
                getinvestmentChartData={getinvestmentChartData}
            />

            <ExecutiveRecurringAndLastTable />

            {
                showBudget ? (
                    <ExecutiveBudgetTable
                        tableloader={tableLoader}
                        increseThisMonth={bugetOverMonthData}
                        increseThisYear={bugetOverYearData}
                        tableFirstHeading={t('executive-summary.top-5-expenses-over-budget')}
                        tableSecondHeading={t('executive-summary.top-5-expenses-over-budget')}
                        tableFirstSubHeading={t('executive-summary.this-year')}
                        tableSecondSubHeading={t('executive-summary.this-month')}
                        thFirst={t('executive-summary.expense')}
                        thSecond={t('executive-summary.amount')}
                        thThird={t('executive-summary.over-budget-by')}
                    />
                ) : (
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ margin: '0px', width: '100%' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Bargraph chartData={overBudgetYearChart} currencyCode={currencySymbol} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Bargraph chartData={overBudgetMonthChart} currencyCode={currencySymbol} />
                        </Grid>
                    </Grid>
                )}

            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowBudget(!showBudget)}
                >
                    {showBudget ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
            </ButtonText>

            {
                showUnderBudget ? (
                    <ExecutiveBudgetTable
                        underBudgetflag
                        tableloader={tableLoader}
                        increseThisMonth={budgetUnderMonthData}
                        increseThisYear={budgetUnderYearData}
                        tableFirstHeading={t('executive-summary.top-5-expenses-under-budget')}
                        tableSecondHeading={t('executive-summary.top-5-expenses-under-budget')}
                        tableFirstSubHeading={t('executive-summary.this-year')}
                        tableSecondSubHeading={t('executive-summary.this-month')}
                        thFirst={t('executive-summary.expense')}
                        thSecond={t('executive-summary.amount')}
                        thThird={t('executive-summary.under-budget-by')}
                    />
                ) : (
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ margin: '0px', width: '100%' }}
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Bargraph chartData={underBudgetYearChart} currencyCode={currencySymbol} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Bargraph chartData={underBudgetMonthChart} currencyCode={currencySymbol} />
                        </Grid>
                    </Grid>
                )}
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    variant="contained"
                    // disabled={}
                    onClick={() => setShowUnderBudget(!showUnderBudget)}
                >
                    {showUnderBudget ? t('executive-summary.graph') : t('executive-summary.view-table')}
                </LoadingButton>
            </ButtonText>
        </MobileResDiv>
    );
}
