import { Hidden } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Page from '../../../components/Page';
import Logo from '../../../components/Logo';
import HeadingStyle from '../../../components/HeadingStyle';
import { CaptionText, HeadBanner, HomeHeaderBanner, RightButtons } from './styled-components';

export default function HomePageBanner() {
    const { t } = useTranslation();
    return (
        <Page>
            <HomeHeaderBanner>
                <img src="/static/homeImg/fullHomeBanner.png" alt="" />
                <HeadBanner>
                    <Hidden smDown>
                        <Logo sx={{ width: 217, height: 'auto' }} />
                    </Hidden>
                    <Hidden smUp>
                        <Logo sx={{ width: 160, height: 'auto' }} />
                    </Hidden>
                    <RightButtons>
                        <LoadingButton size="large" type="submit" variant="contained" href="/login">
                            {' '}
                            {t('login')}{' '}
                        </LoadingButton>
                        <LoadingButton size="large" type="submit" variant="outlined" href="/register">
                            {' '}
                            {t('start-trial')}{' '}
                        </LoadingButton>
                    </RightButtons>
                </HeadBanner>
                <CaptionText>
                    <HeadingStyle headLineOne={t('home-page.headline-1')} headLineTwo={t('home-page.headline-2')} />
                    <Typography variant="h5">
                        <Trans i18nKey="home-page.heading-1">
                        We believe there should be an easier way for you to stay on top of your finances, <strong>so you have one less stress in your life</strong> and have more clarity about what you need to do to have a better financial future.
                        </Trans>
                    </Typography>
                </CaptionText>
            </HomeHeaderBanner>
        </Page>
    );
}
