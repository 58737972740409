import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

export default function DialogAtom({maxWidth = 'lg', open, dialogTitle, content, onDialogAction, isSaveDisabled = false, additionalButtons = '', hideSave = false}) {
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={maxWidth}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant="h4">{dialogTitle}</Typography>
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                {additionalButtons}
                <Button
                    variant="outlined"
                    onClick={() => onDialogAction('cancel')}
                >
                    {t('Cancel')}
                </Button>
                {hideSave ? '' : 
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onDialogAction('save')}
                        autoFocus
                        disabled={isSaveDisabled}
                    >
                        {t('Save')}
                    </Button>}
            </DialogActions>
        </Dialog>
    )
}