import { useTranslation } from 'react-i18next';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInputTwo } from '../styled-components';

export default function VehiclesView({ currencySymbol }) {
    const { t } = useTranslation();

    const getBudgetInputTwo = (value, negative = false, sty = {}) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, ...sty }}>
        <span>{negative ? '-': ''}{currencySymbol}</span>
        <div style={{ minHeight: 21 }}>{value}</div>
    </BudgetInputTwo>

    return (
        <>
            <TableStyle style={{ padding: '0px 2%', marginTop: '1%', borderSpacing: 20 }}>
                <tbody>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'center' }}>
                            {t('view-manual-transactions.estimated-value')}
                        </td>
                    </tr>
                    <tr>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.description')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.major-repairs')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.balance')}</DiffTableContent>
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.original-purchase-price')}</CenterTbText>
                        <CenterTbText>21-Sep-21</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('80,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.change-transmission')}</CenterTbText>
                        <CenterTbText>15-Dec-22</CenterTbText>
                        {getBudgetInputTwo('4,000')}
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('84,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.depreciation')}</CenterTbText>
                        <CenterTbText>31-Jan-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('5,000', true, { color: 'red' })}
                        {getBudgetInputTwo('79,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.depreciation')}</CenterTbText>
                        <CenterTbText>28-Feb-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('5,000', true, { color: 'red' })}
                        {getBudgetInputTwo('74,000')}
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('4,000', false, { borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('', false, { borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('10,000', true, { color: 'red', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingTop: 12, paddingBottom: 12 })}
                    </tr>
                    <tr/>
                    <tr>
                        <td colSpan={5} style={{ borderSpacing: 10 }}>
                            <CenterTbText style={{  fontWeight: 700,
                                fontSize: 18,
                                color: '#00773E' }}>{t('view-manual-transactions.net-inc-dec-vehicle')}</CenterTbText>
                            <CenterTbText />    
                        </td>
                        {getBudgetInputTwo('6,000', true, { color: 'red', borderTop: 'solid 1px #00773E', borderBottom: ' solid 3px #00773E', paddingBottom: 0, paddingTop: 0 })}
                    </tr>
                </tbody>
            </TableStyle>

            <TableStyle style={{ padding: '0px 2%', marginTop: '1%', borderSpacing: 20 }}>
                <tbody>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'left', color: '#00773E', fontWeight: '700' }}>{t('view-manual-transactions.vehicle-info')}</td>
                    </tr>
                    <tr>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.year')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.manufacture')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.model')}</DiffTableContent>
                        <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'left' }}>
                            {t('view-manual-transactions.vin')}
                        </td>
                        <td colSpan={2} style={{ color: '#00773E', fontWeight: '600', textAlign: 'left' }}>
                            {t('view-manual-transactions.other-details')}
                        </td>
                        <DiffTableContent />
                    </tr>
                    <tr>
                        <CenterTbText>2021</CenterTbText>  
                        <CenterTbText>BMW</CenterTbText>
                        <CenterTbText>23</CenterTbText>
                    </tr>
                </tbody>
            </TableStyle>
        </>
    );
}
