import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
// import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
// import 'react-toastify/dist/ReactToastify.css';

import dashboard from '../../../constants/services/dashboard';
import Bargraph from '../Graphs/Bargraph';
import { CHARTCOLOR } from '../constant';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import CreditCardFilter from '../filter/CreditCardFilter';
import { NoDataTd, ButtonText, ReportAmountTd, TotalAmountDiv, SpanTotalFlow } from '../ExecutiveSummary/styled-component'
import { removeEmpty, getMonthNameYear, getCurrencySymbol, getPositiveNumber, getNegativeNumber } from '../../../utils/calCommonFunction';
import { Wrapper, PageTopRightBg, ContentStyle, SubmitButton, TableStyle, BackButtonStyle, StickyFirstTh, StickySecondTh, StickyThirdTh, TableWrapper, StickyFirstTd, StickySecondTd, StickyThirdTd, StickyTable, LoaderGif, AmountDiv } from '../Expense/styled-component';

export default function IncomeExpense(props) {
    const [expenseData, setExpenseData] = useState([])
    const [expenseGroupData, setExpenseGroupData] = useState([])
    const [monthColumn, setMonthColumn] = useState([]);
    const [monthWiseAverage, setMonthWiseAverage] = useState()
    const [monthWiseTotal, setMonthWiseTotal] = useState([])
    const [expenseMonthTotal, setExpenseMonthTotal] = useState()
    const [tableLoader, setTableLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [showGraph, setShowGraph] = useState(true)
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    const [expenseDataLength, setExpenseDataLength]= useState(null)

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const { reportName } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: location?.state?.categoryGroupId || null,
        categoryId: null,
        type: 2
    });

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getExpenseCategoryData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);
        
        const response = await dashboard.getDashboardData(filtersDataObj);
        
        const data = response?.categoriesData;
    
        setTableLoader(false)
        
        const groupedData = [];
        const groupedDataTot = [];
        const groupedDataMonthlyTot = [];
        const monthRange = Object.keys(data)
        const monthDifference= monthRange?.length;

        setExpenseDataLength(monthDifference)
        monthRange.sort();
        setMonthColumn(monthRange)

        // const monthDifference = moment.parseZone(filtersData.fromMonth).diff(moment.parseZone(filtersData.compareMonth), 'months', true) + 1;

        // eslint-disable-next-line
        monthRange.map((item) => {
            let totalAsset = 0;
            let assetName = '';
            let cateTotal = 0;

            data[item].forEach((a) => {
                assetName = a.name;
                groupedData[a.name] = groupedData[a.name] || [];

                groupedData[a.name].push(a.values)
                totalAsset += Number(a.values);
                cateTotal = Number(groupedDataTot[a.name] ? groupedDataTot[a.name] : 0) + Number(a.values);

                groupedDataTot[assetName] = cateTotal;
            });
            groupedDataMonthlyTot.push(totalAsset);
        })

        const ObjectTotalData = Object.values(groupedDataTot)
        const expenseEle = Object.keys(groupedData);
        const monthAvgTotal = ObjectTotalData.map((item) => Number(item) / monthDifference)
        const monthTotal = expenseTotalSum(groupedDataMonthlyTot);
        const expenseavgTotal = expenseTotalSum(monthAvgTotal);
        
        setExpenseGroupData(groupedData)
        setMonthWiseAverage(expenseavgTotal)
        setExpenseMonthTotal(monthTotal)
        setMonthWiseTotal(groupedDataMonthlyTot);
        setExpenseData(expenseEle)

        const graphData = getGraphData(groupedData, monthRange);
        setChartData(graphData);
    }

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0)
        return result
    }

    const getTotalSumofData = ((item) => {
        const sumofdata = expenseGroupData[item].reduce((acc, item) => Number(acc) + Number(item), 0)
        return sumofdata
    })

    const getAvgSumofData = ((item) => {
        const totalval = getTotalSumofData(item)
        const totalAvg = totalval / expenseDataLength
        if (reportName === 'expense-composition') {
            return getNegativeNumber(totalAvg?.toFixed(2));
        }
        return getPositiveNumber(totalAvg?.toFixed(2));
    })

    const getFilterData = () => {
        getExpenseCategoryData()
    }

    const getGraphData = (data, monthrange) => {
        const categoryName = Object.keys(data);
        const catchart = Object.values(data);
        const dataset = [];

        monthrange.forEach((item, index) => {
            const dataitem = { 'label': getMonthNameYear(item), 'data': [], 'backgroundColor': CHARTCOLOR[index] }
            dataset.push(dataitem)
        });
        const labels = categoryName
        for (let i = 0; i < catchart.length ; i += 1) {
            const item = catchart[i];
            const monthData = item;
            for (let j = 0; j < dataset.length; j += 1) {
                let reqvalue = Number(monthData[j]);
                if (reqvalue < 0) {
                    reqvalue = Number(-reqvalue)
                }
                if (reqvalue > 0) {
                    reqvalue = Number(reqvalue)
                }
                dataset[j].data.push(reqvalue);
            }
        }
        // eslint-disable-next-line
        return ({ labels, dataset, title: t(`${reportName}.subtitle`) });
    }

    const getCategories = async () => {
        const response = await dashboard.getDashboardCategory({categoryGroupId:filtersData.categoryGroupId});
        const categories = response?.data || [];
        setCategories(categories);
    };

    useEffect(() => {
        getExpenseCategoryData()
        getCategories()
    }, [])

    return (
        <>
            <Page title={t(`${reportName}.title`)}>
                <Wrapper>
                    <PageTopRightBg />
                    <ContentStyle>
                        <BackButtonStyle
                            variant="contained"
                            onClick={() => navigate('/dashboard/all-reports')}>{t(`${reportName}.back`)}
                        </BackButtonStyle>
                        <Title title={t(`${reportName}.dashboard`)} subtitle={t(`${reportName}.subtitle`)} />

                        <Grid container pt={5} pb={5} mt={0}>
                            <Grid item md={10.5} xs={12}>
                                <CreditCardFilter
                                    filtersData={filtersData}
                                    updateFilters={updateFilters}
                                    categories={reportName==='savings'? [] :categories}
                                />
                            </Grid>
                            <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                <SubmitButton loading={loading} size="large" variant="contained" onClick={() => getFilterData()}>
                                    Go
                                </SubmitButton>
                            </Grid>
                        </Grid>
                        {
                            showGraph ?
                                <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                                    <Grid item xs={12} sm={12} md={12}>
                                        <StickyTable>
                                            <TableWrapper>
                                                <TableStyle className='highlighted-fields'>
                                                    {
                                                        tableLoader ?
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            :
                                                            <>
                                                                {
                                                                    expenseData?.length > 0 ?
                                                                        <>
                                                                            <thead>
                                                                                <tr>
                                                                                    <StickyFirstTh>{t(`${reportName}.category`)}</StickyFirstTh>
                                                                                    {
                                                                                        monthColumn.map((item, indx) => <th style={{ textAlign: 'end' }} key={indx}>{getMonthNameYear(item)}</th>)
                                                                                    }
                                                                                    <StickySecondTh style={{ right:(reportName==='savings'?'119px':'139px')}}>{t(`${reportName}.total`)}</StickySecondTh>
                                                                                    <StickyThirdTh>{t(`${reportName}.average`)}</StickyThirdTh>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    expenseData.map((item, index) => (
                                                                                        <>
                                                                                            <tr key={index}>
                                                                                                <StickyFirstTd>{item}</StickyFirstTd>
                                                                                                {
                                                                                                    expenseGroupData[item].map((item, indx) => 
                                                                                                        <ReportAmountTd key={indx}>
                                                                                                            <TotalAmountDiv>
                                                                                                                {currencyCode}{' '}
                                                                                                                <span>
                                                                                                                    {reportName === 'income-composition'
                                                                                                                        ? getPositiveNumber(item)
                                                                                                                        : getNegativeNumber(item)
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </TotalAmountDiv>
                                                                                                        </ReportAmountTd>
                                                                                                    )
                                                                                                }
                                                                                                <StickySecondTd style={{ right: '139px' }}>
                                                                                                    <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                                        {currencyCode}{' '}
                                                                                                        <span>
                                                                                                            {reportName === 'income-composition'
                                                                                                                ? getPositiveNumber(getTotalSumofData(item))
                                                                                                                : getNegativeNumber(getTotalSumofData(item))
                                                                                                            }
                                                                                                        </span>
                                                                                                    </TotalAmountDiv>
                                                                                                </StickySecondTd>
                                                                                                <StickyThirdTd>
                                                                                                    <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                                        {currencyCode}{' '}
                                                                                                        <span>
                                                                                                            {getAvgSumofData(item)}
                                                                                                        </span>
                                                                                                    </TotalAmountDiv>
                                                                                                </StickyThirdTd>
                                                                                            </tr>
                                                                                        </>
                                                                                    ))
                                                                                }
                                                                                <tr>
                                                                                    <StickyFirstTd style={{ height: '51px', fontSize: '16px', fontWeight: '600' }}>{t(`${reportName}.total`)}</StickyFirstTd>
                                                                                    {
                                                                                        monthWiseTotal.map((item, index) => 
                                                                                            <SpanTotalFlow key={index}>
                                                                                                <TotalAmountDiv>
                                                                                                    {currencyCode}{' '}
                                                                                                    <span>
                                                                                                        {reportName === 'income-composition'
                                                                                                            ? getPositiveNumber(item)
                                                                                                            : getNegativeNumber(item)
                                                                                                        }
                                                                                                    </span>
                                                                                                </TotalAmountDiv>
                                                                                            </SpanTotalFlow>
                                                                                        )
                                                                                    }
                                                                                    <StickySecondTd>
                                                                                        <AmountDiv>{currencyCode}
                                                                                            <span>
                                                                                                {reportName === 'income-composition'
                                                                                                    ? getPositiveNumber(expenseMonthTotal)
                                                                                                    : getNegativeNumber(expenseMonthTotal)
                                                                                                }
                                                                                            </span>
                                                                                        </AmountDiv>
                                                                                    </StickySecondTd>
                                                                                    <StickyThirdTd>
                                                                                        <AmountDiv>{currencyCode}
                                                                                            <span>
                                                                                                {reportName === 'income-composition'
                                                                                                    ? getPositiveNumber(monthWiseAverage?.toFixed(2))
                                                                                                    : getNegativeNumber(monthWiseAverage?.toFixed(2))
                                                                                                }
                                                                                            </span>
                                                                                        </AmountDiv>
                                                                                    </StickyThirdTd>
                                                                                </tr>
                                                                            </tbody>
                                                                        </>
                                                                        :
                                                                        <tbody>
                                                                            <tr>
                                                                                <NoDataTd colSpan={4}>{t('executive-summary.no-data-found')}</NoDataTd>
                                                                            </tr>
                                                                        </tbody>
                                                                }
                                                            </>
                                                    }
                                                </TableStyle>
                                            </TableWrapper>
                                        </StickyTable>
                                    </Grid>
                                </Grid>
                                :
                                <Bargraph chartData={chartData} currencyCode={currencyCode} />
                        }
                        <ButtonText>
                            <LoadingButton
                                fullWidth
                                size="large"
                                variant="contained"
                                // disabled={}
                                onClick={() => setShowGraph(!showGraph)}
                            >
                                {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                            </LoadingButton>
                        </ButtonText>
                    </ContentStyle>
                </Wrapper>
            </Page>
        </>
    )
}