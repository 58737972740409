const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        llg: 1700,
        xl: 1900,
    },
};
export default breakpoints;
