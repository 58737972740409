import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import auth from '../../../constants/services/auth';
import { showError, showSuccess, showToast } from '../../../utils/toast';

const ButtonText = styled('div')(({ theme }) => ({
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        fontWeight: '500',
        marginTop: 15,
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));

export default function ForgotPasswordForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const FormSchema = Yup.object().shape({
        email: Yup.string().email(t('error-message.invalid-email')).required(t('error-message.email-required')),
    });

    const defaultValues = {
        email: '',
    };

    const methods = useForm({
        resolver: yupResolver(FormSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        const {email} = data;
        const forgotPwdPayload = { email };
        try {
            await auth.forgotPassword(forgotPwdPayload);
            showToast(t('forgot-password.verification-email-sent'), { type: 'success' });
            navigate('/login', { replace: false });
        } catch (error) {
            showError(t, error);
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField
                name="email"
                label={t('forgot-password.email-address')}
            />
            <ButtonText>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    {t('forgot-password.title')}
                </LoadingButton>
            </ButtonText>
        </FormProvider>
    );
}
