import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import { AccordionStyle, BankLogo, TableScroll, TableStyle } from "./styled-components";

function titleCase(s) { 
    return s
        .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
        .replace(/[_\\-]+/g, ' ') // split snake_case and lisp-case
        .toLowerCase()
        .replace(/(^\w|\b\w)/g, (m) => m.toUpperCase()) // title case words
        .replace(/\s+/g, ' ') // collapse repeated whitespace
        .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
}

export default function LinkedAccountInfo({linkedInstitutions}) {
    return (
        <>
            {linkedInstitutions?.map((linkedInstitute, index) => {
                let accounts = linkedInstitute.provider_account_infos?.reduce((acc, obj) => {
                    const {container} = obj;
                    return {...acc, [container]: [...(acc[container] || []), obj]};
                }, {});

                accounts = Object.keys(accounts).sort().reduce(
                    (obj, key) => { 
                        obj[key] = accounts[key]; 
                        return obj;
                    }, 
                    {}
                );

                return (
                    <AccordionStyle key={linkedInstitute.id} defaultExpanded={index === 0}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <BankLogo>
                                <img alt="" src={linkedInstitute.logoUrl} />
                            </BankLogo>
                            <Typography mx={1}>{linkedInstitute.providerName}</Typography>
                            <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                            <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <TableScroll container p={0}>
                                <TableStyle>
                                    <tbody style={{position: 'sticky'}}>
                                        <tr className="category-info">
                                            <td>Account Type</td>
                                            <td>Account</td>
                                            <td>Balance</td>
                                        </tr>
                                        {Object.entries(accounts).map(([key, value]) => <>
                                            <tr className="category-info">
                                                <td colSpan={5}><strong>{titleCase(key)}</strong></td>
                                            </tr>
                                            {value.map((accInfo) => <tr key={accInfo.id}>
                                                <td>{accInfo.accountName}</td>
                                                <td>{[titleCase(accInfo.accountType), accInfo.accountNumber].join(' | ')}</td>
                                                <td>
                                                    <span style={{textAlign: 'center',paddingTop: '6px', display:'flex', justifyContent: 'space-between', width: '151px'}}>
                                                        <span>$</span>
                                                        <span>{Number(accInfo?.balance)?.toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
                                                    </span> 
                                                </td>
                                            </tr>)}
                                        </>)}
                                    </tbody>
                                </TableStyle>
                            </TableScroll>
                        </AccordionDetails>
                    </AccordionStyle>
                )
            }
            )}
        </>
    );
}
