import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { TextField, Grid, Box } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import * as React from 'react';
import { forwardRef } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import Title from '../../components/Title';
import { FormProvider } from '../../components/hook-form';
import RHFTextField from '../../components/hook-form/RHFTextField';
import contactUs from '../../constants/services/contactUs';
import {
    BootstrapTextarea,
    InputLabelStyle,
    PageTopRightBg,
    AutoFieldStyle,
    ContentStyle,
    ButtonStyle,
    Fragment,
    Wrapper,
} from './styled-components';

function ContactUs() {
    const [subject, setSubject] = React.useState();
    const methods = useForm({});
    const { handleSubmit } = methods;

    const subjectDetails = [
        { id: 1, label: 'Report a bug' },
        { id: 2, label: 'Feature enhancement' },
        { id: 3, label: 'Other' },
    ];

    const { t } = useTranslation();
    const onSubmit = async (data) => {
        try {
            const userDetails = {
                subject,
                message: data?.message,
            };
            await contactUs.saveContactUsData(userDetails);
            toast.success('Message sent successfully');
        } catch (error) {
            console.log('Profile error', error);
            toast.error(error?.message);
        }
    };

    const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => (
        <>
            <Helmet>
                <title>{`${title}`}</title>
                {meta}
            </Helmet>

            <Box ref={ref} {...other}>
                {children}
            </Box>
        </>
    ));

    return (
        <Page title={t('contact.title')}>
            <Wrapper>
                <Fragment>
                    <PageTopRightBg />
                    <ContentStyle>
                        <Title title={t('contact.contact')} subtitle={t('contact.subtitle')} />
                        <FormProvider methods={methods}>
                            <Grid container spacing={2} pt={2} justifyContent="space-between" marginTop="50px">
                                <Grid item xs={4}>
                                    <InputLabelStyle shrink htmlFor="bootstrap-input">
                                        {t('contact.subject')}
                                    </InputLabelStyle>
                                    <AutoFieldStyle
                                        id="tags-outlined"
                                        options={subjectDetails}
                                        value={subject}
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        disableClearable
                                        disablePortal
                                        onChange={(e, val) => {
                                            setSubject(val.label);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" className="dropMenu" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextFieldMsg name="message" label={t('contact.message')} />
                                </Grid>
                                <Grid item justifyContent="flex-end" alignItems="flex-end" xs={12}>
                                    <ButtonStyle>
                                        <LoadingButton
                                            onClick={handleSubmit(onSubmit)}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            style={{ width: 300 }}
                                        >
                                            {t('contact.submit')}
                                        </LoadingButton>
                                    </ButtonStyle>
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </ContentStyle>
                </Fragment>
            </Wrapper>
        </Page>
    );
}

export default ContactUs;

RHFTextField.propTypes = {
    name: PropTypes.string,
};

function RHFTextFieldMsg({ name, multiline, rows, label, ...other }) {
    const { control } = useFormContext();

    return (
        <>
            <InputLabelStyle shrink htmlFor="bootstrap-input">
                {label}
            </InputLabelStyle>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <BootstrapTextarea
                        {...field}
                        multiline={multiline}
                        rows={rows}
                        fullWidth
                        value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                        error={!!error}
                        helperText={error?.message}
                        {...other}
                    />
                )}
            />
        </>
    );
}
