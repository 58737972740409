import { Grid, styled, Checkbox, Typography, CardContent, CardActions } from '@mui/material';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { LoadingButton } from '@mui/lab';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 12%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 12%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const HeaderDivStyle = styled('div')({
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    borderRadius: '10px',
    marginTop: '20px',
});

export const ThSortStyle= styled('th')(()=>({
    position:'relative',
    '& button':{
        background: 'none',
        border: 'none',
        outline: 'none',
        width: '16px',
        marginLeft: '4px',
        padding: '1px',
        cursor: 'pointer',
        position: 'absolute',
        bottom: '9px',
    }
}))

export const HeaderTableStyle = styled('table')(({ theme }) => ({
    border: '2px solid darkgreen',
    width: '100%',
    borderRadius: '10px',
    fontSize: '16px',
    padding: '10px 15rem 6px 50px',
    marginTop: '20px',
    height: 338,
    background: 'url(/static/onetime/Vector.png) no-repeat',
    backgroundPosition: 'right',
    [theme.breakpoints.down('lg')]: {
        padding: 20,
    },
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
    '& tbody':{
        height: '2px',
        verticalAlign: 'middle',
        display: 'inherit',
        width: '100%',
    },
    '& thead':{
        height: '2px',
        verticalAlign: 'middle',
        display: 'inherit',
        width: '100%',
    },
    '& th': {
        color: theme.palette.primary.dark,
        fontSize: 18,
        paddingRight: '14px',
        paddingLeft: '14px',
        paddingTop: '24px',
        paddingBottom: '2px',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: '14px',
        paddingLeft: '14px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export const TitleBorderStyle = styled('td')(({ theme }) => ({
    borderBottom: '1px solid black',
}));

export const StyledTD = styled('td')(({ theme }) => ({
    textAlign: 'center',
}));

export const StyledSpan = styled('span')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
}));

export const StyledName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#1bb344',
    display: 'flex'
}));

export const SuggestionName = styled('span')(()=>({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '138px'
}))

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

export const NavCalStyle = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '64px',
    marginBottom: '36px',
    background: '#F8F8F8',
    padding: '16px 50px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
        display: 'inline-block',
        margin: '0px',
        padding: '16px 10px'
    },
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const CheckBoxLabel = styled('h4')(({theme}) => ({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00773E',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        marginLeft: '2px'
    },
}));

export const StyledDate = styled(Typography)(() => ({
    padding: '12px 0px 0px 24px',
    fontSize: '13px',
    color: '#1bb344'
}));

export const StyledThumbUp = styled(ThumbUpIcon)(() => ({
    color: '#1bb344',
    width: '17px',
}));

export const StyledThumbUpActive = styled(ThumbUpIcon)(() => ({
    color: 'green',
    width: '20px',
}));

export const StyledThumbDown = styled(ThumbDownAltIcon)(() => ({
    color: '#1bb344',
    width: '17px',
    marginLeft: '5px',
}));

export const StyledThumbDownActive = styled(ThumbDownAltIcon)(() => ({
    color: 'green',
    width: '20px',
    marginLeft: '5px',
}));

export const cardStyle = {
    borderRadius: '0px',
    background: 'linear-gradient(180deg, #F8F8F8 0%, rgba(255, 255, 255, 0) 100%)',
    borderBottom: '1px solid #64A940',
};

export const SuggestionCard= styled(CardContent)(({theme})=>({
    padding: '22px 22px 0px 22px',
    '& button': {
        background: 'none',
        border: 'none',
        outline: 'none',
        fontSize: '13px',
        color: '#00773E',
        fontWeight: '700',
        cursor: 'pointer'
    },
    '& p':{
        height: '122px',
        [theme.breakpoints.up('xl')]: {
            fontSize: 15,
            height: '96px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '94px',
        },
    }
}))

export const SuggestionAction = styled(CardActions)(()=>({
    padding:'7px 24px 10px',
    justifyContent:'space-between'
}))

export const LoadMoreButton= styled(LoadingButton)(({theme})=>({
    width:'22rem',
    [theme.breakpoints.down('md')]: {
        width: '12rem',
        height: '48px'
    },
}))