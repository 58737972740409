import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    '& header + div + div': {
        [theme.breakpoints.up('md')]: {
            paddingLeft: 90,
        },
    },
}));

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: 0,
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
}));

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
