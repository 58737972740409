import { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Snackbar } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import moment from 'moment';
import CloseIcon from "@mui/icons-material/Close";
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Title from '../../components/Title';
import Page from '../../components/Page';
import { NAV_ITEMS } from './constant';
import todos from '../../constants/services/todos';
import { CardStyle, CheckBoxCenter, CheckBoxLabel, CheckboxStyle, ContentStyle, PageTopRightBg, Wrapper, SnackbarDivStyle, SnackBarUl } from './styled-components';

export default function Welcome() {
    const { t } = useTranslation();
    const location = useLocation();
    const [showDescription, setShowDescription] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState([])
    const [snackbarFlag, setSnackbarFlag] = useState(true)
    let dismissLocationState = location?.state?.notificationFlag;

    const getSnackbarDetails = async () => {
        const requestedDate = moment.parseZone().format('yyyy-MM-DD');
        try {
            const response = await todos.getTodaysReminder(requestedDate);
            setSnackbarMsg(response.data)
        }
        catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getSnackbarDetails()
    }, [])

    const handleClose = () => {
        dismissLocationState = false;
        setSnackbarFlag(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <Page title="Welcome Screen">
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Title title={t('welcome.title')} subtitle={t('welcome.subtitle')} />
                    <CheckBoxCenter>
                        <FormControlLabel control={<CheckboxStyle checked={showDescription} onChange={(event) => setShowDescription(event.target.checked)} inputProps={{ "aria-label": "controlled" }} />} label={<CheckBoxLabel>{t('welcome.view-description')}</CheckBoxLabel>} />
                    </CheckBoxCenter>
                    <Grid container spacing={4} pt={4} justifyContent="center" alignItems="flex-start">
                        {NAV_ITEMS.map((nav, key) => (
                            <Grid key={key} item xs={12} sm={6} md={4}>
                                <CardStyle>
                                    <Link to={nav.path}>
                                        {
                                            showDescription ?
                                                <Typography className='description-text'>{nav.description}</Typography> :
                                                <>
                                                    {nav.icon}
                                                    <Typography>{nav.description}</Typography>
                                                </>
                                        }
                                        <Typography variant="h4">{nav.title}</Typography>
                                    </Link>
                                </CardStyle>
                            </Grid>
                        ))}
                    </Grid>
                    <div className="clearspace" style={{ padding: '60px' }}>
                        {' '}
                    </div>
                </ContentStyle>
            </Wrapper>
            {
                dismissLocationState && snackbarMsg.length > 0 ?
                    <Snackbar
                        open={snackbarFlag}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        onClose={handleClose}
                        // eslint-disable-next-line
                        message={
                            <>
                                <SnackbarDivStyle>
                                    <CircleNotificationsIcon /> <b>{t('welcome.reminder-for')}</b>
                                </SnackbarDivStyle>
                                <SnackBarUl>
                                    <ul>{snackbarMsg.map((item, index) => <li key={index}>{item.task}</li>)}</ul>
                                </SnackBarUl>
                            </>
                        }
                        ContentProps={{
                            style: { background: 'white', color: '#00773E', fontSize: '15px' }
                        }}
                        action={action}
                    /> : ""
            }
        </Page>
    );
}
