import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField, Grid, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import { getCurrencySymbol } from 'utils/calCommonFunction';
import { FormProvider } from '../../../components/hook-form';
import calenderIcon from '../../downloadEdits/CalenderIcon';
import { AutoFieldStyle, LabelStyle, Textfield, DateGrid, CheckboxStyle } from './styled-components';

const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

export default function Filter({ filtersData, updateFilters, accountFilters }) {
    const methods = useForm({});
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const filterSubCategories = (selectedCategory = []) => {
        if (!selectedCategory?.length) {
            const filteredAccounts = [...Object.values(accountFilters).flat().map((a) => ({id: a.accountId, label: a.accountName}))];
            setSubCategories(filteredAccounts);
            return;
        }
        const filteredData = Object.keys(accountFilters)
            .filter(key => selectedCategory.includes(key))
            .reduce((obj, key) => {
                obj[key] = accountFilters[key];
                return obj;
            }, {});
        if (filteredData) {
            const filteredAccounts = [...Object.values(filteredData).flat().map((a) => ({id: a.accountId, label: a.accountName}))]
            setSubCategories(filteredAccounts);
        }
    };

    const updateFilterValue = (key, value) => {
        if (key === 'category') {
            filterSubCategories(value);
        }
        updateFilters(key, value);
    };

    const populateFilterData = () => {
        const accTypes = [...Object.keys(accountFilters)];
        setCategories(accTypes);

        const filteredAccounts = [...Object.values(accountFilters).flat().map((a) => ({id: a.accountId, label: a.accountName}))]
        setSubCategories(filteredAccounts);
    };

    useEffect(() => {
        populateFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountFilters]);

    return (
        <Box>
            <FormProvider methods={methods}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Grid container>
                            <Grid item md={4} xs={4}>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    Asset/Liability Category
                                </LabelStyle>
                            </Grid>
                            <Grid item md={8} xs={8}><AutoFieldStyle
                                multiple
                                limitTags={1}
                                options={categories}
                                id="category"
                                onChange={(e, val) => {
                                    updateFilterValue('category', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            /></Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4.25} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={4}>
                                <LabelStyle shrink htmlFor="date-from">
                                    Date Range
                                </LabelStyle>
                            </Grid>
                            <DateGrid item md={4.5} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={filtersData.dateFrom || null}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        inputFormat='MM/dd/yyyy'
                                        maxDate={filtersData.dateTo}
                                        onChange={(newValue) => {
                                            updateFilterValue('dateFrom', newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} 
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:"From"
                                            }}
                                        />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                            <DateGrid item md={4.5} xs={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        PaperProps={{ className: 'customDateSelector' }}
                                        value={filtersData.dateTo || null}
                                        components={{
                                            OpenPickerIcon: calenderIcon,
                                        }}
                                        inputFormat='MM/dd/yyyy'
                                        minDate={filtersData.dateFrom || null}
                                        onChange={(newValue) => {
                                            updateFilterValue('dateTo', newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} 
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:"To"
                                            }} />}
                                    />
                                </LocalizationProvider>
                            </DateGrid>
                        </Grid>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={4}>
                                <LabelStyle shrink htmlFor="payee">
                                    Search
                                </LabelStyle>
                            </Grid>
                            <Grid item md={9} xs={8}>
                                <Textfield
                                    value={filtersData.payee}
                                    name="payee"
                                    style={{ minWidth: 200 }}
                                    placeholder="Payee/Inc or Exp Category/Notes​"
                                    onChange={(e) => updateFilterValue('payee', e.target.value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1.75} xs={12}>
                        <Grid container>
                            <Grid item md={7} xs={4}>
                                <LabelStyle shrink htmlFor="payee">
                                    Hide Reviewed
                                </LabelStyle>
                            </Grid>
                            <Grid item md={5} xs={8}>
                                <CheckboxStyle
                                    checked={filtersData.isReviewed} onChange={() => {
                                        updateFilterValue('isReviewed', !filtersData.isReviewed);
                                    }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Grid container>
                            <Grid item md={4} xs={4}>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    Sub Category
                                </LabelStyle>
                            </Grid>
                            <Grid item md={8} xs={8}><AutoFieldStyle
                                multiple
                                limitTags={1}
                                options={subCategories}
                                id="sub-category"
                                value={filtersData?.subCategory?.length ? filtersData.subCategory : []}
                                onChange={(e, val) => {
                                    updateFilterValue('subCategory', val);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                )}
                            /></Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4.25} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item md={3} xs={4}>
                                <LabelStyle shrink htmlFor="amount-from">
                                    Amount
                                </LabelStyle>
                            </Grid>
                            <Grid item md={4.5} xs={4}>
                                <Textfield
                                    placeholder="From"
                                    type="number"
                                    value={filtersData.amountFrom}
                                    name="amount-from"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currencySymbol === 'CAD' ? '$' : currencySymbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        if (value < 0) return;
                                        updateFilterValue('amountFrom', value);
                                    }}
                                    onKeyPress={preventMinus}
                                />
                            </Grid>
                            <Grid item md={4.5} xs={4}>
                                <Textfield
                                    placeholder="To"
                                    type="number"
                                    value={filtersData.amountTo}
                                    name="amount-to"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currencySymbol === 'CAD' ? '$' : currencySymbol}
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={(e) => {
                                        const {value} = e.target;
                                        if (value < 0) return;
                                        updateFilterValue('amountTo', value);
                                    }}
                                    onKeyPress={preventMinus}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4.75} xs={12}>
                        <Grid container>
                            <Grid item md={3} xs={4}>
                                <LabelStyle shrink htmlFor="bootstrap-input">
                                    Transaction Type
                                </LabelStyle>
                            </Grid>
                            <Grid item md={4} xs={8}>
                                <AutoFieldStyle
                                    options={['Expense', 'Income']}
                                    id="transaction-type"
                                    value={filtersData.transactionType}
                                    onChange={(e, val) => {
                                        updateFilterValue('transactionType', val);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" placeholder="Select" className="dropMenu" />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </FormProvider>
        </Box>
    );
}