import { Box, Grid, styled, Card, Step } from '@mui/material';

export const RootStyle = styled('div')(() => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    paddingBottom: 0,
}));

export const RootStepperInner = styled('div')(({ theme }) => ({
    padding: '50px 0 30px',
    [theme.breakpoints.down('xl')]: {
        paddingTop: 50,
    },
    [theme.breakpoints.down('md')]: {
        paddingTop: 30,
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
}));

export const Wrapper = styled(Grid)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    padding: '50px 10% 40px calc(10% + 90px)',
    [theme.breakpoints.down('md')]: {
        padding: '20px 10px 80px 60px', 
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 4px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));

export const StepLabelStyle = styled(Step)(({ theme }) => ({
    padding: 0,
    width: '25%',
    '& + .MuiStepConnector-root': {
        display: 'none',
    },
    '& .MuiStepLabel-iconContainer': {
        paddingRight: 0,
        '& svg': {
            display: 'none',
        },
    },
    '& .MuiStepLabel-labelContainer': {
        fontSize: 0,
        background: theme.palette.primary.lighter,
        height: 10,
        width: '100%',
        borderRadius: 10,
        marginRight: 27,
        [theme.breakpoints.down('lg')]: {
            marginRight: 5,
        },
        '& .MuiStepLabel-label, & .MuiTypography-root': {
            display: 'none',
        },
    },
    '& .Mui-active + .MuiStepLabel-labelContainer, & .Mui-completed + .MuiStepLabel-labelContainer': {
        background: theme.palette.primary.light,
    }
}));

export const ButtonText = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '50px 15%',
    [theme.breakpoints.down('md')]: {
        padding: '0 0 20px',
    },
    '& button': {
        textTransform: 'inherit',
        fontSize: 18,
        margin: '0 12px',
        fontWeight: '500',
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
}));