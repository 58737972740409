import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FinancialTitle from '../FinancialTitle';
import DetailForm from './DetailsForm';

export default function Step1(props) {
    const { t } = useTranslation();

    return (
        <>
            <FinancialTitle
                heading={t('step1.heading')}
                subheading={t('step1.sub-heading')}
            />
            <Grid container item>
                <Grid item md={7} xs={12} />
                <Grid item md={5} xs={12}>
                    <DetailForm
                        nextStep={props.onNext}
                        updateList={(list) => {
                            props.onsave('step5', list);
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}