import request from '../../utils/request';

const validateUsername = (payload) => request.post('users/validate-user', payload);
const registerUser = (payload) => request.post('users', payload);
const getProfile = () => request.get('users/profile-summary');
const getUserCategories = (query) => request.get(`/categories/user${query ? `?${query}` : ''}`);
const updatePersonalDetails = (payload) => request.put('users', payload);

export default {
    validateUsername,
    registerUser,
    getProfile,
    getUserCategories,
    updatePersonalDetails
};