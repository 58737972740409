import { styled, Grid, Autocomplete, Checkbox } from '@mui/material';
import { RHFTextField } from 'components/hook-form';

export const Textfield = styled(RHFTextField)(({ theme }) => ({
    fontSize: 12,
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-input': {
        fontSize: 12,
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    }
}));

export const FormStyling = styled('div')(({theme}) => ({
    // margin: 20
    [theme.breakpoints.down('md')]: {
        marginTop: '20px !important',
        marginBottom: '24px'
    },
}))

export const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 13,
    backgroundColor: '#F8F8F8',
    padding: '5px 12px 6px 12px!important',
    borderRadius: '10px',
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
    },
    '& .MuiInputBase-root': {
        fontSize: 12,
        borderBottom: '0 !important',
        [theme.breakpoints.up('xl')]: {
            fontSize: 16,
        },
        '&:before': {
            borderBottom: '0 !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: 0,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

export const LabelStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    marginBottom: '8px',
    marginLeft: '4px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    fontWeight:'500',
    [theme.breakpoints.up('xl')]: {
        fontSize: 16,
    },
}));

export const DateGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        '& div': {
            backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
            borderRadius: 10,
            '& input': {
                padding: '10px 0px 10px 10px  !important',
                fontSize: 12,
                [theme.breakpoints.up('xl')]: {
                    fontSize: 16,
                },
            },
            '& fieldset': {
                borderStyle: 'unset',
                borderWidth: 0,
            },
        },
    },
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const GroupHeader = styled("div")(() => ({
    padding: "4px 10px",
    color: 'rgb(0, 119, 62)',
    fontWeight: 600,
    textTransform: 'uppercase'
}));
  
export const GroupItems = styled("ul")({
    padding: 0
});