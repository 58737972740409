export const CALCULATORS = [
    {
        title: 'Loan Payment',
        hoverText: 'Calculates a loan payment',
        path: 'calculators/loan-payment',
        calculatorType: 'Debt',
    },
    {
        title: 'Lease Payment',
        hoverText: 'Calculates a lease payment',
        path: 'calculators/lease-payment',
        calculatorType: 'Debt'
    },
    {
        title: 'Mortgage Payment',
        hoverText: 'Calculates a mortgage payment',
        path: 'calculators/mortgage-payment',
        calculatorType: 'Debt'
    },
    {
        title: 'Mortgage Prepayment',
        hoverText: 'Calculates the impact of additional payments on the total interest saved and shortening of term',
        path: '/calculators/mortgage-prepayment',
        calculatorType: 'Debt'
    },
    {
        title: 'Debt Repayment',
        hoverText: 'Calculates how long it wll take to pay off debts and what you will pay in interest',
        path: '/calculators/debt-repayment',
        calculatorType: 'Debt'
    },
    {
        title: 'Embedded Rate',
        hoverText: 'Calculates the embedded rate in a debt instrument like a lease',
        path: '/calculators/embedded-rate',
        calculatorType: 'Debt'
    },
    {
        title: 'ROI',
        hoverText: 'Calculates the total return on investment from both dividends/interest and the sales proceeds or current value of your investments',
        path: '/calculators/return-on-investment',
        calculatorType: 'Investments'
    },
    // {
    //     title: 'Home Equity',
    //     hoverText: 'Calculates the estimated amount of loan you could be eligible for based on the current net equity of your home',
    //     path: '/',
    //     calculatorType: 'Debt'
    // },
    {
        title: 'Future Value',
        hoverText: 'Caculates the future value of regular savings and the current value of your investments',
        path: '/calculators/future-value-savings',
        calculatorType: 'Investments'
    },
    {
        title: 'Present Value',
        hoverText: 'Calculates the present value of an amount to be received in the future',
        path: '/calculators/present-value',
        calculatorType: 'Investments'
    },
    {
        title: 'Emergency Fund',
        hoverText: 'Calculates the amount of funds that you should always have in cash or short term deposit',
        path: '/calculators/emergency-fund',
        calculatorType: 'Other'
    },
    {
        title: 'Savings Goal',
        hoverText: 'Calculates the amount you need to invest to reach your goal',
        path: '/calculators/savings-goal',
        calculatorType: 'Other'
    },
    {
        title: 'Retirement',
        hoverText: 'Calculates the amount of funds you will have at retirement and beyond',
        path: '/calculators/retirement',
        calculatorType: 'Other'
    },
    // {
    //     title: 'Project Budget',
    //     hoverText: 'Calculates a detailed and customized budget for any project',
    //     path: '/calculators/project-budget',
    //     calculatorType: 'Other'
    // },
];

export const EXCEL_STYLE = {
    boldHeader : {
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    boldColor:{
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        }
    },
    boldColorRight:{
        font: {
            bold: true,
            color: {
                rgb: "889999"
            }
        },
        alignment: {
            horizontal: 'right'
        }
    },
    boldColorHrVr:{
        font: {
            bold: true,
            color: {
                rgb: "8899ff"
            }
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    boldHrVr:{
        font: {
            bold: true,
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    },
    onlyRight:{
        alignment: {
            horizontal: 'right',
        }
    }
}