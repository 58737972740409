import { styled, Link, Box, Typography, Button } from '@mui/material';

export const MainBox = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.dark,
    color: theme.palette.secondary.contrastText,
    paddingTop: 120,
    [theme.breakpoints.down('sm')]: {
        paddingTop: 60,
        marginTop: -100,
        paddingLeft: 10,
        paddingRight: 10,
    },
}));

export const SubTitle = styled(Typography)(() => ({
    textAlign: 'center',
    padding: '35px 0 45px',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
    background: theme.palette.secondary.contrastText,
    color: theme.palette.primary.main,
    borderRadius: 5,
    minWidth: '90%',
    fontWeight: 500,
    fontSize: 18,
    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

export const ContactButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.contrastText,
    width: '200px',
    borderRadius: 5,
    minWidth: '90%',
    fontWeight: 500,
    fontSize: 18,
    '&:hover': {
        background: theme.palette.primary.light,
    },
}));

export const LogoImg = styled(Box)(() => ({
    padding: '69px 0',
    display: 'flex',
    justifyContent: 'center',
}));

export const Logo = styled('img')(() => ({
    width: '69px',
    height: '57px',
}));

export const SocialIcon = styled('img')(() => ({
    width: '32px',
    height: '32px',
    margin: '0 5px',
}));

export const CopyText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        fontSize: 11,
    },
}));

export const FooterMenu = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    '&:hover': {
        textDecoration: 'none',
    },
}));