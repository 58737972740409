export const GENERAL_REPORTS = [
    {
        title: 'Executive Summary',
        path: '/dashboard',
        groupName : ''
    },
    {
        title: 'Financial Goals',
        path: '/dashboard/financial-goals',
        groupName : ''
    },
    {
        title: 'Net Worth',
        path: '/dashboard/net-worth',
        groupName : ''
    }
];

export const DASHBOARD = [
    {
        title: 'Asset composition and net change',
        path: '/dashboard/asset-composition',
        groupName : 'Assets'
    }, 
    {
        title: 'Liability composition and net change',
        path: '/dashboard/liabilities-composition',
        groupName : 'Liabilities'
    },    
    {
        title: 'Status of credit card usage',
        path: '/dashboard/liabilities-credit-card', 
        groupName : ''       
    },
    {
        title: 'Status of line of credits usage',
        path: '/dashboard/line-of-credit',
        groupName : ''
    },
    {
        title: 'Debt Payment Report',
        path: '/dashboard/debt-payment-report',
        groupName : ''
    } 
];

export const INCOME_EXPENSE = {
    LEVEL1: [{
        title: 'Income & Expense Summary',
        path: '/dashboard/income-expense-summary',
        groupName : 'Income/Cash Inflows'
    }, {
        title: 'Income Composition',
        path: '/dashboard/income-composition',
        groupName : 'Income/Cash Inflows'        
    },
    {
        title: 'Expense Composition',
        path: '/dashboard/expense-composition', 
        groupName : 'Expenses/Cash Outflows'       
    }],
    LEVEL2: [{
        title: 'Variable Expenses',
        path: '/dashboard/variable-expense',
        groupName : 'Expenses/Cash Outflows'        
    }, 
    {
        title: 'Fixed Expenses',
        path: '/dashboard/fixed-expense',
        groupName : 'Expenses/Cash Outflows'        
    }],
    LEVEL3: [{
        title: 'Top 5 Expenses',
        path: '/dashboard/top-five-expense',
        groupName : 'Expenses/Cash Outflows'        
    },
    {
        title: 'Top 10 Expenses',
        path: '/dashboard/top-ten-expense', 
        groupName : 'Expenses/Cash Outflows'       
    }]
};

export const FINANCIAL_GOAL = [
]

export const CHARTCOLOR = [
    'rgba(54, 162, 235, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(255, 99, 132, 0.6)',
    'rgba(238, 130, 238,0.6)',
    'rgba(255, 165, 0,0.6)',
    'rgba(255, 206, 90 ,0.6)',
    'rgba(54, 162, 90 ,0.6)',
    'rgba(255, 192, 90 ,0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)'
];
