import { styled, Typography, Grid, Card, Box } from '@mui/material';

export const Wrapper = styled(Grid)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    padding: '50px 10% 30px calc(10% + 90px)',
    width: '100%',
    [theme.breakpoints.down('xl')]: {
        padding: '100px 10% 60px calc(10% + 90px)',
    },
    [theme.breakpoints.down('md')]: {
        padding: '40px 10px 60px 60px',
    },
}));

export const RootStyle = styled('div')(({ theme }) => ({
    background:
    'url(/static/loginImg/loginLeftBottomBg.svg) left bottom no-repeat, url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    paddingBottom: 0,
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px, auto',
        backgroundPosition: '-50px 100%, calc(100% - 20px) calc(100% - 20px)',
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '70%',
    paddingLeft: 0,
    maxWidth: 300,
    [theme.breakpoints.down('lg')]: {
        width: 250,
    },
    [theme.breakpoints.down('md')]: {
        width: 210,
    },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    paddingTop: '25px',
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
        paddingBottom: '30px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const MainPoints = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.light,
    marginLeft: '60px',
    minHeight: 36,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
    },
    [theme.breakpoints.down('xl')]: {
        fontSize: 16,
    },
}));

export const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 0,
    background: theme.palette.primary.main,
    borderRadius: 0,
    position: 'fixed',
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        maxWidth: 50,
    },
}));

export const StartButton = styled('div')(() => ({
    padding: '56px 0px 50px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: 60,
    '& button': {
        textTransform: 'none',
        width: '100%',
        fontSize: 18,
        lineHeight: '27px',
    },
}));

export const SubPoints = styled(Typography)(({ theme }) => ({
    padding: '10px 0 48px',
    marginLeft: '60px',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
    },
    [theme.breakpoints.down('xl')]: {
        fontSize: 14,
        lineHeight: '18px',
        paddingBottom: 20,
        paddingTop: 10,
    },
}));

export const UlStyle = styled('ul')(({ theme }) => ({
    paddingLeft: 60,
    marginTop: -40,
    marginBottom: 48,
    [theme.breakpoints.down('xl')]: {
        marginTop: -15,
        marginBottom: 20,
    },
    '& li': {
        position: 'relative',
        listStyle: 'none',
        '&:after': {
            width: 5,
            height: 5,
            background: theme.palette.primary.dark,
            content: "''",
            position: 'absolute',
            left: -17,
            top: 6,
            borderRadius: 5,
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 14,
            lineHeight: '18px',
        },
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    position: 'relative',
    '& li': {
        position: 'relative',
        marginBottom: 40,
        '&:before': {
            content: '"1."',
            color: '#fff',
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '33px',
            position: 'absolute',
            left: '0px',
            top: '0px',
            background: 'url("/static/onetime/circle.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: '38px',
            height: '36px',
            [theme.breakpoints.down('md')]: {
                left: '10px',
            },
        },
        '&:nth-child(2)': {
            '&:before': {
                content: '"2."',
                background: 'url("/static/onetime/circle.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(3)': {
            '&:before': {
                content: '"3."',
                background: 'url("/static/onetime/circle.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(4)': {
            '&:before': {
                content: '"4."',
                background: 'url("/static/onetime/circle.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(5)': {
            '&:before': {
                content: '"5."',
                background: 'url("/static/onetime/circle.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
    },
    '& ul': {
        '& li': {
            marginLeft: '30px',
            '&:before': {
                display: 'none !important',
            },
        },
    },
}));

export const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '20px 23px 20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: '20px 4px 20px',
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));