import { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled, Autocomplete, TextField, Grid } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MuiPhoneNumber from 'material-ui-phone-number';
import user from 'constants/services/user';
import countryStatesData from '../../../../../constants/countryStatesData';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { currencyCodes } from '../../../../../utils/currencyCodes';
import { FormContext } from '../../context/StepperContext';
import { getParamByParam } from '../../../../../constants/countryCurrencyData';
import { ageGroupOptions, genderOptions } from './constant';

const Fragment = styled('div')(({ theme }) => ({
    '& .dropMenu': {
        borderRadius: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#F8F8F8' : '#2b2b2b',
        padding: '6px 12px',
    },
    '& .MuiInputBase-root': {
        '&:before': {
            border: 0,
        },
    },
    '& .MuiGrid-item': {
        padding: '10px 0 0 0 !important'
    },
    '& .phone-number': {
        '& .MuiInputBase-root': {
            '&:before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important'
            }
        },
    }
}));

const AutoFieldStyle = styled(Autocomplete)(({ theme }) => ({
    fontSize: 20,
    paddingBottom: '8px',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px',
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& .MuiInputBase-root': {
        '&:hover:before': {
            borderBottom: '0 !important',
        },
        '&.Mui-focused:after': {
            transform: 'scaleX(0) !important',
        },
    },
    '& .MuiAutocomplete-popupIndicator': {
        position: 'relative',
        width: 25,
        '& svg': {
            display: 'none',
        },
        '&:after': {
            position: 'absolute',
            right: 0,
            top: -4,
            content: "''",
            background: 'url(/static/loginImg/down-arrow.svg) 0 0 no-repeat',
            width: 25,
            height: 13,
        },
    },
}));

const Text = styled(RHFTextField)(({ theme }) => ({
    paddingBottom: '-8px !important',
    [theme.breakpoints.up('llg')]: {
        paddingBottom: '30px !important',
    },
    marginBottom: '10px !important',
}));

const Input = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    fontSize: 16,
    marginTop: '10px',
    whiteSpace: 'normal',
    overflow: 'auto',
    textOverflow: 'unset',
    [theme.breakpoints.up('xl')]: {
        fontSize: 20,
    },
}));

const StyledGrid = styled(Grid)(() => ({
    width: '100%',
    marginLeft: 0,
    marginTop: 0,
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        boxShadow: theme.shadows[1],
        fontSize: '12px',
        padding: 10,
        fontWeight: 400,
        maxWidth: '491px'
    },
}));

const InfoIcon = styled('img')(({ theme }) => ({
    display: 'revert',
    width: "16px",
    cursor:'pointer',
    [theme.breakpoints.down('md')]: {
        margin: '2px 0 0 -16px',
        width: "16px",
    }
}));

const PhoneInput = styled(MuiPhoneNumber)(({ theme }) => ({
    '&.phone-number': {
        paddingBottom: 30
    },
    '& input': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
}));

export default function DetailForm() {
    const { t } = useTranslation();

    const [countryOptions] = useState(countryStatesData);
    const [stateOptions, setStateOptions] = useState([]);

    const [selectedCurrency, setSelectedCurrency] = useState({});
    const [householdPeopleOptions] = useState(Array.from({length: 20}, (_, i) => i + 1));

    const methods = useForm({});

    const {
        updatePersonalDetails, personalDetails, setPersonalDetails
    } = useContext(FormContext);

    const populateDefaultData = async () => {
        const defaultCountry = countryOptions.find((cd) => cd.country === 'Canada');
        if (defaultCountry) {
            const currencyCode = getParamByParam('countryName', defaultCountry.country, 'currency');
            setPersonalDetails({
                ...personalDetails,
                country: {
                    value: defaultCountry,
                    error: ''
                },
                currency: {
                    value: currencyCode || 'CAD',
                    error: ''
                }
            });    
            setStateOptions(defaultCountry.states);
            setSelectedCurrency(currencyCodes.find((c) => c.CurrencyCode === currencyCode));
        }
    };

    useEffect(() => {
        populateDefaultData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCountryChange = (event, val) => {
        setStateOptions(val.states);
        const currencyCode = getParamByParam('countryName', val.country, 'currency');
        setSelectedCurrency(currencyCodes.find((c) => c.CurrencyCode === currencyCode));
        setPersonalDetails({
            ...personalDetails,
            country: {
                value: val,
                error: ''
            },
            currency: {
                value: currencyCode,
                error: ''
            },
            state: {
                value: '',
                error: ''
            },
            city: {
                value: '',
                error: ''
            },
        });
    };

    const validateUsername = async () => {
        const {value} = personalDetails.username;
        if (value) {
            try {
                await user.validateUsername({ username: value });
            } catch (error) {
                setPersonalDetails({
                    ...personalDetails,
                    username: {
                        value,
                        error: t(`api-error.${error.message}`)
                    }
                });
            }
        }
    };

    return (
        <Fragment>
            <FormProvider methods={methods}>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input" style={{ display: 'flex' }}>
                            {t('step1.username')}
                            <LightTooltip title={<>This will be displayed as your name when you participate in forums or make suggestions. <br/ >Only letters (a-z), numbers (0-9) and periods (.) are allowed.</>}>
                                <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" style={{ marginLeft: 5 }}/>
                            </LightTooltip>
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Text
                            name="username"
                            error={personalDetails.username.error}
                            helperText={personalDetails.username.error}
                            value={personalDetails.username.value}
                            onChange={(e) => {
                                const {value} = e.target;
                                if (!value || /^[a-z0-9.]+$/.test(value)) {
                                    updatePersonalDetails('username', value);
                                }
                            }}
                            onBlur={validateUsername}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.phone-number')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <PhoneInput
                            className='phone-number'
                            fullWidth
                            defaultCountry={'ca'}
                            onChange={(value) => updatePersonalDetails('mobileNumber', value)}
                            value={personalDetails.mobileNumber.value}
                            error={personalDetails.mobileNumber.error}
                            helperText={personalDetails.mobileNumber.error}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.country')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            
                            options={countryOptions}
                            value={personalDetails?.country.value}
                            getOptionLabel={(option) => (option?.country || '')}
                            disableClearable
                            onChange={onCountryChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select Country"
                                    className="dropMenu"
                                    error={personalDetails.country.error}
                                    helperText={personalDetails.country.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.state')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            options={stateOptions}
                            value={personalDetails.state.value}
                            disableClearable
                            onChange={(e, val) => {
                                updatePersonalDetails('state', val);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select State"
                                    className="dropMenu"
                                    error={personalDetails.state.error}
                                    helperText={personalDetails.state.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.city')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Text
                            name="city"
                            error={personalDetails.city.error}
                            helperText={personalDetails.city.error}
                            value={personalDetails.city.value}
                            onChange={(e) => {
                                updatePersonalDetails('city', e.target.value);
                            }} />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.no-of-people')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            options={householdPeopleOptions}
                            value={personalDetails.houseHoldPeople.value}
                            getOptionLabel={(option) => option.toString()}
                            disableClearable
                            onChange={(e, val) => {
                                updatePersonalDetails('houseHoldPeople', parseInt(val, 10));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select"
                                    className="dropMenu"
                                    error={personalDetails.houseHoldPeople.error}
                                    helperText={personalDetails.houseHoldPeople.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.local-currency')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            options={currencyCodes}
                            value={selectedCurrency}
                            getOptionLabel={(option) => (option?.CurrencyCode || '')}
                            disableClearable
                            onChange={(e, val) => {
                                setSelectedCurrency(val);
                                updatePersonalDetails('currency', val.CurrencyCode);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Select Currency Code"
                                    className="dropMenu"
                                    error={personalDetails.currency.error}
                                    helperText={personalDetails.currency.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.gender')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            options={genderOptions}
                            value={personalDetails.gender.value}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            disableClearable
                            onChange={(e, val) => {
                                updatePersonalDetails('gender', val.label);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    className="dropMenu"
                                    error={personalDetails.gender.error}
                                    helperText={personalDetails.gender.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
                <StyledGrid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <Input shrink htmlFor="bootstrap-input">
                            {t('step1.age-group')}
                        </Input>
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <AutoFieldStyle
                            options={ageGroupOptions}
                            value={personalDetails.ageGroup.value}
                            isOptionEqualToValue={(option, value) => option.label === value.label}
                            disableClearable
                            onChange={(e, val) => {
                                updatePersonalDetails('ageGroup', val.label);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    className="dropMenu"
                                    error={personalDetails.ageGroup.error}
                                    helperText={personalDetails.ageGroup.error}
                                />
                            )}
                        />
                    </Grid>
                </StyledGrid>
            </FormProvider>
        </Fragment>
    );
}
