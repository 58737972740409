import { Grid, styled, Input, Link, Typography, Menu, Checkbox, Slider, Button } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    // height: '100vh',
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 12%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 12%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const HeaderTableStyle = styled('table')(({ theme }) => ({
    width: '100%',
    borderRadius: '10px',
    minHeight:'480px',
    fontSize: 16,
    paddingBottom: '14px',
    [theme.breakpoints.down('md')]: {
        padding: 10,
        fontSize: 12,
        minWidth: 500,
    },
    '& th': {
        color: theme.palette.primary.dark,
        fontSize: 18,
        paddingRight: '24px',
        paddingLeft: '24px',
        paddingTop: '24px',
        textAlign: 'start',
        paddingBottom: '2px',
        fontWeight: 700,
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& td': {
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: '24px',
        paddingLeft: '24px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: 5,
            fontSize: 12,
        },
    },
    '& h6': {
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        },
    },
    '& p.Mui-error': {
        color: 'rgb(255, 72, 66)',
        fontSize: '0.75rem',
        fontWeight: 400,
        textAlign: 'left',
        position: 'absolute',
        margin: '3px'
    }
}));

export const HeaderDivStyle = styled('div')({
    width: '100%',
    fontWeight: 400,
    fontSize: 16,
    background: '#F8F8F8',
    borderRadius: '10px',
    marginTop: '20px',
    overflow: 'hidden'
});

export const TitleBorderStyle = styled('td')(({ theme }) => ({
    borderBottom: '2px solid #89A69F',
}))

export const StyledInput = styled(Input)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& input': {
        textAlign: 'left',
    },
}));

export const StyledTypography = styled('td')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    '& span': {
        marginRight: -10,
    },
    '& input': {
        textAlign: 'left',
    },
}));

export const StyledButton = styled(Link)(({ theme }) => ({
    cursor: 'pointer',
    marginRight: '0px',
    fontWeight: '400',
    lineHeight: '2.8',
    marginBottom: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    padding: '0px !important'
}));

export const StyledTask = styled(Typography)(({ theme }) => ({
    width: '440px',
}));

export const DropDownMenu = styled(Menu)(({ theme }) => ({
    '& ul': {
        padding: '8px 6px !important',
        background: '#F8F8F8',
        border: '1px solid #00773E',
        borderRadius: '10px',
        '&li': {
            padding: '10px'
        }
    },
}));

export const CustomSlider = styled(Slider)(() => ({
    color: '#00773E',
    marginBottom: 0,
    "& .MuiSlider-thumb": {
        backgroundColor: '#00773E'
    },
    "& .MuiSlider-rail": {
        color: 'red',
        opacity: 1
    },
    '& .MuiSlider-valueLabel': {
        backgroundColor: '#00773E',
    },
    '& .MuiSlider-mark': {
        color: 'transparent',
        backgroundColor: 'transparent',
    }
}));

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' center',
}))