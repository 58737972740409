import {useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Title from '../../components/Title';
import Page from '../../components/Page';
import { ContentStyle, Wrapper, PageTopRightBg, Content, CardStyle } from './styled-components';
import transaction from '../../constants/services/transaction';

export default function ManualTransactions() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [manuallyUpdatedCategories, setManuallyUpdatedCategories] = useState([]);

    const fetchSubCategory = async () => {
        const subCategoriesData = await transaction.getManaulTransaction();
        const manualCats = subCategoriesData?.data?.filter((cat) => cat?.isUpdatedManually)?.sort((a, b) => a.name.localeCompare(b.name));
        setManuallyUpdatedCategories(manualCats || []);
    };

    useEffect(() => {
        fetchSubCategory();
    }, []);

    const onView = (categ) => {
        navigate(categ?.template ? '/edit-manual-transactions' : '/view-manual-transactions', { state: categ });
    }

    return (
        <Page title={t('manual-transactions.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Content>
                        <Title title={t('manual-transactions.title')} subtitle={t('manual-transactions.subtitle')} tooltip={t('manual-transactions.tooltip')} />
                        <Grid container spacing={4} pt={10} justifyContent="center" style={{ paddingInline: '6%', paddingTp: 70 }}>
                            {manuallyUpdatedCategories.map((cate) => <Grid key={'index'} item xs={12} sm={6} md={4}  onClick={() => onView(cate)}>
                                <CardStyle>
                                    <Typography variant="h4">{cate?.name}</Typography>
                                </CardStyle>
                            </Grid>)}
                        </Grid> 
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%'}}>
                            <Button
                                variant="contained"
                                style={{marginTop: 10, textTransform: 'none', paddingInline: '4%', borderRadius: 2 }}
                                onClick={() => navigate('/my-profile/category')}>
                                {t('manual-transactions.new-category')}
                            </Button>
                            <Button
                                variant="contained"
                                style={{marginTop: 10, marginLeft: 30, textTransform: 'none', paddingInline: '4%', borderRadius: 2 }}
                                onClick={() => navigate('/my-profile/sub-category')}>
                                {t('manual-transactions.new-sub-category')}
                            </Button>
                            <Button
                                variant="contained"
                                style={{marginTop: 10, marginLeft: 30, textTransform: 'none', paddingInline: '4%', borderRadius: 2 }}
                                onClick={() => navigate('/view-all-templates', { state: { readOnly: true } })}>
                                {t('manual-transactions.view-all-templates')}
                            </Button>
                        </div>
                        <Grid container spacing={4} pt={10} style={{ paddingLeft: '10%', display: 'block' }}>
                            <div style={{  fontWeight: 700,
                                fontSize: 18,
                                marginBottom: 10,
                                color: '#00773E' }}>{t('view-manual-transactions.instructions')}
                            </div>
                            <ul style={{ paddingLeft: 30 }}>
                                <li style={{ padding: 10, paddingLeft: 0 }}>These are all the sub-categories that you selected to be tracked manually</li>
                                <li style={{ padding: 10, paddingLeft: 0 }}>Select a sub-category</li>
                                <li style={{ padding: 10, paddingLeft: 0 }}>Preview templates and select an appropriate one</li>
                                <li style={{ padding: 10, paddingLeft: 0 }}>Save the template and then enter your information </li>
                            </ul>
                        </Grid>
                    </Content>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
