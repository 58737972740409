// @mui
import {useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'sheetjs-style';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, TotalFlowColumn, TopSideBar, FromMinimumText, TotalFlow, BudgetInput, NumberAutoFieldStyle, ButtonText, BackButtonStyle, ExtraSpacing, BudgetSubTotals, BudgetSubInputs, BudgetSpacing, BackButton, TotalSubFlowColumn } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, formatZeroValues, removeNumberFormatting, getCurrencySymbol } from '../../../utils/calCommonFunction';

export default function EmergencyFund() {
    const [fromMinimumMonth, setFromMinimumMonth] = useState(3);
    const [minimumMonth, setminimumMonth] = useState("");
    const [rentAmount, setRentAmount] = useState("");
    const [utilitiesAmount, setUtilitiesAmount] = useState("");
    const [telecomAmount, setTelecomAmount] = useState("");
    const [insuranceAmount, setInsuranceAmount] = useState("");
    const [transportAmount, setTransportAmount] = useState("");    
    const [debtAmount, setDebtAmount] = useState("");
    const [groceries, setGroceries] = useState("");
    const [other, setOther] = useState("");
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const navigate= useNavigate();
    const monthOption = [
        { label: 3 },
        { label: 4 },
        { label: 5 },
        { label: 6 },
        { label: 7 },
        { label: 8 },
        { label: 9 },
        { label: 10 },
        { label: 11 },
        { label: 12 }
    ];
    
    const getEmergencyFundDetails=async()=>{
        const getEmergencyFundData = await calculator.getUserCalculator("emergency-fund");
        if (getEmergencyFundData.data) {
            const loanJsonData = getEmergencyFundData.data?.calcJson;
            setminimumMonth(loanJsonData.minimum_month);
            setRentAmount(loanJsonData.rent_amount);
            setUtilitiesAmount(loanJsonData.utilities_amount);
            setTelecomAmount(loanJsonData.telecom_amount);
            setInsuranceAmount(loanJsonData.insurance_amount);
            setTransportAmount(loanJsonData.transport_amount);
            setDebtAmount(loanJsonData.debt_amount);
            setGroceries(loanJsonData.groceries);
            setOther(loanJsonData.other);
        }
    }
    
    useEffect(() => {
        getEmergencyFundDetails()
    }, []);

    // calculation
    const totalExpenseSum = (Number(removeNumberFormatting(rentAmount))) + Number(removeNumberFormatting(utilitiesAmount)) + Number(removeNumberFormatting(telecomAmount)) + Number(removeNumberFormatting(insuranceAmount)) +  Number(removeNumberFormatting(transportAmount)) + Number(removeNumberFormatting(debtAmount)) + Number(removeNumberFormatting(groceries)) + Number(removeNumberFormatting(other))
    const totalMonthlyExpense= (totalExpenseSum).toLocaleString("en-US", {minimumFractionDigits:2});
    const emergencyfundCal = totalExpenseSum * fromMinimumMonth;
    const emergencytofundCal = totalExpenseSum * minimumMonth;
    const emergencyFundTo= Number(emergencytofundCal).toLocaleString("en-US", {minimumFractionDigits:2});
    const emergencyFund= Number(emergencyfundCal).toLocaleString("en-US", {minimumFractionDigits:2});

    const resetFields = () => {
        setminimumMonth("");
        setRentAmount("");
        setUtilitiesAmount("");
        setTelecomAmount("");
        setInsuranceAmount("");
        setTransportAmount("");
        setDebtAmount("");
        setGroceries("");
        setOther("");
    }

    const saveEmergencyFundData = async () => {
        try {
            const postData = {
                "from_rent_amount":fromMinimumMonth,
                "minimum_month": minimumMonth,
                "rent_amount": rentAmount,
                "utilities_amount": utilitiesAmount,
                "telecom_amount": telecomAmount,
                "insurance_amount": insuranceAmount,
                "transport_amount": transportAmount,
                "debt_amount": debtAmount,
                "groceries": groceries,
                "other": other,
            }
            const embeddedDataObj = {
                "calcType": "emergency-fund",
                "calcJson": postData
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "",
                "Value": "From                To"
            },
            {
                "Description": "Minimum # of months of living expenses to",
                "Value": `  ${fromMinimumMonth}                    ${minimumMonth}`
            },
            {
                "Description": "",
                "Value": ""
            },
            {
                "Description": "Approximate Monthly Living Expenses",
                "Value": ""
            },
            {
                "Description": "",
                "Value": ""
            },
            {
                "Description": "Mortgage/Rent",
                "Value": `${currencySymbol} ${rentAmount}`
            },
            {
                "Description": "Utilities (Gas, electricity, water, garbage)",
                "Value": `${currencySymbol} ${utilitiesAmount}`
            },
            {
                "Description": "Telecommunications (Cable, phone, Internet)",
                "Value": `${currencySymbol} ${telecomAmount}`
            },
            {
                "Description": "Insurance (Vehicles, home, health and life)",
                "Value": `${currencySymbol} ${insuranceAmount}`
            },
            {
                "Description": "Transportation (Car payments, gas/petrol, public transport)",
                "Value": `${currencySymbol} ${transportAmount}`
            },
            {
                "Description": "Debt payments (Credit cards, loans other than mortgage)",
                "Value": `${currencySymbol} ${debtAmount}`
            },
            {
                "Description": "Groceries",
                "Value": `${currencySymbol} ${groceries}`
            },
            {
                "Description": "Other",
                "Value": `${currencySymbol} ${other}`
            },
            {
                "Description": "",
                "Value": ""
            },
            {
                "Description":'Total monthly expenses',
                "Value": `${currencySymbol} ${totalMonthlyExpense}`
            },
            {
                "Description":'Required emergency fund',
                "Value": `from     ${currencySymbol} ${emergencyFund}     To     ${currencySymbol} ${emergencyFundTo}`
            }         
        ];
        const style = [
            {
                "col": 'A1',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'B1',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'A5',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'B2',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'B3',
                "style": {
                    font: {
                        bold: true,
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                }
            },
            {
                "col": 'A16',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    }
                }
            },
            {
                "col": 'B16',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'right'
                    }
                }
            },
            {
                "col": 'A17',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    }
                }
            },
            {
                "col": 'B17',
                "style": {
                    font: {
                        bold: true,
                        color: {
                            rgb: "889999"
                        }
                    },
                    alignment: {
                        horizontal: 'right'
                    }
                }
            }
        ]
        downloadExcel(postData, style);
    }
    const downloadExcel = (data, style) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        for(let i=2; i<=data.length; i+=1) {
            ws[`B${i}`].s={
                alignment: {
                    horizontal: 'right'
                }
            }
        }
        style.forEach(item => {
            ws[item.col].s = item.style;
        })
        ws['!cols'] = [{ width: 48 }, { width: 20 }, { width: 19 }];
        const rowSpace = [{ hpt: 20 }];
        data.forEach(() => {
            rowSpace.push({ hpt: 20 })
        });
    
        ws['!rows'] = rowSpace;
        ws["!merges"] = [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 2 } },
            { s: { r: 4, c: 0 }, e: { r: 4, c: 2 } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
            { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
            { s: { r: 16, c: 1 }, e: { r: 16, c: 2 } }
        ];
        XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
        XLSX.writeFile(wb, "EmergencyFund.xlsx");
    };

    return (
        <Page title={t('emergency-fund.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('emergency-fund.go-back')}
                    </BackButtonStyle>
                    <Title title={t('emergency-fund.calculator')} subtitle={t('emergency-fund.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('emergency-fund.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{''}</td>
                                            <BudgetSubInputs>From</BudgetSubInputs>
                                            <BudgetSubInputs>To</BudgetSubInputs>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.living-expense')}</td>
                                            <BudgetInput>
                                                <FromMinimumText>    
                                                    <Input
                                                        type='text'
                                                        name="from_rent_amount"
                                                        value={fromMinimumMonth}
                                                        placeholder="0"
                                                    />  
                                                </FromMinimumText>                                         
                                            </BudgetInput>
                                            <td>
                                                <NumberAutoFieldStyle
                                                    id="tags-outlined"
                                                    options={monthOption}
                                                    value={minimumMonth}
                                                    isOptionEqualToValue={(option, value) => option?.label === value?.label}
                                                    disableClearable
                                                    onChange={(e, val) => {
                                                        setminimumMonth(val.label);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="standard" className="dropMenu" />
                                                    )}
                                                />
                                            </td>
                                        </tr>
                                        <ExtraSpacing>
                                            <tr>
                                                <td>{''}</td>
                                            </tr>
                                        </ExtraSpacing>
                                        <tr>
                                            <th style={{textAlign:'end'}}>{t('emergency-fund.approximate-expenses')}</th>
                                        </tr>
                                        <ExtraSpacing>
                                            <tr>
                                                <td>{''}</td>
                                            </tr>
                                        </ExtraSpacing>
                                        <tr>
                                            <td>{t('emergency-fund.mortgage-Rent')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="rent_amount"
                                                    value={rentAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setRentAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setRentAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.utilities')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="utilities_amount"
                                                    value={utilitiesAmount}
                                                    onBlur={(e)=>{
                                                        setUtilitiesAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setUtilitiesAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.telecommmunications')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="telecom_amount"
                                                    value={telecomAmount}
                                                    onBlur={(e)=>{
                                                        setTelecomAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setTelecomAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.insurance')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="insurance_amount"
                                                    value={insuranceAmount}
                                                    onBlur={(e)=>{
                                                        setInsuranceAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setInsuranceAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>  
                                        <tr>
                                            <td>{t('emergency-fund.transportation')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="transportation"
                                                    value={transportAmount}
                                                    onBlur={(e)=>{
                                                        setTransportAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setTransportAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.debt-payments')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="debt_amount"
                                                    value={debtAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setDebtAmount(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setDebtAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('emergency-fund.groceries')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="groceries"
                                                    value={groceries}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setGroceries(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setGroceries(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr> 
                                        <tr>
                                            <td>{t('emergency-fund.other')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="other"
                                                    value={other}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setOther(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setOther(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr> 
                                        <tr>
                                            <td>
                                                <TopSideBar>{t('emergency-fund.total-monthly-expenses')}</TopSideBar>
                                        
                                            </td>
                                            <div style={{ 
                                                marginTop: '10px',
                                                display: 'flex', 
                                                justifyContent: "space-between",
                                                borderTop: 'solid 1px #00773E',
                                                borderBottom: 'solid 3px #00773E' 
                                            }}>
                                                <div>
                                                    <TopSideBar style={{ color: 'black' }}>{currencySymbol}</TopSideBar>
                                                </div>
                                                <div>
                                                    <TopSideBar style={{ color: 'black' }}>{totalMonthlyExpense}</TopSideBar>
                                                </div>
                                            </div>
                                        </tr>                                                                             
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle style={{padding:'42px 8% 42px 8%'}}>
                                <tbody> 
                                    <tr>
                                        <TotalFlow>{t('emergency-fund.emergency-fund')}</TotalFlow>
                                        <TotalFlowColumn><BudgetSubTotals>From<BudgetSpacing>{currencySymbol} {emergencyFund==="NaN"?0:emergencyFund}</BudgetSpacing></BudgetSubTotals></TotalFlowColumn>
                                        <TotalFlowColumn><BudgetSubTotals>To<BudgetSpacing>{currencySymbol} {emergencyFundTo==="NaN"?0:emergencyFundTo}</BudgetSpacing></BudgetSubTotals></TotalFlowColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('emergency-fund.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('emergency-fund.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={saveEmergencyFundData}
                        >
                            {t('emergency-fund.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('emergency-fund.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('emergency-fund.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}