import { styled, Grid, Typography, Box } from '@mui/material';

export const HomeContentSection = styled(Grid)(() => ({
    background: 'url("/static/homeImg/freeBg.svg") no-repeat',
    backgroundPosition: 'right bottom',
    paddingBottom: 170,
    overflow: 'hidden',
    position: 'relative',
}));

export const BannerImg = styled('img')(({ theme }) => ({
    width: '47%',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
        position: 'static',
        width: '80%',
    },
}));

export const Headline = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    color: theme.palette.primary.main,
    paddingTop: '24px',
    [theme.breakpoints.down('md')]: {
        marginLeft: '10px',
    },
}));

export const SubPoints = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
        marginLeft: '35px',
    },
}));

export const SubText = styled(Typography)(({ theme }) => ({
    padding: '24px 0 48px',
    width: '90%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    position: 'relative',
    '& li': {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            top: '5px',
            background: 'url("/static/homeImg/img1.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: '10.96px',
            height: '11.06px',
            [theme.breakpoints.down('md')]: {
                left: '10px',
            },
        },
        '&:nth-child(2)': {
            '&:before': {
                background: 'url("/static/homeImg/img2.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(3)': {
            '&:before': {
                background: 'url("/static/homeImg/img3.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
    },
}));

export const TrialButton = styled(Box)(({ theme }) => ({
    '& a': {
        fontWeight: 400,
        fontSize: 16,
    },
    '& button': {
        padding: '0 70px',
        [theme.breakpoints.down('md')]: {
            padding: '0 10px',
            marginBottom: -50,
        },
    },
}));