import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFooterActions, getFooterLinks, socialLinks } from './constant';
import { ActionButton, ContactButton, CopyText, FooterMenu, Logo, LogoImg, MainBox, SocialIcon, SubTitle } from './styled-components';

export default function HomeContact() {
    const { t } = useTranslation();
    const footerActions = getFooterActions(t);
    const footerLinks = getFooterLinks(t);
    return (
        <MainBox px={10}>
            <Typography textAlign="center" variant="h3">
                {t('home-page.footer-heading')}
            </Typography>
            <SubTitle variant="subtitle1">
                {t('home-page.footer-subtitle')}
            </SubTitle>

            <Grid container spacing={0} display="flex" justifyContent="center">
                {footerActions.map((action) => (
                    <Grid item xs={12} sm={4} md={3} display="flex" justifyContent="center" my={1} key={action.id}>
                        <ActionButton size="large" type="submit" variant="contained" href={action.link}>
                            {action.text}
                        </ActionButton>
                    </Grid>
                ))}
                <Grid item xs={12} sm={4} md={3} display="flex" justifyContent="center" my={1}>
                    <ContactButton size="large" type="submit" variant="outlined">
                        {t('contact-us')}
                    </ContactButton>
                </Grid>
            </Grid>

            <LogoImg>
                <Logo alt="logo" src="/static/homeImg/Logo.png" />
            </LogoImg>

            <Grid container spacing={2} justifyContent="center">
                {socialLinks.map((socialLink) => (
                    <Grid item key={socialLink.id}>
                        <FooterMenu href={socialLink.url}>
                            <SocialIcon alt="logo" src={socialLink.icon} />
                        </FooterMenu>
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={3} justifyContent="center" pt={8} pb={3}>
                {footerLinks.map((footerLink) => (
                    <Grid item px={4} key={footerLink.id}>
                        <FooterMenu href={footerLink.url}>{footerLink.text}</FooterMenu>
                    </Grid>
                ))}
            </Grid>
            <hr />
            <Grid py={3}>
                <CopyText textAlign="center" variant="subtitle1">
                    {t('home-page.footer-copyright')}
                </CopyText>
            </Grid>
        </MainBox>
    );
}
