const getFooterActions = (t) => ([
    {
        id: 1,
        link: '/register',
        text: t('start-trial'),
    },
    {
        id: 2,
        link: '/login',
        text: t('login')
    },
]);

const socialLinks = [
    {
        id: 1,
        url: '#',
        icon: "/static/homeImg/twitter.png",
    },
    {
        id: 2,
        url: '#',
        icon: "/static/homeImg/linkedin.png",
    },
    {
        id: 3,
        url: '#',
        icon: "/static/homeImg/facebook.png",
    },
    {
        id: 4,
        url: '#',
        icon: "/static/homeImg/instagram.png",
    },
    {
        id: 5,
        url: '#',
        icon: "/static/homeImg/medium.png",
    }
];

const getFooterLinks = (t) => ([
    {
        id: 1,
        url: '#',
        text: t('home-page.footer-link-1')
    },
    {
        id: 2,
        url: '#',
        text: t('home-page.footer-link-2')
    },
    {
        id: 3,
        url: '#',
        text: t('home-page.footer-link-3')
    },
    {
        id: 4,
        url: '#',
        text: t('home-page.footer-link-4')
    },
    {
        id: 5,
        url: '#',
        text: t('home-page.footer-link-5')
    },
]);

export {
    getFooterActions,
    socialLinks,
    getFooterLinks,
}