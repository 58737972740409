// @mui
import { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { EXCEL_STYLE } from '../constant';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { Wrapper, PageTopRightBg, DivTableStyle, PaymentTitle, FullTableStyle, AutoCompleteDebtInputStyle, BackButtonStyle, ButtonText, TotalFlowValueMultipleColumn, TotalAmountDiv, FullContentStyle, CalculatorSubInput, CalculatorInputNumber, AddMoreTbBUtton, AddMoreButton, ExtraTdSpacing, ProjectTotalValue, DebtRepaymentTh, ProjectInputNumber, CalculatorPercentageInput, TotalFlow } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, downloadExcelHeader, getCurrencySymbol } from '../../../utils/calCommonFunction';

export default function CalculatorsPage() {
    const [debtRepaymentList, setDebtRepaymentList] = useState([{
        lender: '',
        debt_type: '',
        current_balance: '',
        interest_rate: '',
        monthly_payment: '',
        pay_in_month: '',
        total_payment: '',
        interest_paid: '',
    }]);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const currencySymbol = getCurrencySymbol();

    const debtObj = [...debtRepaymentList];
    // eslint-disable-next-line
    const totalCurrentBalance = debtObj.reduce((total, currentValue) => total += Number(removeNumberFormatting(currentValue.current_balance)), 0).toLocaleString("en-US", { minimumFractionDigits: 2 });
    // eslint-disable-next-line
    const totalMonthlyAmount = debtObj.reduce((total, currentValue) => total += Number(removeNumberFormatting(currentValue.monthly_payment)), 0).toLocaleString("en-US", { minimumFractionDigits: 2 });
    // eslint-disable-next-line
    const totalPaymentAmount = debtObj.reduce((total, currentValue) => total += Number(currentValue.total_payment), 0);
    // eslint-disable-next-line
    const totalInterestPaid = debtObj.reduce((total, currentValue) => total += Number(currentValue.interest_paid), 0);
    const totalGrandTotalPayment = Number(Number(totalPaymentAmount)).toLocaleString("en-US", { minimumFractionDigits: 2 });
    const totalInterestPaidValue = Number(Number(totalInterestPaid)).toLocaleString("en-US", { minimumFractionDigits: 2 });

    const compoundingFrequencyOptions = [
        { label: 'Credit Card' },
        { label: 'Term Loan' },
        { label: 'Line of Credit' },
        { label: 'Other' },
    ]

    const getDebtRepaymentDetails = async () => {
        const getDebtRepaymentData = await calculator.getUserCalculator("debt-repayment");
        if (getDebtRepaymentData.data) {
            const debtJsonData = getDebtRepaymentData.data?.calcJson?.postData;
            setDebtRepaymentList(debtJsonData);
        }
    }

    useEffect(() => {
        getDebtRepaymentDetails();
    }, []);

    const resetFields = () => {
        setDebtRepaymentList([{
            lender: '',
            debt_type: '',
            current_balance: '',
            interest_rate: '',
            monthly_payment: '',
            pay_in_month: '',
            total_payment: '',
            interest_paid: '',
        }]);
    }

    const savePresentData = async () => {
        try {
            const postData = debtRepaymentList
            const embeddedDataObj = {
                "calcType": "debt-repayment",
                "calcJson": { postData }
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                lender: 'Lender',
                debt_type: 'Debt Type',
                current_balance: 'Current Balance',
                interest_rate: 'Interest Rate',
                monthly_payment: 'Max Monthly Payment That you Can Afford',
                pay_in_month: '# of Months to Repay in Full',
                total_payment: 'Total Payments',
                interest_paid: 'Interest Paid',
            },
        ];
        const showTotal = [
            {
                lender: 'Total',
                debt_type: '',
                current_balance: `${currencySymbol} ${totalCurrentBalance}`,
                interest_rate: '',
                monthly_payment: `${currencySymbol} ${totalMonthlyAmount}`,
                pay_in_month: '',
                total_payment: `${currencySymbol} ${totalGrandTotalPayment}`,
                interest_paid: `${currencySymbol} ${totalInterestPaidValue}`,
            }
        ]
        const afterMap = debtRepaymentList.map(item => {
            item.current_balance = `${currencySymbol} ${item.current_balance}`;
            item.interest_rate = `${item.interest_rate} %`;
            item.monthly_payment = `${currencySymbol} ${item.monthly_payment}`;
            item.total_payment = `${currencySymbol} ${item.total_payment}`;
            item.interest_paid = `${currencySymbol} ${item.interest_paid}`;
            return item;
        })
        const excelValues = postData.concat(afterMap, showTotal);
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'C1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'D1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'E1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'F1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'G1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'H1',
                "style": EXCEL_STYLE.boldHeader
            },
        ]
        const cols = [{ width: 11 }, { width: 11 }, { width: 15 }, { width: 15 }, { width: 40 }, { width: 30 }, { width: 15 }, { width: 15 }];
        const merges = [
            // { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
        ]
        downloadExcelHeader(excelValues, style, cols, merges, 'DebtRepayment', 2, ['C', 'D', 'E', 'F', 'G', 'H'], true);
    }

    const addFormFields = () => {
        setDebtRepaymentList([...debtRepaymentList, {
            lender: '',
            debt_type: '',
            current_balance: '',
            interest_rate: '',
            monthly_payment: '',
            pay_in_month: '',
            total_payment: '',
            interest_paid: '',
        }])
    };

    const totalPayableMonth = (principle, monthlyPayment, interestRate) => {
        let months = 0;
        let totalMonthlyPay = 0;
        let totalInterest = 0;

        if (principle > 0 && monthlyPayment > 0 && interestRate > 0) {
            const initialAmount = principle;
            let rate = interestRate;
            rate /= 100;
            rate /= 12;
            const firstMonthInterest = (initialAmount * rate);
            if (firstMonthInterest < monthlyPayment) {
                let monthsInterest = 0;
                while (principle > 0) {
                    monthsInterest = principle * rate;
                    totalInterest += monthsInterest;
                    principle -= (monthlyPayment - monthsInterest);
                    months += 1;
                }
    
                totalMonthlyPay = (initialAmount + totalInterest).toFixed(2);
                totalInterest = totalInterest.toFixed(2);
            } else {
                totalInterest = firstMonthInterest.toFixed(2);
            }
        }
        return { 'totalmonths': months, 'totalMonthlyPay': totalMonthlyPay, 'totalInterestPay': totalInterest }
    }

    const debtRepaymentTargetValue = (i, e) => {
        const debtRePayValues = [...debtRepaymentList];

        if (e.label !== undefined) {
            debtRePayValues[i].debt_type = e.label;
        }   
        else if(e.target.name !== "lender") {
            debtRePayValues[i][e.target.name] = formatDollarValues(e.target.value);
        }    
        else {
            debtRePayValues[i][e.target.name] = e.target.value;
        }

        const principle = removeNumberFormatting(debtRePayValues[i].current_balance);
        const monthlyPayment = removeNumberFormatting(debtRePayValues[i].monthly_payment);
        const interestRate = removeNumberFormatting(debtRePayValues[i].interest_rate);
        const calulateObj = totalPayableMonth(Number(principle), Number(monthlyPayment), Number(interestRate));
        if (calulateObj !== undefined) {
            debtRePayValues[i].pay_in_month = calulateObj.totalmonths;
            debtRePayValues[i].total_payment = calulateObj.totalMonthlyPay;
            debtRePayValues[i].interest_paid = calulateObj.totalInterestPay;
        }
        setDebtRepaymentList(debtRePayValues);
    }

    return (
        <Page title={t('debt-repayment.title')}>
            <Wrapper>
                <PageTopRightBg />
                <FullContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('debt-repayment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('debt-repayment.calculator')} subtitle={t('debt-repayment.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <DivTableStyle>
                                <PaymentTitle>{t('debt-repayment.description')}</PaymentTitle>
                                <FullTableStyle>
                                    <tbody>
                                        <tr>
                                            <DebtRepaymentTh>{t('debt-repayment.lender')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Debt_type')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Curr_bal')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Interest_rate')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Aff_payment')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Repay_full')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Total')}</DebtRepaymentTh>
                                            <DebtRepaymentTh>{t('debt-repayment.Interest_paid')}</DebtRepaymentTh>
                                        </tr>
                                        {debtRepaymentList.map((item, index) => (
                                            <tr key={index}>
                                                <CalculatorInputNumber>
                                                    <span style={{ padding: '5px' }}>{''}</span>
                                                    <Input
                                                        type='text'
                                                        name="lender"
                                                        value={item.lender}
                                                        onChange={(e) => {
                                                            debtRepaymentTargetValue(index, e)
                                                        }}
                                                    />
                                                </CalculatorInputNumber>
                                                <CalculatorSubInput>
                                                    <AutoCompleteDebtInputStyle
                                                        id="tags-outlined"
                                                        options={compoundingFrequencyOptions}
                                                        name="debt_type"
                                                        value={item.debt_type || ''}
                                                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                                                        disableClearable
                                                        onChange={(e, val) => {
                                                            debtRepaymentTargetValue(index, val)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" className="dropMenu" />
                                                        )}
                                                    />
                                                </CalculatorSubInput>
                                                <ProjectInputNumber>
                                                    <span>{currencySymbol}</span>
                                                    <Input
                                                        type='text'
                                                        placeholder={'0'}
                                                        name="current_balance"
                                                        value={item.current_balance}
                                                        onBlur={(e)=>{
                                                            debtRepaymentList[index].current_balance = formatZeroValues(e.target.value);
                                                            setDebtRepaymentList([...debtRepaymentList]);
                                                        }}
                                                        onChange={(e) => {
                                                            debtRepaymentTargetValue(index, e)
                                                        }}
                                                    />
                                                </ProjectInputNumber>
                                                <CalculatorPercentageInput>
                                                    <Input
                                                        type='text'
                                                        placeholder={'0'}
                                                        name="interest_rate"
                                                        value={item.interest_rate}
                                                        onChange={(e) => {
                                                            debtRepaymentTargetValue(index, e)
                                                        }}
                                                    />
                                                    <span>%</span>
                                                </CalculatorPercentageInput>
                                                <ProjectInputNumber>
                                                    <span>{currencySymbol}</span>
                                                    <Input
                                                        type='text'
                                                        placeholder={'0'}
                                                        name="monthly_payment"
                                                        value={item.monthly_payment}
                                                        onBlur={(e)=>{
                                                            debtRepaymentList[index].monthly_payment = formatZeroValues(e.target.value);
                                                            setDebtRepaymentList([...debtRepaymentList]);
                                                        }}
                                                        onChange={(e) => {
                                                            debtRepaymentTargetValue(index, e)
                                                        }}
                                                    />
                                                </ProjectInputNumber>

                                                <td><ProjectTotalValue><span style={{ padding: '5px' }}>{''}</span> <span>{item.pay_in_month===""?"0":item.pay_in_month}</span></ProjectTotalValue></td>
                                                <td><ProjectTotalValue>{currencySymbol} <span>{Number(item.total_payment).toLocaleString("en-US", { minimumFractionDigits: 2 })}</span></ProjectTotalValue></td>
                                                <td><ProjectTotalValue>{currencySymbol} <span>{Number(item.interest_paid).toLocaleString("en-US", { minimumFractionDigits: 2 })}</span></ProjectTotalValue></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <AddMoreTbBUtton style={{ paddingLeft: 0 }}><AddMoreButton onClick={addFormFields}>Add another</AddMoreButton></AddMoreTbBUtton>
                                        </tr>
                                        <ExtraTdSpacing>
                                            <td>{" "}</td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <TotalFlow>Total</TotalFlow>
                                            <td>{" "}</td>
                                            <TotalFlowValueMultipleColumn><TotalAmountDiv style={{ marginLeft: 0 }}>{currencySymbol}<span> {totalCurrentBalance}</span></TotalAmountDiv></TotalFlowValueMultipleColumn>
                                            <td>{" "}</td>
                                            <TotalFlowValueMultipleColumn><TotalAmountDiv style={{ marginLeft: 0 }}>{currencySymbol}<span> {totalMonthlyAmount}</span></TotalAmountDiv></TotalFlowValueMultipleColumn>
                                            <td>{" "}</td>
                                            <TotalFlowValueMultipleColumn><TotalAmountDiv style={{ marginLeft: 0, width: 121 }}>{currencySymbol}<span> {totalGrandTotalPayment}</span></TotalAmountDiv></TotalFlowValueMultipleColumn>
                                            <TotalFlowValueMultipleColumn><TotalAmountDiv style={{ marginLeft: 0, width: 121 }}>{currencySymbol}<span> {totalInterestPaidValue}</span></TotalAmountDiv></TotalFlowValueMultipleColumn>
                                        </tr>
                                    </tbody>
                                </FullTableStyle>
                            </DivTableStyle>

                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('savings-goal.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('savings-goal.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={savePresentData}
                        >
                            {t('savings-goal.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('savings-goal.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('debt-repayment.go-back')}
                    </BackButtonStyle>
                </FullContentStyle>
            </Wrapper>
        </Page>
    );
}
