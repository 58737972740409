/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, styled } from '@mui/material';
import onboarding from 'constants/services/onboarding';
import yodlee from 'constants/services/yodlee';
import CountryData from 'constants/countryData';
import { showError } from 'utils/toast';
import { addDuration } from 'utils/formatTime';
import { useNavigate } from 'react-router-dom';
import FinancialTitle from './FinancialTitle';
import { FastLinkContainer, Fragment, HeadArea, TextArea } from './styled-component';
import LinkedAccountInfo from './LinkedAccountInfo';
import user from '../../../constants/services/user';

const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: 'left',
    textTransform: 'none',
    marginBottom: 20
}))

export default function FinancialInstitution() {
    const [personalDetails, setPersonalDetails] = useState({});
    const [linkedInstitutions, setLinkedInstitutions] = useState([]);
    const [yodleeAccessToken, setYodleeAccessToken] = useState(null);
    const navigate = useNavigate();
    const getProfile = async () => {
        let profDetail = await user.getProfile();
        profDetail = profDetail?.data || {};
        setPersonalDetails(profDetail);
    }

    const getFinancialInstitution = async () => {
        const response = await onboarding.getOnboardingData();
        setLinkedInstitutions(response?.data?.linkedInstitutions);
    }
    useEffect(() => {
        getProfile();
        getFinancialInstitution();
    }, []);
    const { t } = useTranslation();
    const [showOverview, setShowOverview] = useState(true);

    let selectedCountryData = CountryData.find(c => c.label === personalDetails?.country?.country);
    if (!selectedCountryData) {
        selectedCountryData = CountryData.find((c) => c.label === 'Canada');
    }

    const saveFinancialInstution = async (financialInstitutions) => {
        try {
            const institutions = financialInstitutions.filter((fi) => fi.status === 'SUCCESS').map((fi) => ({
                providerAccountId: fi.providerAccountId,
                providerId: fi.providerId,
                providerName: fi.providerName,
                requestId: fi.requestId,
                additionalStatus: fi.additionalStatus,
                status: fi.status,
            }));
            if (institutions?.length) {
                const result = await onboarding.saveFinancialInstution({
                    institutions,
                    fetchTransactions: true,
                });
                setLinkedInstitutions(result?.linkedInstitutions || []);
            }
        } catch (error) {
            showError(t, error);
        }
    };

    const isAccesssTokenValid = () => {
        if (!yodleeAccessToken?.accessToken) {
            return false;
        }
        if (addDuration(yodleeAccessToken.issuedAt, {seconds: 1799}) < new Date()) {
            return false;
        }
        return true;
    }

    const loadFastlinkContainer = async () => {
        try {
            let accessTokenInfo = yodleeAccessToken;
            document.body.classList.add('loading-indicator');
            if (!isAccesssTokenValid()) {
                const result = await yodlee.getYodleeToken();
                accessTokenInfo = result?.token || null;
                setYodleeAccessToken(accessTokenInfo);
            }
            const { accessToken } = accessTokenInfo;
            const config = {
                fastLinkURL: process.env.REACT_APP_YODLEE_FASTLINK_URL,
                accessToken: `Bearer ${accessToken}`,
                params: { configName: "Aggregation", isIFrameMounted:true },
                onSuccess: () => {
                    document.body.classList.remove('loading-indicator');
                },
                onError: (data) => {
                    console.error("fi onError:");
                    console.error(data);
                    if (data.code) {
                        setShowOverview(true);
                    }
                    if (data.message === 'FastLink already in use, multiple instances of fastLink may not work as expected.') {
                        window.fastlink.close();
                    }
                    document.body.classList.remove('loading-indicator');
                },
                onClose: async (data) => {
                    console.log("fi onClose:");
                    console.log(data);
                    if (data?.sites?.length) {
                        await saveFinancialInstution(data.sites);
                    }
                    setShowOverview(true);
                    document.body.classList.remove('loading-indicator');
                },
                onEvent: () => {
                    document.body.classList.remove('loading-indicator');
                }
            }
            window.fastlink.open(config, "container-fastlink");
        } catch (error) {
            console.log(error);
            showError(t, error);
        }
    }

    useEffect(() => {
        if (!showOverview) {
            loadFastlinkContainer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOverview]);

    return (
        <div style={{
            boxSizing: 'border-box',
            flexDirection: 'row',
            color: 'rgb(75, 75, 75)',
            padding: '30px 10% 40px calc(10% + 90px)'
        }}>
            {showOverview ? <>
                <BackButtonStyle variant="contained" onClick={() => navigate('/my-profile')}>
                    {t('link-institution.go-back')}
                </BackButtonStyle>
                <HeadArea>
                    <FinancialTitle
                        heading={''}
                        subheading={t('link-institution.sub-heading')}
                        description={t('link-institution.description')}
                    />
                </HeadArea>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 15}}>
                    <Button
                        variant="contained"
                        style={{marginTop: 10, marginBottom: 15, textTransform: 'none'}}
                        onClick={() => setShowOverview(false)}>
                        {linkedInstitutions.length === 0 ? 'Link a financial institution' : 'Link more financial institution(s)'}
                    </Button>
                </div>
                <Grid container spacing={2} style={{ marginTop: 0, paddingTop: 16 }}>
                    <LinkedAccountInfo linkedInstitutions={linkedInstitutions} />
                </Grid>
            </> : <>
                <Grid container spacing={2}>
                    <TextArea item md={6} xs={12}>
                        <FinancialTitle
                            heading={t('')}
                            subheading={t('link-institution.sub-heading2')}
                            description={t('link-institution.description')}
                        />
                    </TextArea>
                    <Grid item md={6} xs={12}>
                        <FastLinkContainer id="container-fastlink" />
                    </Grid>
                </Grid>
            </>}
            <BackButtonStyle variant="contained" onClick={() => navigate('/my-profile')}>
                {t('link-institution.go-back')}
            </BackButtonStyle>

        </div>
    );
}
