/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import Page from '../../components/Page';
import Title from '../../components/Title';
import {
    PageTopRightBg,
    ContentStyle,
    Wrapper,
} from './styled-components';
import faq from '../../constants/services/faq';
import transform from '../../constants/strapiHelper';

export default function FAQ() {
    const { t } = useTranslation();

    const [contentData, setContentData] = useState([])

    const getFaqs = async () => {
        const res = await faq.getFaqs();
        setContentData(res.data.sort((a, b) => a?.attributes?.position - b?.attributes?.position));
    };

    useEffect(() => {
        getFaqs();
    }, []);

    const headingStyle = () => ({
        fontWeight: 800,
        fontSize: 20,
        color: '#00773E',
        marginBlock: 35
    })

    const containStyle = () => ({
        background: 'green',
        padding: 10,
        borderRadius: 5,
        marginRight: 5,
        height: 'max-content',
        textAlign: 'center',
        minWidth: 230
    });

    const moduleNameStyle = () => ({
        textDecoration: 'none',
        color: 'white',
        fontSize: 14
    });

    const getContentData = () => {
        const rows = [];
        for(let i =0; i< contentData.length; i+=5) {
            rows.push(<div style={{ display: 'flex', marginBottom: 20 }}>
                {contentData[i]?.attributes?.moduleName && <div style={containStyle()}><a href={`#${contentData[i]?.attributes?.moduleName}`} style={moduleNameStyle()}>{contentData[i]?.attributes?.moduleName}</a></div>}
                {contentData[i+1]?.attributes?.moduleName && <div style={containStyle()}><a href={`#${contentData[i+1]?.attributes?.moduleName}`} style={moduleNameStyle()}>{contentData[i+1]?.attributes?.moduleName}</a></div>}
                {contentData[i+2]?.attributes?.moduleName && <div style={containStyle()}><a href={`#${contentData[i+2]?.attributes?.moduleName}`} style={moduleNameStyle()}>{contentData[i+2]?.attributes?.moduleName}</a></div>}
                {contentData[i+3]?.attributes?.moduleName && <div style={containStyle()}><a href={`#${contentData[i+3]?.attributes?.moduleName}`} style={moduleNameStyle()}>{contentData[i+3]?.attributes?.moduleName}</a></div>}
                {contentData[i+4]?.attributes?.moduleName && <div style={containStyle()}><a href={`#${contentData[i+4]?.attributes?.moduleName}`} style={moduleNameStyle()}>{contentData[i+4]?.attributes?.moduleName}</a></div>}
            </div>);
        }
        return rows;
    }

    return (
        <Page title={t('faq.heading')}>
            <Wrapper>
                <ScrollToTop />
                <PageTopRightBg />
                <ContentStyle>
                    <Title title={t('faq.heading')} subtitle="Frequently Asked Questions" />
                    <div style={{ marginBlock: 30 }}>
                        {getContentData()}
                    </div>

                    {contentData.map((con) => <>
                        <div id={con?.attributes?.moduleName} style={{ marginBlock: 30 }}> 
                            <div style={headingStyle()}>{con?.attributes?.moduleName}</div>
                            {ReactHtmlParser(con?.attributes?.content, {transform: (node) => transform(node)})}
                            <br/>
                            <div style={{ width: 100, height: 5 , background: '#008000', marginLeft: '40%' }} />
                        </div>
                    </>)}
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
