import { Link as RouterLink } from 'react-router-dom';
import { Link, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import { LoginForm } from '../../sections/auth/login';
import AuthSocial from '../../sections/auth/social-login/AuthSocial';
import { RootStyle, SectionStyle, HeaderStyle, ContentStyle, LogoStyle } from './styled-components';

export default function Login() {
    const { t } = useTranslation();
    const smUp = useResponsive('up', 'sm');
    return (
        <Page title={t('login-page.title')}>
            <RootStyle>
                <SectionStyle />
                <Grid container direction="row" spacing={2} alignItems="baseline">
                    <HeaderStyle item xs={12} sm={6}>
                        <LogoStyle>
                            <Logo sx={{ width: 430, height: 'auto' }} />
                        </LogoStyle>
                    </HeaderStyle>
                    <ContentStyle item xs={12} sm={6}>
                        <LoginForm />
                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                {t('login-page.dont-have-account')}{' '}
                                <Link variant="subtitle2" component={RouterLink} to="/register">
                                    {t('login-page.get-started')}
                                </Link>
                            </Typography>
                        )}
                        <AuthSocial />
                    </ContentStyle>
                </Grid>
            </RootStyle>
        </Page>
    );
}
