import { styled } from '@mui/material/styles';
import { Grid, Checkbox, Snackbar } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '20px 10%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const CardStyle = styled('div')(({ theme }) => ({
    padding: '10px 10px',
    textAlign: 'center',
    boxShadow: '0px 8px 24px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 11,
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.cardHover,
        boxShadow: '0 8px 24px 1px rgba(0, 0, 0, 0.05)',
        '& .iconImgHub, & svg': {
            display: 'none',
        },
        '& p:not(.description-text)': {
            display: 'flex !important',
        },
    },
    [theme.breakpoints.between('md', 'lg')]: {
        minHeight: '122px',
    },
    [theme.breakpoints.down('xl')]: {
        padding: '10px 10px',
    },
    '& p': {
        fontSize: 19,
        fontWeight: '400',
        color: theme.palette.primary.darker,
        margin: '10px 0',
        lineHeight: '26px',
        display: 'none',
        minHeight: 80,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            fontSize: 16,
            minHeight: 66,
            lineHeight: '22px',
        },
        '&.description-text': {
            display: 'block'
        }
    },
    '& a': {        
        textDecoration: 'none',
    },
    '& h4': {
        fontSize: 22,
        fontWeight: '700',
        color: theme.palette.primary.main,
        margin: '10px 0',
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            fontSize: 18,
        }
    },
    '& .iconImgHub': {
        margin: 'auto',
        width: 30,
        height: 30,
        marginTop: 35,
        marginBottom: 35,
        [theme.breakpoints.down('xl')]: {
            marginTop: 18,
            marginBottom: 18,
        },
    },
    '& svg': {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        marginTop: 21,
        marginBottom: 21,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('xl')]: {
            marginTop: 10,
            marginBottom: 9,
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
}));

export const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        position: 'absolute'
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 0px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
    '& label':{
        fontSize:'20px',
        fontWeight: '600',
        color: '#00773E',
        marginLeft: '12px',
    }
}));

export const CheckBoxLabel = styled('h4')(() => ({
    fontSize: '17px',
    lineHeight: '24px',
    color: '#00773E',
    marginLeft: '4px',
    fontWeight: '700'
}));

export const CheckBoxCenter = styled('div')(() => ({
    textAlign: 'center',
    marginBottom: '12px',
    marginTop: '12px'
}));

export const SnackbarDivStyle = styled('div')(()=>({
    display:'flex',
    fontSize: '18px',
    '& svg':{
        fontSize: '30px',
        marginRight: '10px'
    }
}))

export const SnackBarUl= styled('div')(()=>({
    marginLeft: '58px',
    fontWeight: "500"
}))