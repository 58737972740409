// @mui
import { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, MidSideColumn, TopSideBar, BackButtonStyle, TotalFlow, BudgetInput, TotalFlowValueColumn, TotalAmountDiv, AutoFieldStyle, ButtonText, LightTooltip, InfoIcon, BudgetPercentageInput, ExtraSpacing, TotalFlowHeader, BackButton } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, formatZeroValues, removeNumberFormatting, downloadExcelHeader, getCurrencySymbol, calcPMT } from '../../../utils/calCommonFunction';

export default function LeasePayment() {
    const [leaseAmount, setLeaseAmount] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const [termsMonth, setTermsMonth] = useState("");
    const [baloonPayment, setBaloonPayment] = useState("");
    const [monthPaymentType, setMonthPaymentType] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();

    const monthlyArrearsDetails = [
        { label: 'Arrears' },
        { label: 'Advance' },
    ]

    const getLeasePaymentDetails=async()=>{
        const getLeasePaymentData = await calculator.getUserCalculator("lease-payment");
        if (getLeasePaymentData.data) {
            const loanJsonData = getLeasePaymentData.data?.calcJson;
            setLeaseAmount(loanJsonData.lease_amount);
            setInterestRate(loanJsonData.interest_rate);
            setTermsMonth(loanJsonData.terms_month);
            setBaloonPayment(loanJsonData.baloon_payment);
            setMonthPaymentType(loanJsonData.month_payment_type);
        }
    }

    useEffect(() => {
        getLeasePaymentDetails()
    }, []);

    const setMonthlypay=()=>{
        let monthlyPaymentTypeVal = 0;
        if (monthPaymentType === 'Advance') {
            monthlyPaymentTypeVal = 1;
        }
        return monthlyPaymentTypeVal
    }

    const pmtResultValue=()=>{
        let leasePaymentAmt = 0;
        const pmtresult = calcPMT(interestRate / 1200, Number(removeNumberFormatting(termsMonth)), -Number(removeNumberFormatting(leaseAmount)), Number(removeNumberFormatting(baloonPayment)), setMonthlypay());
        leasePaymentAmt = Number(pmtresult.toFixed(2))
        leasePaymentAmt = leasePaymentAmt.toLocaleString("en-US", {minimumFractionDigits:2});
        return leasePaymentAmt
    }

    // calculations
    const leaseRepayment = Number(removeNumberFormatting(leaseAmount)) -  Number(removeNumberFormatting(baloonPayment));
    const principalRepayment= leaseRepayment.toLocaleString("en-US", {minimumFractionDigits:2});
    const monthLeasePayment= pmtResultValue();
    const baloonElement=Number(removeNumberFormatting(baloonPayment));
    const baloonValue=Number(baloonElement).toLocaleString("en-US", {minimumFractionDigits:2})
    const totalPayentAmt = (Number(removeNumberFormatting(termsMonth)) * Number(removeNumberFormatting(monthLeasePayment))) + Number(removeNumberFormatting(baloonPayment));
    const interestAmtCal = totalPayentAmt - leaseRepayment - Number(removeNumberFormatting(baloonPayment));
    const totalPayment=totalPayentAmt.toLocaleString("en-US", {minimumFractionDigits:2});
    const interestAmount=interestAmtCal.toLocaleString("en-US", {minimumFractionDigits:2});   

    const handleChanged = (name, value) => {
        if (name === 'interest_rate') {
            setInterestRate(value);
        }
        if (name === 'terms_month') {
            setTermsMonth(value);
        }
    }

    const resetFields = () => {
        setLeaseAmount("");
        setInterestRate("");
        setTermsMonth("");
        setBaloonPayment("");
        setMonthPaymentType("");
    }

    const saveLeaseData = async () => {
        try {
            const postData = {
                "lease_amount": leaseAmount,
                "interest_rate": interestRate,
                "terms_month": termsMonth,
                "month_payment_type": monthPaymentType,
                "baloon_payment": baloonPayment
            }
            const loanDataObj = {
                "calcType": "lease-payment",
                "calcJson": postData
            };
            await calculator.addUserCalculator(loanDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Lease amount",
                "Value": `${currencySymbol} ${leaseAmount}`
            },
            {
                "Description": "Interest rate",
                "Value": `${interestRate} %`
            },
            {
                "Description": "Term in months",
                "Value": termsMonth
            },
            {
                "Description": "Monthly payments in advance or arrears",
                "Value": (monthPaymentType !== '') ? monthPaymentType : ''
            },
            {
                "Description": "Balloon payment",
                "Value": `${currencySymbol} ${baloonPayment}`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Monthly lease payment",
                "Value": `${currencySymbol} ${monthLeasePayment}`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Payment Summary",
                "Value": ""
            },
            {
                "Description": "Lease repayments",
                "Value": `${currencySymbol} ${principalRepayment}`
            },
            {
                "Description": "Baloon repayment",
                "Value": `${currencySymbol} ${baloonPayment}`
            },
            {
                "Description": "Interest",
                "Value": `${currencySymbol} ${interestAmount}`
            },
            {
                "Description": "Total payment",
                "Value": `${currencySymbol} ${totalPayment}`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A8',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B8',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'A10',
                "style": EXCEL_STYLE.boldColorHrVr
            },
            {
                "col": 'A14',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B14',
                "style": EXCEL_STYLE.boldColorRight
            }
        ]
        const cols = [{ width: 25 }, { width: 20 }];
        const merges = [
            { s: { r: 6, c: 0 }, e: { r: 6, c: 1 } },
            { s: { r: 8, c: 0 }, e: { r: 8, c: 1 } },
            { s: { r: 9, c: 0 }, e: { r: 9, c: 1 } },
        ]
        downloadExcelHeader(postData, style, cols, merges, 'LeasePayment', 2, ['B'], false );
    }
    
    return (
        <Page title={t('lease-payment.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('lease-payment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('lease-payment.calculator')} subtitle={t('lease-payment.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('lease-payment.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('lease-payment.lease-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="lease_amount"
                                                    value={leaseAmount}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        setLeaseAmount(formatZeroValues(value));
                                                    }}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        setLeaseAmount(formatDollarValues(value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('lease-payment.interest-rate')} </td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="interest_rate"
                                                    value={interestRate}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        handleChanged(e.target.name, value);
                                                    }
                                                    }
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                        <tr>
                                            <td>{t('lease-payment.term-in-months')}</td>
                                            <BudgetInput>
                                                <span style={{ padding:'5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="terms_month"
                                                    value={termsMonth}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        handleChanged(e.target.name, value.toString().slice(0,3));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('lease-payment.monthly-payments-in-advance-or-arrears')}
                                                <LightTooltip style={{top:'20px'}} title={
                                                    <>
                                                        <p>{t('lease-payment.advance')}</p> 
                                                        <p style={{marginTop:'10px'}}>{t('lease-payment.arrears')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                                </LightTooltip>
                                            </td>
                                            <td>
                                                <BudgetInput>
                                                    <AutoFieldStyle
                                                        id="tags-outlined"
                                                        options={monthlyArrearsDetails}
                                                        value={monthPaymentType}
                                                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                                                        disableClearable
                                                        onChange={(e, val) => {
                                                            setMonthPaymentType(val.label);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" className="dropMenu" />
                                                        )}
                                                    />
                                                </BudgetInput>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('lease-payment.baloon-payment/residual-value')}
                                                <LightTooltip title={
                                                    <>
                                                        <p>{t('lease-payment.due-amount')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="baloon_payment"
                                                    value={baloonPayment}
                                                    onBlur={(e)=>{
                                                        setBaloonPayment(formatZeroValues(e.target.value));
                                                    }}
                                                    onChange={(e) => {
                                                        setBaloonPayment(formatDollarValues(e.target.value));
                                                    }}                                  
                                                />
                                            </BudgetInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TopSideBar>{t('lease-payment.monthly-lease-payment')}</TopSideBar>
                                        <TotalFlowValueColumn><TotalAmountDiv>{currencySymbol}<span> {monthLeasePayment==="NaN" || monthLeasePayment==="Infinity" ? '0.00' : monthLeasePayment}</span></TotalAmountDiv></TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                                <ExtraSpacing>
                                    <tr>
                                        <td>{''}</td>
                                    </tr>
                                </ExtraSpacing>
                                <tbody>
                                    <tr>
                                        <TotalFlowHeader>{t('lease-payment.payment-Summary')}</TotalFlowHeader>
                                        <td>{''}</td>
                                        <td>{''}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>{t('lease-payment.lease-repayments')}</td>
                                        <MidSideColumn>{currencySymbol} {principalRepayment}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('lease-payment.baloon-repayment')}</td>
                                        <MidSideColumn>{currencySymbol} {!baloonValue ? '0.00' : baloonValue} </MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('lease-payment.interest')}</td>
                                        <MidSideColumn>{currencySymbol} {interestAmount ==="NaN" ? '0.00' :interestAmount}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <TotalFlow>{t('lease-payment.total-payment')}</TotalFlow>
                                        <TotalFlowValueColumn><TotalAmountDiv>{currencySymbol}<span> {totalPayment==="NaN" ? '0.00' :totalPayment}</span></TotalAmountDiv></TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('lease-payment.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('lease-payment.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            // variant="contained"
                            onClick={saveLeaseData}
                        >
                            {t('lease-payment.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('lease-payment.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('lease-payment.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
