import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
// import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
// import 'react-toastify/dist/ReactToastify.css';

import user from 'constants/services/user';
import dashboard from '../../../constants/services/dashboard';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import CreditCardFilter from '../filter/CreditCardFilter';
import { NoDataTd, ReportAmountTd, TotalAmountDiv, SpanTotalFlow } from '../ExecutiveSummary/styled-component'
import { removeEmpty, getMonthNameYear, getCurrencySymbol, getPositiveNumber, getNegativeNumber } from '../../../utils/calCommonFunction';
import { Wrapper, PageTopRightBg, ContentStyle, SubmitButton, TableStyle, BackButtonStyle, StickyFirstTh, StickySecondTh, StickyThirdTh, TableWrapper, StickyFirstTd, StickySecondTd, StickyThirdTd, StickyTable, LoaderGif, AmountDiv, StickyFourthTh, StickyFourthTd } from '../Expense/styled-component';

export default function IncomeExpenseSummary() {
    const [tableLoader, setTableLoader] = useState(false);
    const [loading, setLoading] = useState(false);
    const [monthColumn, setMonthColumn] = useState([]);

    const [expenseCategoryNames, setExpenseCategoryNames] = useState([]);
    const [expenseTableRows, setExpenseTableRows] = useState([]);
    const [expenseMonthlyTotal, setExpenseMonthlyTotal] = useState([]);
    const [totalActualExpense, setTotalActualExpense] = useState();
    const [expenseBudgetList, setExpenseBudgetList] = useState([]);
    const [totalExpenseBudget, setTotalExpenseBudget] = useState(0);
    
    const [incomeCategoryNames, setIncomeCategoryNames] = useState([]);
    const [incomeTableRows, setIncomeTableRows] = useState([]);
    const [incomeMonthlyTotal, setIncomeMonthlyTotal] = useState([]);
    const [totalActualIncome, setTotalActualIncome] = useState();
    const [incomeBudgetList, setIncomeBudgetList] = useState([]);
    const [totalIncomeBudget, setTotalIncomeBudget] = useState(0);
    const [categories, setCategories] = useState([]);
    const [netIncomeMonthly, setNetIncomeMonthly] = useState([]);

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: `${location?.state?.categoryGroupId},${location?.state?.expenseCategoryId}` || null,
        categoryId: null,
        type: 2,
        splitDataInCategoryGroup: 'true'
    });

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
            if (value) {
                const categoryGroup = categories.find((c) => c.id === value);
                clonedData.categoryGroupId = categoryGroup?.categoryGroupId;
            } else {
                clonedData.categoryGroupId = `${location?.state?.categoryGroupId},${location?.state?.expenseCategoryId}`;
            }
        }
        setFiltersData(clonedData);
    };

    const getExpenseCategoryData = async () => {
        try {
            const filtersDataObj = removeEmpty(filtersData);
            setTableLoader(true);
            setLoading(true);
    
            const response = await dashboard.getDashboardData(filtersDataObj);
            const { incomeData, expenseData } = response;
            setTableLoader(false);
            setLoading(false);
            
            let monthRange = Object.keys(incomeData);
            if (!monthRange?.length) {
                monthRange = Object.keys(expenseData);
            }
            setMonthColumn(monthRange);
    
            const expenseGroupedData = [];
            const expenseMonthlyTotalArr = [];
            const expenseBudgetData = [];

            const incomeGroupedData = [];
            const incomeMonthlyTotalArr = [];
            const incomeBudgetData = [];
            monthRange.sort();

            // eslint-disable-next-line
            monthRange.map((item) => {
                if (Object.keys(incomeData)?.length) {
                    let totalAsset = 0;
                    incomeData[item].forEach((a) => {
                        const assetName = a.name;
                        incomeGroupedData[assetName] = incomeGroupedData[assetName] || [];
                        incomeGroupedData[assetName].push(a.values);
        
                        incomeBudgetData[assetName] = Number(a.budget) * monthRange.length;
                        totalAsset += Number(a.values);
                    });
                    incomeMonthlyTotalArr.push(totalAsset);
                }
                if (Object.keys(expenseData)?.length) {
                    let totalAsset = 0;
                    expenseData[item].forEach((a) => {
                        const assetName = a.name;
                        expenseGroupedData[assetName] = expenseGroupedData[assetName] || [];
                        expenseGroupedData[assetName].push(a.values);
        
                        expenseBudgetData[assetName] = Number(a.budget) * monthRange.length;
                        totalAsset += Number(a.values);
                    });
                    expenseMonthlyTotalArr.push(totalAsset);
                }
            })
    
            const expenseCategories = Object.keys(expenseGroupedData);
            const totalExpense = expenseTotalSum(expenseMonthlyTotalArr);

            const incomeCategories = Object.keys(incomeGroupedData);
            const totalIncome = expenseTotalSum(incomeMonthlyTotalArr);
            const netIncomeMonthlyArr = monthRange.map((month, i) => incomeMonthlyTotalArr[i] - Math.abs(expenseMonthlyTotalArr[i]));
            setNetIncomeMonthly(netIncomeMonthlyArr);

            setIncomeTableRows(incomeGroupedData);
            setTotalActualIncome(totalIncome);
            setIncomeMonthlyTotal(incomeMonthlyTotalArr);
            setIncomeCategoryNames(incomeCategories);
            setIncomeBudgetList(incomeBudgetData);
            setTotalIncomeBudget(getBudgetTotal(incomeBudgetData));
            
            setExpenseTableRows(expenseGroupedData);
            setTotalActualExpense(totalExpense);
            setExpenseMonthlyTotal(expenseMonthlyTotalArr);
            setExpenseCategoryNames(expenseCategories);
            setExpenseBudgetList(expenseBudgetData);
            setTotalExpenseBudget(getBudgetTotal(expenseBudgetData));

        } catch (error) {
            setTableLoader(false);
            setLoading(false);
        }
    }

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    }

    const getTotalSumofData = (tableData, item) => {
        const sumofdata = tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getIncomeBudgetSummary = (tableData, budgetList, item) => {
        const actual = tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        const budget = budgetList[item];
        return actual - budget;
    };

    const getExpenseBudgetSummary = (tableData, budgetList, item) => {
        const actual = Math.abs(tableData[item].reduce((acc, item) => Number(acc) + Number(item), 0));
        const budget = budgetList[item];
        return budget - actual;
    };

    const getBudgetTotal = (budgetData) => {
        const total = Object.values(budgetData).reduce((acc, item) => Number(acc) + Number(item), 0);
        return total;
    };

    const getFilterData = () => {
        getExpenseCategoryData();
    }

    const getCategories = async () => {
        const response = await user.getUserCategories('categoryGroupName=Income/Cash Inflows,Expenses/Cash Outflows');
        const categories = response?.data || [];
        setCategories(categories);
    };

    useEffect(() => {
        getExpenseCategoryData();
        getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Page title={t('income-expense-summary.title')}>
                <Wrapper>
                    <PageTopRightBg />
                    <ContentStyle>
                        <BackButtonStyle
                            variant="contained"
                            onClick={() => navigate('/dashboard/all-reports')}>{t('income-expense-summary.back')}
                        </BackButtonStyle>
                        <Title title={t('income-expense-summary.dashboard')} subtitle={t('income-expense-summary.subtitle')} />

                        <Grid container pt={5} pb={5} mt={0}>
                            <Grid item md={10.5} xs={12}>
                                <CreditCardFilter
                                    filtersData={filtersData}
                                    updateFilters={updateFilters}
                                    categories={categories}
                                />
                            </Grid>
                            <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                <SubmitButton loading={loading} size="large" variant="contained" onClick={() => getFilterData()}>
                                    Go
                                </SubmitButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12} sm={12} md={12}>
                                <StickyTable>
                                    <TableWrapper>
                                        <TableStyle>
                                            {
                                                tableLoader ?
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    :
                                                    <>
                                                        {
                                                            incomeCategoryNames?.length === 0 && expenseCategoryNames?.length === 0 &&
                                                            <tbody>
                                                                <tr>
                                                                    <NoDataTd colSpan={4}>{t('executive-summary.no-data-found')}</NoDataTd>
                                                                </tr>
                                                            </tbody>
                                                        }
                                                        {
                                                            incomeCategoryNames?.length > 0 &&
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <StickyFirstTh>{t('income-expense-summary.income-category')}</StickyFirstTh>
                                                                        {
                                                                            monthColumn.map((item, indx) => <th style={{ textAlign: 'end' }} key={indx}>{getMonthNameYear(item)}</th>)
                                                                        }
                                                                        <StickySecondTh style={{ right: '300px' }} className="highlighted-th">
                                                                            {t('income-expense-summary.actual')}
                                                                        </StickySecondTh>
                                                                        <StickyThirdTh style={{ right: '160px' }} className="highlighted-th">{t('income-expense-summary.budget')}</StickyThirdTh>
                                                                        <StickyFourthTh style={{ right: '-1px', left: 0 }} className="highlighted-th">{t('income-expense-summary.better')}</StickyFourthTh>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        incomeCategoryNames.map((item, index) => (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <StickyFirstTd>{item}</StickyFirstTd>
                                                                                    {
                                                                                        incomeTableRows[item].map((item, indx) => 
                                                                                            <ReportAmountTd key={indx}>
                                                                                                <TotalAmountDiv>
                                                                                                    {currencyCode}{' '}
                                                                                                    <span>
                                                                                                        {getPositiveNumber(item)}
                                                                                                    </span>
                                                                                                </TotalAmountDiv>
                                                                                            </ReportAmountTd>
                                                                                        )
                                                                                    }
                                                                                    <StickySecondTd style={{ right: '300px' }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getPositiveNumber(getTotalSumofData(incomeTableRows, item))}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickySecondTd>
                                                                                    <StickyThirdTd style={{ right: '160px' }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getPositiveNumber(incomeBudgetList[item])}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickyThirdTd>
                                                                                    <StickyFourthTd style={{ right: '-1px', left: 0 }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getPositiveNumber(getIncomeBudgetSummary(incomeTableRows, incomeBudgetList, item))}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickyFourthTd>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    }
                                                                    <tr>
                                                                        <StickyFirstTd style={{ height: '51px', fontSize: '16px', fontWeight: '600' }}>{t('income-expense-summary.total-income')}</StickyFirstTd>
                                                                        {
                                                                            incomeMonthlyTotal.map((item, index) => 
                                                                                <SpanTotalFlow key={index}>
                                                                                    <TotalAmountDiv>
                                                                                        {currencyCode}{' '}
                                                                                        <span>
                                                                                            {getPositiveNumber(item)}
                                                                                        </span>
                                                                                    </TotalAmountDiv>
                                                                                </SpanTotalFlow>
                                                                            )
                                                                        }
                                                                        <StickySecondTd style={{ right: '300px' }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getPositiveNumber(totalActualIncome)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd style={{ right: '160px' }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getPositiveNumber(totalIncomeBudget)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickyThirdTd>
                                                                        <StickyFourthTd style={{ right: '-1px', left: 0 }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getPositiveNumber(totalActualIncome - totalIncomeBudget)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickyFourthTd>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        }
                                                        {
                                                            expenseCategoryNames?.length > 0 &&
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <StickyFirstTh>{t('income-expense-summary.expense-category')}</StickyFirstTh>
                                                                        {
                                                                            monthColumn.map((item, indx) => <th style={{ textAlign: 'end' }} key={indx}>{getMonthNameYear(item)}</th>)
                                                                        }
                                                                        <StickySecondTh style={{ right: '300px' }} className="highlighted-th">
                                                                            {t('income-expense-summary.actual')}
                                                                        </StickySecondTh>
                                                                        <StickyThirdTh style={{ right: '160px' }} className="highlighted-th">{t('income-expense-summary.budget')}</StickyThirdTh>
                                                                        <StickyFourthTh style={{ right: '-1px', left: 0 }} className="highlighted-th">{t('income-expense-summary.better')}</StickyFourthTh>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        expenseCategoryNames.map((item, index) => (
                                                                            <>
                                                                                <tr key={index}>
                                                                                    <StickyFirstTd>{item}</StickyFirstTd>
                                                                                    {
                                                                                        expenseTableRows[item].map((item, indx) => 
                                                                                            <ReportAmountTd key={indx}>
                                                                                                <TotalAmountDiv>
                                                                                                    {currencyCode}{' '}
                                                                                                    <span>
                                                                                                        {getNegativeNumber(item)}
                                                                                                    </span>
                                                                                                </TotalAmountDiv>
                                                                                            </ReportAmountTd>
                                                                                        )
                                                                                    }
                                                                                    <StickySecondTd style={{ right: '300px' }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getNegativeNumber(getTotalSumofData(expenseTableRows, item))}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickySecondTd>
                                                                                    <StickyThirdTd style={{ right: '160px' }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getPositiveNumber(expenseBudgetList[item])}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickyThirdTd>
                                                                                    <StickyFourthTd style={{ right: '-1px', left: 0 }} className="highlighted-td">
                                                                                        <TotalAmountDiv style={{ width: 120, float: 'right' }}>
                                                                                            {currencyCode}{' '}
                                                                                            <span>
                                                                                                {getPositiveNumber(getExpenseBudgetSummary(expenseTableRows, expenseBudgetList, item))}
                                                                                            </span>
                                                                                        </TotalAmountDiv>
                                                                                    </StickyFourthTd>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    }
                                                                    <tr>
                                                                        <StickyFirstTd style={{ height: '51px', fontSize: '16px', fontWeight: '600' }}>{t('income-expense-summary.total-expense')}</StickyFirstTd>
                                                                        {
                                                                            expenseMonthlyTotal.map((item, index) => 
                                                                                <SpanTotalFlow key={index}>
                                                                                    <TotalAmountDiv>
                                                                                        {currencyCode}{' '}
                                                                                        <span>
                                                                                            {getNegativeNumber(item)}
                                                                                        </span>
                                                                                    </TotalAmountDiv>
                                                                                </SpanTotalFlow>
                                                                            )
                                                                        }
                                                                        <StickySecondTd style={{ right: '300px' }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getNegativeNumber(totalActualExpense)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickySecondTd>
                                                                        <StickyThirdTd style={{ right: '160px' }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getPositiveNumber(totalExpenseBudget)}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickyThirdTd>
                                                                        <StickyFourthTd style={{ right: '-1px', left: 0 }} className="highlighted-td">
                                                                            <AmountDiv>{currencyCode}
                                                                                <span>
                                                                                    {getPositiveNumber(totalExpenseBudget - Math.abs(totalActualExpense))}
                                                                                </span>
                                                                            </AmountDiv>
                                                                        </StickyFourthTd>
                                                                    </tr>
                                                                </tbody>
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr>
                                                                    <StickyFirstTd style={{ height: '51px', fontSize: '16px', fontWeight: '600' }}>{t('income-expense-summary.net-income')}</StickyFirstTd>
                                                                    {
                                                                        netIncomeMonthly.map((item, index) => 
                                                                            <SpanTotalFlow key={index}>
                                                                                <TotalAmountDiv>
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getPositiveNumber(item)}
                                                                                    </span>
                                                                                </TotalAmountDiv>
                                                                            </SpanTotalFlow>
                                                                        )
                                                                    }
                                                                    <StickySecondTd style={{ right: '300px' }} className="highlighted-td">
                                                                        <AmountDiv>{currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber(totalActualIncome - Math.abs(totalActualExpense))}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickySecondTd>
                                                                    <StickyThirdTd style={{ right: '160px' }} className="highlighted-td">
                                                                        <AmountDiv>{currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber(totalIncomeBudget - totalExpenseBudget)}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickyThirdTd>
                                                                    <StickyFourthTd style={{ right: '-1px', left: 0 }} className="highlighted-td">
                                                                        <AmountDiv>{currencyCode}
                                                                            <span>
                                                                                {getPositiveNumber((totalActualIncome - totalIncomeBudget) - (totalExpenseBudget - Math.abs(totalActualExpense)))}
                                                                            </span>
                                                                        </AmountDiv>
                                                                    </StickyFourthTd>
                                                                </tr>
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </TableStyle>
                                    </TableWrapper>
                                </StickyTable>
                            </Grid>
                        </Grid>
                    </ContentStyle>
                </Wrapper>
            </Page>
        </>
    )
}