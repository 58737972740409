import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
    const logo = (
        <Box sx={{ width: 40, height: 40, ...sx }}>
            <svg width="100%" height="100%" viewBox="0 0 384 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M159.611 84.9409H168.371V64.1054C168.371 54.2966 162.489 48.9115 154.166 48.9115C149.098 48.9115 144.53 51.6682 142.277 55.7071C139.899 51.2835 135.456 48.9115 130.074 48.9115C125.756 48.9115 122.064 50.7706 119.874 53.7197V49.4243H111.113V84.9409H119.874V65.3235C119.874 59.6818 122.94 56.7328 127.696 56.7328C132.327 56.7328 135.393 59.6818 135.393 65.3235V84.9409H144.154V65.3235C144.154 59.6818 147.22 56.7328 151.914 56.7328C156.544 56.7328 159.611 59.6818 159.611 65.3235V84.9409Z"
                    fill="#00773E"
                />
                <path
                    d="M208.912 67.1826C208.912 55.9635 201.09 48.8474 191.078 48.8474C181.065 48.8474 173.243 55.9635 173.243 67.1826C173.243 78.3377 180.815 85.5179 190.827 85.5179C200.902 85.5179 208.912 78.3377 208.912 67.1826ZM182.129 67.1826C182.129 60.0665 186.322 56.6687 190.953 56.6687C195.583 56.6687 199.901 60.0665 199.901 67.1826C199.901 74.2347 195.458 77.6966 190.827 77.6966C186.134 77.6966 182.129 74.2347 182.129 67.1826Z"
                    fill="#00773E"
                />
                <path
                    d="M247.566 67.1826C247.566 55.9635 239.744 48.8474 229.731 48.8474C219.719 48.8474 211.897 55.9635 211.897 67.1826C211.897 78.3377 219.469 85.5179 229.481 85.5179C239.556 85.5179 247.566 78.3377 247.566 67.1826ZM220.783 67.1826C220.783 60.0665 224.976 56.6687 229.606 56.6687C234.237 56.6687 238.555 60.0665 238.555 67.1826C238.555 74.2347 234.112 77.6966 229.481 77.6966C224.788 77.6966 220.783 74.2347 220.783 67.1826Z"
                    fill="#00773E"
                />
                <path d="M252.741 84.9409H261.502V37.5H252.741V84.9409Z" fill="#00773E" />
                <path
                    d="M266.653 67.0544C266.653 78.1453 273.662 85.5179 282.423 85.5179C287.929 85.5179 291.872 82.8253 293.937 79.7481V84.9409H302.76V49.4243H293.937V54.489C291.872 51.5399 288.054 48.8474 282.485 48.8474C273.662 48.8474 266.653 55.9635 266.653 67.0544ZM293.937 67.1826C293.937 73.9141 289.556 77.6325 284.738 77.6325C280.045 77.6325 275.602 73.7859 275.602 67.0544C275.602 60.3229 280.045 56.7328 284.738 56.7328C289.556 56.7328 293.937 60.4511 293.937 67.1826Z"
                    fill="#00773E"
                />
                <path
                    d="M310.065 84.9409H318.826V65.3235C318.826 59.6177 321.892 56.5405 326.648 56.5405C331.279 56.5405 334.345 59.6177 334.345 65.3235V84.9409H343.106V64.1054C343.106 54.2966 337.411 48.9115 329.589 48.9115C324.958 48.9115 321.079 50.8347 318.826 53.8479V37.5H310.065V84.9409Z"
                    fill="#00773E"
                />
                <path
                    d="M363.598 52.0675V64.5047H351.771V72.3261H363.598V84.7633H371.858V72.3261H383.686V64.5047H371.858V52.0675H363.598Z"
                    fill="#00773E"
                />
                <path
                    d="M116.312 103.694L113.454 95.95H111.111L115.114 105.944L113.048 110.886H115.224L121.458 95.95H119.282L116.312 103.694Z"
                    fill="#00773E"
                />
                <path
                    d="M132.728 101.021C132.728 97.8308 130.441 95.7841 127.545 95.7841C124.65 95.7841 122.363 97.8308 122.363 101.021C122.363 104.211 124.557 106.276 127.453 106.276C130.367 106.276 132.728 104.211 132.728 101.021ZM124.502 101.021C124.502 98.7159 125.922 97.6095 127.508 97.6095C129.076 97.6095 130.57 98.7159 130.57 101.021C130.57 103.326 129.021 104.45 127.453 104.45C125.867 104.45 124.502 103.326 124.502 101.021Z"
                    fill="#00773E"
                />
                <path
                    d="M144.01 95.95H141.907V101.611C141.907 103.473 140.893 104.432 139.344 104.432C137.813 104.432 136.798 103.473 136.798 101.611V95.95H134.714V101.924C134.714 104.764 136.522 106.257 138.901 106.257C140.1 106.257 141.243 105.76 141.907 104.893V106.11H144.01V95.95Z"
                    fill="#00773E"
                />
                <path
                    d="M148.887 100.763C148.887 98.6052 149.846 97.9599 151.395 97.9599H151.93V95.7841C150.491 95.7841 149.477 96.411 148.887 97.4251V95.95H146.784V106.11H148.887V100.763Z"
                    fill="#00773E"
                />
                <path
                    d="M160.756 97.4436V95.95H158.654V110.941H160.756V104.635C161.42 105.428 162.619 106.276 164.297 106.276C166.972 106.276 169.074 104.118 169.074 100.984C169.074 97.8492 166.972 95.7841 164.297 95.7841C162.637 95.7841 161.402 96.5954 160.756 97.4436ZM166.935 100.984C166.935 103.178 165.422 104.45 163.836 104.45C162.269 104.45 160.756 103.215 160.756 101.021C160.756 98.8449 162.269 97.6095 163.836 97.6095C165.422 97.6095 166.935 98.7896 166.935 100.984Z"
                    fill="#00773E"
                />
                <path
                    d="M175.432 97.5542C177 97.5542 178.236 98.5499 178.273 100.117H172.647C172.869 98.513 174.012 97.5542 175.432 97.5542ZM180.191 103.086H177.922C177.535 103.879 176.816 104.506 175.525 104.506C173.975 104.506 172.777 103.492 172.629 101.814H180.394C180.449 101.463 180.468 101.131 180.468 100.781C180.468 97.7939 178.42 95.7841 175.525 95.7841C172.518 95.7841 170.453 97.8308 170.453 101.021C170.453 104.211 172.611 106.276 175.525 106.276C178.015 106.276 179.619 104.856 180.191 103.086Z"
                    fill="#00773E"
                />
                <path
                    d="M184.639 100.763C184.639 98.6052 185.598 97.9599 187.148 97.9599H187.683V95.7841C186.244 95.7841 185.229 96.411 184.639 97.4251V95.95H182.537V106.11H184.639V100.763Z"
                    fill="#00773E"
                />
                <path
                    d="M197.196 103.27C197.085 99.6009 191.349 100.744 191.349 98.6974C191.349 98.0152 191.939 97.5542 193.064 97.5542C194.208 97.5542 194.89 98.1627 194.964 99.0478H197.066C196.956 97.0195 195.443 95.7841 193.138 95.7841C190.74 95.7841 189.228 97.0932 189.228 98.7159C189.228 102.441 195.13 101.297 195.13 103.289C195.13 103.989 194.484 104.506 193.267 104.506C192.105 104.506 191.312 103.823 191.238 102.994H189.062C189.154 104.838 190.869 106.276 193.304 106.276C195.702 106.276 197.196 104.985 197.196 103.27Z"
                    fill="#00773E"
                />
                <path
                    d="M209.15 101.021C209.15 97.8308 206.863 95.7841 203.968 95.7841C201.072 95.7841 198.785 97.8308 198.785 101.021C198.785 104.211 200.98 106.276 203.875 106.276C206.79 106.276 209.15 104.211 209.15 101.021ZM200.925 101.021C200.925 98.7159 202.345 97.6095 203.931 97.6095C205.499 97.6095 206.992 98.7159 206.992 101.021C206.992 103.326 205.443 104.45 203.875 104.45C202.289 104.45 200.925 103.326 200.925 101.021Z"
                    fill="#00773E"
                />
                <path
                    d="M218.44 106.11H220.525V100.117C220.525 97.2776 218.736 95.7841 216.356 95.7841C215.139 95.7841 214.014 96.2819 213.331 97.1117V95.95H211.229V106.11H213.331V100.431C213.331 98.5684 214.346 97.6095 215.895 97.6095C217.426 97.6095 218.44 98.5684 218.44 100.431V106.11Z"
                    fill="#00773E"
                />
                <path
                    d="M222.506 100.984C222.506 104.118 224.627 106.276 227.264 106.276C228.961 106.276 230.178 105.465 230.824 104.598V106.11H232.945V95.95H230.824V97.4251C230.197 96.5954 229.016 95.7841 227.301 95.7841C224.627 95.7841 222.506 97.8492 222.506 100.984ZM230.824 101.021C230.824 103.215 229.33 104.45 227.744 104.45C226.176 104.45 224.664 103.178 224.664 100.984C224.664 98.7896 226.176 97.6095 227.744 97.6095C229.33 97.6095 230.824 98.8449 230.824 101.021Z"
                    fill="#00773E"
                />
                <path d="M235.706 106.11H237.809V92.465H235.706V106.11Z" fill="#00773E" />
                <path
                    d="M245.619 106.11H247.74V97.6648H249.621V95.95H247.74V95.2309C247.74 94.0692 248.182 93.6267 249.474 93.6267V91.875C246.818 91.875 245.619 92.8891 245.619 95.2309V95.95H244.42V97.6648H245.619V106.11Z"
                    fill="#00773E"
                />
                <path
                    d="M251.484 106.11H253.587V95.95H251.484V106.11ZM252.554 94.604C253.292 94.604 253.882 94.0139 253.882 93.2579C253.882 92.5019 253.292 91.9119 252.554 91.9119C251.798 91.9119 251.208 92.5019 251.208 93.2579C251.208 94.0139 251.798 94.604 252.554 94.604Z"
                    fill="#00773E"
                />
                <path
                    d="M263.559 106.11H265.643V100.117C265.643 97.2776 263.854 95.7841 261.475 95.7841C260.258 95.7841 259.133 96.2819 258.45 97.1117V95.95H256.348V106.11H258.45V100.431C258.45 98.5684 259.465 97.6095 261.014 97.6095C262.545 97.6095 263.559 98.5684 263.559 100.431V106.11Z"
                    fill="#00773E"
                />
                <path
                    d="M267.624 100.984C267.624 104.118 269.745 106.276 272.383 106.276C274.08 106.276 275.297 105.465 275.942 104.598V106.11H278.063V95.95H275.942V97.4251C275.315 96.5954 274.135 95.7841 272.42 95.7841C269.745 95.7841 267.624 97.8492 267.624 100.984ZM275.942 101.021C275.942 103.215 274.448 104.45 272.862 104.45C271.295 104.45 269.782 103.178 269.782 100.984C269.782 98.7896 271.295 97.6095 272.862 97.6095C274.448 97.6095 275.942 98.8449 275.942 101.021Z"
                    fill="#00773E"
                />
                <path
                    d="M288.037 106.11H290.121V100.117C290.121 97.2776 288.332 95.7841 285.952 95.7841C284.735 95.7841 283.61 96.2819 282.928 97.1117V95.95H280.825V106.11H282.928V100.431C282.928 98.5684 283.942 97.6095 285.491 97.6095C287.022 97.6095 288.037 98.5684 288.037 100.431V106.11Z"
                    fill="#00773E"
                />
                <path
                    d="M292.102 101.021C292.102 104.211 294.167 106.276 297.082 106.276C299.572 106.276 301.195 104.893 301.766 102.902H299.498C299.11 103.953 298.336 104.506 297.082 104.506C295.385 104.506 294.26 103.252 294.26 101.021C294.26 98.8081 295.385 97.5542 297.082 97.5542C298.336 97.5542 299.147 98.1811 299.498 99.1584H301.766C301.195 97.0195 299.572 95.7841 297.082 95.7841C294.167 95.7841 292.102 97.8492 292.102 101.021Z"
                    fill="#00773E"
                />
                <path
                    d="M303.862 106.11H305.964V95.95H303.862V106.11ZM304.931 94.604C305.669 94.604 306.259 94.0139 306.259 93.2579C306.259 92.5019 305.669 91.9119 304.931 91.9119C304.175 91.9119 303.585 92.5019 303.585 93.2579C303.585 94.0139 304.175 94.604 304.931 94.604Z"
                    fill="#00773E"
                />
                <path
                    d="M308.024 100.984C308.024 104.118 310.145 106.276 312.782 106.276C314.479 106.276 315.696 105.465 316.342 104.598V106.11H318.463V95.95H316.342V97.4251C315.715 96.5954 314.535 95.7841 312.819 95.7841C310.145 95.7841 308.024 97.8492 308.024 100.984ZM316.342 101.021C316.342 103.215 314.848 104.45 313.262 104.45C311.694 104.45 310.182 103.178 310.182 100.984C310.182 98.7896 311.694 97.6095 313.262 97.6095C314.848 97.6095 316.342 98.8449 316.342 101.021Z"
                    fill="#00773E"
                />
                <path d="M321.225 106.11H323.327V92.465H321.225V106.11Z" fill="#00773E" />
                <path
                    d="M332.981 97.4436V95.95H330.879V110.941H332.981V104.635C333.645 105.428 334.844 106.276 336.523 106.276C339.197 106.276 341.3 104.118 341.3 100.984C341.3 97.8492 339.197 95.7841 336.523 95.7841C334.863 95.7841 333.627 96.5954 332.981 97.4436ZM339.16 100.984C339.16 103.178 337.648 104.45 336.061 104.45C334.494 104.45 332.981 103.215 332.981 101.021C332.981 98.8449 334.494 97.6095 336.061 97.6095C337.648 97.6095 339.16 98.7896 339.16 100.984Z"
                    fill="#00773E"
                />
                <path
                    d="M353.062 101.021C353.062 97.8308 350.775 95.7841 347.879 95.7841C344.983 95.7841 342.696 97.8308 342.696 101.021C342.696 104.211 344.891 106.276 347.787 106.276C350.701 106.276 353.062 104.211 353.062 101.021ZM344.836 101.021C344.836 98.7159 346.256 97.6095 347.842 97.6095C349.41 97.6095 350.904 98.7159 350.904 101.021C350.904 103.326 349.355 104.45 347.787 104.45C346.201 104.45 344.836 103.326 344.836 101.021Z"
                    fill="#00773E"
                />
                <path
                    d="M357.243 100.763C357.243 98.6052 358.202 97.9599 359.751 97.9599H360.286V95.7841C358.847 95.7841 357.833 96.411 357.243 97.4251V95.95H355.14V106.11H357.243V100.763Z"
                    fill="#00773E"
                />
                <path
                    d="M362.551 103.289C362.551 105.354 363.676 106.11 365.483 106.11H367.143V104.358H365.852C364.967 104.358 364.672 104.045 364.672 103.289V97.6648H367.143V95.95H364.672V93.4239H362.551V95.95H361.352V97.6648H362.551V103.289Z"
                    fill="#00773E"
                />
                <path
                    d="M368.38 100.984C368.38 104.118 370.501 106.276 373.139 106.276C374.835 106.276 376.053 105.465 376.698 104.598V106.11H378.819V95.95H376.698V97.4251C376.071 96.5954 374.891 95.7841 373.176 95.7841C370.501 95.7841 368.38 97.8492 368.38 100.984ZM376.698 101.021C376.698 103.215 375.204 104.45 373.618 104.45C372.05 104.45 370.538 103.178 370.538 100.984C370.538 98.7896 372.05 97.6095 373.618 97.6095C375.204 97.6095 376.698 98.8449 376.698 101.021Z"
                    fill="#00773E"
                />
                <path d="M381.581 106.11H383.684V92.465H381.581V106.11Z" fill="#00773E" />
                <path
                    d="M80.9131 13.8356C83.9836 12.2688 87.9922 8.58848 89.2545 3.00457C90.5811 -2.86349 94.3658 2.22158 94.5405 6.7107C94.673 10.1162 93.989 15.1912 91.1227 19.1039C92.1827 20.0931 93.1068 21.221 93.8441 22.507C98.3446 17.2988 112.017 7.80638 130.703 11.5019C131.513 18.9292 130.001 35.387 117.472 41.7998C120.352 38.9467 126.248 30.5502 126.788 19.7897C120.982 18.9745 107.211 19.8983 98.5696 30.1154C98.6146 36.637 97.5435 51.3104 92.899 57.832C94.0241 53.2125 94.7339 42.2977 92.899 34.3272C91.0536 26.3112 85.7881 18.431 74.8071 18.431C73.727 19.3368 71.5397 21.1756 71.4317 24.0016C71.4078 24.6286 71.4287 25.4225 71.4526 26.3256V26.3256V26.3257C71.5632 30.5102 71.7357 37.0385 67.7864 40.1697C66.0481 41.548 63.8988 42.3678 61.8597 43.1456C58.6721 44.3614 55.7537 45.4746 55.095 48.4575C54.6065 50.6695 54.5497 52.7559 54.4965 54.7047V54.7048C54.4322 57.065 54.3733 59.2234 53.5599 61.1583C53.0341 62.4087 52.5478 63.3933 52.0844 64.3314L52.0843 64.3317C51.2386 66.0437 50.4692 67.6012 49.676 70.3382C48.9598 72.8097 48.9699 74.9281 49.676 80.5773C41.2023 68.2198 36.9129 56.7453 36.9129 56.7453C36.9129 56.7453 31.9703 45.2041 34.1526 35.8851C22.7278 25.1547 13.5558 24.7714 5.81485 25.7677C4.73473 32.398 11.7555 43.9284 15.4008 48.8648C-0.692841 37.5608 -0.845856 23.8656 1.08936 18.431C18.8032 12.5616 33.0542 20.8766 37.9655 25.7677C38.4105 24.9528 38.8991 24.1386 39.4334 23.3222L39.4684 23.2688C35.7008 19.6064 34.3728 14.029 34.1565 10.3191C33.8935 5.80628 37.05 0.336057 38.8914 6.07979C40.6059 11.4281 44.678 14.6884 47.7639 16.0086C55.2706 12.1123 63.9456 12.0453 66.0312 12.0453L66.1427 12.0453C67.976 12.0444 74.4723 12.0411 80.9131 13.8356ZM47.2016 91.7216C45.896 94.0969 44.774 96.7605 44.077 99.6228C42.8234 104.771 47.4825 108.821 52.7809 108.821H78.8302C84.1782 108.821 88.7213 104.421 87.5093 99.2121C87.062 97.2896 86.4225 95.3103 85.5126 93.3761C82.3916 86.7422 77.069 84.4286 69.8066 83.4253C61.8333 82.3238 51.0788 84.668 47.2016 91.7216Z"
                    fill="#4B4B4B"
                />
            </svg>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
