import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import auth from 'constants/services/auth';
import { showError } from 'utils/toast';
import useResponsive from '../../hooks/useResponsive';
import SmallLogo from '../../components/SmallLogo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import navConfig from './NavConfig';
import Iconify from '../../components/Iconify';

const DRAWER_WIDTH = 90;
const DRAWER_FULL_WIDTH = 320;

const RootStyle = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '999 !important',
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH,
        '&:hover': {
            width: DRAWER_FULL_WIDTH,
        },
    },
}));

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

const ScrollbarStyle = styled(Scrollbar)(({ theme }) => ({
    width: DRAWER_WIDTH,
    background: theme.palette.primary.main,
    '& .MuiListItemText-root': {
        display: 'none',
        color: theme.palette.secondary.contrastText,
        fontWeight: '400',
    },
    '& .MuiListItemIcon-root': {
        color: theme.palette.secondary.contrastText,
    },
    '&:hover': {
        width: DRAWER_FULL_WIDTH,
        '& .MuiListItemText-root': {
            display: 'block',
        },
        '& a.MuiTypography-root, & p.MuiTypography-root': {
            display: 'block',
        },
    },
}));

const SmallLogoStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '10px 23px 10px',
    alignItems: 'center',
    '& a.MuiTypography-root': {
        display: 'none',
        marginLeft: 16,
        color: theme.palette.secondary.contrastText,
    },
    '& a div': {
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
    '& + .MuiBox-root': {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0',
    },
}));

const UserProfile = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '10px 31px 10px',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 44,
    '& a.MuiTypography-root, & p.MuiTypography-root': {
        display: 'none',
        marginLeft: 20,
        color: theme.palette.secondary.contrastText,
    },
}));

const Logout = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: '10px 31px 10px',
    alignItems: 'center',
    cursor: 'pointer',
    minHeight: 44,
    '& a.MuiTypography-root, & p.MuiTypography-root': {
        display: 'none',
        marginLeft: 20,
        color: theme.palette.secondary.contrastText,
    },
    '& svg': {
        fill: theme.palette.secondary.contrastText,
        '& path': {
            fill: theme.palette.secondary.contrastText,
        },
    },
}));

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const logout = async () => {
        try {
            await auth.logout();
            sessionStorage.clear();
            localStorage.clear();
            navigate('/login', { replace: true });
        } catch (error) {
            showError(t, error);
        };
    };

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const goTo = () => {
        if (sessionStorage.getItem('userData')) {
            const usr = JSON.parse(sessionStorage.getItem('userData'))
            if (usr.id)
                setTimeout(() => {
                    navigate('/welcome', { replace: true });
                }, 500);
        }
        else {
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 500);
        }
    }

    const renderContent = (
        <ScrollbarStyle
            sx={{
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
            }}
        >
            <SmallLogoStyle style={{ cursor: 'pointer' }} onClick={goTo}>
                <SmallLogo sx={{ width: 40, height: 'auto' }} />
                <Link variant="body" style={{ cursor: 'pointer', textDecoration: 'none' }}>Home Page</Link>
            </SmallLogoStyle>
            <NavSection navConfig={navConfig} />
            <UserProfile onClick={() => navigate('/my-profile')} >
                <img src="/static/drawer/profile.png" alt="" />
                <Typography style={{ color: 'white', marginLeft: 20 }}>My Settings</Typography>
            </UserProfile>
            <Logout onClick={logout}>
                <Iconify icon="ri:logout-circle-r-line" sx={{ minWidth: 22, height: 22 }} />
                <Typography style={{ color: 'white', marginLeft: 20 }}>Logout</Typography>
            </Logout>
        </ScrollbarStyle>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer open={isOpenSidebar} onClose={onCloseSidebar}>
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            bgcolor: 'background.default',
                            borderRightStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </RootStyle>
    );
}
