import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import forum from "constants/services/forum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function AutoCompleteForumSearch() {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [topics, setTopics]= useState([]);
    const navigate= useNavigate();
    const { t } = useTranslation();
    const typeCategory = "category";
    const typeTopic = "topic";
    const typeReply = "reply";

    const onTopicSelect = (id, type) => {
        setInputValue('');
        setTopics([]);
        if(type === typeCategory) {
            navigate(`/forum-topics/category/${id}`) ;
        }else if(type === typeTopic) {
            navigate(`/forum-topics/topic/${id}`) ;
        }else if(type === typeReply) {
            navigate(`/forum-topics/topic/${id}`) ;
        }
    }

    const search = async () => {
        if(inputValue.length <= 3) {
            return;
        }
        let categoryData = [];
        categoryData = await forum.getCategoriesByTagAndContent(inputValue);
        
        let topicsData = [];
        topicsData = await forum.getTopicsByTagAndContent(inputValue);
        
        let repliesData = [];
        repliesData = await forum.getRepliesByTagAndContent(inputValue);
 
        const topicsList = [];   
        if (categoryData.data) {
            categoryData.data.forEach((category) => {
                if(category.id) {
                    topicsList.push({
                        id: category.id,
                        type: typeCategory,
                        title: category.attributes.title
                        // description: category.attributes.description
                    });
                }
            });
        }
        if (topicsData.data) {
            topicsData.data.forEach((topic) => {
                if(topic.id) {
                    topicsList.push({
                        id: topic.id,
                        type: typeTopic,
                        // title: topic.attributes.title,
                        // tags: topic.attributes.tags,
                        title: topic.attributes.description
                    });
                }
            });
        }
        if (repliesData.data) {
            repliesData.data.forEach((reply) => {
                if(reply.attributes.topicId) {
                    topicsList.push({
                        id: reply.attributes.topicId,
                        type: typeReply,
                        title: reply.attributes.content,
                        // tags: "",
                        // description: reply.attributes.content
                    });
                }
            });
        }
        setTopics(topicsList);
    }

    useEffect(() => {
        search();
    }, [inputValue]);

    return (
        <Autocomplete
            id="forum-search"
            sx={{ width: 300 }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.title
            }
            filterOptions={(x) => x}
            options={topics}
            autoComplete
            filterSelectedOptions
            value={value}
            noOptionsText={t('forumcategory.noDataFound')}
            onChange={(event, newValue) => {
                setValue(newValue);
                if(newValue && newValue.id) {
                    onTopicSelect(newValue.id, newValue.type);  
                }  
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} variant="standard" label={t('forumcategory.searchTopics')} fullWidth height={30}/>
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box
                                    key={option.id}
                                    component="span"
                                    sx={{ fontWeight: 'regular' }}
                                >
                                    {option.title}
                                </Box>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};
