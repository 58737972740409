import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} />;
const getImgIcon = (name) => <img className="iconImgHub" src={name} alt={name} />;

export const NAV_ITEMS = [
    {
        title: 'Transactions',
        description: 'Review and edit transactions from linked Financial Institutions',
        path: '/downloaded-transactions',
        icon: getIcon('el:download-alt'),
    },
    {
        title: 'Other Transactions',
        description: 'Review and edit transactions related to manually managed categories like a house, car etc.',
        path: '/manual-transactions',
        icon: getImgIcon('/static/welcome/ManualTrans.png'),
    },
    {
        title: 'Dashboard',
        description: 'Review reports and take control of your finances',
        path: '/dashboard',
        icon: getImgIcon('/static/welcome/Dashboard.png'),
    },
    {
        title: 'To Do’s',
        description: 'Manage tasks related to your financial affairs',
        path: '/todos',
        icon: getIcon('ci:list-check'),
    },
    {
        title: 'Key Dates',
        description: 'Track expiry, maturity, and other key dates',
        path: '/key-dates',
        icon: getImgIcon('/static/welcome/KeyDates.png'),
    },
    {
        title: 'Calculators',
        description: 'Do “what if” analyses',
        path: '/calculators',
        icon: getImgIcon('/static/welcome/Calculators.png'),
    },
    {
        title: 'Knowledge Base',
        description: 'Expand your knowledge of financial concepts, terms and more',
        path: '/knowledge-base',
        icon: getIcon('fluent:brain-circuit-20-filled'),
    },
    {
        title: 'Forums',
        description: 'Participate in discussions with other moolah+ members',
        path: '/forum-categories',
        icon: getImgIcon('/static/welcome/Forums.png'),
    },
    {
        title: 'Suggestion Box',
        description: 'Review and rate suggested feature enhancements',
        path: '/suggestion-box',
        icon: getImgIcon('/static/welcome/SuggestionBox.png'),
    },
    {
        title: 'Contact Us',
        description: 'Email your suggestions for feature enhancements and whatever else is on your mind',
        path: '/contact-us',
        icon: getIcon('raphael:mail'),
    },
    {
        title: 'My Settings',
        description: 'Edit your personal information, categories, sub-categories and budget or link another financial insitution',
        path: '/my-profile',
        icon: getImgIcon('/static/welcome/user-icon.png'),
    },
    {
        title: 'Favourite Websites',
        description: 'Add and/or view favorite financial websites',
        path: '/favorite-websites',
        icon: getIcon('ant-design:appstore-filled'),
    },
    {
        title: 'FAQs',
        description: 'View FAQs',
        path: '/faqs',
        icon: getIcon('raphael:question'),
    },
];