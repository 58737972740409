/* eslint-disable no-param-reassign */
import axios from 'axios';
import { API_URL } from '../constants/url';

const request = axios.create();
const REQUEST_URLS = [];

request.interceptors.request.use(
    (config) => {
        if (['users/link-financial-institution', 'fi/load-account-data', '/sub-categories', 'users/profile-summary', 
            'categories/master-data', '/categories/user', 'sub-categories',
            '/users/onboarding-data'].includes(config.url)) {
            document.body.classList.add('loading-indicator');
        }
        REQUEST_URLS.push(config.url);

        config.baseURL = `${API_URL}`;
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            config.headers = {
                Authorization: `Bearer ${user.token}`,
                Accept: 'application/json',
            };
        }
        return config;
    },
    (error) => Promise.reject(error),
);

request.interceptors.response.use(
    (response) => {
        const index = REQUEST_URLS.indexOf(response.config.url);
        if (index > -1) {
            REQUEST_URLS.splice(index, 1);
        }
        if (!REQUEST_URLS.length) {
            document.body.classList.remove('loading-indicator');
        }
        return response.data;
    },
    (error) => {
        if (error.response.status === 401 && error.response?.data?.code === 'invalid_token') {
            sessionStorage.removeItem('userData');
            localStorage.clear();
            window.location.href = '/login';
        }
        const index = REQUEST_URLS.indexOf(error.config.url);
        if (index > -1) {
            REQUEST_URLS.splice(index, 1);
        }
        if (!REQUEST_URLS.length) {
            document.body.classList.remove('loading-indicator');
        }
        if (error.response) {
            return Promise.reject(error.response.data);
        }
        if (error.request) {
            return Promise.reject(error.request);
        }
        return Promise.reject(error);
    },
);

export default request;
