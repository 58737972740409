import { Link as RouterLink } from 'react-router-dom';
import { Link, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import RegisterForm from '../../sections/auth/register/RegisterForm';
import { ContentStyle, HeaderStyle, LogoStyle, RootStyle, SectionStyle } from './styled-components';

export default function Register() {
    const { t } = useTranslation();
    const smUp = useResponsive('up', 'sm');
    return (
        <Page title={t('register-page.title')}>
            <RootStyle>
                <SectionStyle />
                {/* <Container maxWidth="lg"> */}
                <Grid container direction="row" spacing={2} alignItems="baseline">
                    <HeaderStyle item xs={12} sm={6}>
                        <LogoStyle>
                            <Logo sx={{ width: 430, height: 'auto' }} />
                        </LogoStyle>
                    </HeaderStyle>
                    <ContentStyle item xs={12} sm={6}>
                        <RegisterForm />

                        {!smUp && (
                            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                                {t('register-page.already-have-account')}{' '}
                                <Link variant="subtitle2" to="/login" component={RouterLink}>
                                    {t('register-page.login')}
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Grid>
                {/* </Container> */}
            </RootStyle>
        </Page>
    );
}
