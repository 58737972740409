import { useContext } from 'react';
import { Grid, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import FinancialTitle from '../FinancialTitle';
import ListAddSubCategory from '../ListAddSubCategory';
import Iconify from '../../../../../components/Iconify';
import { AccordionStyle, HeadArea } from './styled-components';
import { FormContext } from '../../context/StepperContext';

export default function Step4() {
    const {
        categoriesMasterData: categoryGroups,
        openAccordionsStep4,
        onSubCategoryAccordionClick,
    } = useContext(FormContext);
    const { t } = useTranslation();

    return (
        <>
            <HeadArea>
                <FinancialTitle
                    heading={t('step4.heading')}
                    subheading={t('step4.sub-heading')}
                    description={<Trans i18nKey="step4.description">
                    Listed below are the categories you selected in step three.<br />Now add or rename individual sub-categories
                    </Trans>}
                />
            </HeadArea>
            <Grid container>
                {categoryGroups.map((categoryGroup) => (
                    <AccordionStyle
                        key={categoryGroup.id}
                        expanded={openAccordionsStep4.includes(categoryGroup.name)}
                        onChange={onSubCategoryAccordionClick(categoryGroup.name)}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{categoryGroup.name}</Typography>
                            <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                            <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListAddSubCategory
                                categoryGroupId={categoryGroup.id}
                                categories={categoryGroup.categories}
                                showUpdateManually={['Assets', 'Liabilities'].includes(categoryGroup.name)}
                                showDiscretionary={categoryGroup.name === 'Expenses/Cash Outflows'}
                            />
                        </AccordionDetails>
                    </AccordionStyle>
                ))}
            </Grid>
        </>
    );
}