// @mui
import { useEffect, useState } from 'react';
import { Grid} from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, BudgetPercentageInput, TotalFlow, BudgetInput, BackButtonStyle, ButtonText, TotalFlowValueColumn, TotalAmountDiv, BackButton } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting, formatZeroValues, getFormattedDate, downloadExcelHeader, getCurrencySymbol } from '../../../utils/calCommonFunction';

export default function PresentValue() {
    const [futurePayment, setFuturePayment] = useState("");
    const [paymentReceivedDate, setPaymentReceivedDate] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [interestRate, setInterestRate] = useState("");
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();
    const navigate = useNavigate();

    const getPresentValueDetails = async () => {
        const getpresentValueData = await calculator.getUserCalculator("present-value");
        if (getpresentValueData.data) {
            const loanJsonData = getpresentValueData.data?.calcJson;
            setFuturePayment(loanJsonData.future_payment);
            setPaymentReceivedDate(loanJsonData.payment_received_date);
            setCurrentDate(loanJsonData.current_date);
            setInterestRate(loanJsonData.interest_rate);
        }
    }

    useEffect(() => {
        getPresentValueDetails()
    }, []);

    const persentValueDetails=()=>{
        let termsYear = 0;
        if (currentDate !== "" && paymentReceivedDate !== "") {
            const curentFormatDate = getFormattedDate(new Date(currentDate))
            const futureFormatDate = getFormattedDate(new Date(paymentReceivedDate))
            const date1 = new Date(curentFormatDate);
            const date2 = new Date(futureFormatDate);
            const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
            termsYear = Number((diffDays / 365).toFixed(2));
        }
        const interestVal = Number(interestRate/100);
        const pvCalResult = pvCalculate(Number(removeNumberFormatting(futurePayment)),interestVal,termsYear);
        const presentValue= Number(pvCalResult).toLocaleString("en-US", {minimumFractionDigits:2});
        return presentValue;
    }

    const pvCalculate = (payment, interest, terms) => {
        // Interest part should be pass divided by 100 eg.(3/100) 
        // eslint-disable-next-line
        const pvVal = payment / Math.pow((1 + interest), terms);
        return pvVal.toFixed(2);
    }

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'payment_received_date') {
            setPaymentReceivedDate(value);
        }
        if (name === 'current_date') {
            setCurrentDate(value);
        }
    }

    const resetFields = () => {
        setFuturePayment("");
        setPaymentReceivedDate("");
        setCurrentDate("");
        setInterestRate("");
    }

    const savePresentData = async () => {       
        try {
            const postData = {
                "future_payment": futurePayment,
                "payment_received_date": paymentReceivedDate,
                "current_date": currentDate,
                "interest_rate": interestRate
            }
            const embeddedDataObj = {
                "calcType": "present-value",
                "calcJson": postData
            };
            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Payment to be received in the future",
                "Value": `${currencySymbol} ${futurePayment}`
            },
            {
                "Description": "When will the payment be received?",
                "Value": paymentReceivedDate
            },
            {
                "Description": "What is the current date?",
                "Value": currentDate
            },
            {
                "Description": "Current interest rate on similar term asset",
                "Value": `${interestRate} %`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Present Value",
                "Value": `${currencySymbol} ${persentValueDetails()}`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A7',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B7',
                "style": EXCEL_STYLE.boldColorRight
            }
        ]
        const cols = [{ width: 35 }, { width: 20 }];
        const merges = [
            { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } }
        ]
        downloadExcelHeader(postData, style, cols, merges, 'PresentValue', 2, ['B'], false );
    }

    return (
        <Page title={t('present-value.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('present-value.go-back')}
                    </BackButtonStyle>
                    <Title title={t('present-value.calculator')} subtitle={t('present-value.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('present-value.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('present-value.future-payment')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="future_payment"
                                                    value={futurePayment}
                                                    placeholder="0"
                                                    onBlur={(e)=>{
                                                        setFuturePayment(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setFuturePayment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('present-value.payment-received-or-not')} </td>
                                            <BudgetInput>
                                                <Input
                                                    type='date'
                                                    placeholder="0"
                                                    name="payment_received_date"
                                                    value={paymentReceivedDate}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('present-value.current-date')}</td>
                                            <BudgetInput>
                                                <Input
                                                    type='date'
                                                    placeholder="0"
                                                    name="current_date"
                                                    value={currentDate}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('present-value.intrest-rate')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="interest_rate"
                                                    value={interestRate}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/[^0-9.-]+/g, '');
                                                        setInterestRate(value);
                                                    }}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TotalFlow>{t('present-value.present-value')}</TotalFlow>
                                        <TotalFlowValueColumn><TotalAmountDiv>{currencySymbol}<span> {persentValueDetails() === "NaN" ? 0 : persentValueDetails ()}</span></TotalAmountDiv></TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('present-value.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('present-value.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={savePresentData}
                        >
                            {t('present-value.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('present-value.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('present-value.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
