import { useState, useEffect, Fragment } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// components
import 'react-toastify/dist/ReactToastify.css';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import CreditCardFilter from '../filter/CreditCardFilter';
import { removeEmpty, getMonthNameYear, getCurrencySymbol, getPositiveNumber, formatCreditRecords } from '../../../utils/calCommonFunction';
import dashboard from '../../../constants/services/dashboard';
import {
    TableStyle,
    TableWrapper,
    StickyTable,
    StickyFourthTd,
    ExtraTd,
    NoDataTd,
    StickyFourthTh,
    LoaderGif,
    StickyCreditFirstTd,
    StickyCreditFirstTh
} from '../Expense/styled-component';
import {
    Wrapper,
    PageTopRightBg,
    ContentStyle,
    SubmitButton,
    BackButtonStyle,
    TotalAmountDiv,
    SpanTotalFlow,
} from './styled-component';
import { ReportAmountTd } from '../ExecutiveSummary/styled-component';

export default function CreditCard(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { reportName } = props;
    const currencyCode = getCurrencySymbol();
    const assetApi = props?.apiData;

    const [loading, setLoading] = useState(false);
    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
    });

    const [tableLoader, setTableLoader] = useState(false);
    const [monthColumn, setMonthColumn] = useState([]);
    const [creditCardRecords, setCreditCardRecords] = useState({});
    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const getdashboardData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        const queryString = Object.keys(filtersDataObj)
            .map(
                (key) =>
                    // eslint-disable-next-line
                    key + '=' + filtersDataObj[key]
            )
            .join('&');
        setTableLoader(true);
        const apiqueryString = `/dashboard/${assetApi}?${queryString}`;
        const { data } = await dashboard.getcreditCardUsage(apiqueryString);
        setCreditCardRecords(formatCreditRecords(data));
        setTableLoader(false);
        setMonthColumn(Object.keys(data));
    };

    useEffect(() => {
        getdashboardData();
    }, []);

    const getFilterData = () => {
        getdashboardData();
    };

    const getMonthWiseTotal = (records = []) => {
        records = records.map(({ records }) => records).flat();
        const sumMap = new Map();
        records.forEach((record) => {
            if (!sumMap.has(record.date)) {
                sumMap.set(record.date, 0);
            }
            sumMap.set(record.date, sumMap.get(record.date) + record.amount);
        });
        return Array.from(sumMap.values());
    };

    const renderMonthsHeaders = () => monthColumn.map((item, index) => {
        let styles;
        if (index >= 0) {
            styles = (
                <th
                    style={{
                        textAlign: 'end',
                        width: '16%',
                    }}
                    key={index}
                >
                    {getMonthNameYear(item)}
                </th>
            );
        }
        return styles;
    });

    const renderMonthsRecords = (records) => records.map((item, idx) => (
        <tr key={idx}>
            <StickyCreditFirstTd>{item.providerName}</StickyCreditFirstTd>
            <StickyFourthTd>{item.accountName}</StickyFourthTd>
            {item?.records?.map((record, idx) => (
                <ReportAmountTd key={idx}>
                    <TotalAmountDiv>
                        {currencyCode} <span>{getPositiveNumber(record.amount)}</span>
                    </TotalAmountDiv>
                </ReportAmountTd>
            ))}
        </tr>
    ));

    const renderMonthsTotals = (records) => (
        <tr>
            <StickyCreditFirstTd>{''}</StickyCreditFirstTd>
            <StickyFourthTd>{''}</StickyFourthTd>
            {getMonthWiseTotal(records).map((item, index) => (
                <SpanTotalFlow key={index}>
                    <TotalAmountDiv>
                        {currencyCode} <span>{getPositiveNumber(item)}</span>
                    </TotalAmountDiv>
                </SpanTotalFlow>
            ))}
        </tr>
    );

    const renderCategoryHeader = (category) => (
        <tr>
            <StickyCreditFirstTh>{t(`${reportName}.${category}`)}</StickyCreditFirstTh>
            <StickyFourthTh>{''}</StickyFourthTh>
            {renderMonthsHeaders()}
        </tr>
    );

    const renderCreditCardRecords = () => {
        const items = [
            { dataKey: 'total_credit_line', dataHeader: 'amount_available' },
            { dataKey: 'running_balance', dataHeader: 'amount_used' },
            { dataKey: 'available_credit', dataHeader: 'amount_unused' },
        ];
        return items.map(({ dataKey, dataHeader }) => (
            <Fragment key={dataKey}>
                {renderCategoryHeader(dataHeader)}
                {renderMonthsRecords(creditCardRecords[dataKey])}
                {renderMonthsTotals(creditCardRecords[dataKey])}
            </Fragment>
        ));
    };

    return (
        <Page title={t(`${reportName}.title`)}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/dashboard/all-reports')}>
                        {t('asset-composition.back')}
                    </BackButtonStyle>
                    <Title title={t(`${reportName}.dashboard`)} subtitle={t(`${reportName}.subtitle`)} />

                    <Grid container pt={5} pb={5} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <CreditCardFilter filtersData={filtersData} updateFilters={updateFilters} categories={[]} />
                        </Grid>
                        <Grid
                            item
                            md={1.5}
                            xs={12}
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                        >
                            <SubmitButton
                                loading={loading}
                                size="large"
                                variant="contained"
                                onClick={() => getFilterData()}
                            >
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle>
                                        {tableLoader ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {creditCardRecords.total_credit_line?.length ||
                                                    creditCardRecords.available_credit?.length ||
                                                    creditCardRecords.running_balance?.length ? (
                                                        <>
                                                            <tr>
                                                                <ExtraTd>{''}</ExtraTd>
                                                                <ExtraTd>{''}</ExtraTd>
                                                                <ExtraTd>{''}</ExtraTd>
                                                                <ExtraTd>{''}</ExtraTd>
                                                                <ExtraTd>{''}</ExtraTd>
                                                            </tr>
                                                            {renderCreditCardRecords()}
                                                        </>
                                                    ) : (
                                                        <tr>
                                                            <NoDataTd colSpan={4}>
                                                                {t('executive-summary.no-data-found')}
                                                            </NoDataTd>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        )}
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
