import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography, Link } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';

import Title from '../../components/Title';
import Page from '../../components/Page';
import { CALCULATORS } from './constant';

const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '100px 10%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

const NavCalStyle = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '64px',
    marginBottom: '36px',
    background: '#F8F8F8',
    padding: '16px 50px',
    borderRadius: '10px',
}));

const CardStyle = styled('div')(({ theme }) => ({
    padding: '10px 16px',
    height: '166px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    boxShadow: '0px 8px 24px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 11,
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.cardHover,
        boxShadow: '0 8px 24px 1px rgba(0, 0, 0, 0.05)',
        '& p': {
            display: 'flex',
        },
        '& h5': {
            display: 'none',
        }
    },
    [theme.breakpoints.between('md', 'lg')]: {
        minHeight: '122px',
    },
    [theme.breakpoints.down('xl')]: {
        // padding: '48px 10px',
        height: '166px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    '& p': {
        fontSize: 19,
        fontWeight: '400',
        marginTop: '14px',
        color: '#155041',
        margin: '10px 0',
        lineHeight: '26px',
        display: 'none',
        minHeight: 80,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
            minHeight: 'auto',
            lineHeight: '22px',
            marginBottom: '10px'
        }
    },
    '& a': {
        textDecoration: 'none',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& h4': {
        fontSize: 22,
        fontWeight: '700',
        color: theme.palette.primary.main,
        marginBottom: '15px',
        margin: '10px 0',
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            fontSize: 18,
            marginBottom: '15px'
        }
    },
    '& h5': {
        border: '1px solid #00773E',        
        textDecoration: 'none',
        borderRadius: '50px',
        padding: '4px 10px',
        fontSize: '12px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& svg': {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        marginTop: 21,
        marginBottom: 21,
        [theme.breakpoints.down('xl')]: {
            marginTop: 10,
            marginBottom: 9,
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
    '& svg': {
        display: 'none',
    },
    position: 'relative',
    '&:after': {
        width: 20,
        height: 20,
        border: `solid 2px ${theme.palette.primary.main}`,
        content: "''",
        borderRadius: 3,
    },
    '&.Mui-checked': {
        '&:before': {
            width: 10,
            height: 10,
            background: theme.palette.primary.main,
            content: "''",
            position: 'absolute',
            borderRadius: 1,
        },
    },
}));

export const CheckBoxLabel = styled('h4')(() => ({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00773E',
    marginLeft: '10px'
}));

const loanCategoryList = ['All', ...new Set(CALCULATORS.map((item)=> item.calculatorType))];

export default function CalculatorsPage() {
    const [calculatorFilter, setCalculatorFilter] = useState('All');

    const { t } = useTranslation();

    const getFilteredCalculators = () => {
        if (calculatorFilter === 'All') {
            return CALCULATORS;
        }
        return CALCULATORS.filter((calculator) => calculator.calculatorType === calculatorFilter);
    }

    return (
        <Page title={t('calculation.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <Title title={t('calculation.title')} subtitle={t('calculation.description')} />
                    <NavCalStyle>
                        {
                            loanCategoryList.map((item, index)=> <FormControlLabel
                                key={index}
                                control={<CheckboxStyle
                                    checked={calculatorFilter === item}
                                    onChange={(e) => {
                                        const selectedFilter = e.target.value;
                                        if (calculatorFilter === selectedFilter) {
                                            return;
                                        }
                                        setCalculatorFilter(selectedFilter);
                                    }}/>
                                }
                                label={<CheckBoxLabel>{item}</CheckBoxLabel>}
                                value={item}
                            />
                            )
                        }
                    </NavCalStyle>
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        {getFilteredCalculators().map((cal, key) => (
                            <Grid key={key} item xs={12} sm={6} md={4}>
                                <CardStyle>
                                    <Link href={cal.path} >
                                        <Typography>{cal.hoverText}</Typography>
                                        <Typography variant="h4">{cal.title}</Typography>
                                        <Typography variant="h5">{cal.calculatorType}</Typography>
                                    </Link>
                                </CardStyle>
                            </Grid>
                        ))}
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
