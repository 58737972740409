import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from '../../components/Title';
import Page from '../../components/Page';
import ExecutiveSummary from "./ExecutiveSummary/ExecutiveSummary"
import {Wrapper, PageTopRightBg, ContentStyle, ButtonFlexDiv, BackButtonStyle, GoToReportButton} from './styled-component'

export default function Dashboard() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Page title={t('dashboard.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <ButtonFlexDiv>
                        <BackButtonStyle variant="contained" onClick={() => navigate('/welcome')} >
                            Go Back  
                        </BackButtonStyle>
                        <GoToReportButton variant="contained" onClick={() => navigate('/dashboard/all-reports')} >
                            Go to all reports menu    
                        </GoToReportButton>
                    </ButtonFlexDiv>
                    <Title title={t('dashboard.title')} subtitle={t('executive-summary.subtitle')} />
                    <ExecutiveSummary/>
                    <ButtonFlexDiv>
                        <BackButtonStyle variant="contained" onClick={() => navigate('/welcome')} >
                            Go Back  
                        </BackButtonStyle>
                        <GoToReportButton variant="contained" onClick={() => navigate('/dashboard/all-reports')} >
                            Go to all reports menu    
                        </GoToReportButton>
                    </ButtonFlexDiv>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
