import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from './banner/Banner';
import Benefits from './benefits/Benefits';
import Info from './info/Info';
import Advantage from './advantage/Advantage';
import NotFree from './not-free/NotFree';
import Footer from './footer/Footer';

export default function HomePage() {
    const navigate = useNavigate();
    useEffect(() => {
        if (sessionStorage.getItem('userData')) {
            const user = JSON.parse(sessionStorage.getItem('userData'));
            if (Object.keys(user).length > 0) {
                navigate('/welcome', { replace: true });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Banner />
            <Info />
            <Advantage />
            <Benefits />
            <NotFree />
            <Footer />
        </>
    );
}
