import React from "react";
import { Bar } from "react-chartjs-2";
import { StickyTable, GraphStyle } from "../Expense/styled-component"

const Bargraph = (props) => {
    const labels = props?.chartData.labels;
    const datasets = props?.chartData.dataset;
    const currencyCode = props?.currencyCode;
    return (
        <StickyTable style={{ marginTop: '40px' }}>
            <GraphStyle>
                <Bar
                    data={{
                        labels,
                        datasets

                    }}
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    callback (value, index, values) {
                                        if (parseInt(value, 10) >= 1000) {
                                            return `${currencyCode}${  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                                        } 
                                        return `${currencyCode} ${  value}`;
                                        
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label(tooltipItem, data) {
                                    return `${currencyCode} ${  Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => i > 0 && c !== "." && (a.length - i) % 3 === 0 ? `,${  c}` : c)}`;
                                }
                            }
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: ""
                            },
                            legend: {
                                display: false
                            }
                        },
                        title: {
                            display: true,
                            text: props?.chartData.title,
                            fontSize: 18
                        }
                    }}
                />
            </GraphStyle>
        </StickyTable>
    )
}

export default Bargraph;