import { Box, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import HeadingStyle from '../../../components/HeadingStyle';
import { getAdvantageData } from './constant';
import { BannerImg, CenterHeading, ListItem, SubTitleText, TrialButton } from './styled-components';

export default function HomeAdvantage() {
    const { t } = useTranslation();
    const advantages = getAdvantageData(t);
    return (
        <>
            <CenterHeading>
                <HeadingStyle headLineOne="How" headLineTwo="did we do that?" headLineThree="" />
                <SubTitleText variant="subtitle2">
                    <b>
                        <Trans i18nKey="home-page.advantage-subtitle">
                        By designing a more holistic, <br />
                        one-stop solution that:
                        </Trans>
                    </b>
                </SubTitleText>
            </CenterHeading>
            <Box pt={10}>
                <ListItem>
                    {advantages.map((adv) => (
                        <li key={adv.id}>
                            <Typography variant="subtitle2">{adv.text}</Typography>
                        </li>
                    ))}
                </ListItem>
            </Box>
            <BannerImg>
                <img alt="" src="/static/homeImg/group.png" />
            </BannerImg>
            <TrialButton>
                <LoadingButton size="large" type="submit" variant="contained" href="/register">
                    {t('home-page.start-30-days-trial')}
                </LoadingButton>
            </TrialButton>
        </>
    );
}
