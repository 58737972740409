import { Grid, styled, Link, Button } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
    background: 'url(/static/loginImg/loginBottomLogo.svg) calc(100% - 20px) calc(100% - 20px) no-repeat',
    height:'100%',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: '80px',
    },
}));

export const PageTopRightBg = styled(Grid)(({ theme }) => ({
    position: 'absolute',
    background: 'url(/static/loginImg/loginLeftBottomBg.svg) 120% 40px no-repeat',
    height: 500,
    width: 675,
    top: 0,
    right: 0,
    backgroundSize: '100%',
    transform: 'rotate(180deg)',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        backgroundSize: '400px',
        backgroundPosition: '-50px 100%',
    },
}));

export const ContentStyle = styled('div')(({ theme }) => ({
    padding: '40px 10%',
    [theme.breakpoints.down('xl')]: {
        padding: '100px 7%',
    },
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(4, 2),
    },
    '& h1': {
        width: 400,
        marginBottom: 20,
    },
    '& h2': {
        display: 'none',
    },
}));

export const CardStyle = styled('div')(({ theme }) => ({
    padding: '10px 16px',
    height: '166px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    boxShadow: '0px 8px 24px 1px rgba(0, 0, 0, 0.05)',
    borderRadius: 11,
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.primary.cardHover,
        boxShadow: '0 8px 24px 1px rgba(0, 0, 0, 0.05)',
        '& p': {
            display: 'flex',
        },
        '& h5': {
            display: 'none',
        }
    },
    [theme.breakpoints.between('md', 'lg')]: {
        minHeight: '122px',
    },
    [theme.breakpoints.down('xl')]: {
        // padding: '48px 10px',
        height: '166px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    '& p': {
        fontSize: 19,
        fontWeight: '400',
        marginTop: '14px',
        color: '#155041',
        margin: '10px 0',
        lineHeight: '26px',
        display: 'none',
        minHeight: 80,
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            fontSize: 16,
            minHeight: 'auto',
            lineHeight: '22px',
            marginBottom: '10px'
        }
    },
    '& a': {
        textDecoration: 'none',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& h4': {
        fontSize: 22,
        fontWeight: '700',
        color: theme.palette.primary.main,
        marginBottom: '15px',
        margin: '10px 0',
        [theme.breakpoints.down('xl')]: {
            margin: 0,
            fontSize: 18,
            marginBottom: '15px'
        }
    },
    '& h5': {
        border: '1px solid #00773E',
        textDecoration: 'none',
        borderRadius: '50px',
        padding: '4px 10px',
        fontSize: '12px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    '& svg': {
        width: 40,
        height: 40,
        fill: theme.palette.primary.main,
        marginTop: 21,
        marginBottom: 21,
        [theme.breakpoints.down('xl')]: {
            marginTop: 10,
            marginBottom: 9,
        },
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
}));

export const BackButton = styled(Link)(() => ({
    textDecoration: 'none',
    fontSize: '18px',
    margin: '0 12px',
    fontWeight: '500',
    minWidth: '64px',
    padding: '7px 21px',
    border: '1px solid rgba(0, 119, 62, 0.5)',
    color: '#00773E',
    width: '100%',
    textAlign: ' center',
    height: '48px',
    borderRadius: '8px'
}))

export const CheckBoxLabel = styled('h4')(() => ({
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00773E',
    marginLeft: '10px'
}));

export const ButtonFlexDiv= styled('div')(()=>({
    display:'flex',
    justifyContent:'space-between',
    marginTop: 20,
    marginLeft: 32
}))

export const BackButtonStyle = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' right',
}))

export const GoToReportButton = styled(Button)(() => ({
    color: '#fff',
    marginBottom: '15px',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    textAlign: ' right',
}))