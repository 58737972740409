import moment from 'moment';
import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dashboard from '../../../constants/services/dashboard';
// components
import { getPositiveNumber, getCurrencySymbol, getNegativeNumber } from '../../../utils/calCommonFunction';
import {
    TableStyle,
    GridExtraSpacing,
    ExecutiveTbStart,
    ExecutiveTitleStyle,
    ExecutiveTbEnd,
    ExecutiveDivStyle,
    ExecutiveDivSpacing,
    NoDataTd,
    LoaderGif,
    TotalAmountDiv,
} from './styled-component';

export default function ExecutiveRecurringAndLastTable() {
    const [tableLoader, setTableLoader] = useState(false);
    const [recurringPaymentData, setRecurringPaymentData] = useState([]);
    const [lastFiveTransactionData, setLastFiveTransactionData] = useState([]);

    const currencySymbol = getCurrencySymbol();
    const { t } = useTranslation();

    const getExecutiveSummaryData = async () => {
        const lasttransaction = 'lastfivetransaction?type=month';
        const recurringpayment = 'recurringpayment?type=month';

        let lastFiveTransaction;
        let recurringPaymentMonth;

        try {
            lastFiveTransaction = await getIncInvestmentData(lasttransaction);
            setLastFiveTransactionData(lastFiveTransaction);
        }
        catch (error) {
            console.log("error", error)
        }

        try {
            recurringPaymentMonth = await getIncInvestmentData(recurringpayment);
            setRecurringPaymentData(recurringPaymentMonth);
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const getIncInvestmentData = async (selectdata) => {
        setTableLoader(true)
        try {
            const response = await dashboard.getDashboardCategoryDetails(selectdata);
            const data = await response.data;
            setTableLoader(false)
            return data;
        } catch (error) {
            console.log(error);
            setTableLoader(false)
        }
    };

    useEffect(() => {
        getExecutiveSummaryData()
    }, [])

    return (
        <>
            <ExecutiveDivStyle>
                <ExecutiveDivSpacing style={{ padding: '24px 0px' }}>
                    <Grid
                        container
                        spacing={4}
                        justifyContent="center"
                        alignItems="flex-start"
                        style={{ margin: '0px', width: '100%' }}
                    >
                        <GridExtraSpacing item xs={12} sm={6} md={6}>
                            <ExecutiveTitleStyle>{t('executive-summary.top-3-recurring-payments')}</ExecutiveTitleStyle>
                            <ExecutiveTitleStyle>&nbsp;</ExecutiveTitleStyle>
                            <TableStyle>
                                <thead>
                                    <tr>
                                        <ExecutiveTbStart style={{ width: '40%' }}>{t('executive-summary.payee')}</ExecutiveTbStart>
                                        <th style={{ textAlign: 'start', width: '30%' }}>{t('executive-summary.description')}</th>
                                        <th style={{ textAlign: 'end', width: '30%' }}>{t('executive-summary.amount')}</th>
                                    </tr>
                                </thead>
                                {tableLoader ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {recurringPaymentData?.length > 0 ? (
                                            recurringPaymentData?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.payee}</td>
                                                    <td>{item.category}</td>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{getNegativeNumber(item.amount)}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </TableStyle>
                        </GridExtraSpacing>
                        <GridExtraSpacing item xs={12} sm={6} md={6}>
                            <ExecutiveTitleStyle>{t('executive-summary.last-5-Transactions-this-month')}</ExecutiveTitleStyle>
                            <ExecutiveTitleStyle>{t('executive-summary.this-month')}</ExecutiveTitleStyle>
                            <TableStyle>
                                <thead>
                                    <tr>
                                        <ExecutiveTbStart style={{ width: '40%' }}>{t('executive-summary.date')}</ExecutiveTbStart>
                                        <th style={{ textAlign: 'start', width: '30%' }}>{t('executive-summary.description')}</th>
                                        <th style={{ textAlign: 'end', width: '30%' }}>{t('executive-summary.amount')}</th>
                                    </tr>
                                </thead>
                                {tableLoader ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}>
                                                <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        {lastFiveTransactionData?.length > 0 ? (
                                            lastFiveTransactionData?.map((item, indx) => (
                                                <tr key={indx}>
                                                    <td>
                                                        {moment
                                                            .parseZone(item?.transaction_date_original)
                                                            .format('DD-MMM')}
                                                    </td>
                                                    <td>{item.category}</td>
                                                    <ExecutiveTbEnd>
                                                        <TotalAmountDiv>
                                                            {currencySymbol}{' '}
                                                            <span>{getPositiveNumber(item.amount)}</span>
                                                        </TotalAmountDiv>
                                                    </ExecutiveTbEnd>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <NoDataTd colSpan={3}>{t('executive-summary.no-data-found')}</NoDataTd>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </TableStyle>
                        </GridExtraSpacing>
                    </Grid>
                </ExecutiveDivSpacing>
            </ExecutiveDivStyle>
        </>
    );
}
