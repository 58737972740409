import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FinancialTitle from '../FinancialTitle';
import ListAddCategory from '../ListAddCategory';
import Iconify from '../../../../../components/Iconify';
import { AccordionStyle, HeadArea } from './styled-components';
import { FormContext } from '../../context/StepperContext';

export default function Step3() {
    const {
        categoriesMasterData: categoryGroups,
        openAccordionsStep3,
        onCategoryAccordionClick,
    } = useContext(FormContext);
    const { t } = useTranslation();

    return (
        <>
            <HeadArea>
                <FinancialTitle
                    heading={t('step3.heading')}
                    subheading={t('step3.sub-heading')}
                    description={t('step3.description')}
                />
            </HeadArea>
            <Grid container>
                {categoryGroups?.map((categoryGroup) => (
                    <AccordionStyle
                        key={categoryGroup.id}
                        expanded={openAccordionsStep3.includes(categoryGroup.name)}
                        onChange={onCategoryAccordionClick(categoryGroup.name)}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{categoryGroup.name}</Typography>
                            <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 32, height: 32 }} />
                            <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 32, height: 32 }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListAddCategory
                                categoryGroupId={categoryGroup.id}
                                categories={categoryGroup.categories}
                            />
                        </AccordionDetails>
                    </AccordionStyle>
                ))}
            </Grid>
        </>
    );
}
