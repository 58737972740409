import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const SaveSection = styled(Box)(() => ({
    position: 'relative',
}));

export const BannerImg = styled('img')(({ theme }) => ({
    width: '40%',
    position: 'absolute',
    top: 120,
    [theme.breakpoints.down('md')]: {
        position: 'static',
        width: '80%',
        marginTop: 60,
        marginBottom: -100,
    },
}));

export const Headline = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
        marginLeft: '10px',
    },
}));

export const SubPoints = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    [theme.breakpoints.down('md')]: {
        marginRight: '10px',
        marginLeft: '35px',
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    listStyle: 'none',
    position: 'relative',
    '& li': {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            top: '5px',
            background: 'url("/static/homeImg/img1.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: '10.96px',
            height: '11.06px',
            [theme.breakpoints.down('md')]: {
                left: '10px',
            },
        },
        '&:nth-child(2)': {
            '&:before': {
                background: 'url("/static/homeImg/img2.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(3)': {
            '&:before': {
                background: 'url("/static/homeImg/img3.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
        '&:nth-child(4)': {
            '&:before': {
                background: 'url("/static/homeImg/img4.png") no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            },
        },
    },
}));

export const ThinkSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    margin: '15%',
    border: `solid 3px ${theme.palette.primary.main}`,
    display: 'block',
    padding: '100px 150px',
    borderRadius: 15,
    background: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('xl')]: {
        margin: '25% 25% 10%',
    },
    [theme.breakpoints.down('lg')]: {
        padding: '80px 50px',
    },
    [theme.breakpoints.down('md')]: {
        margin: '10%',
        '& Box': {
            transform: 'scale(0.7)',
        },
    },
    [theme.breakpoints.down('sm')]: {
        margin: '-100px 10% 0',
        padding: '40px 10px',
    },
    textAlign: 'center',
    '& h3': {
        fontWeight: '700',
        lineHeight: '48px',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down('sm')]: {
            lineHeight: '26px',
            fontSize: 20,
        },
    },
    '& h6': {
        padding: '0 10% 27px',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            lineHeight: '16px',
            fontSize: 12,
        },
    },
}));

export const TopLeftBox = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
}));

export const TopRightBox = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    right: 0,
}));

export const BottomLeftBox = styled(Box)(() => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
}));

export const BottomRightBox = styled(Box)(() => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
}));

export const TopLeftBoxOne = styled(Box)(({ theme }) => ({
    width: 178,
    height: 78,
    position: 'absolute',
    right: -20,
    bottom: -10,
    borderRadius: 10,
    background: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
        width: 100,
        height: 50,
        right: -30,
        bottom: -20,
    },
}));

export const TopLeftBoxSec = styled(Box)(({ theme }) => ({
    width: 60,
    height: 29,
    position: 'absolute',
    right: -50,
    bottom: 54,
    borderRadius: 5,
    background: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 18,
        right: -30,
        bottom: 13,
    },
}));

export const TopRightBoxOne = styled(Box)(({ theme }) => ({
    width: 313,
    height: 282,
    position: 'absolute',
    right: -150,
    bottom: -100,
    borderRadius: 10,
    background: theme.palette.primary.main,
    zIndex: -30,
    [theme.breakpoints.down('md')]: {
        height: 333,
        bottom: -128,
    },
    [theme.breakpoints.down('sm')]: {
        width: 153,
        height: 147,
        right: -75,
        bottom: -73,
    },
}));

export const TopRightBoxSec = styled('img')(({ theme }) => ({
    minWidth: 411,
    position: 'absolute',
    right: -100,
    bottom: -55,
    [theme.breakpoints.down('sm')]: {
        minWidth: 153,
        right: 0,
        bottom: -35,
    },
}));

export const BottomLeftBoxOne = styled(Box)(({ theme }) => ({
    width: 80,
    height: 80,
    position: 'absolute',
    right: 70,
    bottom: 45,
    borderRadius: 10,
    background: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
        width: 53,
        height: 53,
        right: 10,
        bottom: 0,
    },
}));

export const BottomLeftBoxSec = styled('img')(({ theme }) => ({
    minWidth: 300,
    position: 'absolute',
    right: -170,
    bottom: -135,
    [theme.breakpoints.down('sm')]: {
        minWidth: 100,
        right: -60,
        bottom: -50,
    },
}));

export const BottomLeftBoxThe = styled(Box)(({ theme }) => ({
    width: 102,
    height: 102,
    position: 'absolute',
    right: -230,
    bottom: -105,
    borderRadius: '0 0 15px 15px',
    background: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
        width: 53,
        height: 53,
        right: -95,
        bottom: -45,
    },
}));

export const BottomRightBoxOne = styled(Box)(({ theme }) => ({
    width: 122,
    height: 177,
    position: 'absolute',
    right: -90,
    bottom: -26,
    borderRadius: 15,
    background: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
        width: 53,
        height: 100,
        right: -30,
        bottom: -25,
    },
}));

export const BottomRightBoxSec = styled(Box)(({ theme }) => ({
    width: 45,
    height: 45,
    position: 'absolute',
    right: -113,
    bottom: -47,
    borderRadius: 10,
    background: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
        width: 30,
        height: 30,
        right: -35,
        bottom: -25,
    },
}));