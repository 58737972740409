import React, { useState, createContext } from 'react';

export const AuthContext = createContext({
    auth: JSON.parse(sessionStorage.getItem('userData')),
    currencyMasterData: JSON.parse(localStorage.getItem('currencyData')) || [],
});

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(JSON.parse(sessionStorage.getItem('userData')));
    const [currencyMasterData, setCurrencyMasterData] = useState(JSON.parse(localStorage.getItem('currencyData')) || []);

    return (
        <AuthContext.Provider value={{
            auth, setAuth, currencyMasterData, setCurrencyMasterData
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
