import { styled, Typography } from '@mui/material';

const TitleStyle = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    '& .MuiTypography-h6': {
        fontWeight: 700,
        color: theme.palette.primary.light,
        paddingBottom: 8,
    },
    '& .MuiTypography-h3': {
        fontWeight: 700,
        paddingBottom: 45,
        lineHeight: '48px',
    },
}));

export default function FinancialTitle(props) {
    return (
        <TitleStyle>
            {props.heading && <Typography variant="h6">{props.heading}</Typography>}
            {props.subheading && <Typography variant="h3">{props.subheading}</Typography>}
            {props.description && <Typography variant="subtitle1">{props.description}</Typography>}
        </TitleStyle>
    );
}
