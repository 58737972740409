import { styled, Box, Typography } from '@mui/material';

export const TrialButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    '& a': {
        fontWeight: 400,
        fontSize: 16,
    },
    '& button': {
        padding: '0 70px',
        [theme.breakpoints.down('md')]: {
            padding: '0 10px',
            marginBottom: -50,
        },
    },
}));

export const BannerImg = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    padding: '66px 0px 44px',
    '& img': {
        width: '100%',
    },
}));

export const ListItem = styled('ul')(({ theme }) => ({
    maxWidth: '100%',
    listStyle: 'none',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000',
    padding: '0 6% 0 16%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    '& li': {
        minWidth: '30%',
        position: 'relative',
        paddingRight: '12%',
        [theme.breakpoints.down('md')]: {
            minWidth: '100%',
            marginBottom: 30,
        },
        '& h6': {
            marginLeft: '60px',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: '0px',
            top: '-12px',
            background: 'url("/static/homeImg/pt1.png") no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            width: '45.6px',
            height: '43.24px',
        },
        '&:nth-child(2)': {
            '&:before': {
                background: 'url("/static/homeImg/pt2.png") no-repeat',
            },
        },
        '&:nth-child(3)': {
            '&:before': {
                background: 'url("/static/homeImg/pt3.png") no-repeat',
            },
        },
    },
}));

export const CenterHeading = styled('div')(({ theme }) => ({
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    '& div': {
        display: 'inline-block',
        width: 'auto',
        textAlign: 'left',
        paddingLeft: 90,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 60,
        },
    },
}));

export const SubTitleText = styled('Typography')(({ theme }) => ({
    paddingLeft: 52,
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        marginLeft: -80,
    },
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 12,
        marginLeft: 0,
    },
}));