// @mui
import { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { Wrapper, PageTopRightBg, DivTableStyle, PaymentTitle, FullTableStyle, ProjectInputNumber, BackButtonStyle, ButtonText, FullContentStyle, CalculatorSubInput, AddMoreButton, ProjectTotalValue, CalculatorInputNumber, ExtraTdSpacing, TotalFlowColumn, BudgetInput, AddMoreTbBUtton } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, removeNumberFormatting } from '../../../utils/calCommonFunction';

export default function CalculatorsPage() {
    const [projectName, setProjectName] = useState("");
    const [projectBudgetDetails, setProjectBudgetDetails] = useState([{
        category: '',
        subcategory: '',
        unitprice: '',
        ofunitvalue: '',
        subtotal: '',
        budget: '',
        actual: '',
        totalprojectvalue: ''
    }]);
    const [projectSubCategory, setProjectSubCategory] = useState({})

    const { t } = useTranslation();
    const navigate = useNavigate();

    const totalActualValue = projectBudgetDetails.reduce((acc, obj) => { return acc + Number(obj?.actual); }, 0);
    const totalBudgetValue = projectBudgetDetails.reduce((acc, obj) => { return acc + Number(obj?.budget); }, 0);
    const totalProjectValue = projectBudgetDetails.reduce((acc, obj) => { return acc + Number(obj?.totalprojectvalue); }, 0);

    const getProjectBudgetDetails = async () => {
        const getProjectBudgetData = await calculator.getUserCalculator("savings-goal");
        if (getProjectBudgetData.data) {
            const loanJsonData = getProjectBudgetData.data?.calcJson;
            setProjectName(loanJsonData.projectName);
            setProjectBudgetDetails([{
                category: loanJsonData.category,
                subcategory: loanJsonData.subcategory,
                unitprice: loanJsonData.unitprice,
                ofunitvalue: loanJsonData.ofunitvalue,
                actual: loanJsonData.actual,
            }])
        }
    }

    useEffect(() => {
        getProjectBudgetDetails();
    }, []);

    const resetFields = () => {
        setProjectBudgetDetails([])
    }

    const savePresentData = async () => {
        try {
            const postData = {
                "projectname": projectName,
                "category": projectBudgetDetails.category,
                "subcategory": projectBudgetDetails.subcategory,
                "unitprice": projectBudgetDetails.unitprice,
                "ofunitvalue": projectBudgetDetails.ofunitvalue,
                "actual": projectBudgetDetails.actual,
            }
            const embeddedDataObj = {
                "calcType": "project-budget",
                "calcJson": postData
            };

            await calculator.addUserCalculator(embeddedDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Payment to be received in the future",
                "Value": ""
            },
            {
                "Description": "When will the payment be received?",
                "Value": ""
            },
            {
                "Description": "What is the current date?",
                "Value": ""
            },
            {
                "Description": "Current interest rate on similar term asset",
                "Value": ""
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Present Value",
                "Value": ""
            }
        ];
        downloadExcel(postData);
    }

    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "LoanPayment.xlsx");
    };

    const addCategoryInputFields = () => {
        setProjectBudgetDetails([...projectBudgetDetails, {
            category: '',
            subcategory: '',
            unitprice: '',
            ofunitvalue: '',
            subtotal: '',
            budget: '',
            actual: '',
            totalprojectvalue: ''
        }])
    }

    const projectBudgetTargetValue = (i, e) => {
        const projectValues = [...projectBudgetDetails];
        projectValues[i][e.target.name] = e.target.value;

        const subtotalElement = projectValues[i].ofunitvalue * projectValues[i].unitprice;
        const totalActualInput = subtotalElement - projectValues[i].actual
        const totalseleted = subtotalElement - projectValues[i].actual;

        projectValues[i].subtotal = subtotalElement;
        projectValues[i].budget = totalseleted;
        projectValues[i].totalprojectvalue = totalActualInput;

        setProjectBudgetDetails(projectValues);
    }

    return (
        <Page title={t('project-budget.title')}>
            <Wrapper>
                <PageTopRightBg />
                <FullContentStyle>
                    <BackButtonStyle
                        variant="contained"
                        onClick={() => navigate('/calculators')}>{t('debt-repayment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('project-budget.calculator')} subtitle={t('project-budget.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <DivTableStyle>
                                <PaymentTitle>{t('debt-repayment.description')}</PaymentTitle>
                                <FullTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>Project Name</td>
                                            <BudgetInput>
                                                <span style={{ padding: '5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    name="projectname"
                                                    value={projectName}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setProjectName(e.target.value);
                                                    }}
                                                />
                                            </BudgetInput>
                                            <AddMoreTbBUtton><AddMoreButton onClick={addCategoryInputFields}>Add Category</AddMoreButton></AddMoreTbBUtton>
                                        </tr>
                                        <ExtraTdSpacing>
                                            <td>{""}</td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <th>Category</th>
                                            <th>Sub Category</th>
                                            <th>Unit Price</th>
                                            <th># of Units</th>
                                            <th>Sub-Total</th>
                                            <th>Budget</th>
                                            <th>Actual</th>
                                            <th>Good (Bad)</th>
                                        </tr>
                                        {projectBudgetDetails.map((item, index) => (
                                            <>
                                                <tr key={index}>
                                                    <CalculatorInputNumber>
                                                        <span style={{ padding: '5px' }}>{''}</span>
                                                        <Input
                                                            type='text'
                                                            name="category"
                                                            value={item.category}
                                                            placeholder="Add category"
                                                            onChange={(e) => {
                                                                projectBudgetTargetValue(index, e)
                                                            }}
                                                        />
                                                    </CalculatorInputNumber>
                                                    <CalculatorInputNumber>
                                                        <span style={{ padding: '5px' }}>{''}</span>
                                                        <Input
                                                            type='text'
                                                            name="subcategory"
                                                            value={item.subcategory}
                                                            placeholder="Add subcategory"
                                                            onChange={(e) => {
                                                                projectBudgetTargetValue(index, e)
                                                            }}
                                                        />
                                                    </CalculatorInputNumber>
                                                    <CalculatorSubInput>
                                                        <span>$</span>
                                                        <Input
                                                            type='text'
                                                            placeholder={'0'}
                                                            name="unitprice"
                                                            value={item.unitprice}
                                                            onChange={(e) => {
                                                                projectBudgetTargetValue(index, e)
                                                            }}
                                                        />
                                                    </CalculatorSubInput>
                                                    <CalculatorSubInput>
                                                        <span style={{ padding: '5px' }}>{''}</span>
                                                        <Input
                                                            type='text'
                                                            placeholder={'0'}
                                                            name="ofunitvalue"
                                                            value={item.ofunitvalue}
                                                            onChange={(e) => {
                                                                projectBudgetTargetValue(index, e)
                                                            }}
                                                        />
                                                    </CalculatorSubInput>
                                                    <td><ProjectTotalValue>$ <span>{item.subtotal}</span></ProjectTotalValue></td>
                                                    <td><ProjectTotalValue>$ <span>{item.budget}</span> </ProjectTotalValue></td>
                                                    <ProjectInputNumber>
                                                        <span>$</span>
                                                        <Input
                                                            type='text'
                                                            placeholder={'0'}
                                                            name="actual"
                                                            value={item.actual}
                                                            onChange={(e) => {
                                                                projectBudgetTargetValue(index, e)
                                                            }}
                                                        />
                                                    </ProjectInputNumber>
                                                    <td><ProjectTotalValue>$ <span>{item.totalprojectvalue}</span></ProjectTotalValue></td>
                                                </tr>
                                            </>
                                        ))}
                                        <ExtraTdSpacing>
                                            <td>{" "}</td>
                                        </ExtraTdSpacing>
                                        <tr>
                                            <td>Total</td>
                                            <td>{" "}</td>
                                            <td>{" "}</td>
                                            <td>{" "}</td>
                                            <td>{" "}</td>
                                            <TotalFlowColumn><span>$</span><span> {totalBudgetValue}</span></TotalFlowColumn>
                                            <TotalFlowColumn><span>$</span><span> {totalActualValue}</span></TotalFlowColumn>
                                            <TotalFlowColumn><span>$</span><span> {totalProjectValue}</span></TotalFlowColumn>
                                        </tr>
                                    </tbody>
                                </FullTableStyle>
                            </DivTableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('savings-goal.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            // disabled={}
                            onClick={resetFields}
                        >
                            {t('savings-goal.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={savePresentData}
                        >
                            {t('savings-goal.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('savings-goal.export')}
                        </LoadingButton>
                    </ButtonText>

                </FullContentStyle>
            </Wrapper>
        </Page>
    );
}
