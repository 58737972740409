import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import moment from "moment";
import Page from "components/Page";
import transaction from "constants/services/transaction";
import Title from "components/Title";
import dashboard from "constants/services/dashboard";
import { PageTopRightBg, SubmitButton, Wrapper  } from "./styled-components";
import { BackButtonStyle, ContentStyle } from "../styled-component";
import Filter from "./Filter";
import { AmountDiv, LoaderGif, NoDataTd, StickyFirstTh, StickyFirstTd, SpanTotalFlow, StickySecondTd, StickySecondTh, StickyTable, TableStyle, TableWrapper, StickyThirdTd, StickyThirdTh } from "../Expense/styled-component";
import { getMonthNameYear, getCurrencySymbol, getPositiveNumber } from '../../../utils/calCommonFunction';
import { ReportAmountTd, TotalAmountDiv } from "../ExecutiveSummary/styled-component";

export default function DebtPayment() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currencyCode = getCurrencySymbol();

    const [loading, setLoading] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);

    const [accountFilters, setAccountFilters] = useState(null);
    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryId: null,
        subCatId: null,
    });
    const [debtPaymentData, setDebtPaymentData] = useState(null);
    const [linkedAccounts, setLinkedAccounts] = useState([]);

    const updateFilters = (key, value) => {
        const clonedData = {
            ...filtersData,
            [key]: value,
        };
        if (key === 'categoryId') {
            clonedData.subCatId = '';
        }
        setFiltersData(clonedData);
    };

    const getFilters = async () => {
        const response = await transaction.getTransactionFilters();
        let { transactionFilters } = response;
        if (transactionFilters) {
            transactionFilters = Object.fromEntries(Object.entries(transactionFilters).filter(([key]) => ['Credit Card', 'Loan'].includes(key)));
            setAccountFilters(transactionFilters);
        }
    };

    const fetchData = async () => {
        try {
            const filters = [];
            setLoading(true);
            setTableLoader(true);
            // eslint-disable-next-line no-restricted-syntax, prefer-const
            for (let [key, value] of Object.entries(filtersData)) {
                if (value) {
                    if (key === 'categoryId' && !filtersData.subCatId) {
                        key = 'account';
                        const filteredData = Object.keys(accountFilters)
                            .filter(key => value.includes(key))
                            .reduce((obj, key) => {
                                obj[key] = accountFilters[key];
                                return obj;
                            }, {});
                        if (filteredData) {
                            const filteredAccounts = [...Object.values(filteredData).flat().map((a) => a.accountId)]
                            value = filteredAccounts.join(',');
                        }
                    }
                    if (key === 'subCatId' && value) {
                        key = 'account';
                        value = value.id;
                        
                    }
                    filters.push(`${key}=${value}`);
                }
            }
            const query = filters.join('&');
    
            const response = await dashboard.getDebtPaymentDetails(query);
            const {debtPaymentData, linkedAccounts: userLinkedAccounts} = response;
            setDebtPaymentData(debtPaymentData || null);
            setLinkedAccounts(userLinkedAccounts || null);
            setLoading(false);
            setTableLoader(false);
        } catch (error) {
            setLoading(false);
            setTableLoader(false);
        }
    };

    useEffect(() => {
        getFilters();
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTableRow = (linkedAccount) => {
        const rowData = [];
        Object.entries(debtPaymentData).forEach(([, value]) => {
            rowData.push(value[linkedAccount]);
        });
        // eslint-disable-next-line no-return-assign
        const total = rowData.reduce((acc, value) => acc += value, 0);
        const monthsCount = Object.keys(debtPaymentData).length;
        const average = total / monthsCount;
        return (<tr>
            <StickyFirstTd>{linkedAccount}</StickyFirstTd>
            {
                rowData.map((value, index) => 
                    <ReportAmountTd key={index}>
                        <TotalAmountDiv>
                            {currencyCode}{' '}
                            <span>
                                {getPositiveNumber(value)}
                            </span>
                        </TotalAmountDiv>
                    </ReportAmountTd>
                )
            }
            <StickySecondTd className="highlighted-td">
                <TotalAmountDiv
                    style={{
                        width: 120,
                        float: 'right',
                    }}
                >
                    {currencyCode}{' '}
                    <span>
                        {getPositiveNumber(total)}
                    </span>
                </TotalAmountDiv>
            </StickySecondTd>
            <StickyThirdTd className="highlighted-td">
                <TotalAmountDiv
                    style={{
                        width: 120,
                        float: 'right',
                    }}
                >
                    {currencyCode}{' '}
                    <span>
                        {getPositiveNumber(average)}
                    </span>
                </TotalAmountDiv>
            </StickyThirdTd>
        </tr>)
    };

    const getTotalRowData = () => {
        const totalRowData = [];
        Object.entries(debtPaymentData).forEach(([, value]) => {
            // eslint-disable-next-line no-return-assign
            const columnTotal =  Object.entries(value).reduce((acc, [, amount]) => acc += amount, 0);
            totalRowData.push(columnTotal);
        });
        // eslint-disable-next-line no-return-assign
        const total = totalRowData.reduce((acc, amount) => acc += amount, 0);
        const monthsCount = Object.keys(debtPaymentData).length;
        const average = total / monthsCount;
        return (
            <>
                <tr>
                    <StickyFirstTd style={{ height: '51px', fontSize: '16px', fontWeight: '600' }}>
                        {t('debt-payment.total')}
                    </StickyFirstTd>
                    {
                        totalRowData.map((item, index) => <td key={index}>
                            <AmountDiv>
                                {currencyCode}
                                <span>{getPositiveNumber(item)}</span>
                            </AmountDiv>
                        </td>)
                    }
                    <StickySecondTd className="highlighted-td">
                        <AmountDiv style={{ width: 120, float: 'right' }}>
                            {currencyCode}{' '}
                            <span>
                                {getPositiveNumber(total)}
                            </span>
                        </AmountDiv>
                    </StickySecondTd>
                    <StickyThirdTd className="highlighted-td">
                        <AmountDiv style={{ width: 120, float: 'right' }}>
                            {currencyCode}{' '}
                            <span>
                                {getPositiveNumber(average)}
                            </span>
                        </AmountDiv>
                    </StickyThirdTd>
                </tr>
            </>
        );
    }

    return (
        <Page title={t('debt-payment.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle
                        variant="contained"
                        onClick={() => navigate('/dashboard/all-reports')}>{t('net-worth.back')}
                    </BackButtonStyle>
                    <Title title={t('debt-payment.dashboard')} subtitle={t('debt-payment.subtitle')} />

                    <Grid container pt={5} pb={5} mt={0}>
                        <Grid item md={10.5} xs={12}>
                            <Filter
                                filtersData={filtersData}
                                updateFilters={updateFilters}
                                debtAccountFilters={accountFilters}
                            />
                        </Grid>
                        <Grid item md={1.5} xs={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                            <SubmitButton loading={loading} size="large" variant="contained" onClick={() => fetchData()}>
                                Go
                            </SubmitButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <StickyTable>
                                <TableWrapper>
                                    <TableStyle>
                                        {
                                            tableLoader ?
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <LoaderGif alt="homeInfo" src="/static/animation_loader.gif" />
                                                        </td>
                                                    </tr>
                                                </tbody> :
                                                <>
                                                    {
                                                        debtPaymentData === null &&
                                                       <tbody>
                                                           <tr>
                                                               <NoDataTd colSpan={4}>{t('executive-summary.no-data-found')}</NoDataTd>
                                                           </tr>
                                                       </tbody> 
                                                    }
                                                    {
                                                        debtPaymentData !== null &&
                                                        <>
                                                            <thead>
                                                                <tr>
                                                                    <StickyFirstTh style={{ width: '120px !important', minWidth: '120px !important' }}>
                                                                        {t('debt-payment.category')}
                                                                    </StickyFirstTh>
                                                                    {
                                                                        Object.keys(debtPaymentData).map((key, index) => 
                                                                            <th style={{ textAlign: 'end',  }} key={index}>{getMonthNameYear(key)}</th>
                                                                        )
                                                                    }
                                                                    <StickySecondTh className="highlighted-th">
                                                                        {t('debt-payment.total')}
                                                                    </StickySecondTh>
                                                                    <StickyThirdTh className="highlighted-th">
                                                                        {t('debt-payment.average')}
                                                                    </StickyThirdTh>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    linkedAccounts.map((linkedAccount) => getTableRow(linkedAccount))
                                                                } 
                                                                
                                                                {getTotalRowData()}
                                                            </tbody>
                                                        </>
                                                    }
                                                </>
                                        }
                                    </TableStyle>
                                </TableWrapper>
                            </StickyTable>
                        </Grid>
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>
    )
}