import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import dashboard from '../../../constants/services/dashboard';
import Bargraph from '../Graphs/Bargraph';
import { CHARTCOLOR } from '../constant';
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import CreditCardFilter from '../filter/CreditCardFilter';
import { NoDataTd, ButtonText, ReportAmountTd, TotalAmountDiv } from '../ExecutiveSummary/styled-component';
import {
    removeEmpty,
    getNegativeNumber,
    getMonthNameYear,
    getCurrencySymbol,
} from '../../../utils/calCommonFunction';
import {
    Wrapper,
    PageTopRightBg,
    ContentStyle,
    SubmitButton,
    TableStyle,
    BackButtonStyle,
    StickyFirstTh,
    StickySecondTh,
    StickyThirdTh,
    TableWrapper,
    StickyFourthTh,
    StickyFourthTd,
    StickyFirstTd,
    StickySecondTd,
    StickyThirdTd,
    StickyTable,
    LoaderGif,
    AmountDiv,
    AntSwitch,
} from './styled-component';

export default function Expense(props) {
    const [expenseData, setExpenseData] = useState([]);
    const [expenseGroupData, setExpenseGroupData] = useState([]);
    const [monthColumn, setMonthColumn] = useState([]);
    const [monthWiseAverage, setMonthWiseAverage] = useState();
    const [monthWiseTotal, setMonthWiseTotal] = useState([]);
    const [expenseMonthTotal, setExpenseMonthTotal] = useState();
    const [tableLoader, setTableLoader] = useState(false);
    const [isDiscretFlag, setIsDiscretFlag] = useState([])
    const [showGraph, setShowGraph] = useState(true);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    const [discretinaryDataLength, setDiscretinaryDataLength] = useState(null);

    const location = useLocation();
    const currencyCode = getCurrencySymbol();
    const { reportName } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [filtersData, setFiltersData] = useState({
        fromMonth: moment.parseZone().format('YYYY-MM'),
        compareMonth: moment.parseZone().startOf('year').format('YYYY-MM'),
        categoryGroupId: location?.state?.categoryGroupId || null,
        categoryId: null,
        subCatId: null,
        type: 2,
    });

    const updateFilters = (key, value) => {
        setFiltersData({
            ...filtersData,
            [key]: value,
        });
    };

    const getTotalSumofData = (item) => {
        const sumofdata = expenseGroupData[item].reduce((acc, item) => Number(acc) + Number(item), 0);
        return sumofdata;
    };

    const getExpenseCategoryData = async () => {
        const filtersDataObj = removeEmpty(filtersData);
        setTableLoader(true);

        const response = await dashboard.getDashboardData(filtersDataObj);
        const data = response?.categoriesData;
        setTableLoader(false);

        const groupedData = [];
        const groupedDataTotal = [];
        const groupedDataMonthlyTotal = [];
        const totalExpenseValue = [];
        const isDiscretionaryFlag={}
        const monthRange = Object.keys(data);
        const monthDifference = monthRange?.length;

        setDiscretinaryDataLength(monthDifference);
        monthRange.sort();
        setMonthColumn(monthRange);

        // eslint-disable-next-line
        monthRange.map((item) => {
            let totalAsset = 0;
            let totalExpense = 0;
            let assetName = '';
            let cateTotal = 0;

            data[item].forEach((a) => {
                assetName = a.name;
                groupedData[a.name] = groupedData[a.name] || [];
                groupedData[a.name].push(a.values);
                isDiscretionaryFlag[a.name] = a.discretionaryflag
                totalAsset += Number(a.values);
                cateTotal = Number(groupedDataTotal[a.name] ? groupedDataTotal[a.name] : 0) + Number(a.values);
                totalExpense += Number(a.values);
                groupedDataTotal[assetName] = cateTotal;
            });
            groupedDataMonthlyTotal.push(totalAsset);
            totalExpenseValue.push(totalExpense);
            assetName = '';
        });

        const ObjectTotalData = Object.values(groupedDataTotal);

        Object.values(groupedData).forEach((item, idx) => item.push(ObjectTotalData[idx]));
        const sortedValues = Object.entries(groupedData).sort((a, b) => a[1][a[1].length - 1] - b[1][b[1].length - 1]);
        let topExpenses = [];
        if (reportName === 'top-five-expense') {
            topExpenses = sortedValues.slice(0, 5).flat();
        } else {
            topExpenses = sortedValues.slice(0, 10).flat();
        }
        const items = {};
        let i = 0;
        while (i < topExpenses.length) {
            topExpenses[i + 1].pop();
            items[topExpenses[i]] = topExpenses[i + 1];
            i += 2;
        }

        const multipleArraySum = (arrays) =>
            arrays.reduce(
                (acc, array) => acc.map((sum, i) => Number(sum) + Number(array[i])),
                new Array(arrays[0]?.length).fill(0)
            );
        const expenseDataKey = Object.values(items);
        const expenseElement = Object.keys(items);

        const expenseavgTotal = expenseDataKey.flat().reduce((acc, item) => Number(item) + Number(acc)) / monthDifference;
        const expenseMonthSum = multipleArraySum(expenseDataKey);
        const monthTotal = expenseTotalSum(expenseMonthSum);
        
        setIsDiscretFlag(isDiscretionaryFlag)
        setExpenseGroupData(groupedData);
        setMonthWiseAverage(expenseavgTotal);
        setExpenseMonthTotal(monthTotal);
        setMonthWiseTotal(expenseMonthSum);
        setExpenseData(expenseElement);

        const graphData = getGraphData(items, monthRange);
        setChartData(graphData);
    };

    const expenseTotalSum = (array) => {
        const result = array.reduce((acc, val) => acc + val, 0);
        return result;
    };

    const getAvgSumofData = (item) => {
        const totalval = getTotalSumofData(item);
        const totalAvg = totalval / discretinaryDataLength;
        return getNegativeNumber(totalAvg?.toFixed(2));
    };

    const getFilterData = () => {
        getExpenseCategoryData();
    };

    const getGraphData = (data, monthrange) => {
        const categoryName = Object.keys(data);
        const catchart = Object.values(data);
        const labels = categoryName;
        const dataset = [];

        monthrange.forEach((item, index) => {
            const dataitem = { label: getMonthNameYear(item), data: [], backgroundColor: CHARTCOLOR[index] };
            dataset.push(dataitem);
        });

        for (let i = 0; i < catchart.length; i += 1) {
            const item = catchart[i];
            const monthData = item;

            for (let j = 0; j < dataset.length; j += 1) {
                let reqvalue = Number(monthData[j]);
                if (reqvalue < 0) {
                    reqvalue = Number(-reqvalue);
                }
                if (reqvalue > 0) {
                    reqvalue = Number(reqvalue);
                }
                dataset[j].data.push(reqvalue);
            }
        }
        // eslint-disable-next-line
        return { labels, dataset, title: t(`${reportName}.subtitle`) };
    };

    useEffect(() => {
        getExpenseCategoryData();
    }, []);

    return (
        <>
            <Page title={t(`${reportName}.title`)}>
                <Wrapper>
                    <PageTopRightBg />
                    <ContentStyle>
                        <BackButtonStyle variant="contained" onClick={() => navigate('/dashboard/all-reports')}>
                            {t(`${reportName}.back`)}
                        </BackButtonStyle>
                        <Title title={t(`${reportName}.dashboard`)} subtitle={t(`${reportName}.subtitle`)} />

                        <Grid container pt={5} pb={5} mt={0}>
                            <Grid item md={10.5} xs={12}>
                                <CreditCardFilter
                                    filtersData={filtersData}
                                    updateFilters={updateFilters}
                                    categories={[]}
                                />
                            </Grid>
                            <Grid
                                item
                                md={1.5}
                                xs={12}
                                style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}
                            >
                                <SubmitButton
                                    loading={loading}
                                    size="large"
                                    variant="contained"
                                    onClick={() => getFilterData()}
                                >
                                    Go
                                </SubmitButton>
                            </Grid>
                        </Grid>
                        {showGraph ? (
                            <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                                <Grid item xs={12} sm={12} md={12}>
                                    <StickyTable>
                                        <TableWrapper>
                                            <TableStyle className='highlighted-fields'>
                                                {tableLoader ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <LoaderGif
                                                                    alt="homeInfo"
                                                                    src="/static/animation_loader.gif"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    <>
                                                        {expenseData?.length > 0 ? (
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <StickyFirstTh>
                                                                            {t(`${reportName}.category`)}
                                                                        </StickyFirstTh>
                                                                        <StickyFourthTh className="align-left" style={{left:'250px'}}>
                                                                            {t(`${reportName}.discretionary`)}
                                                                        </StickyFourthTh>
                                                                        {monthColumn.map((item, indx) => (
                                                                            <th style={{ textAlign: 'end' }} key={indx}>
                                                                                {getMonthNameYear(item)}
                                                                            </th>
                                                                        ))}
                                                                        <StickySecondTh>
                                                                            {t(`${reportName}.total`)}
                                                                        </StickySecondTh>
                                                                        <StickyThirdTh>
                                                                            {t(`${reportName}.average`)}
                                                                        </StickyThirdTh>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {expenseData.map((item, index) => (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <StickyFirstTd>{item}</StickyFirstTd>
                                                                                <StickyFourthTd style={{left:'250px'}}>
                                                                                    <AntSwitch
                                                                                        disabled
                                                                                        checked={!isDiscretFlag[item]}
                                                                                        inputProps={{ 'aria-label': 'ant design' }}
                                                                                    />
                                                                                </StickyFourthTd>
                                                                                {expenseGroupData[item].map(
                                                                                    (item, indx) => (
                                                                                        <ReportAmountTd key={indx}>
                                                                                            <TotalAmountDiv
                                                                                                style={{
                                                                                                    width: 120,
                                                                                                    float: 'right',
                                                                                                }}
                                                                                            >
                                                                                                {currencyCode}
                                                                                                <span>
                                                                                                    {getNegativeNumber(
                                                                                                        item
                                                                                                    )}
                                                                                                </span>
                                                                                            </TotalAmountDiv>
                                                                                        </ReportAmountTd>
                                                                                    )
                                                                                )}
                                                                                <StickySecondTd>
                                                                                    <TotalAmountDiv
                                                                                        style={{
                                                                                            width: 120,
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {currencyCode}{' '}
                                                                                        <span>
                                                                                            {getNegativeNumber(
                                                                                                getTotalSumofData(item)
                                                                                            )}
                                                                                        </span>
                                                                                    </TotalAmountDiv>
                                                                                </StickySecondTd>
                                                                                <StickyThirdTd>
                                                                                    <TotalAmountDiv
                                                                                        style={{
                                                                                            width: 120,
                                                                                            float: 'right',
                                                                                        }}
                                                                                    >
                                                                                        {currencyCode}{' '}
                                                                                        <span>
                                                                                            {getAvgSumofData(item)}
                                                                                        </span>
                                                                                    </TotalAmountDiv>
                                                                                </StickyThirdTd>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                    {
                                                                        <tr>
                                                                            <StickyFirstTd
                                                                                style={{
                                                                                    height: '51px',
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}
                                                                            >
                                                                                {t(`${reportName}.total`)}
                                                                            </StickyFirstTd>
                                                                            <StickyFourthTd style={{left:'250px'}}>{' '}</StickyFourthTd>
                                                                            {monthWiseTotal.map((item, index) => (
                                                                                <td key={index}>
                                                                                    <AmountDiv>
                                                                                        {currencyCode}
                                                                                        <span>
                                                                                            {getNegativeNumber(item)}
                                                                                        </span>
                                                                                    </AmountDiv>
                                                                                </td>
                                                                            ))}
                                                                            <StickySecondTd>
                                                                                <AmountDiv
                                                                                    style={{
                                                                                        width: 120,
                                                                                        float: 'right',
                                                                                    }}
                                                                                >
                                                                                    {currencyCode}{' '}
                                                                                    <span>
                                                                                        {getNegativeNumber(
                                                                                            expenseMonthTotal
                                                                                        )}
                                                                                    </span>
                                                                                </AmountDiv>
                                                                            </StickySecondTd>
                                                                            <StickyThirdTd>
                                                                                <AmountDiv>
                                                                                    {currencyCode}
                                                                                    <span>
                                                                                        {getNegativeNumber(
                                                                                            monthWiseAverage?.toFixed(2)
                                                                                        )}
                                                                                    </span>
                                                                                </AmountDiv>
                                                                            </StickyThirdTd>
                                                                        </tr>
                                                                    }
                                                                </tbody>
                                                            </>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <NoDataTd colSpan={4}>
                                                                        {t('executive-summary.no-data-found')}
                                                                    </NoDataTd>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </>
                                                )}
                                            </TableStyle>
                                        </TableWrapper>
                                    </StickyTable>
                                </Grid>
                            </Grid>
                        ) : (
                            <Bargraph chartData={chartData} currencyCode={currencyCode} />
                        )}
                        <ButtonText>
                            <LoadingButton
                                fullWidth
                                size="large"
                                variant="contained"
                                // disabled={}
                                onClick={() => setShowGraph(!showGraph)}
                            >
                                {showGraph ? t(`${reportName}.graph`) : t(`${reportName}.view-table`)}
                            </LoadingButton>
                        </ButtonText>
                    </ContentStyle>
                </Wrapper>
            </Page>
        </>
    );
}
