// @mui
import {useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '@mui/material/Input';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// components
import Title from '../../../components/Title';
import Page from '../../../components/Page';
import { EXCEL_STYLE } from '../constant';
import { Wrapper, PageTopRightBg, ContentStyle, PaymentTitle, HeaderDivStyle, HeaderTableStyle, TableStyle, MidSideColumn, TopSideBar, BackButtonStyle, TotalFlow, BudgetInput, TotalFlowValueColumn, TotalAmountDiv, ButtonText, InfoIcon, LightTooltip, BudgetPercentageInput, ExtraSpacing, TotalFlowHeader, BackButton } from '../styled-components';
import calculator from '../../../constants/services/calculator';
import { formatDollarValues, formatZeroValues, removeNumberFormatting, downloadExcelHeader, getCurrencySymbol, calcPMT} from '../../../utils/calCommonFunction';

export default function LoanPayment() {
    const [loanAmount, setLoanAmount] = useState("");
    const [annualRate, setAnnualRate] = useState("");
    const [termsMonth, setTermsMonth] = useState("");
    const [baloonPayment, setBaloonPayment] = useState("");
    const navigate= useNavigate();
    const { t } = useTranslation();
    const currencySymbol = getCurrencySymbol();
    
    const monthlyLoanpayment= calcPMT (Number(annualRate/ 1200), Number(termsMonth), (0-Number(removeNumberFormatting(loanAmount))), Number(removeNumberFormatting(baloonPayment)));
    const repayments= Number(removeNumberFormatting(loanAmount)) - Number(removeNumberFormatting(baloonPayment));
    const totalLoan=  Number(termsMonth) * monthlyLoanpayment + Number(removeNumberFormatting(baloonPayment));
    const totalInterest= totalLoan - repayments - Number(removeNumberFormatting(baloonPayment))
    const baloonElement=Number(removeNumberFormatting(baloonPayment));

    const loanMonthlyRepayment= Number(monthlyLoanpayment.toFixed(2)).toLocaleString("en-US", {minimumFractionDigits:2});
    const principalRepayment= repayments.toLocaleString("en-US", {minimumFractionDigits:2});
    const interestAmount= Number(totalInterest.toFixed(2)).toLocaleString("en-US", {minimumFractionDigits:2});
    const baloonValue=Number(baloonElement).toLocaleString("en-US", {minimumFractionDigits:2});
    const totalLoanPayment=Number(totalLoan.toFixed(2)).toLocaleString("en-US", {minimumFractionDigits:2});

    const getLoanPayamentDetails=async()=>{
        const getLoanPaymentData = await calculator.getUserCalculator("loan-payment");
        if (getLoanPaymentData.data) {
            const loanJsonData = getLoanPaymentData.data?.calcJson;
            setLoanAmount(loanJsonData.loan_amount);
            setAnnualRate(loanJsonData.annual_rate);
            setTermsMonth(loanJsonData.terms_month);
            setBaloonPayment(loanJsonData.baloon_payment);
        }
    }
    
    useEffect(() => {
        getLoanPayamentDetails()
    }, []);

    const handleChanged = (e) => {
        const { name, value } = e.target;
        if (name === 'annual_rate') {
            const value = e.target.value.replace(/[^0-9.-]+/g, '');
            setAnnualRate(value);
        }
        if (name === 'terms_month') {
            setTermsMonth(value.toString().slice(0,3));
        }
        if (name === 'baloon_payment') {
            const value = e.target.value.replace(/[^0-9.-]+/g, '');
            setBaloonPayment(value);
        }
    }

    const resetFields = () => {
        setLoanAmount("");
        setAnnualRate("");
        setTermsMonth("");
        setBaloonPayment("");
    }

    const saveLoanData = async () => {
        try {
            const postData = {
                "loan_amount": loanAmount,
                "annual_rate": annualRate,
                "terms_month": termsMonth,
                "baloon_payment": baloonPayment
            }
            const loanDataObj = {
                "calcType": "loan-payment",
                "calcJson": postData
            };
            await calculator.addUserCalculator(loanDataObj);
            toast.success('Calculation saved successfully');
        } catch (error) {
            toast.error(error.message);
        }
    }

    const exportExcel = () => {
        const postData = [
            {
                "Description": "Loan amount",
                "Value": `${currencySymbol} ${loanAmount}`
            },
            {
                "Description": "Annual interest rate",
                "Value": `${annualRate} %`
            },
            {
                "Description": "Term in months",
                "Value": termsMonth
            },
            {
                "Description": "Balloon payment",
                "Value": `${currencySymbol} ${baloonPayment}`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Monthly loan payment",
                "Value": `${currencySymbol} ${loanMonthlyRepayment}`
            },
            {
                "Description": "",
                "Value": ''
            },
            {
                "Description": "Payment Summary",
                "Value": ""
            },
            {
                "Description": "Principal repayments",
                "Value": `${currencySymbol} ${principalRepayment}`
            },
            {
                "Description": "Baloon repayment",
                "Value": `${currencySymbol} ${baloonValue}`
            },
            {
                "Description": "Interest",
                "Value": `${currencySymbol} ${interestAmount}`
            },
            {
                "Description": "Total payment",
                "Value": `${currencySymbol} ${totalLoanPayment}`
            }
        ];
        const style = [
            {
                "col": 'A1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'B1',
                "style": EXCEL_STYLE.boldHeader
            },
            {
                "col": 'A7',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B7',
                "style": EXCEL_STYLE.boldColorRight
            },
            {
                "col": 'A9',
                "style": EXCEL_STYLE.boldColorHrVr
            },
            {
                "col": 'A13',
                "style": EXCEL_STYLE.boldColor
            },
            {
                "col": 'B13',
                "style": EXCEL_STYLE.boldColorRight
            }
        ]
        const cols = [{ width: 25 }, { width: 20 }];
        const merges = [
            { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } },
            { s: { r: 7, c: 0 }, e: { r: 7, c: 1 } },
            { s: { r: 8, c: 0 }, e: { r: 8, c: 1 } },
        ]
        downloadExcelHeader(postData, style, cols, merges, 'LoanPayment', 2, ['B'], false );
    }

    return (
        <Page title={t('loan-payment.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('loan-payment.go-back')}
                    </BackButtonStyle>
                    <Title title={t('loan-payment.calculator')} subtitle={t('loan-payment.subtitle')} />
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <HeaderDivStyle>
                                <PaymentTitle>{t('loan-payment.description')}</PaymentTitle>
                                <HeaderTableStyle>
                                    <tbody>
                                        <tr>
                                            <td>{t('loan-payment.loan-amount')}</td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    name="loan_amount"
                                                    value={loanAmount}
                                                    onBlur={(e)=>{
                                                        setLoanAmount(formatZeroValues(e.target.value))
                                                    }}
                                                    placeholder="0"
                                                    onChange={(e) => {
                                                        setLoanAmount(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('loan-payment.annual-interest')}</td>
                                            <BudgetPercentageInput>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="annual_rate"
                                                    value={annualRate}
                                                    onChange={handleChanged}
                                                />
                                                <span>%</span>
                                            </BudgetPercentageInput>
                                        </tr>
                                        <tr>
                                            <td>{t('loan-payment.term-in-months')}</td>
                                            <BudgetInput>
                                                <span style={{ padding:'5px' }}>{''}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="terms_month"
                                                    value={termsMonth}
                                                    onChange={handleChanged}
                                                />
                                            </BudgetInput>
                                        </tr>
                                        <tr>
                                            <td>{t('loan-payment.baloon-payment')}
                                                <LightTooltip title={
                                                    <>
                                                        <p>{t('loan-payment.due-amount')}</p>
                                                    </>
                                                }>
                                                    <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                                </LightTooltip>
                                            </td>
                                            <BudgetInput>
                                                <span>{currencySymbol}</span>
                                                <Input
                                                    type='text'
                                                    placeholder="0"
                                                    name="baloon_payment"
                                                    value={baloonPayment}
                                                    onBlur={(e)=>{
                                                        setBaloonPayment(formatZeroValues(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        setBaloonPayment(formatDollarValues(e.target.value));
                                                    }}
                                                />
                                            </BudgetInput>
                                        </tr>
                                    </tbody>
                                </HeaderTableStyle>
                            </HeaderDivStyle>
                            <TableStyle>
                                <tbody>
                                    <tr>
                                        <TopSideBar>{t('loan-payment.monthly-loan-payment')}</TopSideBar>
                                        <TotalFlowValueColumn><TotalAmountDiv>{currencySymbol}<span> {loanMonthlyRepayment==="NaN" || loanMonthlyRepayment==="∞"?'0.00':loanMonthlyRepayment}</span></TotalAmountDiv></TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                                <ExtraSpacing>
                                    <tr>
                                        <td>{''}</td>
                                    </tr>
                                </ExtraSpacing>
                                <tbody>
                                    <tr>
                                        <TotalFlowHeader>{t('loan-payment.payment-summary')}</TotalFlowHeader>
                                        <td>{''}</td>
                                        <td>{''}</td>
                                    </tr>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td>{t('loan-payment.principal-repayments')}</td>
                                        <MidSideColumn>{currencySymbol} {principalRepayment}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('loan-payment.baloon-repayment')}</td>
                                        <MidSideColumn>{currencySymbol} {baloonValue===""?0:baloonValue}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <td>{t('loan-payment.interest')}</td>
                                        <MidSideColumn>{currencySymbol} {interestAmount==="NaN"?0.00:interestAmount}</MidSideColumn>
                                    </tr>
                                    <tr>
                                        <TotalFlow>{t('loan-payment.total-payment')}</TotalFlow>
                                        <TotalFlowValueColumn><TotalAmountDiv>{currencySymbol}<span> {totalLoanPayment==="NaN"?0:totalLoanPayment}</span></TotalAmountDiv></TotalFlowValueColumn>
                                    </tr>
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                    <ButtonText>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="outlined"
                            onClick={() => navigate('/calculators')}
                        >
                            {t('loan-payment.back')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={resetFields}
                        >
                            {t('loan-payment.reset')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="outlined"
                            onClick={saveLoanData}
                        >
                            {t('loan-payment.save')}
                        </LoadingButton>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={exportExcel}
                        >
                            {t('loan-payment.export')}
                        </LoadingButton>
                    </ButtonText>
                    <BackButtonStyle variant="contained" onClick={() => navigate('/calculators')}>
                        {t('loan-payment.go-back')}
                    </BackButtonStyle>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}
