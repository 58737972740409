import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import HeadingStyle from '../../../components/HeadingStyle';
import { BannerImg, Headline, HomeContentSection, ListItem, SubPoints, SubText, TrialButton } from './styled-components';
import { getWhyNotFreeData } from './constant';

export default function HomeContent() {
    const { t } = useTranslation();
    const notFreeData = getWhyNotFreeData(t);
    return (
        <HomeContentSection py={2}>
            <BannerImg alt="homeInfo" src="/static/homeImg/HomeContentImg.png" />
            <>
                <Grid justifyContent="flex-start" alignItems="center" container spacing={2} py={4}>
                    <Grid item md={7} xs={12} />
                    <Grid item md={4} xs={12} mt={5} mx={3}>
                        <HeadingStyle
                            headLineOne={t('home-page.not-free-heading-1')}
                            headLineTwo={t('home-page.not-free-heading-2')}
                            headLineThree={t('home-page.not-free-heading-3')}
                        />
                        <Headline paragraph variant="subtitle1">
                            {t('home-page.not-free-subtitle-1')}
                        </Headline>
                        <ListItem>
                            {notFreeData.map((point) => (
                                <li key={point.id}>
                                    <SubPoints gutterBottom variant="subtitle1">
                                        {point.text}
                                    </SubPoints>
                                </li>
                            ))}
                        </ListItem>
                        <SubText variant="subtitle1">
                            {t('home-page.not-free-subtitle-2')}
                        </SubText>
                        <TrialButton>
                            <LoadingButton size="large" type="submit" variant="contained" href="/register">
                                {t('home-page.start-30-days-trial')}
                            </LoadingButton>
                        </TrialButton>
                    </Grid>
                </Grid>
            </>
        </HomeContentSection>
    );
}
