// @mui
import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import ReplyIcon from '@mui/icons-material/Reply';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Stack from '@mui/material/Stack';
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Badge } from '@mui/material';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { BackButtonStyle } from 'pages/calculators/styled-components';
import {
    PageTopRightBg,
    ContentStyle,
    Wrapper,
    ForumDivStyle,
    ForumTableStyle,
    ForumSubTopicsTd,
    ForumTopicsTd,
    ForumMediaFlag,
    ForumTagsTopicsTd,
    BootstrapTextarea,
} from './styled-components';
// components
import transform from '../../constants/strapiHelper';
import forum from '../../constants/services/forum';
import Title from '../../components/Title';
import Page from '../../components/Page';
import ForumReplies from './ForumReplies';
import { InputLabelStyle } from '../contact-us/styled-components';
import AutoCompleteForumSearch from './AutoCompleteForumSearch';

export default function ForumTopic() {
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [topicLiked, setTopicLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(0);
    const { topicId } = useParams();
    const [topic, setTopic] = useState([]);
    const [reply, setReply] = useState('');
    let addedById = '';

    const navigate = useNavigate();
    const { t } = useTranslation();

    if (sessionStorage.getItem('userData')) {
        const usr = JSON.parse(sessionStorage.getItem('userData'));
        if (usr.username) {
            addedById = usr.username;
        }
    }

    const setLikedTopic = (topicObj) => {
        const { likes } = topicObj;
        if (likes != null) {
            if (likes.includes(addedById)) {
                setTopicLiked(true);
            } else {
                setTopicLiked(false);
            }
            setLikeCount(likes.length);
        }
    };

    const addTopicLike = async () => {
        let newLikesList = [];
        const topicLikes = await forum.getTopicLikes(topic.id);
        let likesData = [];
        if (topicLikes.data) {
            topicLikes.data.forEach((topic) => {
                likesData = topic.attributes.likes;
            });
        }
        if (likesData != null) {
            if (likesData.includes(addedById)) {
                newLikesList = [...likesData];
                const index = newLikesList.indexOf(addedById);
                if (index > -1) {
                    newLikesList.splice(index, 1);
                }
                setTopicLiked(false);
            } else {
                newLikesList = [...likesData, addedById];
                setTopicLiked(true);
            }
        } else {
            newLikesList = [addedById];
            setTopicLiked(true);
        }

        const addTopicLikeMessage = {
            data: {
                likes: newLikesList,
            },
        };
        await forum.addTopicLike(topic.id, addTopicLikeMessage);
        setLikeCount(newLikesList.length);
    };

    const getTopic = async () => {
        let topicData = {};
        if (topicId) {
            topicData = await forum.getTopic(topicId);
        }
        let topicObj = {};
        if (topicData.data) {
            topicData.data.forEach((topic) => {
                topicObj = {
                    id: topic.id,
                    ...topic.attributes,
                };
            });
        }
        setTopic(topicObj);
        setLikedTopic(topicObj);
    };

    useEffect(() => {
        getTopic();
    }, [topicId]);

    const openReplyPopup = () => {
        setOpen(true);
    };

    const closeReplyPopup = () => {
        setOpen(false);
    };

    const handleReply = (event) => {
        setReply(event.target.value);
    };

    const handleReplySubmit = async () => {
        setOpen(false);
        if (reply && reply.length > 0) {
            const addReplyMessage = {
                data: {
                    content: reply,
                    forum_topic: {
                        id: topicId,
                    },
                    parent_reply: {
                        id: null,
                    },
                    username: addedById,
                    topicId,
                },
            };
            await forum.addReply(addReplyMessage);
            getTopic();
        }
    };

    return (
        <Page title={t('forumcategory.title')}>
            <Wrapper>
                <PageTopRightBg />
                <ContentStyle>
                    <BackButtonStyle variant="contained" onClick={() => navigate(-1)}>
                        {t('embedded-rate.go-back')}
                    </BackButtonStyle>
                    <Title title={t('forumcategory.title')} subtitle={t('forumcategory.discussions')} />
                    <div style={{ textAlign: 'center', paddingTop: 25, color: '#00773E', fontSize: '1.25rem', fontWeight: 700 }}>
                        {location?.state?.categoryName}
                    </div>
                    <Stack
                        direction="row-reverse"
                        justifyContent="center"
                        alignItems="flex-center"
                        spacing={3}
                        style={{ paddingTop: '20px' }}
                    >
                        <AutoCompleteForumSearch />
                    </Stack>
                    <Grid container spacing={4} pt={5} justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12}>
                            <ForumDivStyle>
                                <ForumTableStyle>
                                    {topic.id ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} style={{ paddingBottom: 0 }}>
                                                    <div
                                                        key={topic.id}
                                                        role="presentation"
                                                        style={{ display: 'flex' }}
                                                    >
                                                        {/* <ForumTopicsTd>{topic.title}</ForumTopicsTd> */}
                                                        <ForumTopicsTd>
                                                            {ReactHtmlParser(topic.description, {
                                                                transform: (node, index) => transform(node),
                                                            })}
                                                        </ForumTopicsTd>
                                                    </div>
                                                    {/* {
                                                        topic.tags?.length > 0 && <span style={{ fontWeight: 700, color: 'green' }}>{t('forumcategory.tags')} </span>
                                                    }
                                                    {
                                                        topic.tags?.map((tag, index) =>
                                                            (<ForumTagsTopicsTd key={tag} onClick={() => navigate(`/forum-topics/tag/${tag}`)}>
                                                                {index > 0 ? `, ${tag}` : tag} </ForumTagsTopicsTd>))
                                                    } */}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ verticalAlign: 'top', paddingTop: '0px' }}>
                                                    <Stack direction="column">
                                                        <Typography
                                                            style={{ fontWeight: '600', color: 'green' }}
                                                            variant="h7"
                                                        >
                                                            {format(new Date(topic.updatedAt), 'dd MMM yy')}
                                                        </Typography>
                                                        <Typography
                                                            style={{ fontWeight: '600' }}
                                                            variant="h7"
                                                        >
                                                            {topic.username}
                                                        </Typography>
                                                    </Stack>
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: 'top',
                                                        horizontalAlign: 'right',
                                                        paddingTop: '0px',
                                                    }}
                                                >
                                                    <Stack direction="row" spacing={4} justifyContent="end">
                                                        <ForumMediaFlag key={`AddTopicLike-${topic.id}`}>
                                                            <Badge
                                                                key={`BadgeTopicLike-${topic.id}`}
                                                                sx={{
                                                                    '& .MuiBadge-badge': {
                                                                        color: 'lightgreen',
                                                                        backgroundColor: 'green',
                                                                        position: 'absolute',
                                                                        top: '1px',
                                                                        right: '2px',
                                                                    },
                                                                }}
                                                                badgeContent={likeCount}
                                                            >
                                                                {topicLiked ? (
                                                                    <FavoriteIcon
                                                                        style={{ color: 'red', position: 'relative' }}
                                                                        onClick={addTopicLike}
                                                                        key={`FavoriteIconTopic-${topic.id}`}
                                                                    />
                                                                ) : (
                                                                    <FavoriteBorderIcon
                                                                        style={{ color: 'red' }}
                                                                        onClick={addTopicLike}
                                                                        key={`FavoriteBorderIconTopic-${topic.id}`}
                                                                    />
                                                                )}
                                                            </Badge>
                                                            <span>{t('forumcategory.like')}</span>
                                                        </ForumMediaFlag>
                                                        <ForumMediaFlag
                                                            onClick={openReplyPopup}
                                                            key={`TypographyReplyIconTopic-${topic.id}`}
                                                        >
                                                            <ReplyIcon key={`ReplyIconTopic-${topic.id}`} />
                                                            <span>{t('forumcategory.reply')}</span>
                                                        </ForumMediaFlag>
                                                    </Stack>
                                                </td>
                                            </tr>
                                            {topic.forum_replies
                                                ? topic.forum_replies.data
                                                    .filter((reply) => reply.attributes.parent_reply.data === null)
                                                    .map((reply) => (
                                                        <ForumReplies
                                                            style={{ background: 'white' }}
                                                            key={reply.id}
                                                            data={reply}
                                                            topicId={topic.id}
                                                            depth={1}
                                                            forumReplies={topic.forum_replies}
                                                            refreshTopic={getTopic}
                                                        />
                                                    ))
                                                : ''}
                                        </tbody>
                                    ) : (
                                        <tbody />
                                    )}
                                </ForumTableStyle>
                            </ForumDivStyle>
                        </Grid>
                        <div>
                            <Dialog open={open} onClose={closeReplyPopup} key={`DialogTopic-${topic.id}`}>
                                <DialogTitle>{t('forumcategory.addReply')}</DialogTitle>
                                <DialogContent style={{ paddingBottom: '0px' }}>
                                    <RHFTextFieldMsg
                                        label={t('forumcategory.addReply')}
                                        multiline={'true'}
                                        rows={10}
                                        name="addReplyField"
                                        onChange={handleReply}
                                        key={`RHFTextFieldMsgTopic-${topic.id}`}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeReplyPopup} key={`closeReplyPopupTopic-${topic.id}`}>
                                        {t('forumcategory.cancel')}
                                    </Button>
                                    <Button onClick={handleReplySubmit} key={`handleReplySubmitTopic-${topic.id}`}>
                                        {t('forumcategory.submit')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Grid>
                </ContentStyle>
            </Wrapper>
        </Page>
    );
}

const RHFTextFieldMsg = ({ name, multiline, rows, label, ...other }) => (
    <>
        <InputLabelStyle shrink htmlFor="bootstrap-input">
            {label}
        </InputLabelStyle>
        <BootstrapTextarea multiline={multiline} rows={rows} cols={100} name={name} fullWidth {...other} />
    </>
);
